import { useLocation, useNavigate, useParams, useMatch, matchRoutes, useSearchParams } from 'react-router-dom';

export const withRouter = (Component, pMatch) => {
    const Wrapper = (props) => {
        let location = useLocation();
        let navigate = useNavigate();
        let search = useSearchParams();
        let params = useParams();
        let match = undefined;
        if (pMatch != undefined && pMatch.length > 1) {
            match = matchRoutes(pMatch, location)
            if (match.length > 0) match = match[0];
        } else if (pMatch != undefined) {
            match = useMatch(pMatch[0].path);
            
        }
        
        return (
            <Component
                navigate={navigate}
                location={location}
                params={params}
                match={match}
                search={search}
                {...props}
            />
        );
    };

    return Wrapper;
};
