import { PureComponent } from "react";
import AuthenticationContext, { AuthContextType } from "../../../../../Authentication/types/AuthContextType"
import { withTranslation } from "react-i18next";
import { InstallationUsersContainerProps, InstallationUsersContainerState } from "../types/InstallationUsersTypes";
import InstallationUsersView from "../view/InstallationUsersView";
import Moment from "react-moment";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UserHttpClient from '../../../../../HttpClient/HttpClient';
import InstallationsHttpClient from '../../../../../HttpClient/InstallationsHttpClient';
import { manageInfoUser } from '../../../../Common/utils/helpers';
import { t } from "i18next";

class InstallationUsersContainer extends PureComponent<InstallationUsersContainerProps, InstallationUsersContainerState> {
    static contextType = AuthenticationContext;
    id: number;
    me

    constructor(props: InstallationUsersContainerProps) {
      super(props);
      this.state = {
        page: 1,
        sizePerPage: 5,
        status: '',
        openConfirmDelete: false,
        showForm: false,
        showDelete: false,
        message: null,
        userInstallations: null,
        userId: null,
        parent: null,
        mail: null,
        role: null
      }

      this.id = parseInt(window.location.pathname.split('/')[2]);
    }

    async componentWillMount() {
        this.getInstallationUsers();
    
        if (this.props.data.parentId != undefined) {
          this.getParentSite();
        }
    
        let httpUser = new UserHttpClient(this.context);
        this.me = await this.getMe();
      }

      async getMe() {
        const userBasicData = await manageInfoUser(this.context as AuthContextType, "userBasicData");
        if (userBasicData){
          return userBasicData;
        }else {
          this.renderMessage('error', t('messages.errorGettingUser'));
        }
      }

      renderMessage = (type: string, content: string) => {
        this.setState({
          message: {
            type: type,
            content: content,
          },
        });
      };

      async getInstallationUsers() {
        let http = new InstallationsHttpClient(this.context, this.id);
        let response = await http.InstallationGetInstallationsById();
        if (response.status == 200) {
          let data = response.data;
          this.setState({ userId: data.content.userId });
          this.setState({ userInstallations: data.content.userInstallations });
        }
      }
    
      async getParentSite() {
        let httpParent = new InstallationsHttpClient(this.context, this.props.data.parentId);
        let response = await httpParent.InstallationGetInstallationsById();
        if (response.status == 200) {
          let data = response.data;
          this.setState({ parent: data.content });
          console.log(JSON.stringify(data.content));
        }
      }

    getTableColumns(){
        return [
            {
              dataField: 'userId',
              text: t('tables.userId'),
              align: 'left',
              sort: true,
              hidden: true,
              headerStyle: () => {
                return { width: '0%' };
              },
            },
            {
              dataField: 'userMail',
              text: t('invitation.mail'),
              align: 'left',
              sort: true,
              formatter: this.userFormatter.bind(this),
              headerStyle: () => {
                return { width: '26%' };
              },
            },
            {
              dataField: 'role',
              text: t('invitation.role'),
              align: 'left',
              sort: true,
              headerStyle: () => {
                return { width: '18%' };
              },
            },
            {
              dataField: 'invited',
              text: t('invitation.invited'),
              align: 'left',
              sort: true,
              formatter: this.dateFormatterInvited.bind(this),
              headerStyle: () => {
                return { width: '18%' };
              },
            },
            {
              dataField: 'signUp',
              text: t('general.signUp'),
              align: 'left',
              sort: true,
              formatter: this.dateFormatter.bind(this),
              headerStyle: () => {
                return { width: '18%' };
              },
            },
            {
              dataField: 'DeleteFormatter',
              text: 'Actions',
              align: 'left',
              hidden: false,
              formatter: this.deleteFormatter.bind(this),
              headerStyle: () => {
                return { width: '10%' };
              },
            },
          ];
    }

    userFormatter({ row, value }) {
        if (
          (this.props.data.hasOwnerAccess || this.state.parent?.hasOwnerAccess) &&
          this.me?.userId.toString() == row.userId.toString()
        ) {
          return (
            <span>
              <img
                src='https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/key.svg'
                style={{ height: '15px', marginRight: '5px' }}
              />{' '}
              {row.userMail}{' '}
            </span>
          );
        } else {
          return <span style={{ marginLeft: '20px' }}>{row.userMail}</span>;
        }
      }

      deleteFormatter({ row, value }) {
        const onClick = (evt) => {
          this.setState({ showDelete: true, userId: row.userId });
        };
    
        var idEle = 'btnDelete_' + row.userId;
    
        if (
          (this.props.data.hasOwnerAccess || this.state.parent?.hasOwnerAccess) &&
          this.me?.userId.toString() != row.userId.toString()
        ) {
          if (this.state.parent?.userId == row.userId) {
            return null;
          } else {
            return (
              <DeleteOutlineIcon
                id={idEle}
                style={{ width: '24px', cursor: 'pointer' }}
                onClick={(evt) => onClick(evt)}
                data-user={row.userId}
              />
            );
          }
        } else if (
          !this.props.data.hasOwnerAccess &&
          !this.state.parent?.hasOwnerAccess &&
          this.me?.userId.toString() == row.userId.toString()
        ) {
          return (
            <DeleteOutlineIcon
              id={idEle}
              style={{ width: '24px', cursor: 'pointer' }}
              onClick={(evt) => onClick(evt)}
              data-user={row.userId}
            />
          );
        } else {
          return null;
        }
      }

      dateFormatterInvited({ row, value }) {
        var id = 'custom-switch_' + row.id;
    
        if (row.invited == null) {
          return <span></span>;
        } else if (row.invited !== null && row.invited.startsWith('0001-01-01')) {
          return <span></span>;
        } else {
          return (
            <Moment format='DD/MM/YYYY' withTitle locale='es'>
              {value}
            </Moment>
          );
        }
      }
      dateFormatter({ row, value }) {
        var id = 'custom-switch_' + row.id;
    
        return (
          <Moment format='DD/MM/YYYY' withTitle locale='es'>
            {value}
          </Moment>
        );
      }
  
      handleClose() {
        this.getInstallationUsers();
        this.setState({ showForm: false });
      };
      handleCloseDelete() {
        this.setState({ showDelete: false });
      };

      
  async confirmDelete(t) {
    await this.deleteUserInstallation(this.id, this.state.userId, t);
  }

  async deleteUserInstallation(installationId, userId, t) {
    if (this.state.userId!.toString() == this.me?.userId) {
      let http = new InstallationsHttpClient(this.context, this.id);
      let data = await http.deleteUserInstallations(this.id, this.state.userId);
      //this.props.navigate("/filter/installations");
    } else {
      let http = new InstallationsHttpClient(this.context, this.id);
      let data = await http.deleteUserInstallations(this.id, this.state.userId);
      let messageObj: {message: string, type: string} = {message: "", type: ""};
      if (data.content != undefined && data.content) {
        messageObj.message = t('installationspages.userinstallation.deleteUserInstallationSuccess');
        messageObj.type = 'success';
      } else if (data.status != undefined) {
        if (data.status > 200) {
          messageObj.message = data.title == undefined ? t('installationspages.userinstallation.forbid') : data.title;
          messageObj.type = 'error';
        }
      } else if (data.errors != undefined) {
        messageObj.message = data.title == undefined ? t('installationspages.userinstallation.forbid') : data.errors[0];
        messageObj.type = 'error';
      } else if (data.startsWith('System.InvalidOperation')) {
        messageObj.message = data.title == undefined ? t('installationspages.userinstallation.forbid') : data.title;
        messageObj.type = 'error';
      }

      this.setState({ showDelete: false, userInstallations: null }, () => {
        this.getInstallationUsers();
      });
      if (messageObj.type) {
        this.renderMessage(messageObj.type, messageObj.message);
      }
    }
  }

  handleDialogClose = () => {
    this.setState({ message: null });
  };

  isInvitationsOutboxVisible(){
    if (this.props.data.hasOwnerAccess || this.state.parent?.hasOwnerAccess) {
      return true
    }

    return false;
  }
  
    render() {
      return (
        <>
          <InstallationUsersView
            userInstallations={this.state.userInstallations}
            invitationsOutboxVisible={this.isInvitationsOutboxVisible()}
            message={this.state.message}
            handleClose={this.handleClose.bind(this)}
            handleCloseDelete={this.handleCloseDelete.bind(this)}
            showDelete={this.state.showDelete}
            confirmDelete={this.confirmDelete.bind(this)}
            showForm={this.state.showForm}
            id={this.id}
            role ={this.state.role}
            userId = {this.state.userId}
            mail = {this.state.mail}
            data = {this.props.data}
            columns = {this.getTableColumns()}
            page = {this.state.page}
            sizePerPage = {this.state.sizePerPage}
            handleDialogClose={this.handleDialogClose}
          ></InstallationUsersView>
        </>
      );
    }
  }
  
  export default InstallationUsersContainer;