import React, { Component } from 'react';
import { Navigate, Link } from 'react-router-dom';
import AlertHttpClient from "../../HttpClient/AlertHttpClient";
import './Sites.css';
import AuthenticationContext from "../../Authentication/types/AuthContextType";
import SitesMap from "./SitesMap";
import InstallationsSummaryEdit from "../InstallationComponents/Main/InstallationsSummaryEdit";
import SiteReportingModal from "./Main/SiteReportingModal";
import { withTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import BreadCrumb from "../BreadCrumb";
import SitesNotificationList from "./SitesNotificationList";
import moment from 'moment';
import Moment from 'react-moment';
import Message from '../Message/Message';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import BusinessIcon from '@mui/icons-material/Business';



class SitesList extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.state = {
            mySites: this.props.mySites,
            show: false,
            showReporting: false,
            notificationAlerts: this.props.notificationAlerts,
            notificationAlertsList: null,
            message: null
        };

    }

    async componentDidMount() {
        window.scrollTo({ top: 0 });
        this.addStyleToParents();   
    }

    renderMessage = (type, content) => {
        this.setState({
          message: {
            type: type,
            content: content,
          }
        });
      };
    
      handleDialogClose = () => {
        this.setState({ message: null });
      };
    async getNotificationAlerts(sites) {
        let notifications = [];

        for (const site of sites) {
            this.httpAlerts = new AlertHttpClient(this.context, site.id, null, null, null, 0, 10);
            let data = await this.httpAlerts.GetProducedIndividualRulesTotalsBySite();
            let dataList = await this.httpAlerts.GetProducedIndividualRulesBySite({all: true});
            if (data.content.length > 0) {
                let list = null;
                if (dataList.content.length > 0) {
                    list = dataList.content;
                }
                notifications.push({ id: site.id, alerts: data.content, alertslist: list, name: site.name });
            }
                
        }

        this.setState({ notificationAlerts: notifications });
    }

    componentDidUpdate(prevProps) {
        this.addStyleToParents();
    }

    async getMySites() {
        if (typeof this.props.handleUpdate === 'function') {
            await this.props.handleUpdate();
        }
        //this.setState({ mySites: null });
        //var data = await this.httpSites.GetSites();
        //this.setState({ mySites: data.content });

        //this.getNotificationAlerts(data.content);
    }

    goSite(id) {
        let url = '/site/' + id
        return <Navigate to={url} />
    }

    handleClick() {
        this.setState({ show: !this.state.show });
    }
    handleClickReporting() {
        this.setState({ showReporting: !this.state.showReporting });
    }

    addStyleToParents() {

        let tree = document.querySelectorAll(".sites-list .site-card");
        tree.forEach((treeItem) => {
            treeItem.classList.forEach((c) => {
                if (c.startsWith("prdEventId")) {
                    let vClass = "." + c;
                    treeItem.addEventListener("mouseenter", function (event) {
                        let vmap = document.querySelector(".custom-map");
                        if (vmap == null) { return null; }
                        let m = vmap.querySelector(vClass);
                        if (m != null) {
                            m.parentElement.classList.add("zIndexMax");
                            m.classList.add("activeAnimation");
                        }
                    }, false);
                    treeItem.addEventListener("mouseleave", function (event) {
                        let vmap = document.querySelector(".custom-map");
                        if (vmap == null) { return null; }
                        let m = vmap.querySelector(vClass);
                        if (m != null) {
                            m.parentElement.classList.remove("zIndexMax");
                            m.classList.remove("activeAnimation");
                        }
                    }, false);

                }
            });
        });

    }

    getNotifications(id, html = true) {
        let val = null;
        let types = ['alarm', 'rule', 'warning'];

        if (this.state.notificationAlerts.length > 0) 
            val = this.state.notificationAlerts.find(e => e.id == id);

        if (val == null || val == undefined) {
            return (!html ? null : '');
        }
        if (val.length <= 0) {
            return (!html ? null : '');
        }
        if (!html) {
            return val;
        }
        let content = [];
        let typesId = 0;
        types.map(function (elem) {

            let obj = val.alerts.find(e => e.type == elem);
            obj = obj == undefined ? '' : <div key={id+'-'+typesId} className={elem}>{obj.total}</div>;
            typesId++;
            content.push(obj); 
        });

        return (<div key={id} className="notificationAlarms">
                    {content}
                </div>);
    }

    render() {

        const { t } = this.props

        const getAddress = (item) => {

            let address = ""

            if (item.postalCode && item.postalCode != "") {
                address += item.postalCode
            }
            if (item.city && item.city != "") {
                if (address.length > 1) address += ", "
                address += item.city
            }
            if (item.country && item.country != "") {
                if (address.length > 1) address += ", "
                address += item.country
            }

            return address
        }

        const handleCloseReporting = (res) => {

            this.setState({ showReporting: false });

            if (res && res.id) {
                this.renderMessage('success', t('actions.success'));
            }
            
        };

        const handleClose = (res) => {

            this.setState({ show: false });

            if (res && res.id) {
                this.renderMessage('success', t('actions.success'));
            }

            this.getMySites();
        };

        let locations = []
        let dataLoaded = this.state.mySites != null;
        let alertsLoaded = this.state.notificationAlerts != null && this.props.notificationAlerts != undefined;
        let autoZoom = false

        if (dataLoaded) {
            this.state.mySites.forEach((site) => { site.name = site.name ?? " " })
            this.state.mySites = this.state.mySites.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
        }

        if (dataLoaded && alertsLoaded) {

            autoZoom = true

            this.state.mySites.forEach((site) => {
                if (site.longitude && site.latitude)
                    locations.push({ id:site.id, name: site.name, address: site.address, location: [site.longitude, site.latitude], notifications: this.getNotifications(site.id,false) })
            })
        }
      

        let img = "";
        if (this.context.brandCode == 11) {
            img = "https://strgpiotcoredev.blob.core.windows.net/web-html/laufen/DefaultSite.jpg";
        } else {
            img = "https://strgpiotcoredev.blob.core.windows.net/web-html/roca/DefaultSite.jpg";
        }

        return (
            <div className="row" style={{ }}>
                {dataLoaded ? (
                    <React.Fragment>
                    <BreadCrumb empty={true}></BreadCrumb>
                    {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}
                <Dialog
                    classes={{ paper: "installationModal" }}
                    disableEscapeKeyDown={false}
                    open={this.state.show}
                    onClose={handleClose}>
                    <DialogTitle>New Site</DialogTitle>
                    <DialogContent>
                        <InstallationsSummaryEdit type="site" createSite={true} installation={{}} id={0} onSaveClose={handleClose} mode="add" showdelete={false}></InstallationsSummaryEdit>
                    </DialogContent>
                </Dialog>

                <Dialog
                    classes={{ paper: "sitesReportingModal" }}
                    disableEscapeKeyDown={false}
                    open={this.state.showReporting}
                    onClose={handleCloseReporting}
                    fullWidth={true}
                    maxWidth={"md"}>
                    <DialogTitle>Reporting</DialogTitle>
                    <DialogContent>
                        <SiteReportingModal></SiteReportingModal>
                    </DialogContent>
                </Dialog>
                    <div className="container mt-0">
                        <div className="col p-0">

                            <div className="row d-none">
                                <div className="col-12">
                                    <Button className="primary float-right" style={{ marginRight: "35px" }} onClick={() => this.handleClick()}> {t('actions.addname', { name: t('site')}) }</Button>
                                </div>
                            </div>
                            <div className="row w-100" style={{margin:'0 auto'}}>
                                <div className="col-12 col-lg-4 sites-list" style={{ overflowY: "auto", alignContent: "flex-start" }}>
                                    <div className="row pr-lg-3">
                                        <Button className="primary add-sites"  onClick={() => this.handleClick()}> {t('actions.addname', { name: t('site') })}</Button>
                                        {this.state.mySites.map((item, i) => (
                                     
                                            <Link key={i} className={"col-12 mb-5 site-card noUnderLine prdEventId-" + item.id} tag={Link} to={`/site/${item.id}`}>
                                                <div className="row h-100" >
                                                    <div className="col-4 h-100" style={{display:"flex"}}>
                                                    {item.avatarUri == null ? (
                                                            <div className="w-100 h-100 site-image"
                                                            style={{ backgroundImage: 'url("' + img + '")', backgroundSize: "cover" }}>
                                                        </div>
                                                    ) : (
                                                            <div className="w-100 h-100 site-image"
                                                                style={{ backgroundImage: 'url("' + item.avatarUri + '")', backgroundSize: "cover"}}>
                                                            </div>)
                                                    }
                                                    </div>
                                                    <div className="col-8 pl-4">
                                                        <div className="row h-100">
                                                    
                                                            <div className="w-100">
                                                                <div className="d-flex w-100">
                                                                    <h4 style={{ marginRight: "5px" }}> {item.name} </h4>
                                                                    {alertsLoaded ? (this.getNotifications(item.id)) : (null)}
                                                                   
                                                                </div>
                                                       
                                                                <span className="t2 w-100">{item.address} </span>
                                                            </div>
                                                            <div className="row w-100 pb-2 m-0" style={{alignItems:"end"}}>
                                                                <div className="col-3 sites-icons">
                                                                    <img src={require('../../assets/icons/11_00.31_black.svg').default} style={{ height: '13px', marginTop: "1px", marginLeft: '5px'}} />
                                                                    <span className="t3" style={{marginLeft: '4px'}}>{item.aggregatedInfo.gatewayDevices}</span>
                                                                </div>
                                                                <div className="col-3 sites-icons">
                                                                    <BusinessIcon style={{ height: '12px', marginTop: "1px"}} />
                                                                    <span className="t3">{item.aggregatedInfo.totalInstallations}</span>
                                                                </div>
                                                                <div className="col-3 sites-icons">
                                                                    <PersonOutlineIcon style={{ height: '12px', marginTop: "1px"}} />
                                                                    <span className="t3">{item.aggregatedInfo.totalUsers}</span>
                                                                </div>
                                                                <div className="col-3 sites-icons">
                                                                    <img src={require('../../assets/icons/11_00.03_black.svg').default} style={{ height: '13px', marginTop: "1px", marginRight: "5px" }} />
                                                                    <span className="t3">{item.aggregatedInfo.totalDevices}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                
                                                    </div>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-12 col-lg-8 w-100 custom-map " style={{ padding: 0 }}>

                                    <div style={{ minWidth: '100%', height: 'calc(100vh - 230px)', minHeight:'550px'  }}>
                                            {alertsLoaded ? <SitesMap locations={locations} autoZoom={autoZoom} /> : (<div className="loadingParent"><div className="loadingImg"></div></div>)}
                                        
                                    </div>
                                    

                                        {alertsLoaded ? <SitesNotificationList notifications={this.state.notificationAlerts} /> : null}


                                </div>
                            </div>
                        </div>
                        </div>
                        </React.Fragment>
                ) : (
                    <div className="loadingParent"><div className="loadingImg"></div></div>
                )}
            </div>
        );
    }
}
export default withTranslation()(SitesList);