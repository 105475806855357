import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { t } from 'i18next';
import { ModalAddPackageStep0ViewProps } from '../types/ModalAddPackageTypes';

const ModalAddPackageStep0View = (props: ModalAddPackageStep0ViewProps) => (
  <div className='ModalAddPackageStep0View'>
    <div className='prodChildNoZoom p-5'>
      <h2>{t('firmwareManagementPage.createNewHardwarePackage')}</h2>
      <div className='row'>
        <div className='col'>
          <h3>{t('firmwareManagementPage.selectType')}</h3>
          <FormControl>
            <RadioGroup
              aria-labelledby='demo-controlled-radio-buttons-group'
              name='controlled-radio-buttons-group'
              value={props.typePackage}
            >
              <FormControlLabel
                value='systemId'
                control={<Radio required />}
                label={t('firmwareManagementPage.systemId')}
                checked
              />
              <FormControlLabel
                value='smartComponentTypeId'
                control={<Radio required />}
                label={t('firmwareManagementPage.smartComponentTypeId')}
                disabled
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </div>
    <div className='buttons row'>
      <div className='col'>
        <button
          onClick={() => props.handleShowAddPackageModal(false)}
          className='MuiButtonBase-root MuiButton-root MuiButton-text primary pull-left float-left'
          type='button'
        >
          <span className='MuiButton-label'>{t('actions.cancel')}</span>
          <span className='MuiTouchRipple-root'></span>
        </button>

        <button
          onClick={() => props.handleStep('next')}
          className='MuiButtonBase-root MuiButton-root MuiButton-text primary pull-right float-right'         
          disabled={!props.typePackage}
        >
          <span className='MuiButton-label'>{t('actions.next')}</span>
          <span className='MuiTouchRipple-root'></span>
        </button>
      </div>
    </div>
  </div>
);

export default ModalAddPackageStep0View;
