import { PureComponent } from "react";
import AuthenticationContext from "../../../Authentication/types/AuthContextType";
import { withTranslation, WithTranslation } from "react-i18next";
import ChartRepository from "../utils/chartRepository";
import {
  Chart,
  KPIsChartContainerProps,
  KPIsChartContainerState,
  Systems,
} from "../types/ChartsComponents";
import {
  calculateDates,
  configurateOptions,
  getIdFromUrl,
  mapperStackedFromChart,
  setFilter,
} from "../utils/chartComponentsUtils";
import _ from "lodash";
import Style from "../styles/KPIsChart.module.scss";
import GroupChartContainer from "./groups/GroupChartContainer";
import GroupGeneralSitesChartContainer from "./groups/GroupGeneralSitesChartContainer";
import BarChartContainer from "./charts/BarChartContainer";
import BarChartProductContainer from "./charts/BarChartProductContainer";
import GaugeChartContainer from "./charts/GaugeChartContainer";
import HeatmapChartContainer from "./charts/HeatmapChartContainer";
import LastValueChartContainer from "./charts/LastValueChartContainer";
import LastValueChartProductContainer from "./charts/LastValueChartProductContainer";
import PieChartContainer from "./charts/PieChartContainer";
import SankeyChartContainer from "./charts/SankeyChartContainer";
import React from "react";
import { ChartViewZone } from "../types/ChartViewZone";
import HeatMapChartSkeleton from "../skeleton/HeatMapChartSkeleton";
import BarChartSkeleton from "../skeleton/BarChartSkeleton";
import LastValueSkeleton from "../skeleton/LastValueSkeleton";

export class KPIsChartContainer extends PureComponent<
  WithTranslation & KPIsChartContainerProps,
  KPIsChartContainerState
> {
  static contextType = AuthenticationContext;
  private chartRepository: ChartRepository;

  constructor(props: WithTranslation & KPIsChartContainerProps) {
    super(props);
    this.chartRepository = new ChartRepository(this);
    this.state = {
      language: this.props.i18n.language,
      dataLoaded: false,
      currentLoading: 1,
      chartsByGroup: [],
      // allCharts: [],
      allChartsStatus: {},
      timeZoneOffset: this.getTimeZone(),
      id: getIdFromUrl(),
      allChartsRendered: false,
    };
  }

  async componentDidMount() {
    if (
      this.props.chartViewZones &&
      this.props.chartViewZones.content &&
      !this.state.dataLoaded
    ) {
      // Primera carga, recuperar datos
      await this.loadData();
    }
  }

  async componentDidUpdate(
    prevProps: Readonly<
      WithTranslation<"translation", undefined> & KPIsChartContainerProps
    >
  ) {
    // si cambia el rango de fechas o los chartsViewZones, recargar datos
    if (
      prevProps.groupBySelected !== this.props.groupBySelected ||
      prevProps.dateRange !== this.props.dateRange
    ) {
      this.setState(
        {
          dataLoaded: false,
          chartsByGroup: [],
          currentLoading: this.state.currentLoading + 1,
        },
        async () => {
          await this.loadData();
        }
      );
    }
  }

  async loadData() {
    try {
      const selectionedSmartComponentTypesIdFilters: number[] =
        await this.getSelectionedSmartComponentTypesIdFilters();
      let itemChartViewZone: ChartViewZone =
        this.props.chartViewZones.content.find((item: ChartViewZone) => item)!;
      const chartsByGroup: Chart[] = [];
      let kpisProcessed: string[] = [];
      // let allCharts: Chart[] = [];
      let allChartsStatus = {};
      var currentLoading = this.state.currentLoading;

      if (selectionedSmartComponentTypesIdFilters.length) {
        let previousGroupName = null;
        let currentGroup: any = {};

        // iterate over the smartComponentTypesIdFilters
        for (let smartComponentTypeId of selectionedSmartComponentTypesIdFilters) {
          let objSmartComponentTypeId =
            itemChartViewZone.smartComponentTypes.find(
              (item) => item.id == smartComponentTypeId
            );

          // select SmartComponentType by Id
          if (objSmartComponentTypeId) {
            let zone = objSmartComponentTypeId.zones?.find(
              (item) => item.pageZonePosition === this.props.pageZonePosition
            );

            // select zone by pageZonePosition
            if (zone) {
              if (zone.elements.length) {
                // iterate over the elements of the zone
                for (let element of zone.elements) {
                  // assign element to chart
                  let chart: any = this.assignElementToChart(
                    element,
                    objSmartComponentTypeId
                  );

                  const kpiExistsInArray = kpisProcessed.some(
                    (kpi) => kpi === chart.kpiNameChartType
                  );
                  if (!kpiExistsInArray) {
                    kpisProcessed.push(chart.kpiNameChartType);
                    // chart.dataPoints = await this.chartRepository.manageChartType(chart);

                    // Compare with the previous group's name
                    if (previousGroupName === chart.title) {
                      // If the name is the same, add to the existing group
                      // const legend = this.manageLegend(chart, currentGroup.legend);
                      currentGroup.charts.push(chart);
                      // currentGroup.legend = legend;
                    } else {
                      // const legend = this.manageLegend(chart);
                      currentGroup = { charts: [chart] };
                      // currentGroup = { charts: [chart], legend };
                      chartsByGroup.push(currentGroup); // Add new group to array
                      previousGroupName = chart.title; // Refresh name of the previous group
                    }
                    // allCharts.push(chart);
                    allChartsStatus[chart.kpiNameChartType] = false;
                  }
                }
              }
            }
          }
        }
      }

      /* This is to check if, while loading data, user might have changed dates or filter (day, week, month...) */
      if (currentLoading && currentLoading === this.state.currentLoading) {
        if (
          chartsByGroup.length === 0 &&
          Object.keys(allChartsStatus).length === 0
        ) {
          this.handlerChartRendered(true);
          this.setState({ dataLoaded: true, allChartsRendered: true });
        }
        if (
          chartsByGroup.length !== (this.state.chartsByGroup as any[]).length
        ) {
          const customOrderChartsByGroup = this.orderByChartType(chartsByGroup);
          this.setState({
            dataLoaded: true,
            chartsByGroup: customOrderChartsByGroup,
            allChartsStatus,
          });
        }
      }
    } catch (err) {
      console.log(err);
      this.handlerChartRendered(true);
      this.setState({ dataLoaded: true, allChartsRendered: true });
    }
    // dataLoaded true to remove spinner
    this.setState({ dataLoaded: true });
  }

  handlerChartRendered = (allChartsRendered: boolean) => {
    if (allChartsRendered && this.props.componentFinished) {
      const idChartContainer = `KPIsChartContainer_${this.props.pageZonePosition}`;
      this.props.componentFinished(idChartContainer);
    }
  };

  chartFinished = (componentName: string) => {
    this.setState((prevState) => {
      const allChartsStatus = { ...prevState.allChartsStatus };
      allChartsStatus[componentName] = true;
      const allChildComponentsRendered = Object.values(allChartsStatus).every(
        (value) => value === true
      );
      const allChartsRendered = allChildComponentsRendered
        ? true
        : prevState.allChartsRendered;
      this.handlerChartRendered(allChartsRendered);
      return {
        ...prevState,
        allChartsStatus,
        allChildComponentsRendered: allChildComponentsRendered,
        allChartsRendered: allChartsRendered,
      };
    });
  };

  getChartComponentByType = (chart: Chart) => {
    const childrenProps = {
      chart,
      chartRepository: this.chartRepository,
      language: this.state.language,
      context: this.context,
      chartFinished: this.chartFinished.bind(this),
    };
    switch (chart.chartType) {
      case "pie":
        return <PieChartContainer {...childrenProps} />;
      case "gauge":
        return <GaugeChartContainer {...childrenProps} />;
      case "bar_echarts":
        if (this.props.page === "product") {
          return <BarChartProductContainer {...childrenProps} />;
        } else {
          return <BarChartContainer {...childrenProps} />;
        }
      case "sankey":
        return <SankeyChartContainer {...childrenProps} />;
      case "heatmap_echarts":
        return <HeatmapChartContainer {...childrenProps} />;
      case "lastvalue":
        if (this.props.page === "product") {
          return <LastValueChartProductContainer {...childrenProps} />;
        } else {
          return <LastValueChartContainer {...childrenProps} />;
        }
      default:
        <p>{`Tipo de gráfico no soportado: ${chart.chartType}`}</p>;
        break;
    }
  };

  orderByChartType = (chartsByGroup: any[]): any[] => {
    if (this.props.site !== "all") return chartsByGroup;
    return chartsByGroup.map((group) => {
      return {
        ...group,
        charts: group.charts.sort((a, b) => {
          const chartTypeWeights = {
            pie: 1,
            gauge: 2,
          };
          const weightA = chartTypeWeights[a.chartType] || 0;
          const weightB = chartTypeWeights[b.chartType] || 0;
          return weightA - weightB;
        }),
      };
    });
  };

  async getSelectionedFilters(product: any, systems: any, aggregateBy: any) {
    let selectionedFilters = [];
    if (aggregateBy && aggregateBy === "family") {
      if (
        this.props.filter?.familiesSelected &&
        this.props.filter.familiesSelected.indexOf(
          product.productType.letter
        ) >= 0
      ) {
        let sComponentTypeIds = await this.returnSmartComponentTypeIds(
          product,
          systems
        );
        selectionedFilters = selectionedFilters.concat(sComponentTypeIds);
      }
    } else if (aggregateBy && aggregateBy === "productType") {
      if (
        this.props.filter?.productTypesSelected &&
        this.props.filter.productTypesSelected.indexOf(product.productTypeId) >=
          0
      ) {
        let sComponentTypeIds = await this.returnSmartComponentTypeIds(
          product,
          systems
        );
        selectionedFilters = selectionedFilters.concat(sComponentTypeIds);
      }
    } else if (aggregateBy && aggregateBy === "product") {
      if (
        this.props.filter?.productsSelected &&
        this.props.filter.productsSelected.indexOf(product.id) >= 0
      ) {
        let sComponentTypeIds = await this.returnSmartComponentTypeIds(
          product,
          systems
        );
        selectionedFilters = selectionedFilters.concat(sComponentTypeIds);
      }
    } else if (aggregateBy === undefined) {
      let sComponentTypeIds = await this.returnSmartComponentTypeIds(
        product,
        systems
      );
      selectionedFilters = selectionedFilters.concat(sComponentTypeIds);
    }
    return selectionedFilters;
  }

  getSelectionedSmartComponentTypesIdFilters = async () => {
    const { product, installation, site, aggregateBy } = this.props;
    const systems: Systems =
      (await this.chartRepository.getSystems()).content ?? [];
    let selectionedSmartComponentTypesIdFilters = [];

    if (product && product.content) {
      selectionedSmartComponentTypesIdFilters =
        await this.getSelectionedFilters(product.content, systems, aggregateBy);
    } else if (installation?.products) {
      for (let prod of installation.products) {
        selectionedSmartComponentTypesIdFilters =
          selectionedSmartComponentTypesIdFilters.concat(
            await this.getSelectionedFilters(prod, systems, aggregateBy)
          );
      }
    } else if (site) {
      selectionedSmartComponentTypesIdFilters =
        await this.chartRepository.getSitesSmartComponentTypeIds();
    }

    return [...new Set(selectionedSmartComponentTypesIdFilters)];
  };

  // assignElementLastValueToChart(elementZone: any, objSmartComponentTypeId: any): any {

  // }

  assignElementToChart = (elementZone, objSmartComponentTypeId) => {
    const newElementZone = _.cloneDeep(elementZone);
    const groupOfChartTypes = [
      "lastvalue",
      "bar_week",
      "heatmap",
      "heatmap_echart",
    ];
    let { groupBy, granularity, chart, name } = calculateDates(
      newElementZone,
      this.props.dateRange,
      this.props.groupBySelected
    );
    let options = newElementZone.options?.length
      ? { ...newElementZone.options[0], title: { text: newElementZone.title } }
      : null;
    let telemetry =
      newElementZone.chart === "lastvalue"
        ? this.props.productTelemetry
        : false;

    options = configurateOptions(options, newElementZone);
    if (newElementZone.kpisStacked) {
      const kpisStacked: any[] = [];
      for (let stacked of newElementZone.kpisStacked) {
        let optionsStacked =
          stacked.options && stacked.options.length
            ? newElementZone.options[0]
            : null;
        newElementZone.name = name;
        stacked = mapperStackedFromChart(
          stacked,
          newElementZone,
          this.props,
          groupBy,
          granularity
        );
        optionsStacked = configurateOptions(optionsStacked, stacked);
        stacked.options = optionsStacked;
        // stacked.kpiReference = stacked.kpiReference ? stacked.kpiReference : null;
        kpisStacked.push(stacked);
      }
      newElementZone.kpisStacked = kpisStacked;
    }
    newElementZone.options = options;

    var deviceUnits = this.getDeviceUnits(this.context);
    return {
      chartType: newElementZone.chart,
      type: newElementZone.type ? newElementZone.type : "kpi",
      library: newElementZone.library,
      units: newElementZone.units,
      granularity: groupOfChartTypes.includes(newElementZone.chart)
        ? newElementZone.granularity
        : granularity,
      groupBy: groupOfChartTypes.includes(newElementZone.chart)
        ? newElementZone.groupBy
        : groupBy,
      // granularity: elementZone.granularity ? elementZone.granularity : granularity,
      // groupBy: elementZone.groupBy ? elementZone.groupBy : groupBy,
      kpisStacked: newElementZone.kpisStacked,
      calculation:
        chart.aggregateBy !== "product" && chart.calculation !== "sum"
          ? "sum"
          : newElementZone.calculation,
      title: newElementZone.title,
      titleStacked: newElementZone.titleStacked,
      color: newElementZone.color,
      name: name,
      id: newElementZone.id,
      decimals: newElementZone.decimals,
      brandCode: objSmartComponentTypeId.brandCode,
      filterByBrandCode: newElementZone.filterByBrandCode,
      groupByEcharts: this.props.groupBySelected,
      aggregateBy: this.props.aggregateBy,
      page: this.props.page,
      dateRange: this.props.dateRange,
      kpiNameChartType: `${newElementZone.name}-${newElementZone.chart}`,
      kpiReference: newElementZone.kpiReference
        ? newElementZone.kpiReference
        : null,
      options: newElementZone.options,
      telemetry: telemetry,
      filters: setFilter(this.props.filter, this.props.gender),
      icon: newElementZone.icon,
      siteReportingPage: this.props.site === "all",
      deviceUnits: deviceUnits,
      heatMapRange: newElementZone.chart === 'heatmap_echarts' ? newElementZone.heatMapRange : null
    };
  };

  private getDeviceUnits = (context: any): { [key: string]: string } => {
    const measures = context.userPreferences?.measures;
    if (!measures) {
      return {};
    }

    var deviceUnits = {};
    measures.forEach((element) => {
      const { measureTypeName, measureUnitCode } = element;
      const measureTypeNameKey = measureTypeName
        .split(" ")
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join("");
      deviceUnits[measureTypeNameKey] = measureUnitCode;
    });

    return deviceUnits;
  };

  async returnSmartComponentTypeIds(product: any, systems: any) {
    let selectionedFilters: any = [];
    if (product.productType?.smartComponentTypeId == undefined) {
      let smartCompTypeIds = await this.getSmartComponentTypeIds(
        product.productType.systemId,
        systems
      );
      selectionedFilters = selectionedFilters.concat(smartCompTypeIds);
    } else {
      selectionedFilters.push(Number(product.productType.smartComponentTypeId));
    }
    return selectionedFilters;
  }

  async getSmartComponentTypeIds(systemId: any, systems: any) {
    let smartComponentTypesId: any = [];
    let item = systems.find((item) => item.id === systemId);
    for (let smartCmpType of item.smartComponentTypes) {
      if (smartComponentTypesId.indexOf(parseInt(smartCmpType.id)) == -1)
        smartComponentTypesId.push(parseInt(smartCmpType.id));
    }
    return smartComponentTypesId;
  }

  getTimeZone = () => {
    /* Check if is timezone for product */
    let timeZoneOffset =
      this.props?.product?.content?.installation?.parentTimeZone
        ?.timeZoneOffset;
    if (!timeZoneOffset) {
      /* Check if is timezone for installation */
      timeZoneOffset = this.props?.installation?.parentTimeZone?.timeZoneOffset;
    }

    /* If still undefined get site timezone */
    return timeZoneOffset
      ? timeZoneOffset
      : this.props?.installation?.timeZone?.timeZoneOffset;
  };

  renderKPIsCharts = (groupedCharts: any[]) => {
    if (this.props.site === "all") {
      // General Sites use case
      return (
        <GroupGeneralSitesChartContainer
          chartRepository={this.chartRepository}
          groups={groupedCharts}
          page={this.props.page}
        />
      );
    }
    const groupChartArray: any[] = [];

    // All groups are iterated
    Object.values(groupedCharts).forEach((group) => {
      const groupTitle = this.props.t("charts:titles." + group.charts[0].title);
      //  It checks if the groups have at least one chart with dataPoints
      if (group.charts.length) {
        if (group.charts.length === 1) {
          groupChartArray.push(
            <React.Fragment key={groupChartArray.length++}>
              <div
                className={`${
                  group.charts[0].chartType === "lastvalue"
                    ? "lastValueBox"
                    : ""
                }`}
              >
                {this.getChartComponentByType(group.charts[0])}
              </div>
            </React.Fragment>
          );
        } else {
          //LastValue useCase
          groupChartArray.push(
            <GroupChartContainer
              key={groupChartArray.length++}
              chartRepository={this.chartRepository}
              group={group.charts}
              legend={group.legend}
              title={groupTitle}
              tooltip={this.props.tooltip}
              page={this.props.page}
              context={this.context}
              lastValueGroup={true}
              hiddenLegend={true}
              chartFinished={this.chartFinished.bind(this)}
            />
          );
        }
      }
    });
    return groupChartArray;
  };

  render() {
    return (
      <div className={Style.KPISChart}>
        <div
          className={`KPISChart-container ${
            this.props.withoutBorder ? "without-border" : ""
          }
           ${this.props.auxClassName ? this.props.auxClassName : ""}`}
        >
          {this.props.chartViewZones && this.state.dataLoaded ? (
            this.renderKPIsCharts(this.state.chartsByGroup as any[])
          ) : (
            // <div className={this.state.chartsByGroup[0].charts[0].chartType === 'lastvalue' ? 'charts-box' : ''}>
            //   {this.renderKPIsCharts(this.state.chartsByGroup as any[])}
            // </div>
            this.renderLoading()
          )}
        </div>
      </div>
    );
  }

  renderLoading() {
    let pageZonePosition = this.props.pageZonePosition;

    if (pageZonePosition === "Products-Reporting-Zone0") {
      return <HeatMapChartSkeleton></HeatMapChartSkeleton>;
    } else if (pageZonePosition === "Products-Reporting") {
      return <BarChartSkeleton showTitle={true} ></BarChartSkeleton>;
    } else if (pageZonePosition === "Products-Reporting-LastValues") {
      const skeletons = [1, 2, 3]; // This can be any array that represents the number of skeletons you want

      return <div style={{ display: 'flex', justifyContent: 'center' }}>
        {skeletons.map((_, index) => (
          <div key={index} style={{ margin: '0px 10px' }}>
            <LastValueSkeleton />
          </div>
        ))}
      </div>
    }

    return (
      <div className="row loadingParent">
        <div className="loadingImg"></div>
      </div>
    );
  }
}

export default withTranslation()(KPIsChartContainer);
