import Style from '../styles/ProductsGeneralSkeletonView.module.scss';
import { Skeleton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import { ArrowBackIos, ArrowDownward, ArrowDropDown, ArrowForwardIos } from '@mui/icons-material';

const TableSkeleton = (props: { dev?: boolean }) => {
  return (
    <div>
      <div style={{display: 'flex', alignItems: 'center', padding: '30px 20px 20px 25px'}}>
        <h6 style={{marginRight: '25px'}}>FILTRO: </h6>
        <Skeleton variant='rectangular' width={50} height={30} style={{margin: '0 5px', borderRadius: '5px'}} />
        <Skeleton variant='rectangular' width={75} height={30} style={{margin: '0 5px', borderRadius: '5px'}} />
        <Skeleton variant='rectangular' width={125} height={30} style={{margin: '0 5px', borderRadius: '5px'}} />
        <Skeleton variant='rectangular' width={75} height={30} style={{margin: '0 5px', borderRadius: '5px'}} />
        <Skeleton variant='rectangular' width={100} height={30} style={{margin: '0 5px', borderRadius: '5px'}} />
      </div>

      <div style={{display: 'flex', justifyContent: 'space-between', margin: '30px 20px 35px 25px'}}>
        <Skeleton variant='rectangular' width={100} height={25} style={{margin: '0 5px'}} />
        <Skeleton variant='rectangular' width={100} height={25} style={{margin: '0 5px'}} />
        <Skeleton variant='rectangular' width={100} height={25} style={{margin: '0 5px'}} />
        <Skeleton variant='rectangular' width={100} height={25} style={{margin: '0 5px'}} />
        <Skeleton variant='rectangular' width={100} height={25} style={{margin: '0 5px'}} />
      </div>

      <div style={{borderTop: 'solid 1px #EBEBEB', borderBottom: 'solid 1px #EBEBEB'}}>
        <div style={{display: 'flex', justifyContent: 'space-between', padding: '15px 0px', margin: '0px 20px 0px 25px' }}>
            <Skeleton variant='rectangular' width={100} height={25} style={{margin: '0 5px'}} />
            <Skeleton variant='rectangular' width={100} height={25} style={{margin: '0 5px'}} />
            <Skeleton variant='rectangular' width={100} height={25} style={{margin: '0 5px'}} />
            <Skeleton variant='rectangular' width={100} height={25} style={{margin: '0 5px'}} />
            <Skeleton variant='rectangular' width={100} height={25} style={{margin: '0 5px'}} />
        </div>
      </div>
      <div style={{borderBottom: 'solid 1px #EBEBEB'}}>
        <div style={{display: 'flex', justifyContent: 'space-between', padding: '15px 0px', margin: '0px 20px 0px 25px' }}>
            <Skeleton variant='rectangular' width={100} height={25} style={{margin: '0 5px'}} />
            <Skeleton variant='rectangular' width={100} height={25} style={{margin: '0 5px'}} />
            <Skeleton variant='rectangular' width={100} height={25} style={{margin: '0 5px'}} />
            <Skeleton variant='rectangular' width={100} height={25} style={{margin: '0 5px'}} />
            <Skeleton variant='rectangular' width={100} height={25} style={{margin: '0 5px'}} />
        </div>
      </div>
      <div style={{borderBottom: 'solid 1px #EBEBEB'}}>
        <div style={{display: 'flex', justifyContent: 'space-between', padding: '15px 0px', margin: '0px 20px 0px 25px' }}>
            <Skeleton variant='rectangular' width={100} height={25} style={{margin: '0 5px'}} />
            <Skeleton variant='rectangular' width={100} height={25} style={{margin: '0 5px'}} />
            <Skeleton variant='rectangular' width={100} height={25} style={{margin: '0 5px'}} />
            <Skeleton variant='rectangular' width={100} height={25} style={{margin: '0 5px'}} />
            <Skeleton variant='rectangular' width={100} height={25} style={{margin: '0 5px'}} />
        </div>
      </div>

      <div style={{display: 'flex', alignItems: 'center', margin: '20px 20px 20px 25px', justifyContent: 'end' }}>
        <Skeleton variant='rectangular' width={100} height={30} style={{margin: '0 5px', borderRadius: '5px'}} />
        <Skeleton variant='rectangular' width={30} height={30} style={{margin: '0 5px 0px 15px', borderRadius: '5px'}} />
        <IconButton>
            <ArrowDropDown fontSize="small" style={{color: '#757575'}}  />
        </IconButton>
        <Skeleton variant='rectangular' width={60} height={30} style={{margin: '0 30px', borderRadius: '5px'}} />
        <IconButton>
            <ArrowBackIos fontSize="small" style={{color: '#E3E3E3', height: '15px', width: '15px'}}  />
        </IconButton>
        <Skeleton variant='rectangular' width={30} height={30} style={{margin: '0 5px', borderRadius: '5px'}} />
        <Skeleton variant='rectangular' width={30} height={30} style={{margin: '0 5px', borderRadius: '5px'}} />
        <IconButton>
            <ArrowForwardIos fontSize="small" style={{color: '#757575', height: '15px', width: '15px'}} />
        </IconButton>
      </div>

      <div style={{height: '10px' }}></div>
    </div>
  );
};

export default TableSkeleton;
