import React, { Component } from 'react';
import AuthenticationContext from "../Authentication/types/AuthContextType";

export default class LoginButton extends Component {
    static contextType = AuthenticationContext;

    constructor(props) {
        super(props)
    }

    render() {

        if (this.props.redirect == true) {
            return (
                <button className="primary" name="login" id="login" onClick={() => window.location.href = "/home?action=login" }>Login</button>
            )
        }
        else {
            return (
                <button className="primary" name="login" id="login" onClick={() => this.context.login()}>Login</button>
            );
        }
    }
}