import { PureComponent } from 'react';
import AuthenticationContext from '../../../../Authentication/types/AuthContextType';
import ReactECharts from 'echarts-for-react';
import { t } from 'i18next';
import { compareGeneralLegend, getColor, hexToHSL } from '../../utils/chartComponentsUtils';
import _ from 'lodash';
import { formatNumber, formatNumberAsString } from '../../utilsCharts';
import { Skeleton } from '@mui/material';
import EmptyGaugeChartView from '../../views/EmptyGaugeChartView';

class GaugeChartContainer extends PureComponent<any, any> {
  static contextType = AuthenticationContext;
  constructor(props: any) {
    super(props);
    this.state = {
      chartsData: false,
      dataPoints: false,
      chartLegend: false,
      error: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.chart && !this.state.dataPoints && !this.state.chartsData) {
      await this.getDataPoints();
    }
  };

  componentDidUpdate() {
    if (!_.isEmpty(this.state.chartLegend) && !_.isEmpty(this.props.generalLegend)) {
      const newLegend = compareGeneralLegend(this.props.generalLegend, this.state.chartLegend);
      if (newLegend) {
        this.applyLegendFilterToChartData(newLegend);
      }
    }
  }

  units
  getDataPoints = async () => {
    try {
      const response = await this.props.chartRepository.manageChartType(this.props.chart);
      const dataPoints = response?.content;
      const deviceUnits = response?.deviceUnits;
      this.units = deviceUnits && deviceUnits != '' ? deviceUnits : this.props.chart.units
      if (!dataPoints) {
        this.setState({
          error: {
            type: 'call',
            message: t('translation:somethingWrong'),
          },
        });
        return null;
      } else if (!dataPoints.length) {
        this.setState({
          error: {
            type: 'empty',
            message: t('translation:nodata'),
          },
        });
        return null;
      }

      let chartsData: any[] = [];
      if (dataPoints.length) {
        dataPoints.forEach((dataPoint) => {
          const gaugeChart = this.generateGaugeChart(dataPoint);
          chartsData.push(gaugeChart);
        });

        const chartLegend = this.props.chartRepository.manageLegend(dataPoints);
        this.props.getLegendFromChart(chartLegend);
        this.setState({ dataPoints, chartsData, chartLegend });
      }
    } catch (error: any) {
      this.setState({
        error: {
          type: 'call',
          message: error?.message ? error?.message : error,
        },
      });
    }
  };

  applyLegendFilterToChartData = (updatedLegend) => {
    // Cambiar el estado de chartData a false para obligar a renderizar el componente con los datos nuevos
    this.setState({ chartsData: false }, () => {
      // Después de que el estado se haya actualizado, llenar chartData con los nuevos datos
      let chartsData: any[] = [];
      this.state.dataPoints.forEach((dataPoint) => {
        if (this.props.generalLegend && this.props.generalLegend[dataPoint.name].status === true) {
          const gaugeChart = this.generateGaugeChart(dataPoint);
          chartsData.push(gaugeChart);
        }
      });
      this.setState({ chartsData, chartLegend: updatedLegend });
    });
  };

  generateGaugeChart = (dataPoint: any) => {
    const newOptions = _.cloneDeep(this.props.chart.options);
    const decimals = this.props.chart.decimals;
    const units = this.units;
    const name = t('charts:titles.' + dataPoint.name).includes('.')
      ? t('charts:titles.' + dataPoint.name).split('.')[1]
      : t('charts:titles.' + dataPoint.name);

    newOptions.series[0].axisLabel = {
      color: '#999',
      padding: [25, -35, 0, -30],
      fontSize: 10,
      formatter: function (value) {
        return `\n\n\n${value > 0 ? formatNumberAsString(value, decimals) + ' ' + units : formatNumberAsString(value, decimals)}`;
      },
    };

    newOptions.series[0].detail = {
      borderRadius: 8,
      fontSize: 15,
      lineHeight: 40,
      offsetCenter: [0, '-15%'],
      valueAnimation: true,
      formatter: function (value) {
        return formatNumberAsString(value, decimals);
      },
    };

    newOptions.series[0].itemStyle.color = getColor(dataPoint.color).color;
    newOptions.series[0].name = name;
    newOptions.series[0].data = [{ value: formatNumber(dataPoint.sum, decimals), name: 'Last Value' }];

    var max = formatNumber(dataPoint.max, decimals);
    newOptions.series[0].max = max > 0 ? max : 1;
    newOptions.series[0].min = dataPoint.min;

    //average
    newOptions.series[1].itemStyle.color = hexToHSL(dataPoint.color);
    newOptions.series[1].name = name;
    newOptions.series[1].data = [{ value: formatNumber(dataPoint.avg, decimals), name: 'Average' }];
    newOptions.series[1].max = dataPoint.max > 0 ? dataPoint.max : 1;
    newOptions.series[1].min = dataPoint.min;

    newOptions.tooltip = {
      formatter: function (params) {
        return `<div style = display:table> ${params.seriesName}<br />
                  <div style='display:flex; align-items:center'>
                    ${params.marker} 
                    <div style='display:flex; justify-content:space-between; width:100%'>
                      ${params.name} <b style='margin-left:15px'>${formatNumberAsString(params.data.value, decimals)}</b>
                    </div>
                  </div>
                </div>`;
      },
    };

    delete newOptions.title;
    return newOptions;
  };

  render() {
    return (
      <>
        {this.state.error ? (
          <EmptyGaugeChartView {...this.state.error} />
        ) : this.state.chartsData.length ? (
          <div className='gauges-chart-box'>
            {this.state.chartsData.map((chartData, key) => (
              <div className='gauge' key={key}>
                <ReactECharts style={{ height: '200px' }} option={chartData} />
              </div>
            ))}
          </div>
        ) : !this.state.dataPoints.length ? (
          <div className='gauges-chart-box'>
            {/* <Skeleton animation="wave" variant='rectangular' width={966} height={200} style={{ margin: '15px auto' }} /> */}
            <div className='row loadingParent'>
              <div className='loadingImg'></div>
            </div>
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default GaugeChartContainer;
