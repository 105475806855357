import { t } from 'i18next';
import { WelcomeStateType } from '../types/WelcomeStateType';
import LoginButton from '../../LoginButton';
import Error from '../../Error';
import Style from '../styles/Registration.module.scss'
const WelcomeView = (props: WelcomeStateType) => {
  return (
    <div className={Style.Registration}>
      {props.dataLoaded ? (
                    props.isError && props.redirect ? (null) : (
                        <div className="register confirmRegister">
                            <div className="registerContainer unified_container">
                                <div className="registerRow">
                                    <div className="register-col-lg-6">
                                        <div className="panel panel-default">
                                            {
                                                props.isError ? (
                                                    !props.redirect ? (
                                                        <div>
                                                            <img alt={props.brandUser} className="companyLogo" src={props.brandLogo} />
                                                            <div id="api" data-name="Unified">
                                                                <Error errors={props.error} />
                                                                <LoginButton />
                                                            </div>
                                                        </div>) : (null)
                                                ) : (
                                                    <div className="panel-body">
                                                        <img alt={props.brandUser} className="companyLogo" src={props.brandLogo} />
                                                            <img src={require('../../../assets/icons/handshake.svg').default} style={{ marginTop: '20px', opacity: 0.4 }} />
                                                        <div id="api" data-name="Unified">
                                                            <div id="localAccountForm" className="localAccount">
                                                                <div className="intro">
                                                                    <span className="h2">{t('register.welcomeTo', { brand: props.brandDescription })}</span>
                                                                </div>
                                                                <div className="entry">
                                                                    <div className="entry-item">
                                                                        <span className="t2">{t('register.registerSuccess')}</span>
                                                                    </div>
                                                                    <div className="buttons">
                                                                        <button id="next" onClick={() => window.location.href = "/home?action=login#" + window.location.hash}>{t('actions.next')}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                ) : (
                        props.isError && props.redirect ? (null) : (
                        <div className="register confirmRegister">
                            <div className="registerContainer unified_container">
                                <div className="registerRow">
                                    <div className="register-col-lg-6">
                                        <div className="panel panel-default">
                                            <div className="row loadingParent">< div className="loadingImg"></div></div >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    )
                }
    </div>
  )
};

export default WelcomeView;