import React from 'react';
import { withTranslation } from 'react-i18next';
import Moment from 'react-moment';

const ParameterToolTipV1 = ({ t, values, children, description }) => {
  return (
    <div className='section-tooltip'>
      {/* when hover trigger-tooltip -> box-tooltip appears */}
      <div className='trigger-tooltip'>{children}</div>
      {/* content of tooltip */}
      {values ? (
        <div className='box-tooltip'>
          <h6 className='mb-3 mt-2' style={{ whiteSpace: 'pre-line' }}>{description}</h6>
          <div className='dates'>
            <span className='t3 bold'>{t('productspages.parameters.lastvaluedate')}: </span>
            <span className='t3'>
              <Moment className='t3' format='D MMM YYYY HH:mm' withTitle>
                {values.lastProcessed}
              </Moment>
            </span>
          </div>
          <div className='dates'>
            <span className='t3 bold'>{t('productspages.parameters.lastvaluechangedate')}: </span>
            <span className='t3'>
              <Moment className='t3' format='D MMM YYYY HH:mm' withTitle>
                {values.tsISO}
              </Moment>
            </span>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default withTranslation()(ParameterToolTipV1);