import axios from 'axios';
import i18n from '../i18n/i18n';
export default class ChartHttpClient {
  constructor(context, timeZoneOffset) {
    this.context = context;
    this.language = i18n.language;
    this.timeZoneOffset = timeZoneOffset ? timeZoneOffset : '00:00';
  }

  addTimezone = (timeStamp) => {
    const sign = this.timeZoneOffset.includes('-') ? 'min' : 'plus';
    return `${timeStamp}${sign}${this.timeZoneOffset.replace('+', '').replace('-', '').substring(0, 5)}`;
  };

  HeatmapKPIDatapoints = async (kpiName, granularity, type, typeId, groupBy, startTimeStamp, endTimeStamp, deviceUnits) => {
    const accessToken = await this.context.getToken();
    const config = {
      headers: {
        Authorization: `${accessToken.accessToken}`,
        "x-device-units": JSON.stringify(deviceUnits)
      },
    };
    const url = `charts/${this.language}/heatmap/${kpiName}/${granularity}/${type}/${typeId}/${groupBy}/${this.addTimezone(startTimeStamp)}/${this.addTimezone(endTimeStamp)}`;

    try {
      const response = await axios.get(url, config);
      return response;
    } catch (e) {
      return e.response;
    }
  };
}
