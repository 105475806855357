import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';

import { Template, TemplatePlaceholder } from '@devexpress/dx-react-core';
import {
  PagingState,
  CustomPaging,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  withComponents,
  RowDetailState,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
  PagingPanel,
  TableColumnVisibility,
  TableColumnResizing,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import { convertColumns } from './utils/converter';

const HeaderTitle = (props) => <TableHeaderRow.Title {...props} className='table-custom-header' />;
const CellComponent = (props) => <TableHeaderRow.Cell {...props} className='table-custom-header-cell' />;
const RowComponent = (props) => <Table.Row {...props} className='table-custom-row' />;

const RowComponentWithLink = (props) => (
  <Link to={`/detail/user/${props.row.guid}`}>
    <Table.Row {...props} className='table-custom-row' />
  </Link>
);

const RootComponent = (props) => <Grid.Root {...props} className='custom-grid' />;
let lastQuery = '';
export default (props) => {
  let c = convertColumns({ columns: props.columns, allAuto: props.allAuto ? props.allAuto : false, name: props.name });
  const [columns] = useState(c.columns);
  const [rows, setRows] = useState(props.rows);
  const [tableColumnExtensions] = useState(c.tableColumnExtensions);
  const [columnExtensions] = useState(c.columnExtensions);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(props.page ? props.page - 1 : 0);
  const [pageSize, setPageSize] = useState(props.pageSize != undefined ? props.pageSize : 5);
  const [pageSizes] = useState([props.pageSizes != undefined ? props.pageSizes : 5, 15, 0]);

  const checkWidth = (p) => {
    return p.map((val) => {
      if (isNaN(val.width) || val.width == null) {
        val.width = 100;
      }
      return val;
    });
  };
  let defaultColumnsW = [];
  if (props.name) {
    defaultColumnsW = JSON.parse(sessionStorage.getItem(props.name));
    if (defaultColumnsW == null) {
      defaultColumnsW = checkWidth(c.defaultWidth);
      sessionStorage.setItem(props.name, JSON.stringify(c.defaultWidth));
    }
  }
  const [defaultColumnWidth, setColumnWidths] = useState(checkWidth(defaultColumnsW));
  const setWidth = (p) => {
    p = checkWidth(p);
    setColumnWidths(p);
    sessionStorage.setItem(props.name, JSON.stringify(p));
  };
  const [defaultHiddenColumnNames] = useState(c.hiddenColumns);
  const [loading, setLoading] = useState(false);
  //const [lastQuery, setLastQuery] = useState();

  const tableMessages = {
    noData: props.t('tables.noData'),
  };
  const editColumnMessages = {
    addCommand: 'Neue Zeile',
    editCommand: 'Bearbeiten',
    deleteCommand: 'Entfernen',
    commitCommand: 'Speichern',
    cancelCommand: 'Abbrechen',
  };
  const groupingPanelMessages = {
    groupByColumn: 'Ziehen Sie eine Spalte hierhin, um danach zu gruppieren',
  };
  const filterRowMessages = {
    filterPlaceholder: 'Filter...',
  };
  const pagingPanelMessages = {
    showAll: props.t('tables.all'),
    rowsPerPage: props.t('tables.rowsPerPage'),
    info: props.t('tables.customTotal'),
  };
  const searchMessages = {
    searchPlaceholder: props.t('tables.search') + '...',
  };
  const tableHeaderMessages = {
    sortingHint: '',
  };
  const getQueryString = () => `take=${pageSize}&skip=${pageSize * currentPage}`;
  const loadData = () => {
    const queryString = getQueryString();
    if (queryString !== lastQuery && !loading) {
      setLoading(true);
      let total = 0;
      try {
        total = props.pageChanged(currentPage, pageSize);
        setTotalCount(total);
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
      //setLastQuery(queryString);
      lastQuery = queryString;
    }
  };
  useEffect(() => {
    if (props.pageChanged) {
      loadData();
    }
    if (props.rows && props.rows.length) {
      setRows(props.rows);
    }
  }, [props.rows]);

  const customPageSizeHandler = (newPageSize) => {
    if (props.hasOwnProperty('onSizePerPageChange')) {
      props.onSizePerPageChange(newPageSize);
    }
    setPageSize(newPageSize);
  };

  const customPageChangeHandler = (newPage) => {
    if (props.hasOwnProperty('onPageChange')) {
      props.onPageChange(newPage);
    }
    setCurrentPage(newPage);
  };

  const RowComponentToUse = props.rowLinks ? RowComponentWithLink : RowComponent;

  return (
    <Paper elevation={0}>
      <Grid rows={rows} columns={columns} rootComponent={RootComponent}>
        <SearchState defaultValue='' />
        <PagingState
          currentPage={currentPage}
          pageSize={pageSize}
          onCurrentPageChange={customPageChangeHandler}
          onPageSizeChange={customPageSizeHandler}
        />
        <SortingState columnExtensions={columnExtensions} />
        {props.RowDetail ? <RowDetailState defaultExpandedRowIds={[]} /> : null}

        {c.formatterProviders}

        <IntegratedFiltering />
        <IntegratedSorting />
        {props.pageChanged ? <CustomPaging totalCount={totalCount} /> : <IntegratedPaging />}

        <Table columnExtensions={tableColumnExtensions} rowComponent={RowComponentToUse} messages={tableMessages} />
        {props.resize ? <TableColumnResizing columnWidths={defaultColumnWidth} onColumnWidthsChange={setWidth} /> : null}
        <TableHeaderRow
          showSortingControls
          titleComponent={HeaderTitle}
          cellComponent={CellComponent}
          messages={tableHeaderMessages}
        />
        <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />

        {props.RowDetail ? <TableRowDetail contentComponent={props.RowDetail} /> : null}

        {props.filter || props.search ? <Toolbar /> : null}

        {props.secondaryFilter ? (
          <Template name='toolbarContent' className='test'>
            <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', marginTop: '12px', alignItems: 'center' }}>
              {props.secondaryFilter}
            </div>
            <TemplatePlaceholder />
          </Template>
        ) : null}

        {props.filter ? (
          <Template name='toolbarContent' className='test'>
            <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', marginTop: '12px', alignItems: 'center' }}>
              {props.filter}
            </div>
            <TemplatePlaceholder />
          </Template>
        ) : null}

        {props.search ? <SearchPanel messages={searchMessages} /> : null}

        {<PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />}

        {loading && <Loading />}
      </Grid>
    </Paper>
  );
};
