import Style from '../styles/ProductsGeneralSkeletonView.module.scss';
import { Skeleton } from '@mui/material';

const ProductsGeneralSkeletonView = (props: { dev?: boolean }) => {
  return (
    <div className={Style.ProductsGeneralSkeletonView}>
      <div className={`productsGeneralSkeletonView-content ${props.dev && 'dev'}`}>
        <Skeleton variant='rectangular' width='100%' height={220} />
        <div className='content-box'>
          <div className='installation-map'>
            <Skeleton variant='rectangular' width='100%' height={35} />
            <Skeleton variant='rectangular' width='100%' height={235} />
            <div className='lastValues-box'>
              <Skeleton variant='rectangular' width='100%' height={18} />
              <div className='lastValues'>
                <Skeleton variant='rectangular' width={205} height={210} />
                <Skeleton variant='rectangular' width={205} height={210} />
                <Skeleton variant='rectangular' width={205} height={210} />
                <Skeleton variant='rectangular' width={205} height={210} />
              </div>
            </div>
          </div>
          <div className='statistics'>
            <Skeleton variant='rectangular' width='100%' height={35} />
            <Skeleton variant='rectangular' width='100%' height={562} />
            <Skeleton variant='rectangular' width='100%' height={562} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsGeneralSkeletonView;
