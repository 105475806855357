import axios from "axios";
import i18n from '../i18n/i18n';

export default class HttpClient {


    constructor(context, id, kpiName, granularity, groupBy,page, pagesize) {
        this.context = context;
        this.productID = id;
        this.kpiName = kpiName;
        this.granularity = granularity;
        this.groupBy = groupBy;
        this.language = i18n.language;
        this.debug = false; 
        this.page = page;
        this.pagesize = pagesize;
    }


    GetActiveAlerts = async () => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.get("alerts/" + this.language + "/" + this.productID + "/activealerts", config);
        if (this.debug) {
            console.log("calling AlertsGetConfigAlertsRules: " + "alerts/" + this.language + "/" + this.productID + "/activealerts");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    GetAlerts = async () => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.get("alerts/" + this.language + "/" + this.productID + "/getalerts/"+this.page+"/"+ this.pagesize, config);
        if (this.debug) {
            console.log("calling Alerts " + "alerts/" + this.language + "/" + this.productID + "/getalerts/");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }


    GetProducedIndividualRules = async (filter) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.post("alerts/" + this.language + "/" + this.productID + "/getProducedIndividualRules/"+this.page+"/"+ this.pagesize, filter, config);
        if (this.debug) {
            console.log("calling Alerts " + "alerts/" + this.language + "/" + this.productID + "/getalerts/");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    GetProducedIndividualRulesBySite = async (filter) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.post("alerts/" + this.language + "/" + this.productID + "/getProducedIndividualRulesBySite/" + this.page + "/" + this.pagesize, filter, config);
        if (this.debug) {
            console.log("calling Alerts " + "alerts/" + this.language + "/" + this.productID + "/getProducedIndividualRulesBySite/");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    GetProducedIndividualRulesTotalsBySite = async () => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("alerts/" + this.language + "/" + this.productID + "/getProducedIndividualRulesTotalsBySite/totals", config);

        if (this.debug) {
            console.log("calling Alerts " + "alerts/" + this.language + "/" + this.productID + "/getProducedIndividualRulesBySite/totals");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    GetProducedIndividualRulesTotals = async () => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`, 
            }
        }
        var response = await axios.get("alerts/" + this.language + "/" + this.productID + "/getProducedIndividualRules/totals", config);

        if (this.debug) {
            console.log("calling Alerts " + "alerts/" + this.language + "/" + this.productID + "/getProducedIndividualRules/totals");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }
    UpdateAlert = async (alertId, productId, patch) => {
        // {lng}/{id}/updateAlert/{alertId}
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`
            }
        }

        var response = await axios.put("alerts/" + this.language + "/configalertsrules/update/" + productId + "/" + alertId, patch, config);
        if (this.debug) {
            console.log("calling UpdateAlert: " + "alerts/" + this.language + "/" + this.productID + "/updateAlert/" + alertId);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    AlertsGetConfigAlertsRules = async (page, pagesize) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        
        var response = await axios.get("alerts/" + this.language + "/configalertsrules/" + this.productID +"/"+  page + "/" + pagesize, config);
        if (this.debug) {
            console.log("calling AlertsGetConfigAlertsRules: " + "alerts/" + this.language + "configalertsrules/" + page + "/" + pagesize);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    AlertsGetAlertTypes = async (productTypeId) => {
        let accessToken = await this.context.getToken();
        
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("alerts/" + this.language + "/configalertsrules/" + productTypeId , config);
        console.log("calling AlertsGetConfigAlertsRules: " + "alerts/" + this.language + "/configalertsrules/" + productTypeId );
        if (this.debug) {
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    //SaveAlertRule = async (name, severity, productid, description, operator, thresholdValue, agregation, frecuency, active, type, id) => {
    SaveAlertRule = async (data) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`
            }
        }
        
        var response = await axios.post("alerts/" + this.language + "/configalertsrules/Add/",data, config);
        if (this.debug) {
            console.log("calling SaveAlertRule: " + "alerts/" + this.language + "/configalertsrules/Add");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    DeleteAlertRule = async (idRule, idProduct) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`
            }
        }
        var response;
        try {
            response = await axios.delete("alerts/" + this.language + "/configalertsrules/Delete/" + idProduct+ "/" + idRule, config);
            if (this.debug) {
                console.log("calling SaveAlertRule: " + "alerts/" + this.language + "/configalertsrules/Delete/" + idRule);
                console.log("response: " + JSON.stringify(response));
            }
        } catch (e) {
            response = e.response;
        }

        return response.data
    }

}