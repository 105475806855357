import { PureComponent } from "react";
import {
  InstallationUserEditContainerProps,
  InstallationUserEditContainerState,
} from "../types/InstallationUserEditTypes";
import AuthenticationContext from "../../../../../Authentication/types/AuthContextType";
import InstallationsHttpClient from "../../../../../HttpClient/InstallationsHttpClient";
import InstallationUserEditView from "../view/InstallationUserEditView";

class InstallationUserEditContainer extends PureComponent<
  InstallationUserEditContainerProps,
  InstallationUserEditContainerState
> {
  static contextType = AuthenticationContext;
  id;
  installationId;
  constructor(props: InstallationUserEditContainerProps) {
    super(props);
    this.state = {
      data: null,
      gateways: 0,
      open: false,
      showModalDelete: false,
      expired: "",
      saving: false,
      role: "",
      roles: {},
      showModalError: false,
      errorMessage: "",
      showModal: false,
      roleId: "",
    };
  }

  componentDidMount() {
    this.setState({ roleId: this.props.roleSelected });
    this.getRoles();
  }

  handleClose = () => {
    this.setState({ showModalDelete: false });
  };
  handleCloseModal = () => {
    this.props.onSaveClose();
  };
  handleCloseError = () => {
    this.setState({ showModal: false, showModalError: false });
  };

  async confirmDelete() {
    await this.deleteUserInstallation(this.state.data.id);
    this.setState({ showModalDelete: false });
  }

  handleChange = (event) => {
    this.setState({ roleId: event.target.value });
  };
  handleChangeExpired = (event) => {
    this.setState({ expired: event.target.value });
  };

  async getRoles() {
    let http = new InstallationsHttpClient(this.context, this.id);
    let data = await http.getRoles();

    this.setState({ roles: data.content });
  }

  async deleteUserInstallation(id) {
    let data;

    let http = new InstallationsHttpClient(this.context, this.id);
    data = await http.deleteUserInstallations(id);
    if (data.errors == null) {
      this.props.onSaveClose();
    } else {
      this.setState({ showModalError: true, errorMessage: data.errors });
    }
    return data;
  }

  async saveData(e) {
    this.setState({ saving: true });
    //Modificamos los datos en this.installation para luego comparar con los datos del this.state.data y generar el patch
    e.preventDefault();
    try {
      let data;

      data = await this.SendPatch(
        this.props.userId,
        this.props.roleSelected,
        this.state.roleId
      );
    } catch (e) {}
  }

  async SendPatch(userId, roleId, newRoleId) {
    let data;
    try {
      let http = new InstallationsHttpClient(
        this.context,
        this.installationId
      );
      data = await http.PatchUserInstallation(userId, roleId, newRoleId);

      if (data.status > 200) {
        this.setState({
          saving: false,
          showModalError: true,
          errorMessage: data.title,
        });
      } else {
        this.props.onSaveClose();
      }
    } catch (e) {
      console.log(e);
    }

    return data;
  }

  render() {
    return (
      <>
        <InstallationUserEditView
          showModalError={this.state.showModalError}
          handleCloseError={this.handleClose.bind(this)}
          errorMessage={this.state.errorMessage}
          showModalDelete={this.state.errorMessage}
          handleClose={this.handleClose.bind(this)}
          confirmDelete={this.handleClose.bind(this)}
          saveData={this.saveData.bind(this)}
          mail={this.props.mail}
          roles={this.state.roles}
          roleId={this.state.roleId}
          dataLoaded={true}
          handleChange={this.handleChange.bind(this)}
          handleCloseModal= {this.handleCloseModal.bind(this)}
          saving = {this.state.saving}
        ></InstallationUserEditView>
      </>
    );
  }
}

export default InstallationUserEditContainer;
