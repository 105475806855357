//TODO include the refactor when refactoring InstallationsSummary Edit.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { AddressInputProps, GeographyEntity, Suggestion } from './AddressInputAzureType';
import TextField from '@mui/material/TextField';
import { t } from 'i18next';

const AddressInput = (props: AddressInputProps) => {
  const [query, setQuery] = useState<string>('');
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  const subscriptionKey = 'xlUv8b1iNmVh1uccKVhZhV44rlryS6vl9Yx6mto8mwo';
  const searchURL = 'https://atlas.microsoft.com/search/address/json';

  useEffect(() => {
    setQuery(props.value ? props.value : '');
  }, [props.value]);

  const fetchSuggestions = async (searchText: string) => {
    if (!searchText) return;

    try {
      const response = await axios.get(searchURL, {
        params: {
          'api-version': '1.0',
          'subscription-key': subscriptionKey,
          typeahead: true,
          query: searchText,
          limit: 5,
        },
      });

      const results: GeographyEntity[] = response.data.results;
      setSuggestions(
        results.map((result: GeographyEntity) => ({
          address: result.address.freeformAddress,
          lat: result.position.lat,
          lon: result.position.lon,
          postalCode: result.address.postalCode || null,
          municipality: result.address.municipality || null,
          country: result.address.country || null,
        }))
      );
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
    fetchSuggestions(value);
  };

  const selectSuggestion = (suggestion: Suggestion) => {
    setQuery(suggestion.address);
    setSuggestions([]);
    props.handlerSelectSuggestion(suggestion);
  };

  return (
    <div style={{ position: 'relative' }}>
      <TextField
        variant='standard'
        id='txtAddress'
        label={t('installationspages.installationsummaryedit.address')}
        helperText=''
        fullWidth
        margin='normal'
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{ maxLength: 255 }}
        value={query}
        onChange={handleChange}
        placeholder={t('installationspages.installationsummaryedit.address') as string}
        />
      {suggestions.length > 0 && (
        <ul
          style={{
            listStyleType: 'none',
            padding: '0',
            marginTop: '5px',
            border: '1px solid #ccc',
            position: 'absolute', 
            backgroundColor: 'white',
            maxHeight: '170px', 
            overflowY: 'auto', 
            width: '100%', 
            zIndex: 1000, 
          }}
        >
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => selectSuggestion(suggestion)}
              style={{ padding: '5px 10px', cursor: 'pointer', borderBottom: '1px solid #ccc' }}
            >
              {suggestion.address}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AddressInput;
