import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import AuthenticationContext from "../../Authentication/types/AuthContextType";

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { DateRangePicker, CustomProvider } from 'rsuite';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "rsuite/dist/rsuite.min.css";
import es from 'rsuite/locales/es_ES';
import de from 'rsuite/locales/de_DE';
import en from 'rsuite/locales/en_GB';
import it from 'rsuite/locales/it_IT';
import fr from 'rsuite/locales/fr_FR';

const localeMap = {
    'es': es,
    'de': de,
    'en': en,
    'it': it,
    'fr': fr
};
class CustomDateSelector extends Component {
    static contextType = AuthenticationContext;

    constructor(props) {
        super(props);
        const selectedLocale = localeMap[this.props.i18n.language] || en;
        this.state = { 
            locale: selectedLocale,
            groupBySelected: (this.props.defaultGranularity ? this.props.defaultGranularity : 'week'),
            dateRange: (this.props.dateRange ? this.props.dateRange : this.getWeekDays(new Date())),
            calOpened: false,
            calYearOpened: false,
            calMonthOpened: false,
            multi: false,
            currentDateType: ""
        };
        this.timeframes = ['custom', 'day', 'week', 'month', 'year'];
        this.maxCustomDays = 90;
    }

    componentDidMount() {

        var currentDateRange = null
        try {
            currentDateRange = localStorage.getItem('CurrentDateRange') || null;
        }
        catch (e) {
            currentDateRange = null;
        }
        var currentGroupBy = null
        try {
            currentGroupBy = localStorage.getItem('CurrentGroupBy') || 'day';
        }
        catch (e) {
            currentGroupBy = 'day';
        }

        if (currentDateRange == 'null' || !currentDateRange) {
            if (this.props.defaultGranularity) {
                let updateDate = false;
                if (this.props.defaultDate != undefined && this.props.defaultDate != null) {
                    updateDate = true;
                }
                this.changeGranularity(this.props.defaultGranularity, !updateDate);
                if (updateDate) {
                    this.setDateRange(this.props.defaultDate, this.props.defaultGranularity);
                }
            }
        }
        else {

            currentDateRange = currentDateRange.split(",");

            let formattedData = [];

            currentDateRange.forEach((element) => {
                formattedData.push(new Date(element))
            });

            this.changeGranularity(currentGroupBy, false);
            this.setDateRange(formattedData, currentGroupBy);
        }

        if (this.props.timeframes != undefined) {
            this.timeframes = this.props.timeframes;
        } else {
            this.timeframes = ['custom', 'day', 'week', 'month', 'year'];
        }

    }

    componentDidUpdate() {
        const lang = this.props.i18n.language.toLowerCase();
        if (this.state.locale?.Calendar?.dateLocale?.code?.split("-")[0] !== lang) {
            this.setState({ locale: localeMap[lang] });
        } 
    }    

    setDateRange(value, gran = "") {

        value = [new Date(value[0].setHours(0, 0, 0)), new Date(value[1].setHours(23, 59, 59))]

        if (typeof this.props.onDateChange === 'function') {
            this.props.onDateChange(value);
        }
        this.setState({ dateRange: value });

        this.setChevron(value, gran);
    }
    setGranularity(value) {
        this.setState({ groupBySelected: value });
        if (typeof this.props.onGranularityChange === 'function') {
            this.props.onGranularityChange(value);
        }
    }

    getDateText() {
        if (this.state.groupBySelected == "day") {
            return this.state.dateRange[0].toLocaleDateString(undefined);
        } else if (this.state.groupBySelected == "week" || this.state.groupBySelected == "custom") {
            return this.state.dateRange[0].toLocaleDateString(undefined) + " - " + this.state.dateRange[1].toLocaleDateString(undefined);
        } else if (this.state.groupBySelected == "month") {
            return this.state.dateRange[0].getMonth() + 1 + "/" + this.state.dateRange[0].getFullYear();
        } else if (this.state.groupBySelected == "year") {
            return this.state.dateRange[0].getFullYear();
        } else {
            return null;
        }
    }
    setRange(date) {
        if (this.state.groupBySelected == "year") {
            this.setDateRange([new Date(date.getFullYear(), 0, 1), new Date(date.getFullYear(), 11, 31, 23, 59, 59)], "year")
        } else if (this.state.groupBySelected == "month") {
            this.setDateRange(this.getMonthDays(date), "month");
        }


    }
    getWeekDays(day) {
        const today = day

        if (today.getDay() == 0) {
            const firstDay = new Date(today.setDate(today.getDate() - 7 + 1));
            const lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));

            return [firstDay, lastDay];

        }
        else {
            const firstDay = new Date(today.setDate(today.getDate() - today.getDay() + 1));
            const lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));

            return [firstDay, lastDay];

        }
    }
    getMonthDays(day) {
        return [new Date(day.getFullYear(), day.getMonth(), 1), new Date(day.getFullYear(), day.getMonth() + 1, 0, 23, 59, 59)];
    }
    getYearDays(day) {
        let y = day.getFullYear();
        return [new Date(y, 0, 1), new Date(y, 11, 31, 23, 59, 59)];
    }

    setActualRange(sum) {
        let min = this.state.dateRange[0];
        let max = this.state.dateRange[1];
        let d = new Date();
        let resta = 1;
        if (this.state.groupBySelected != "day") {
            resta = 2;
        }
        let result = null;
        if (sum) { d = new Date(max.setDate(max.getDate() + 1)); } else { d = new Date(min.setDate(min.getDate() - resta)); d = new Date(d.setHours(0, 0, 0)); }

        if (this.state.groupBySelected == "day" || this.state.groupBySelected == "custom") {

            result = [new Date(d.setHours(0, 0, 0)), new Date(d.setHours(23, 59, 59))];

        } else if (this.state.groupBySelected == "week") {

            result = this.getWeekDays(d);

        } else if (this.state.groupBySelected == "month") {

            result = this.getMonthDays(d);

        } else if (this.state.groupBySelected == "year") {

            result = this.getYearDays(d);

        } else {
            return null;
        }
        this.setDateRange(result, this.state.groupBySelected);
    }
    changeGranularity(value, updateDate = true) {

        this.setGranularity(value);
        this.setState({ currentDateType: value });

        if (!updateDate) {
            return;
        }

        if (value == "year") {
            this.setDateRange(this.getYearDays(new Date()), "year")
        } else if (value == "month") {
            let d = new Date();
            this.setDateRange(this.getMonthDays(d), "month")
        } else if (value == "week") {
            this.setDateRange(this.getWeekDays(new Date()), "week")
        } else if (value == "day") {
            this.setDateRange([new Date(), new Date()], "day");
        } else if (value = "custom") {
            this.setDateRange([new Date(), new Date()], "custom");
        }
    }

    setChevron(value, gran) {
        let today = new Date();

        if (gran == "day") {
            let futureDate = new Date((value[0].getMonth() + 1) + '/' + value[0].getDate() + '/' + value[0].getFullYear());
            futureDate = futureDate.setDate(futureDate.getDate()+1)

            if (futureDate > today) {
                document.getElementById("chevron_right").disabled = true
            }
            else {
                document.getElementById("chevron_right").disabled = false
            }
        }
        else if (gran == "week") {

            let futureDate = new Date((value[0].getMonth() + 1) + '/' + value[0].getDate() + '/' + value[0].getFullYear());
            futureDate.setDate(futureDate.getDate() + 7)

            if (futureDate > today) {
                document.getElementById("chevron_right").disabled = true
            }
            else {
                document.getElementById("chevron_right").disabled = false
            }
        }
        else if (gran == "month") {
            let futureDate = new Date((value[0].getMonth() + 1) + '/' + value[0].getDate() + '/' + value[0].getFullYear());
            futureDate.setMonth(futureDate.getMonth() + 1)

            if (futureDate > today) {
                document.getElementById("chevron_right").disabled = true
            }
            else {
                document.getElementById("chevron_right").disabled = false
            }
        }
        else if (gran == "year") {
            let futureDate = new Date((value[0].getMonth() + 1) + '/' + value[0].getDate() + '/' + value[0].getFullYear());
            futureDate.setYear(futureDate.getFullYear() + 1)

            if (futureDate > today) {
                document.getElementById("chevron_right").disabled = true
            }
            else {
                document.getElementById("chevron_right").disabled = false
            }
        }
        else{
            let futureDate = new Date((value[0].getMonth() + 1) + '/' + value[0].getDate() + '/' + value[0].getFullYear());
            futureDate = futureDate.setDate(futureDate.getDate() + 1)

            if (futureDate > today) {
                document.getElementById("chevron_right").disabled = true
            }
            else {
                document.getElementById("chevron_right").disabled = false
            }
        }
    }

    setCurrentDateType() {
        this.changeGranularity(this.state.currentDateType, true);
    }

    render() {
        const { allowedMaxDays, allowedRange } = DateRangePicker;
        const { t } = this.props;
        const months = [
            t('monthNames.jan'),
            t('monthNames.feb'),
            t('monthNames.mar'),
            t('monthNames.apr'),
            t('monthNames.may'),
            t('monthNames.jun'),
            t('monthNames.jul'),
            t('monthNames.aug'),
            t('monthNames.sep'),
            t('monthNames.oct'),
            t('monthNames.nov'),
            t('monthNames.dec'),
        ];

        const locale = {
            localize: {
                month: n => months[n]
            },
            formatLong: {
                date: () => 'MM/yyyy'
            }
        }

        let dataLoaded = true;

        if (this.props.paramObj != null) {
            dataLoaded = true;
            this.setState();
        }



        const toggle = (event) => {

            if (this.state.groupBySelected == "year") {
                if (!this.state.calYearOpened) {
                    this.componentYear.setOpen(true);
                }
            } else if (this.state.groupBySelected == "month") {
                if (!this.state.calMonthOpened) {
                    this.componentMonth.setOpen(true);
                }
            }
            else {
                if (!this.state.calOpened) {
                    this.component.open();
                }
            }

        }
        const rightButtonClick = (event) => {
            this.setActualRange(true);
        }
        const leftButtonClick = (event) => {
            this.setActualRange(false);
        }

        const openMenu = () => {
            this.selectItem.focus();
            this.setState({ multi: true });
        };


        const closeMenu = () => {
            this.setState({ multi: false });
        };


        const handleGroupBySelected = (event) => {

            this.changeGranularity(event.target.value);
        };


        const ranges = [
            {
                label: 'today',
                value: [new Date(), new Date()]
            }
        ];
        const {
            afterToday,
        } = DateRangePicker;


        const dateToday = new Date();
        const today = dateToday.getFullYear() + '-' + (dateToday.getMonth() + 1) + '-' + dateToday.getDate();

        if (dataLoaded) {

            return (
                <div className={this.props.className + " dateSelector calendarModal"} style={this.props.style} >
                    <div className="childCalendar" ref={(r) => {
                        this.child = r;
                    }}> </div>
                    <div style={{ position: "relative", display: "flex" }}>
                        <div style={{ display: "flex" }}>

                            <Button size="small" style={{ marginRight: "15px" }} className="primary centeredButtonText" onClick={() => this.setCurrentDateType()}>{ t('tables.today')}</Button>

                            <Select
                                variant="standard"
                                value={this.state.groupBySelected}
                                onChange={handleGroupBySelected}
                                ref={node => (this.selectItem = node)}
                                onFocus={openMenu}
                                onBlur={closeMenu}
                                className="selectCalendarGranularity">
                                {this.timeframes.includes("custom") ? (<MenuItem value="custom"> {t('tables.custom')} </MenuItem>) : null}
                                {this.timeframes.includes("day") ? (<MenuItem value="day"> {t('tables.day')} </MenuItem>) : null}
                                {this.timeframes.includes("week") ? (<MenuItem value="week"> {t('tables.week')} </MenuItem>) : null}
                                {this.timeframes.includes("month") ? (<MenuItem value="month"> {t('tables.month')} </MenuItem>) : null}
                                {this.timeframes.includes("year") ? (<MenuItem value="year"> {t('tables.year')} </MenuItem>) : null}
                            </Select>

                        </div>
                        <div className="buttonScroll configButton toFilters mt-0 d-none" style={{ position: "relative", padding: 0 }}>
                            <span>
                                <span className="t2" style={{ position: "relative", display: "block", margin: 0 }}>
                                    <img alt="Filter" style={{ width: "24px", top: "50%", position: "absolute", left: "8px", transform: "translate(0,-50%)" }} src="https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/11_filters.svg" />


                                </span>
                            </span>
                        </div>
                        <div style={{ width: "20px" }}>
                        </div>
                        <div className="date-container calendar">
                            <div className="chevron">
                                <button id="chevron_left" className="chevron_left" onClick={leftButtonClick}>
                                </button>
                            </div>
                            <div className="date-content" onClick={toggle}>
                                <span className="date-text t2 p-0 m-0"  >{this.getDateText()} </span>
                            </div>
                            <div className="chevron">
                                <button id="chevron_right" className="chevron_right" onClick={rightButtonClick}>
                                </button>
                            </div>
                            <DatePicker
                                className="calYearSelector"
                                selected={this.state.dateRange[0]}
                                onChange={(date) => { this.setRange(date) }}
                                showYearPicker
                                dateFormat="yyyy"
                                maxDate={new Date(today)}
                                onCalendarOpen={() => {
                                    this.state.calYearOpened = true;
                                }}
                                onCalendarClose={() => {
                                    this.state.calYearOpened = false;
                                }}
                                ref={(r) => {
                                    this.componentYear = r;

                                }}
                                locale={locale}
                                popperPlacement="left-start"
                            />
                            <DatePicker
                                className="calYearSelector month"
                                selected={this.state.dateRange[0]}
                                onChange={(date) => { this.setRange(date); }}
                                dateFormat="MM/yyyy"
                                maxDate={new Date(today)}
                                showMonthYearPicker
                                onCalendarOpen={() => {
                                    this.state.calMonthOpened = true;
                                }}
                                onCalendarClose={() => {
                                    this.state.calMonthOpened = false;
                                }}
                                ref={(r) => {
                                    this.componentMonth = r;

                                }}
                                locale={locale}
                                popperPlacement="left-start"
                            />
                            <CustomProvider locale={this.state.locale} >
                                <DateRangePicker
                                    oneTap={this.state.groupBySelected != "custom"}
                                    isoWeek
                                    format="dd-MM-yyyy"
                                    disabledDate={afterToday()}
                                    appearance='subtle'
                                    value={this.state.dateRange}
                                    showOneCalendar
                                    ranges={this.state.groupBySelected == "day" ? ranges : []}
                                    onOpen={() => {
                                        this.state.calOpened = true;
                                    }}
                                    onClose={() => {
                                        this.state.calOpened = false;
                                    }}
                                    ref={(r) => {
                                        this.component = r;

                                    }}



                                    hoverRange={this.state.groupBySelected == 'week' ? this.state.groupBySelected : null}

                                    container={() => this.child}
                                    onChange={value => {
                                        if (this.state.groupBySelected != "year") {
                                            this.setDateRange(value);
                                        }


                                    }}
                                    style={{ display: "none" }}
                                    className="z-100"
                                />
                            </CustomProvider>
                        </div>

                    </div>
                </div>
            );

        }
        else {
            return (<div className="row loadingParent">< div className="loadingImg"></div></div >);
        }
    }
}
export default withTranslation()(CustomDateSelector)