import React, { Component, useContext } from 'react';
import '../../App.css';
import InvitationsHttpClient from '../../HttpClient/InvitationsHttpClient';
import AuthenticationContext from '../../Authentication/types/AuthContextType';
import moment from 'moment';
import Moment from 'react-moment';
import { withTranslation } from 'react-i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Message from '../Message/Message';
import Table from '../CustomComponents/ReactTable/Table';

class InvitationsOutbox extends Component {
  static contextType = AuthenticationContext;
  constructor(props) {
    super(props);
    this.id = this.props.id;
    this.state = {
      invitations: {},
      numInvitations: 0,
      page: localStorage.getItem('InvitationsInboxPage') ? parseInt(localStorage.getItem('InvitationsInboxPage')) : 1,
      sizePerPage: localStorage.getItem('InvitationsInboxSizePerPage') ? parseInt(localStorage.getItem('InvitationsInboxSizePerPage')) : 5,
      showDelete: false,
      showInvitation: false,
      showAcceptInvitation: false,
      showRejectInvitation: false,
      message: null,
    };

    this.paginaActual = 1;

    this.confirmAcceptInvitation = this.confirmAcceptInvitation.bind(this);
    this.confirmRejectInvitation = this.confirmRejectInvitation.bind(this);
    if (this.props.userPage != null && this.props.userPage) {
      this.userPage = this.props.userPage;
    } else {
      this.userPage = false;
    }

    const { t } = props;

    this.columns = [
      {
        dataField: 'id',
        text: 'Id',
        align: 'left',
        sort: true,
        hidden: true,
        headerStyle: () => {
          return { width: '0%' };
        },
      },
      {
        dataField: 'senderMail',
        text: t('invitation.from'),
        align: 'left',
        sort: true,
        headerStyle: () => {
          return { width: '20%' };
        },
      },
      {
        dataField: 'installationId',
        text: 'InstallationId',
        align: 'left',
        sort: true,
        hidden: true,
        headerStyle: () => {
          return { width: '0%' };
        },
      },
      {
        dataField: 'gatewayId',
        text: 'GatewayId',
        align: 'left',
        sort: true,
        hidden: true,
        headerStyle: () => {
          return { width: '0%' };
        },
      },
      {
        dataField: 'installationName',
        text: t('invitation.sitesInstallations'),
        align: 'left',
        sort: true,
        hidden: !this.userPage,
        headerStyle: () => {
          return { width: '18%' };
        },
      },
      {
        dataField: 'gatewayName',
        text: 'Gateways',
        align: 'left',
        sort: true,
        hidden: !this.userPage,
        headerStyle: () => {
          return { width: '11%' };
        },
      },
      {
        dataField: 'type',
        text: t('invitation.type'),
        align: 'left',
        sort: true,
        hidden: false,
        formatter: this.TypeFormatter.bind(this),
        headerStyle: () => {
          return { width: '15%' };
        },
      },
      {
        dataField: 'state',
        text: t('invitation.state'),
        align: 'left',
        sort: true,
        hidden: false,
        headerStyle: () => {
          return { width: '9%' };
        },
      },
      {
        dataField: 'expiredAt',
        text: t('invitation.expiredAt'),
        align: 'left',
        formatter: this.DateFormatter.bind(this),
        sort: true,
        headerStyle: () => {
          return { width: '11%' };
        },
      },
      {
        dataField: 'AcceptInvitationFormatter',
        text: ' ',
        align: 'center',
        formatter: this.AcceptInvitationFormatter.bind(this),
        headerStyle: () => {
          return { width: '50' };
        },
      },
      {
        dataField: 'RejectInvitationFormatter',
        text: ' ',
        align: 'center',
        formatter: this.RejectInvitationFormatter.bind(this),
        headerStyle: () => {
          return { width: '50' };
        },
      },
    ];

    this.customTotal = (from, to, size) => (
      <span className='react-bootstrap-table-pagination-total' style={{ marginLeft: '5px' }}>
        {t('tables.customTotal', { from: from, to: to, size: size })}
      </span>
    );

    this.defaultSorted = [
      {
        sortName: 'expiredAt', //default sort column name
        sortOrder: 'desc', //default sort order
      },
    ];
  }

  renderMessage = (type, content) => {
    this.setState({
      message: {
        type: type,
        content: content,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({ message: null });
  };

  TypeFormatter({ row, value }) {
    if (row.type == null) {
      return <span>Installation access</span>;
    } else {
      switch (row.type.toLowerCase()) {
        case 'installationaccess':
          return <span>Installation access</span>;
        case 'installationtransferownership':
          return <span>Installation Transfer ownership</span>;
        case 'gatewaytransferownership':
          return <span>Gateway transfer ownership</span>;
      }
    }
  }

  DateFormatter({ row, value }) {
    if (row.expiredAt == null) {
      return <span></span>;
    } else {
      return <span>{moment(row.expiredAt).format('DD/MM/YYYY')}</span>;
    }
  }

  AcceptInvitationFormatter({ row, value }) {
    const onClick = (evt) => {
      this.setState({ showAcceptInvitation: true, invitationId: evt.currentTarget.id.split('_')[1] });
    };

    var idEle = 'btnAcceptInvitation_' + row.id;

    if (row.state == null || row.state == 'Pending') {
      return (
        <CheckCircleOutlineIcon
          id={idEle}
          title='Accept Invitation'
          style={{ width: '24px', cursor: 'pointer', color: '#2ecc71' }}
          onClick={(evt) => onClick(evt)}
        />
      );
    } else {
      return null;
    }
  }

  RejectInvitationFormatter({ row, value }) {
    const onClick = (evt) => {
      this.setState({ showRejectInvitation: true, invitationId: evt.currentTarget.id.split('_')[1] });
    };

    var idEle = 'btnRejectInvitation_' + row.id;
    if (row.state == null || row.state == 'Pending') {
      return (
        <HighlightOffIcon
          id={idEle}
          title='Reject Invitation'
          style={{ width: '24px', cursor: 'pointer', color: '#e74c3c' }}
          onClick={(evt) => onClick(evt)}
        />
      );
    } else {
      return null;
    }
  }

  async confirmAcceptInvitation() {
    const { t } = this.props;
    let messageObj = {};

    try {
      let data = await this.http.AcceptInvitation(this.state.invitationId);

      if (data.errors == null) {
        messageObj.message = t('invitation.invitationAccepted');
        messageObj.type = 'success';
      } else {
        messageObj.message = t('invitation.errorAcceptingInvitation');
        messageObj.type = 'error';
      }
      this.setState({ showAcceptInvitation: false, invitations: null }, () => {
        this.getInvitations(this.state.page, this.state.sizePerPage);
      });
      if (messageObj.type) {
        this.renderMessage(messageObj.type, messageObj.message);
      }
      await this.context.refreshInvitationsAndRequestsPending();      
    } catch (error) {
      console.error('Error accepting invitation:', error);
      this.setState({ showAcceptInvitation: false });
      this.renderMessage('error', t('invitation.errorAcceptingInvitation'));
    }
  }

  async confirmRejectInvitation() {
    const { t } = this.props;
    let messageObj = {};

    try {
      let data = await this.http.RejectInvitation(this.state.invitationId);
      if (data.errors == null) {
        messageObj.message = t('invitation.invitationRejected');
        messageObj.type = 'success';
      } else {
        messageObj.message = t('invitation.errorRejectingInvitation');
        messageObj.type = 'error';
      }

      this.setState({ showRejectInvitation: false, invitations: null }, () => {
        this.getInvitations(this.state.page, this.state.sizePerPage);
      });
      if (messageObj.type) {
        this.renderMessage(messageObj.type, messageObj.message);
      }
      await this.context.refreshInvitationsAndRequestsPending();
      } catch (error) {
      console.error('Error rejecting invitation:', error);
      this.setState({ showAcceptInvitation: false });
      this.renderMessage('error', t('invitation.errorRejectingInvitation'));
    }
  }

  componentDidMount() {
    this.http = new InvitationsHttpClient(this.context);
    this.getInvitations(this.state.page, this.state.sizePerPage);
  }

  componentWillUnmount(){
    localStorage.removeItem("InvitationsInboxSizePerPage");
    localStorage.removeItem("InvitationsInboxPage");
  }

  handleTableChange = (type, { page, sizePerPage }) => {
    this.getInvitations(page, sizePerPage);
  };

  async getInvitations(page, sizePerPage) {
    try {
      this.setState({ invitations: {} });
      var data = null;
      if (this.id != null) data = await this.http.getInvitationsReceivedInstallation(this.id, page, sizePerPage);
      else data = await this.http.getInvitationsReceived(page, sizePerPage);

      this.setState({ invitations: data, numInvitations: data.content.length });
    } catch (error) {
      console.error('Error with invitation:', error);
      this.renderMessage('error', t('invitation.errorReceivingInvitation'));
    }
  }

  dropdownPermissions() {
    const handleChange = (event) => {
      this.setState({ newRole: event.target.value });
    };
    const { t } = this.props;

    let dataLoaded = this.state.roles != undefined;
    let listitems = null;
    if (dataLoaded) {
      listitems = this.state.roles.map((item, i) => {
        return <MenuItem value={item.id}>{item.name}</MenuItem>;
      });

      return (
        <FormControl variant='standard'>
          <InputLabel id='permissionLabel'>Select permission level</InputLabel>
          <Select
            variant='standard'
            name='permission'
            className='titleProperty1'
            labelId='permissionLabel'
            placeholder='Select an option'
            id='ddlRoles'
            value={this.state.role}
            onChange={handleChange}
          >
            {listitems}
          </Select>
        </FormControl>
      );
    }
  }

  toggleInvitations() {
    this.setState({ showInvitation: !this.state.showInvitation });
  }

  async getRoles() {
    let data = await this.http.getRoles();
    this.setState({ roles: data.content });
  }

  render() {
    const { t } = this.props;
    let dataLoaded = this.state.invitations?.content != undefined;
    
    const handleDeleteInvitation = () => {
      this.setState({ showDelete: false });
    };
    const handleAcceptInvitation = () => {
      this.setState({ showAcceptInvitation: false });
    };
    const handleRejectInvitation = () => {
      this.setState({ showRejectInvitation: false });
    };

    const onSizePerPageChange = (sizePerPage) => {
      localStorage.setItem('InvitationsInboxSizePerPage', sizePerPage)
      this.setState({ sizePerPage: sizePerPage });
    }
  
    const onPageChange = (newPage) => {
      localStorage.setItem('InvitationsInboxPage', newPage)
      this.paginaActual = newPage;
      this.setState({ page: newPage });
    }
    return (
      <div className='col' style={{ padding: 0, margin: 0 }}>
        {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}
        <div className='row col-12' style={{ padding: 0, margin: 0 }}>
          {dataLoaded ? (
            <div className='col chartContainer' style={{ padding: 0, margin: 0 }}>
              {!this.userPage ? (
                <div className='row ml-1'>
                  {' '}
                  <h3>{t('invitation.invitationtitle')}</h3>{' '}
                </div>
              ) : null}
              <Table
                name={'InvitationsInbox-locales'}
                t={t}
                resize={true}
                columns={this.columns}
                onSizePerPageChange={onSizePerPageChange}
                onPageChange={onPageChange}
                rows={this.state.invitations.content.sort(function (a, b) {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                })}
                search={true}
                page={this.state.page}
                pageSize={this.state.sizePerPage}
              />
              <Dialog disableEscapeKeyDown open={this.state.showDelete} onClose={handleDeleteInvitation}>
                <DialogTitle>{t('invitation.deleteInvitation')}</DialogTitle>
                <DialogContent>
                  <div>
                    <span>{t('invitation.confirmDeleteInvitation')}</span>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button className='primary pull-right' onClick={() => this.confirmDelete(t)}>
                    {t('actions.yes')}
                  </Button>
                  <Button className='primary pull-right' onClick={handleDeleteInvitation}>
                    {t('actions.no')}
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog disableEscapeKeyDown open={this.state.showAcceptInvitation} onClose={handleAcceptInvitation}>
                <DialogTitle>{t('invitation.acceptInvitation')}</DialogTitle>
                <DialogContent>
                  <div>
                    <span>{t('invitation.confirmAcceptInvitation')}</span>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button className='primary pull-right' onClick={() => this.confirmAcceptInvitation()}>
                    {t('actions.yes')}
                  </Button>
                  <Button className='primary pull-right' onClick={handleAcceptInvitation}>
                    {t('actions.no')}
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog disableEscapeKeyDown open={this.state.showRejectInvitation} onClose={handleRejectInvitation}>
                <DialogTitle>{t('invitation.rejectInvitation')}</DialogTitle>
                <DialogContent>
                  <div>
                    <span>{t('invitation.confirmRejectInvitation')}</span>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button className='primary pull-right' onClick={() => this.confirmRejectInvitation()}>
                    {t('actions.yes')}
                  </Button>
                  <Button className='primary pull-right' onClick={handleRejectInvitation}>
                    {t('actions.no')}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          ) : (
            <div className='loadingParent'>
              <div className='loadingImg'></div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(InvitationsOutbox);
