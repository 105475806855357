import React, { Component } from 'react';
import '../../App.css';
import AuthenticationContext from '../../Authentication/types/AuthContextType';
import EmailIcon from '@mui/icons-material/Email';
import InboxIcon from '@mui/icons-material/Inbox';

import { withTranslation } from 'react-i18next';
import RequestsInbox from './RequestsInbox';
import RequestsOutbox from './RequestsOutbox';

import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/material/styles';

function SetClasses() {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: 500,
    },
  }));
  return useStyles();
}

function SetStyles() {
  const classes = SetClasses();
  return classes;
}

function SetTheme() {
  const theme = useTheme();
  return theme;
}

class RequestsList extends Component {
  static contextType = AuthenticationContext;
  constructor(props) {
    super(props);

    if (this.props.id) this.id = this.props.id;
    else this.id = null;

    if (this.props.userPage != null && this.props.userPage) {
      this.userPage = this.props.userPage;
    } else {
      this.userPage = false;
    }

    this.state = {
      tabValue: 0,
      tabIndex: 0,
    };
  }

  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  handleChangeIndex = (index) => {
    this.setState({ tabIndex: index });
  };

  render() {
    const { t } = this.props;
    function TabPanel(props) {
      const { children, value, index, ...other } = props;

      return (
        <div
          role='tabpanel'
          hidden={value !== index}
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box p={3}>
              <Typography component={'span'}>{children}</Typography>
            </Box>
          )}
        </div>
      );
    }

    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    };

    function a11yProps(index) {
      return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
      };
    }

    return (
      <div className='col chartContainer'>
        {/* <div className="row ml-1">
                            <h3>{t('invitation.requesttitle')}</h3>
                        </div> */}
        <AppBar position='static' color='default'>
          <Tabs value={this.state.tabValue} onChange={this.handleChange} indicatorColor='primary'>
            <Tab label={<h3 className='tabTitle'>{t('invitation.inbox')}</h3>} icon={<InboxIcon />} {...a11yProps(0)} />
            <Tab label={<h3 className='tabTitle'>{t('invitation.outbox')}</h3>} icon={<EmailIcon />} {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={SetTheme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.tabValue}
          onChangeIndex={this.handleChangeIndex}
        >
          <TabPanel value={this.state.tabValue} index={0} dir={SetTheme.direction}>
            <RequestsInbox id={this.id} userPage={this.userPage} />
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={1} dir={SetTheme.direction}>
            <RequestsOutbox id={this.id} userPage={this.userPage} />
          </TabPanel>
        </SwipeableViews>
      </div>
    );
  }
}

export default withTranslation()(RequestsList);