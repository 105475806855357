import React, { Component } from 'react';
import { Container } from 'reactstrap';
import NavFooter from './NavFooter';
import HttpClient from '../HttpClient/HttpClient';
import AuthenticationContext from "../Authentication/types/AuthContextType";
import * as CryptoJS from 'crypto-js';
import GeneralNavMenuContainer from './GeneralNavMenu/container/GeneralNavMenuContainer'


export class Layout extends Component {
    static displayName = Layout.name;
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.state = { user: null, brandCode: null, brandName: null, dataLoaded: false };
        // Declare this key and iv values in declaration
        this.key = CryptoJS.enc.Utf8.parse('4512631236589784');
        this.iv = CryptoJS.enc.Utf8.parse('4512631236589784');

    }

    // Method decrypt Using AES
    decryptUsingAES256(decString) {
        let decrypted = CryptoJS.AES.decrypt(decString, this.key, {
            keySize: 128 / 8,
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    async b2cConfig() {
        let httpClient = new HttpClient();
        let data = await httpClient.getConfigB2c();
        let decrypted = this.decryptUsingAES256(data);
        return JSON.parse(decrypted);
    }


    async componentDidMount() {
        let b2c = await this.b2cConfig();
        this.setState({ brandName: b2c.brandName, brandCode: b2c.brandCode, dataLoaded:true});
    }

    render() {
        let dataLoaded = this.state.dataLoaded;
        let linkIcons = document.createElement('link');
        linkIcons.rel = 'stylesheet';
        linkIcons.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
        linkIcons.type = 'text/css';
        linkIcons.id = 'linkIconsStyleSheet'
        document.head.appendChild(linkIcons);

        let oldMeta = document.getElementById("brandName");

        if (oldMeta) oldMeta.remove();

        let meta = document.createElement('meta');
        meta.property = "og:site_name";
        meta.id = 'brandName';
        meta.content = this.state.brandName;
        document.head.appendChild(meta);

        let oldMetaCode = document.getElementById("brandCode");

        if (oldMetaCode) oldMetaCode.remove();

        let metaCode = document.createElement('meta');
        metaCode.property = "og:site_name";
        metaCode.id = 'brandCode';
        metaCode.content = this.state.brandCode;
        document.head.appendChild(metaCode);


        let favicon = document.getElementById("favIcon");
        if (this.state.brandCode == 11) {
            favicon.href = "https://www.laufen.com/o/laufen-theme/images/favicon.ico";
            document.title = "Laufen"
        }
        else if (this.state.brandCode == 14) {
            favicon.href = "https://www.roca.es/o/roca-theme/images/favicon.ico";
            document.title = "Roca"
        }

        let hideHeaderFooter = (window.location.pathname.includes("welcomeRoca") || window.location.pathname.includes("welcomeLaufen") )? true : false;

        return (
            <div>
                {
                    dataLoaded ? (

                        <div style={{ minHeight: '100vh' }}>
                            { hideHeaderFooter ? (
                                <Container className="" style={{ minHeight: '70vh' }}>
                                    {this.props.children}
                                </Container>
                            )
                                :
                                (
                                    <div style={{ margin: 0, padding: 0 }}>
                                        <GeneralNavMenuContainer brandcode={this.state.brandCode} context={this.context}/>
                                        <div className="" style={{ minHeight: '70vh'}}>
                                        {this.props.children}
                                        </div>
                                        <NavFooter isLogged={true} style={{ clear: 'both', position: 'relative', height: '200px', marginTop: '-200px' }} />
                                    </div>
                                )
                            }

                        </div>

                    ) : (<div></div>)
                }
             
                 
            </div>


        );
    }

}