import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import '../../App.css';
import GatewaysHttpClient from "../../HttpClient/GatewaysHttpClient";
import ProductHttpClient from "../../HttpClient/ProductHttpClient";
import SitesHttpClient from "../../HttpClient/SitesHttpClient";
import InstallationHttpClient from "../../HttpClient/InstallationsHttpClient";
import AuthenticationContext from "../../Authentication/types/AuthContextType";
import FilterListPage from './FilterListPage';
import Button from '@mui/material/Button';
import { withRouter } from '../withRouter';

class BaseFilter extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.state = {
            language: this.props.i18n.language
        }

        this.contentType = (window.location.pathname.split('/')[2]);
        this.objTypes = { product: "productsFilter", all: "generalFilter", installation: "installations", gateways: "gateways", sites: "sites" };

        /* CONFIG PARAMETERS */

        //If true when we click any filter it will filter the menu to hidde empty results
        this.menuFilter = true; 

        // Default filter
        this.filter = this.props.filter ? this.props.filter : { 
            product: {}, // productTypeId
            site: {},
            installation: {}, //id
            gateway: {} // gatewayTypeId
        }

        //Custom visualization order, if we remove one item it will not show
        this.order = this.props.order ? this.props.order : this.defaultOrder();

        // Filter attributes that will use when search by text
        this.searchAttrFilter = {
            productsFilter: ['puuid', 'installation.name', 'designLine', 'name', 'productType.sku', 'productType.name'],
            installations: ['name', 'address'],
            sites: ['name'],
            gateways: ['name', 'gatewayId']
        };

        /* ----------------- */


        this.alert = "error";
        this.isLaufen = false;       

        this.listContent = {};
       
        this.search = "";

        this.state = {
            menuContent: [],
            resultContent: [],
            showMenu: false,
            search:"",
            noContent: false,
            firstLoad: true,
            dataLoaded: false
        }


    }
    componentDidMount() {

        this.isLaufen = this.context.brandCode == 11;
        this.checkFilters(true);
    }

    componentDidUpdate() {
        if(this.state.language !== this.props.i18n.language){
            this.setState({ language: this.props.i18n.language });
        }

        if (this.contentType != (window.location.pathname.split('/')[2])) {
            this.contentType = (window.location.pathname.split('/')[2]);
            window.scrollTo({ top: 0 });
            this.resetFilters();
            this.checkFilters(true);
        }

    }

    //We reset all the filters, so if we change the page it will reset all as default. 
    resetFilters() {
        this.search = "";
        this.filter = {
            product: {},
            site: {},
            installation: {},
            gateway: {}
        }
        //this.listContent = {};
        this.setState({
            showMenu: false, search: "", menuContent: [], resultContent: [], noContent: false, firstLoad: true, dataLoaded: false
        });
        this.order = this.props.order ? this.props.order : this.defaultOrder();
    }

    //We check the order that the items will visualize, if one obj is not declared it will not show. 
    defaultOrder() {
       
        switch (this.contentType) {
            case this.objTypes.product:
                return [{ type: "products" }, { type: "sites" },{ type: "installations" },{ type: "gateways" }]
                break;
            case this.objTypes.all:
                return [{ type: "products" }, { type: "sites" }, { type: "installations" }, { type: "gateways" }]
                break;
            case this.objTypes.installation:
                return [{ type: "sites", showContent: false }, { type: "installations" , showMenu: false}, { type: "gateways" }, { type: "products" }]
                break;
            case this.objTypes.gateways:
                return [{ type: "gateways" }, { type: "sites" }, { type: "installations" }, { type: "products" }]
                break;
            default:
                return [];
        }
        return [];
    }

    //When a filter is aply, it must show the content filtered first
    getOrderedKeys(arrayToSort) {
        const keysWithValue = [];
        const keysWithoutValue = [];
        let change = false;
        for (const key in this.filter) {
            if (Object.hasOwnProperty.call(this.filter, key)) {
                if (Object.keys(this.filter[key]).length > 0) {
                    keysWithValue.push(key);
                    change = true;
                } else {
                    keysWithoutValue.push(key);
                }
            }
        }
        let keysOrder = keysWithValue.concat(keysWithoutValue);
        if (change) {
            arrayToSort.sort((a, b) => {
                return keysOrder.indexOf(a.key) - keysOrder.indexOf(b.key);
            });
        }
        
        return arrayToSort;
    }

    //We get all the url parameters, so we can add filter by url.
    //  To add a filter it must follow ->
    //      "name of object"-"id"="value".Ex: product - productTypeId=1
    //      Result this.filter.product = {productTypeId: 1}
    searchUrl() {
        const queryString = require('query-string').default;
        const parsed = queryString.parse(this.props.location.search);

        for (let e in parsed) {
            let elem = e.split("-");
            let parent = elem[0];
            switch (parent.toLowerCase()) {
                case "gateway": 
                case "installation":
                case "product":
                case "site":
                    if (elem.length > 0) {
                        this.filter[parent][elem[1]] = parsed[e];
                    }
                    break;
                case "s":
                    if (parsed.s != "") {
                        this.setState({ search: parsed.s });
                        this.search = parsed.s;
                    }
                    break;
            }
        }
    }

    

    //Main function. 
    //  Fill menu items and result content
    async checkFilters(firstLoad) {
        let retContent = [];
        let retMenu = [];
        let val = null;
        let shouldShowContent = false;
        let shouldFillProducts = false;
        if(firstLoad) this.searchUrl();
        
        if (!this.listContent[this.objTypes.gateways]) {

            this.httpGateways = new GatewaysHttpClient(this.context);
            let v = await this.httpGateways.GetGateways();
            this.listContent[this.objTypes.gateways] = v.content;
        }
        if (!this.listContent[this.objTypes.installation]) {

            this.httpInstallation = new InstallationHttpClient(this.context);
            let v = await this.httpInstallation.InstallationGetInstallations();
            this.listContent[this.objTypes.installation] = v.content;
        }
        if (!this.listContent[this.objTypes.sites]) {

            this.httpSites = new SitesHttpClient(this.context);
            let s = await this.httpSites.GetSites();
            this.listContent[this.objTypes.sites] = s.content;
            shouldFillProducts = true;
        }
        if (!this.listContent[this.objTypes.product]) {

            this.http = new ProductHttpClient(this.context);
            let p = await this.http.ProductsGetMyProducts();
            this.listContent[this.objTypes.product] = p.content;
        }

        if (shouldFillProducts) this.fillProductsToInstallationSites();
        
        // Changing the order will afect the final visualization,first we add first we show.
        for (let e of this.order) {
            if (e.type == "gateways") {
                shouldShowContent = (this.contentType == this.objTypes.gateways || this.contentType == this.objTypes.all) && e.showContent != false;
                val = this.getGateways(shouldShowContent);
                if (e.showMenu != false) retMenu.push(val.menu);
                if (val.content && val.content.props.content.length > 0) {
                    retContent.push(val.content);
                }
            } else if (e.type == "installations") {
                shouldShowContent = (this.contentType == this.objTypes.installation || this.contentType == this.objTypes.all) && e.showContent != false;
                val = this.getInstallations(shouldShowContent);
                if (e.showMenu != false) retMenu.push(val.menu);
                if (val.content && val.content.props.content.length > 0) {
                    retContent.push(val.content);
                }
            } else if (e.type == "products") {
                shouldShowContent = (this.contentType == this.objTypes.product || this.contentType == this.objTypes.all) && e.showContent != false;
                val = this.getProducts(shouldShowContent);
                if (e.showMenu != false) retMenu.push(val.menu);
                if (val.content && val.content.props.content.length > 0) {
                    retContent.push(val.content);
                }
            } else if (e.type == "sites") {
                shouldShowContent = (this.contentType == this.objTypes.sites || this.contentType == this.objTypes.all) && e.showContent != false;
                val = this.getSites(shouldShowContent);
                if (e.showMenu != false) retMenu.push(val.menu);
                if (val.content && val.content.props.content.length > 0) {
                    retContent.push(val.content);
                }
            }
        }
        let noContent = true;
        if (retContent.length > 0) {
            noContent = false;
        }

        retContent = this.getOrderedKeys(retContent);
        this.setState({
            menuContent: retMenu,
            resultContent: retContent,
            noContent: noContent,
            dataLoaded: true
        });
    }
    fillProductsToInstallationSites() {
        let installations = this.listContent[this.objTypes.installation];
        let sites = this.listContent[this.objTypes.sites];

        let updatedSites = sites.map(site => {
            site.installations = site.installations.map(installation => {
                let matchingInstallation = installations.find(installationToUpdate => installationToUpdate.id === installation.id);
                if (matchingInstallation) {
                    installation.products = matchingInstallation.products;
                }
                return installation;
            });
            return site;
        });
        this.listContent[this.objTypes.sites] = updatedSites;

    }
    getProducts(showContent) {
        let ret = { content: null, menu: null };
        let list = this.listContent[this.objTypes.product];
        let filteredList = null;
        list = this.filterProducts(list, true);
        filteredList = this.filterProducts(list);

        if (showContent) {
            ret.content = this.getContentItems(this.objTypes.product, filteredList);
        }
        ret.menu = this.getMenuItems(this.objTypes.product, list, filteredList);
        return ret;
    }


    filterProducts(prod, preFilter = false) {
        let res = prod;

        if (this.contentType == this.objTypes.gateways && preFilter) {
            let list = this.listContent[this.objTypes.gateways].map((e) => e.products?.map((i) => i.productTypeId));
            list = list.flat();
            res = res.filter((item) => list.includes(item.productTypeId));
            return res;
        }
        if (this.contentType == this.objTypes.installation && preFilter) {
            let list = this.listContent[this.objTypes.installation].map((e) => e.id);
            res = res.filter((item) => list.includes(item.installation?.id));
            return res;
        }
        if (this.contentType == this.objTypes.sites && preFilter) {
            let list = this.listContent[this.objTypes.sites].map((e) => e.installations?.map((i) => i.id));
            list = list.flat();
            res = res.filter((item) => list.includes(item.installation?.id));
            return res;
        }

        //filter product
        for (const [key, value] of Object.entries(this.filter?.product)) {
            if (key != 'all') {
                res = res.filter((item) => item[key] == value);
            }
            
        }

        //Filter installation
        for (const [key, value] of Object.entries(this.filter?.installation)) {
            if (key != 'all') {
                res = res.filter((item) => item.installation[key] == value);
            }
        }
        //Filter sites
        for (const [key, value] of Object.entries(this.filter?.site)) {
            if (key == 'id') {
                res = res.filter((item) => item.installation?.parentId == value);
            }
        }

        //Filter gw
        if (this.listContent[this.objTypes.gateways] && Object.entries(this.filter?.gateway).length > 0) {

            // call gw filter? 

            let gw = this.listContent[this.objTypes.gateways];
            for (const [key, value] of Object.entries(this.filter?.gateway)) {
                if (key != 'all') {
                    gw = gw.filter((item) => item[key] == value);
                }
            }
            if (gw.length > 0) {
                gw = gw.map(function (obj) {
                    return obj.id;
                });
            } else {
                gw = [];
            }
            res = res.filter((item) => gw.includes(item.gatewayId));

        }
        res = this.filterListSearch(this.objTypes.product, res);
        return res;

    }   

    getInstallations(showContent) {
        let ret = { content: null, menu: null };
        let list = this.listContent[this.objTypes.installation];
        let filteredList = null;

        //list = list.concat(sitesList);

        list = this.filterInstallations(list, true);
        filteredList = this.filterInstallations(list, false);
        if (showContent) {
            ret.content = this.getContentItems(this.objTypes.installation, filteredList);
        }
        ret.menu = this.getMenuItems(this.objTypes.installation, this.listToTree(list), this.listToTree(filteredList));
        return ret;
    }

    filterInstallations(list, preFilter = false, isSite = false) {
        let res = list;

        if (this.contentType == this.objTypes.product && preFilter) {
            let list = this.listContent[this.objTypes.product].map((e) => (isSite ? e.installation?.parentId : e.installation?.id));
            res = res.filter((item) => list.includes(item.id));
            return res;
        }
        if (this.contentType == this.objTypes.gateways && preFilter) {
            let list = this.listContent[this.objTypes.gateways].map((e) => e.installations?.map((i) => i.id));
            list = list.flat();
            res = res.filter((item) => list.includes(item.id));
            return res;
        }
        if (this.contentType == this.objTypes.installation && preFilter && isSite) {
            let list = this.listContent[this.objTypes.installation].map((e) => e.parentId);
            res = res.filter((item) => list.includes(item.id));
            return res;
        }

        
        if (isSite) {
            //Filter site
            for (const [key, value] of Object.entries(this.filter?.site)) {
                if (key != 'all') {
                    res = res.filter((item) => item[key] == value);
                }
            }
            //Filter installation
            for (const [key, value] of Object.entries(this.filter?.installation)) {
                if (key != 'all') {
                    res = res.filter((item) => item.installations.some((ins) => ins[key] == value));
                }
            }
        } else {
            //Filter installation
            for (const [key, value] of Object.entries(this.filter?.installation)) {
                if (key != 'all') {
                    res = res.filter((item) => item[key] == value);
                }
            }
            //Filter site
            for (const [key, value] of Object.entries(this.filter?.site)) {
                if (key == 'id') {
                    res = res.filter((item) => item.parentId == value);
                }
            }
        }
        
        //filter product
        for (const [key, value] of Object.entries(this.filter?.product)) {
            if (key != 'all' && !isSite) {
                res = res.filter((item) => item.products.some((prd) => prd[key] == value));
            }else if (key != 'all' && isSite) {
                res = res.filter((item) => item.installations?.some((ins) => ins.products?.some((prd) => prd[key] == value)));
            }
        }

        //Filter gw
        if (this.listContent[this.objTypes.gateways] && Object.entries(this.filter?.gateway).length > 0) {

            let gw = this.listContent[this.objTypes.gateways];
            for (const [key, value] of Object.entries(this.filter?.gateway)) {
                if (key != 'all') {
                    gw = gw.filter((item) => item[key] == value);
                }
            }
            if (gw.length > 0) {
                gw = gw.map(function (obj) {
                    return obj.installations.map((o) => o.id);
                });
                gw = gw.flat();
            } else {
                gw = [];
            }
            res = res.filter((item) => gw.includes(item.id));

        }

        res = this.filterListSearch(isSite ? this.objTypes.sites : this.objTypes.installation, res);

        return res;

    }

    getSites(showContent) {
        let ret = { content: null, menu: null };
        let list = this.listContent[this.objTypes.sites];
        let filteredList = null;

        list = this.filterInstallations(list, true, true);
        filteredList = this.filterInstallations(list, false, true);
        if (showContent) {
            ret.content = this.getContentItems(this.objTypes.sites, filteredList);
        }

        ret.menu = this.getMenuItems(this.objTypes.sites, this.listToTree(list), this.listToTree(filteredList));
        return ret;
    }


    getGateways(showContent) {
        let ret = { content: null, menu: null };
        let list = this.listContent[this.objTypes.gateways];
        let filteredList = null;
        list = this.filterGateways(list, true);
        filteredList = this.filterGateways(list,false);
        if (showContent) {
            ret.content = this.getContentItems(this.objTypes.gateways, filteredList);
        }
        ret.menu = this.getMenuItems(this.objTypes.gateways, list, filteredList);
        return ret;
    }


    filterGateways(gList, preFilter) {
        let res = gList;

        if (this.contentType == this.objTypes.product && preFilter) {
            let list = this.listContent[this.objTypes.product].map((e) => e.gatewayId);
            res = res.filter((item) => list.includes(item.id));
            return res;
        }
        if (this.contentType == this.objTypes.installation && preFilter) {
            let list = this.listContent[this.objTypes.installation].map((e) => e.id);
            res = res.filter((item) => item.installations?.some((i) => list.includes(i.id)));
            return res;
        }
        if (this.contentType == this.objTypes.sites && preFilter) {
            let list = this.listContent[this.objTypes.sites].map((e) => e.id);
            res = res.filter((item) => item.installations?.some((i) => list.includes(i.id)));
            return res;
        }
        //filter gw
        for (const [key, value] of Object.entries(this.filter?.gateway)) {
            if (key != 'all') {
                res = res.filter((item) => item[key] == value);
            }
        }
        //Filter installation
        for (const [key, value] of Object.entries(this.filter?.installation)) {
            if (key != 'all') {
                res = res.filter((item) => item.installations.some((i) => i[key] == value));
            }
        }
        //Filter installation
        for (const [key, value] of Object.entries(this.filter?.site)) {
            if (key != 'all') {
                res = res.filter((item) => item.installations.some((i) => i[key] == value));
            }
        }
        //Filter Products
        for (const [key, value] of Object.entries(this.filter?.product)) {
            if (key != 'all') {
                res = res.filter((item) => item.products.some((i) => i[key] == value));
            }
        }
        res = this.filterListSearch(this.objTypes.gateways, res);
        return res;
    }

    // It returns the result content formated by type
    getContentItems(type, list) {
   
        // actualMenuFilter should be this.filter
        if (this.objTypes.product == type) {
            return (<FilterListPage key={"product"} type={"products"} content={list} header={true} actualMenuFilter={null} viewAll={this.filterMenu.bind(this)} customType={type} />)
        }
        if (this.objTypes.installation == type) {
            return (<FilterListPage key={"installation"} type={"installations"} content={list} header={true} actualMenuFilter={null} viewAll={this.filterMenu.bind(this)} customType={type} />)
        }
        if (this.objTypes.gateways == type) {
            return (<FilterListPage key={"gateway"} type={"gateways"} content={list} header={true} actualMenuFilter={null} viewAll={this.filterMenu.bind(this)} customType={type} />)
        }
        if (this.objTypes.sites == type) {
            return (<FilterListPage key={"site"} type={"sites"} content={list} header={true} actualMenuFilter={null} viewAll={this.filterMenu.bind(this)} customType={type} />)
        }
    }

    //It returns the menu items for each type
    getMenuItems(type, list, filterList) {
        const { t } = this.props;

        if (this.menuFilter) {
            list = filterList;
        }

        let trans = "";
        let ret = null;
        if (this.objTypes.product == type) {
            trans = "productsType";
            list = this.getUnique(list, 'productTypeId');

            list = list.map((item, i) => (
                <li key={type + "menuItem" + item.productTypeId}>
                    <a onClick={this.filterMenu(type, 'productTypeId', item.productTypeId )} href='javascript.void(0)'
                        className={this.isInFilter(type, item.productTypeId) ? 'active t3' : 't3'}>{item.productType.brand}  - {item.productType.name} </a>
                </li>
            ))
        }else if (this.objTypes.installation == type) {
            trans = "installations";
            list = this.getUnique(list, 'id');
            list = this.createTree(list, true, 0, type);
            /*
            list = list.map((item, i) => (
                <li key={type + "menuItem" + item.id}>
                    <a onClick={this.filterMenu(type, 'id', item.id)} href='javascript.void(0)'
                        className={this.isInFilter(type, item.id) ? 'active t3' : 't3'}>{item.name} </a>
                </li>
            ))*/
        } else if (this.objTypes.sites == type) {
            trans = "sites";
            list = this.getUnique(list, 'id');
            list = this.createTree(list, true, 0, type);

        } else if (this.objTypes.gateways == type) {
            trans = "gatewaysType";
            list = this.getUnique(list, 'gatewayTypeId');

            list = list.map((item, i) => (
                <li key={type + "menuItem" + item.gatewayTypeId}>
                    <a onClick={this.filterMenu(type, 'gatewayTypeId', item.gatewayTypeId)} href='javascript.void(0)'
                        className={this.isInFilter(type, item.gatewayTypeId) ? 'active t3' : 't3'}>{item.gatewayType.brandName}  - {item.gatewayType.model} </a>
                </li>
            ))
        }

        if (list?.length > 0) {
            ret = <li key={"menuList" + type}>
                <h2>
                    <a className={this.isInFilter(type) ? 'active' : ''} onClick={this.filterMenu(type, 'all', true)}>{t(trans)}
                    </a>
                </h2>
                <ul className="subcategory collapse show">
                    { list }
                </ul>
            </li>
        }
        return ret;
        
    }
    getUnique(arr, index) {

        const unique = arr
            .map(e => e[index])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e])

            // eliminem els nulls
            .filter(x => x !== null);

        return unique;
    }

    listToTree(list) {

        var map = {}, node, roots = [], i;

        for (i = 0; i < list.length; i += 1) {
            map[list[i].id] = i; // initialize the map
            list[i].children1 = []; // initialize the children
        }

        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            if (node.parentId != 0) {
                if (map[node.parentId] != undefined) {
                    list[map[node.parentId]].children1.push(node);
                } else {
                    roots.push(node);
                }

            } else {
                roots.push(node);
            }
        }
        return roots

    }

    createTree(data, isSub, lev, type) {

        let level = lev || 0;
        let children = [];
        if (isSub) {
            if (level < 3) // To prevent too much padding
                level++;
        }
        for (let i in data) {
            if (typeof (data[i].children1) === 'object') {
                if (data[i].children1 != []) {
                    let d = <li key={data[i].id} className={"filter-group level-" + (level)}>
                        <a onClick={this.filterMenu(type, 'id', data[i].id)} href='javascript.void(0)'
                            className={this.isInFilter(type, data[i].id) ? 'active t3' : 't3'}
                        >{data[i].name} </a>
                        {this.createTree(data[i].children1, true, level, type)}
                    </li>;
                    if (isSub) {
                        children.push(<ul key={"ul" + data[i].id}>{d}</ul>);
                    } else {
                        children.push(d);
                    }

                }

            }
        }
        return children;
    }

    isInFilter(type, id = null) {
        if (this.objTypes.product == type) {
            if (this.filter.product?.all) {
                return id != null ? false : true;
            } else if (id != null) {
                return this.filter.product?.productTypeId != undefined && this.filter.product?.productTypeId == id;
            }
        }else if (this.objTypes.installation == type ) {
            if (this.filter.installation?.all) {
                return id != null ? false : true;
            } else if (id != null){
                return this.filter.installation?.id != undefined && this.filter.installation?.id == id;
            }
        } else if (this.objTypes.sites == type) {
            if (this.filter.site?.all) {
                return id != null ? false : true;
            } else if (id != null) {
                return this.filter.site?.id != undefined && this.filter.site?.id == id;
            }
        } else if (this.objTypes.gateways == type) {
            if (this.filter.gateway?.all) {
                return id != null ? false : true;
            } else if (id != null) {
                return this.filter.gateway?.gatewayTypeId != undefined && this.filter.gateway?.gatewayTypeId == id;
            }
        }
        return false;
    }

    // We change the this.filter values, this function is called by menu items
    filterMenu = (type, atr, value) => (e) => {
        e.preventDefault();

        if (atr == undefined) {
            this.filter = {
                product: {},
                site: {},
                installation: {},
                gateway: {}
            }
            type = null;
        }

        if (this.objTypes.product == type) {
            
            if (this.filter.product[atr] == value) {
               
                delete this.filter.product[atr];
                
                
            } else {
                if (atr == "all") {
                    this.filter.product = {};
                } else {
                    this.filter.product[atr] = value;
                }
                
            }
        } else if (this.objTypes.installation == type) {
            if (this.filter.installation[atr]) {
                delete this.filter.installation[atr];
            } else {
                if (atr == "all") {
                    this.filter.installation = {};
                }
                this.filter.installation[atr] = value;
            }
        } else if (this.objTypes.sites == type) {
            if (this.filter.site[atr]) {
                delete this.filter.site[atr];
            } else {
                if (atr == "all") {
                    this.filter.site = {};
                }
                this.filter.site[atr] = value;
            }
        } else if (this.objTypes.gateways == type) {
            if (this.filter.gateway[atr]) {
                delete this.filter.gateway[atr];
            } else {
                if (atr == "all") {
                    this.filter.gateway = {};
                }
                this.filter.gateway[atr] = value;
            }
        }
        this.checkFilters();

    }

    filterListSearch(type, list) {
        const text = this.search;
        const searchAttrs = this.searchAttrFilter[type];
        if (!searchAttrs || text == "") {
            return list;
        }

        return list.filter(item => {
            for (let i = 0; i < searchAttrs.length; i++) {
                const attrs = searchAttrs[i].split('.');
                let value = item;
                for (let j = 0; j < attrs.length; j++) {
                    value = value[attrs[j]];
                    if (value === undefined) {
                        break;
                    }
                }
                if (value && value.toLowerCase().includes(text.toLowerCase())) {
                    return true;
                }
            }
            return false;
        });
    }

    searchChange = (e) => {
        this.setState({ search: e.target.value })
        this.search = e.target.value;
        this.checkFilters();
    }
    menuCollapse = (e) => {
        e.preventDefault();
        this.setState({ showMenu: false });
    }
    menuOpen = (e) => {
        e.preventDefault();
        this.setState({ showMenu: true });
    }
    historyBackClick = (e) => {
        if (Router.History.length > 1) {
            Router.History.back();
        } else {
            var currentRoutes = this.context.router.getCurrentRoutes();
            var routeName = currentRoutes[currentRoutes.length - 2].name;
            this.context.router.transitionTo(routeName);
        }
    }
    // It returns the elements in case the filters return no data
    getContentRemoveFilters() {
        const { t } = this.props;
        let result = (<></>)

        if (this.state.noContent) {
            let elemNoData = (<h1 className="">{t('nodata')}</h1>);
            let elemRemoveBtn;

            if (this.props.location.search) {
                elemRemoveBtn = (<Button size="small" className="primary" onClick={() => this.removeUrlFilter()}>{t('actions.removeFilters')}</Button>);
            }

            result = (<div className="noDataAvailable">{elemNoData}{elemRemoveBtn}</div>);
        }

        return result;

    }

    removeUrlFilter() {
        this.props.navigate(this.props.location.pathname);
        this.resetFilters();
        this.checkFilters();
    }

    render() {

        const { t } = this.props;

        return (
            <div className="" style={{ margin: '10px 0' }}>
                <section className="portlet" id="portlet_RocaSearch">
                    <div className="portlet-content">
                        <div className="autofit-float autofit-row portlet-header">
                            <div className="autofit-col autofit-col-expand">
                            </div>
                            <div className="autofit-col autofit-col-end">
                                <div className="autofit-section">
                                </div>
                            </div>
                        </div>
                        <div className=" portlet-content-container">
                            <div className="portlet-body">
                                <section className="search-box">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="close-back"><a href="#!" onClick={this.historyBackClick} className="js-close icon-cross-24">
                                                    <span><img alt="Close" style={{ height: '30px' }} src="close.svg" className="hidden" /></span>
                                                </a></p>
                                                <input className="search-box-input" id="searchBox" name="searchBox"
                                                    onChange={this.searchChange}
                                                    value={this.state.search}
                                                    placeholder={t('productspages.filterproducts.searchplaceholder')} type="text" autoFocus maxLength={50} />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section id="mainSection" className="search-box" style={{}}>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="d-block d-md-none advanced-search">
                                                    <p>
                                                        <a className="js-collapse-mobile-open" href="javascript.void(0);" onClick={this.menuOpen}>
                                                            <span className="pr-1"><img alt="Filtros" src="https://www.roca.es/o/roca-theme/images/icons/filters-24.svg" /></span> {t('productspages.filterproducts.titlesearch')} </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                {this.getContentRemoveFilters() }
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div className="search-filter">
                                                    <div className={"js-collapse-mobile collapse-mobile " + (this.state.showMenu == true ? 'active' : '')} id="category">
                                                        <div className="d-block d-md-none prev">
                                                            <p>
                                                                <a className="js-collapse-mobile-close arrow" href="javascript.void(0);" onClick={this.menuCollapse}> {t('productspages.filterproducts.returntoresults')}</a>
                                                            </p>
                                                        </div>
                                                        <ul id="sectionsUL" className="category accordion">

                                                            {this.state.menuContent.length > 0 ? this.state.menuContent : (<div className="loadingParent"><div className="loadingImg"></div></div>)}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-9">

                                                {this.state.dataLoaded ? this.state.resultContent : (<div className="loadingParent"><div className="loadingImg"></div></div>)}
     
                                            </div>

                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>
                    </div>
                </section>
            </div>

        );
    }
}
export default withTranslation()(withRouter(BaseFilter, [{ path: '/filter/installations' }, { path: '/filter/generalFilter' }, { path: '/filter/productsFilter' }, { path: '/filter/gateways' }]));