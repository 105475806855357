import { PureComponent } from 'react';
import AuthenticationContext from "../../../../../Authentication/types/AuthContextType"
import InstallationsGeneralView from '../view/InstallationsGeneralView';
import { InstallationsGeneralContainerProps, InstallationsGeneralContainerState } from '../types/InstallationsGeneralTypes';

class InstallationsGeneralContainer extends PureComponent<InstallationsGeneralContainerProps, InstallationsGeneralContainerState> {
  static contextType = AuthenticationContext;

  constructor(props: InstallationsGeneralContainerProps) {
    super(props);
    this.state = {
      showLayout: true,
      keyComponent: 0
    };

    this.toggleLayout = this.toggleLayout.bind(this);
    this.componentChanged = this.componentChanged.bind(this);
    this.handlerReset = this.handlerReset.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("installationGeneral-ShowLayout") == 'true') {
        this.setState({ showLayout: true })
    }
  }

  componentChanged(show) {
    localStorage.setItem("installationGeneral-ShowLayout", show);
    this.setState({ showLayout: show });
  }

  toggleLayout() {
    this.setState({ showLayout: !this.state.showLayout })
  }

  handlerReset = () => {
    this.setState((prevState: any) => ({
      keyComponent: prevState.keyComponent + 1
  }));  }

  render() {
    return (
      <>
        <InstallationsGeneralView
          showLayout={this.state.showLayout}
          expanded={[]}
          notifications={this.props.notifications}
          data={this.props.data} 
          componentChanged={this.componentChanged}
          toggleLayout={this.toggleLayout} 
          groupBy={this.props.groupBySelected} 
          chartViewZones={this.props.chartViewZones}
          handlerReset={this.handlerReset}
          keyComponent={this.state.keyComponent}
        ></InstallationsGeneralView>
      </>
    );
  }
}

export default InstallationsGeneralContainer;
