import { PureComponent } from 'react';
import AuthenticationContext from '../../../Authentication/types/AuthContextType';
import type { AuthContextType } from '../../../Authentication/types/AuthContextType';
import ManagementView from '../views/ManagementView';
import Error from '../../../components/Error';
import Loader from '../../Common/Loader';
import { Role } from '../types/User';
import { manageInfoUser } from '../../Common/utils/helpers';

class ManagementContainer extends PureComponent<{}, { tabValue: number; access: boolean; loading: boolean }> {
  static contextType = AuthenticationContext;

  constructor(props: {}) {
    super(props);
    this.state = {
      tabValue: 0,
      access: false,
      loading: true,
    };
  }

  // Temporary fix until the role is available in the context
  async componentDidMount() {
    await this.getUserInfo();
  }
  checkRoles = (roles: Role[]) => {
    let rolesPermited = [100, 110];
    return roles.some((role) => rolesPermited.includes(role.code));
  };

  getUserInfo = async () => {
    const userDataContext = this.context as AuthContextType;
    const userB2CData = await manageInfoUser(userDataContext, "userB2CData");
    if (
      userB2CData &&
      userB2CData.userRoles &&
      this.checkRoles(userB2CData.userRoles)
    ) {
      this.setState({
        access: true,
        loading: false,
      });
    } else {
      this.setState({ access: false, loading: false });
    }
  };
  //********* */

  handleChangeTab = (_event: React.SyntheticEvent, tabValue: number) => {
    this.setState({ tabValue });
  };

  render() {
    return (
      <>
        {this.state.loading ? (
          <Loader />
        ) : this.state.access ? (
          <ManagementView tabValue={this.state.tabValue} changeTab={this.handleChangeTab} />
        ) : (
          <Error errors={{ status: 401 }} />
        )}
      </>
    );
  }
}

export default ManagementContainer;