import React, { Component } from 'react';
import '../../App.css';

import { withTranslation } from 'react-i18next';
import AuthenticationContext from "../../Authentication/types/AuthContextType";

import TransferOwnershipInstallations from "./TransferOwnershipInstallations";
import TransferOwnershipSites from "./TransferOwnershipSites";
import TransferOwnershipGateways from "./TransferOwnershipGateways";

class UnsubscribeTransferOwnership extends Component {
    static contextType = AuthenticationContext;

    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className="prodChildNoZoom">
                    <h3>Transfer ownership</h3>
                    <blockquote className="warningText"><p>{t('userpages.unsubscribeWarningTextStep1')}</p></blockquote>
                    <TransferOwnershipSites verification={this.props.verification}/>
                    <TransferOwnershipInstallations verification={this.props.verification}/>
                    <TransferOwnershipGateways verification={this.props.verification}/>
                </div>
            </React.Fragment>
        );
    }

}


export default withTranslation()(UnsubscribeTransferOwnership);