import React, { Component } from 'react';
import '../../../../App.css';
import ProductsConfigAlertRule from './ProductsConfigAlertRule';
import ProductAlerts from '../Alerts/ProductAlerts';

export class ProductsAlertsReporting extends Component {

    constructor(props) {
        super(props);
    }
    
    stopRefresh(val) {
        if (typeof this.props.stopRefresh === 'function') {
            this.props.stopRefresh(val);
        }
    }
    render() {
    // <div style={{ margin: "20px 0px" }}><ProductsAlertsReportingHistorical /></div>
    return (
        <React.Fragment>
            <div>
                <div className="mb-5" ><ProductAlerts product={this.props.product} /></div>
                
                <div className="mb-5" ><ProductsConfigAlertRule product={this.props.product} stopRefresh={this.stopRefresh.bind(this)} /></div>

            </div>
        </React.Fragment>
    );
  }

}