import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { t } from 'i18next';
import { IconButton } from 'rsuite';
import CloseIcon from '@mui/icons-material/Close';
import Style from '../styles/ModalAddPackageView.module.scss';

import { ModalAddPackageViewProps } from '../types/ModalAddPackageTypes';
import ModalAddPackageStep0View from './ModalAddPackageStep0View';
import ModalAddPackageStep1View from './ModalAddPackageStep1View';
import ModalAddPackageStep2View from './ModalAddPackageStep2View';
import ModalAddPackageStep3View from './ModalAddPackageStep3View';

const ModalAddPackageView = (props: ModalAddPackageViewProps) => (
  <Dialog
    classes={{ paper: `${Style.ModalAddPackageView} installationModal custom` }}
    disableEscapeKeyDown
    open={props.open}
    onClose={() => props.handleShowAddPackageModal(false)}
  >
    <DialogActions className='jc-spaceBetween'>
      <h2 className='mb-0 ml-4'>{t('firmwareManagementPage.stepOfTotal', { step: props.step, total: props.totalSteps })}</h2>
      <IconButton
        className='close-btn'
        onClick={() => props.handleShowAddPackageModal(false)}
        appearance='default'
        active
        icon={<CloseIcon />}
      />
    </DialogActions>

    <DialogContent>
      {props.step === 0 && (
        <ModalAddPackageStep0View
          handleShowAddPackageModal={props.handleShowAddPackageModal}
          handleStep={props.handleStep}
          typePackage={props.typePackage}
        />
      )}
      {props.step === 1 && (
        <ModalAddPackageStep1View
          handleStep={props.handleStep}
          handleValueTextField={props.handleValueTextField}
          handleSystemId={props.handleSystemId}
          handleCheck={props.handleCheck}
          name={props.name}
          description={props.description}
          version={props.version}
          systemId={props.systemId}
          allSystemIds={props.systemIds}
          available={props.available}
          production={props.production}
          isNextStep1Disabled={props.isNextStep1Disabled}
        />
      )}
      {props.step === 2 && (
        <ModalAddPackageStep2View
          handleStep={props.handleStep}
          handle2Selects={props.handle2Selects}
          name={props.name!}
          systemId={props.systemId!}
          optionsSmartComponentTypes={props.optionsSmartComponentTypes!}
          optionsNoSmartCompoBySystemId={props.optionsNoSmartCompoBySystemId}
          optionsSmartCompoTypesBySystemId={props.optionsSmartCompoTypesBySystemId}
          allHardwarePackage={props.allHardwarePackage}
          selectedSmartCompoTypesBySystemId={props.selectedSmartCompoTypesBySystemId}
          selectedNoSmartCompoBySystemId={props.selectedNoSmartCompoBySystemId}
          systemIds={props.systemIds}
          allSmartComponentTypes={props.allSmartComponentTypes}
          handleShowModalFromPackageWithSmartCompo={props.handleShowModalFromPackageWithSmartCompo}
          handleShowModalFromPackageWithNoSmartCompo={props.handleShowModalFromPackageWithNoSmartCompo}
          showAddHWModal={props.showAddHWModal}
          newHardwareFromNewPackage={props.newHardwareFromNewPackage}
          isNextStep2Disabled={props.isNextStep2Disabled}
          refreshAllNoSmartComponents={props.refreshAllNoSmartComponents}
        />
      )}
      {props.step === 3 && (
        <ModalAddPackageStep3View
          handleStep={props.handleStep}
          savePackage={props.savePackage}
          name={props.name!}
          systemId={props.systemId!}
          version={props.version!}
          description={props.description}
          available={props.available}
          production={props.production}
          selectedSmartCompoTypesBySystemId={props.selectedSmartCompoTypesBySystemId}
          selectedNoSmartCompoBySystemId={props.selectedNoSmartCompoBySystemId}         
          optionsSmartCompoTypesBySystemId={props.optionsSmartCompoTypesBySystemId!}
          loading={props.loading}
        />
      )}
    </DialogContent>
  </Dialog>
);

export default ModalAddPackageView;
