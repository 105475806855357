import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AuthenticationContext from "../../Authentication/types/AuthContextType";
import { Link } from 'react-router-dom';
import Error from '../Error';
class SiteNotificationList extends Component {
    static contextType = AuthenticationContext;

    constructor(props) {
        super(props);
        this.state = { dataLoaded: false, collapse: true };
    }

    handleOnClick(){
        let vCollapse = this.state.collapse;
        this.setState({ collapse: !vCollapse });
    };
    popupContent(t) {
        let types = [];
        let content = [];
        let contentList = [];
        if (this.props.notifications != null) { types = ['alarm', 'rule', 'warning'] }
        let name = { "rule": t("productspages.alertreporting.rules"), "alarm": t("productspages.alertreporting.alarms"), "warning": t("productspages.alertreporting.warnings") };
        let objTypeAdded = { alarm: false, rule: false, warning: false };
        let totalContent = 0;
        let zIndex = 1;
        let typesId = 0;
        this.props.notifications.map(function (notification) {
            types.map(function (elem) {
                let obj = notification.alerts.find(e => e.type == elem);
                if (obj != undefined) {
                    totalContent += obj.total;
                }
                if (!objTypeAdded[elem] && obj != undefined) {
                    objTypeAdded[elem] = true;
                    let icon = elem + "_icon_color.svg";
                    obj = obj == undefined ? '' : (<div key={'types'+typesId} className="notificationAlarms mr-3"> <img src={require('../../assets/icons/' + icon)} className="img-fluid" /><span>{name[elem]}</span></div>);
                    typesId++;
                    content.push(obj);
                }
                
            });
            if (notification.alertslist.length > 0) {
                let obj = (<div key={'alertsList'+typesId} className="row mb-1 fixedHeaderAlert" style={{ zIndex: zIndex }}><h4 style={{ fontWeight: "bold", marginBottom: "10px" }}>{notification.name}</h4></div>);
                zIndex += 1;
                contentList.push(obj);
                typesId++;
            }
            
            notification.alertslist.map(function (elem) {
                let obj = (
                    <div key={'alertsList1' + typesId} className="row mb-3 pl-3"><div className={"list-point col " + elem.individualRule.type}></div>
                        <Link tag={Link} to={`/product/${elem.transactionalId}`}>
                            <span className="col t2">{elem.individualRule.description ? elem.individualRule.description : elem.individualRule.name}</span>
                        </Link>
                    </div>);
                contentList.push(obj);
                typesId++;
            });

        });
        

        return (
            <div className={(this.state.collapse ? 'alert-collapse' :'alert-expand') + " notificationAlertsList"}>
                <div onClick={() => { this.handleOnClick() }} style={{ display: "flex", cursor: "pointer" }} ><h3 className="p-0 col titleTotals" > {t('productspages.alertreporting.totalAlerts', { total: totalContent })} </h3> 
                <img src={require('../../assets/icons/chevron-down.svg').default} className="iconArrow"/>           

            </div>
                <div><span className="t2 w-100"></span></div>

                <div className="d-flex mt-3 mb-3 pb-3 totalAlerts">
                    {content}
                </div>
                <div className="col notificationListContent">
                    {contentList}
                </div>
            </div>
    );
}
    render() {
        let dataLoaded = false;
        const { t } = this.props;
        if (this.props.notifications != null) {
            dataLoaded = true;
            //this.setState();
        }

        if (dataLoaded) {

            try {
                return (
                    this.popupContent(t)
                );
            } catch (e) {
                return (<Error errors={e} />);
            }

        }
        else {
            return (<div className="row loadingParent">< div className="loadingImg"></div></div >);
        }
    }
}
export default withTranslation()(SiteNotificationList)