import React, { Component } from 'react';
import '../../../../App.css';
import { withTranslation } from 'react-i18next';
import FilterListPage from '../../../FilterComponents/FilterListPage';
import $ from 'jquery';

export class ProductsSpareParts extends Component {

    constructor(props) {
        super(props);
        this.state = { search: "", filteredList: null, list: null, diagramImg: null }
        this.inputValGeneral = "";
        this.url = this.props.spareParts.brandCode == "11" ? "https://www.laufen.ch/LaufenResourceServlet/resources/getTransformation?idObject=" : "https://www.roca.com/RocaResourceServlet/resources/getTransformation?idObject=";

    }

    componentDidMount() {
        let content = [];
        this.props.spareParts.finisheds.forEach((e) => {

            if (e.relatedExplodedSkus?.length > 0) {
                let c = e.relatedExplodedSkus.map(
                    obj => ({
                        avatarUri: null,
                        id: obj.id,
                        name: obj.designlines[0].name,
                        description: obj.description,
                        ref: obj.displayCode,
                        pos: parseInt(obj.finisheds[0]?.explodedInformation[0]?.explodedposition, 0),
                        specs: obj.finisheds[0]?.explodedInformation[0]?.specifications,
                        cords: obj.finisheds[0]?.explodedInformation[0]?.coordinates,
                        colection: (obj.designlines.length == 1 ? obj.designlines[0].name : ''), 
                        fullContent: obj
                    }));
                content = content.concat(c);
            }
            

        })
        let diagram = null;
        if (this.props.spareParts.explodedDiagram) {
            diagram = this.props.spareParts.explodedDiagram[0];
        }
        if (content.length > 0) {
            content.sort((a, b) => (a.pos > b.pos) ? 1 : -1)
        }

        this.setState({ list: content, filteredList: content, diagramImg: diagram });
    }
    filterObj(prod) {
        let inputVal = this.inputValGeneral;
        try {

            if (prod.name != null) {
                if (prod.name.toLowerCase().includes(inputVal)) {
                    return true;
                }
            }
            if (prod.description != null) {
                if (prod.description.toLowerCase().includes(inputVal)) {
                    return true;
                }
            }
            if (prod.ref != null) {
                if (prod.ref.toLowerCase().includes(inputVal)) {
                    return true;
                }
            }
            if (prod.specs != null) {
                if (prod.specs.toLowerCase().includes(inputVal)) {
                    return true;
                }
            }
            if (prod.pos != null) {
                if (prod.pos.toLowerCase().includes(inputVal)) {
                    return true;
                }
            }
            if (prod.ref != null) {
                if (prod.ref.toLowerCase().includes(inputVal)) {
                    return true;
                }
            }

        } catch (error) {
            return false;
        }

        return false;
    }
    findInObject(content) {

        if (this.state.search != undefined) {
            this.inputValGeneral = this.state.search.toLowerCase();
        }
        let ret = content.filter(this.filterObj.bind(this));

        this.setState({ filteredList: ret });
    }
    contentRoca() {
        

        function productPlanUpdateMarkers() {
            if (productPlanTimeout) { clearTimeout(productPlanTimeout); }
            productPlanTimeout = setTimeout(productPlanUpdateMarkersImpl, 200);
        }

        function productPlanUpdateMarkersImpl() {
            console.log('productPlanUpdateMarkersImpl');
            productPlanRemoveMarkers();
            var img = document.getElementById('productPlanImage');
            console.log(img);
            if (img) {
                var markers = productPlanGetMarkers();
                console.log(markers);
                if (markers) {
                    var keys = Object.keys(markers);
                    var il = img.offsetLeft, it = img.offsetTop, iw = img.clientWidth, ih = img.clientHeight;
                    console.log('il = ' + il + ' , iw = ' + iw + ' , it = ' + it + ' , ih = ' + ih);
                    if ((!iw) || (!ih)) {
                        productPlanUpdateMarkers();
                        return;
                    }
                    for (var i = 0; i < keys.length; i++) {
                        var key = keys[i], coords = markers[key];
                        console.log('key = ' + key + ' , coords = ' + coords);
                        if ((coords) && (coords.length)) {
                            for (var j = 0; j < coords.length; j++) {
                                var coord = coords[j], cdata = productPlanGetCoordinates(coord, il, it, iw, ih);
                                console.log(coord);
                                console.log(cdata);
                                if (cdata) { productPlanAddMarker(key, cdata); }
                            }
                        }
                    }
                    $('#productPlanImageWrapper span.marker').on('click', function () {
                        var obj = document.getElementById($(this).data('target'));
                        if (obj) {
                            window.scrollTo({
                                top: window.pageYOffset + obj.getBoundingClientRect().top - 69,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }
                    });
                }
            }
        }

        function productPlanGetMarkers() {
            var markers = null;
            $('#productPlanContentsDiv div.sparepart-item:visible').each(function () {
                let id = $(this).attr('id'), coords = $(this).data('coordinates');
                if ((id) && (coords)) {
                    if (!markers) { markers = {}; }
                    if (!markers[id]) { markers[id] = []; }
                    var cs = coords.split(',');
                    for (var i = 0; i < cs.length; i++) { markers[id].push(cs[i]); }
                }
            });
            return markers;
        }

        function productPlanGetCoordinates(ctoken, il, it, iw, ih) {
            var productPlanCoordWidth = 4500, productPlanCoordHeight = 6600;
            var result = null;
            if ((ctoken) && (ctoken.length >= 20)) {
                var x1 = parseInt(ctoken.substring(1, 5)), y1 = parseInt(ctoken.substring(6, 10)),
                    x2 = parseInt(ctoken.substring(11, 15)), y2 = parseInt(ctoken.substring(16, 20));
                console.log('x1 = ' + x1 + ' , x2 = ' + x2 + ' , y1 = ' + y1 + ' , y2 = ' + y2);
                var imgx1 = il + 1 + ((iw * x1) / productPlanCoordWidth);
                var imgx2 = il + 1 + ((iw * x2) / productPlanCoordWidth);
                var imgy1 = it + 1 + ((ih * y1) / productPlanCoordHeight);
                var imgy2 = it + 1 + ((ih * y2) / productPlanCoordHeight);
                console.log('imgx1 = ' + imgx1 + ' , imgx2 = ' + imgx2 + ' , imgy1 = ' + imgy1 + ' , imgy2 = ' + imgy2);
                result = {
                    'top': imgy1,
                    'left': imgx1,
                    'width': (imgx2 - imgx1),
                    'height': (imgy2 - imgy1)
                };
            }
            return result;
        }

        function productPlanRemoveMarkers() {
            $('#productPlanImageWrapper span.marker').remove();
        }

        function productPlanAddMarker(id, coords) {
            var parent = document.getElementById('productPlanImageWrapper');
            if (parent) {
                var span = document.createElement('SPAN');
                span.className = 'marker';
                span.dataset.target = id;
                span.style.position = 'absolute';
                span.style.cursor = 'pointer';
                span.style.border = '1px solid red';
                span.style.top = coords.top + 'px';
                span.style.left = coords.left + 'px';
                span.style.width = coords.width + 'px';
                span.style.height = coords.height + 'px';
                parent.appendChild(span);
            }
        }
    }
    render() {
        const { t } = this.props;

        let isLoaded = this.state.filteredList != undefined;

        

        const searchChange = (e) => {
            this.state.search = e.target.value;
            this.findInObject(this.state.list);
        }

        return (
            <div className="p-3 boxSpareParts mt-2 mb-4">
                {this.state.diagramImg != null ?
                    <div className="row">
                        <div className="col-12 main-img-container px-5 py-4">
                            <div id="productPlanImageWrapper" className="wrapper-img">
                                <img src={this.url + this.state.diagramImg.code + "&trName=TF_Web_Big"} style={{ border: "solid 1px #9A9A9A", maxWidth: "100%" }} />
                            </div>

                        </div>
                    </div>
                : null}
                <div className="row py-3">
                    <div className="col technicalInfo">
                        <h2 className="n1" style={{ padding: "20px" }}> {t('productspages.spareparts.tabname')} </h2>
                    </div>
                    <div className="col customInput">
                        <div style={{position:"relative"}}>
                            <input type="text" onChange={searchChange}
                                placeholder={t('productspages.filterproducts.searchplaceholder')} maxLength={50}></input>
                            <img src={require('../../../../assets/icons/search-24.svg').default} />
                        </div>
                    </div>
                </div>

                {isLoaded ? (
                    <FilterListPage type={"spareParts"} content={this.state.filteredList} header={false} customFlex="col-12" />
                ) : null}

                <hr style={{ marginLeft: "15px", marginRight:"15px" }} />
            </div>
        );
    }

}
export default withTranslation()(ProductsSpareParts);