import { PureComponent } from 'react';
import { withRouter } from '../../withRouter';
import AuthenticationContext from '../../../Authentication/types/AuthContextType';
import UserTabsView from '../views/UserTabsView';
import Loader from '../../Common/Loader';
import { ProfileType } from '../Tabs/UserProfile/types/UserProfileTypes';
import { RouteComponentProps } from '../../Common/types/RouteComponentProps';
import Error from '../../../components/Error';
import { Role } from '@ComponentsRoot/Management/types/User';
import type { AuthContextType } from '@RootPath/Authentication/types/AuthContextType';
import { checkRedirectAfterLogin, manageInfoUser } from '../../Common/utils/helpers';
import { UserTabsStateContainer } from '../types/UserTabsTypes';

export class UserTabsContainer extends PureComponent<{ profileType: ProfileType } & RouteComponentProps, UserTabsStateContainer> {
  static contextType = AuthenticationContext;

  constructor(props: { profileType: ProfileType } & RouteComponentProps) {
    super(props);
    this.state = {
      loading: true,
      tabValue: 0,
      userPending: {
        invitations: (this.context as AuthContextType)?.userPending?.invitations || 0,
        requests: (this.context as AuthContextType)?.userPending?.requests || 0,
      },
      personalAccount: false,
      access: false,
    };
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.userPending !== (this.context as AuthContextType)?.userPending) {
      this.setState({
        userPending: (this.context as AuthContextType)!.userPending!,
      });
    }
  }

  async componentDidMount() {
    if (this.props.profileType === 'personal') {
    } else if (this.props.profileType === 'manage') {
      await this.checkIfIsMyUser();
    }
    await this.getUserInfo();
    await (this.context as AuthContextType).refreshInvitationsAndRequestsPending();
    this.checkIfFromRedirect();
  }

  checkIfFromRedirect = () => {
    const fromRedirect = checkRedirectAfterLogin(this.context as AuthContextType, true);
    if (fromRedirect && fromRedirect.from === 'invitations') {
      this.handleChangeTab(2);
    }
  };

  // Temporary fix until the role is available in the context
  checkRoles = (roles: Role[]) => {
    let rolesPermited = [100, 110];
    return roles.some((role) => rolesPermited.includes(role.code));
  };

  getUserInfo = async () => {
    if (this.props.profileType === 'personal') {
      this.setState({
        access: true,
        loading: false,
      });
    } else {
      const userDataContext = this.context as AuthContextType;
      const userB2CData = await manageInfoUser(userDataContext, 'userB2CData');
      if (userB2CData && userB2CData.userRoles && this.checkRoles(userB2CData.userRoles)) {
        this.setState({
          access: true,
          loading: false,
        });
      } else {
        this.setState({ access: false, loading: false });
      }
    }
  };
  //********* */

  // Use case for when accessing management but the profile is personal
  checkIfIsMyUser = async () => {
    const { pathname } = this.props.location;
    const urlUserId: string | undefined = pathname.split('/').pop();
    const userDataContext = this.context as AuthContextType;
    const userB2CData = await manageInfoUser(userDataContext, 'userB2CData');
    if (urlUserId !== undefined && userB2CData?.guid === urlUserId) {
      this.setState({ personalAccount: true });
    }
  };

  handleChangeTab = (tabValue: number) => {
    this.setState({ tabValue });
  };

  render() {
    return (
      <>
        {this.state.loading ? (
          <Loader />
        ) : this.state.access ? (
          <UserTabsView
            tabValue={this.state.tabValue}
            changeTab={this.handleChangeTab}
            numInvitationsReceived={this.state.userPending.invitations}
            numRequestsReceived={this.state.userPending.requests}
            profileType={this.props.profileType}
            personalAccount={this.state.personalAccount}
          />
        ) : (
          <Error errors={{ status: 401 }} />
        )}
      </>
    );
  }
}

export default withRouter(UserTabsContainer);
