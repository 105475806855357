import React, { Component } from 'react';
import IconButton from "@mui/material/IconButton";
import { withTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { withRouter } from '../../withRouter';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Moment from 'react-moment';
import FilterListPage from '../../FilterComponents/FilterListPage';

class GatewaySummary extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    handleClick = () => {
        if (typeof this.props.buttonClick === 'function') {
            this.props.buttonClick();
        }
    };

    render() {
        const { t } = this.props;
        
        if (this.props.gateway) {
            let gateway = this.props.gateway
            let buttonEdit = null

            //TODO: We should check if we have ownership of the gateway
            buttonEdit = <div className="edit-parent col-auto" style={{ alignItems: "center" }} ><Button className="primary w-sm-100" onClick={() => this.handleClick()}><img src={require('../../../assets/icons/edit-18.svg').default} /></Button></div>

            let state = "Disconnected";
            if (gateway.deviceStatus == "Online") state = "Connected";

            let lastDataTimestamp = gateway.lastProcessedFrame ?? null;

            return (
                <div style={{ position: "relative" }} className="summaryPorodut mb-4">
                    <div className="row prodInfo" style={{ minHeight: "220px" }}>
                        <div>
                            <div className="prodInfo" style={{ alignItems: "stretch", padding: "0px 10px 10px 15px" }}>
                                <div className="col-12 col-md-3">
                                    <img style={{ width: "100%" }} src={gateway.gatewayType.imageUri} />
                                    <div className="prodState">
                                        <div className={this.connectionState}></div>
                                        <div className="stateDiv">
                                            <div>
                                                <img className={"state-" + state} src="" />
                                                <img src={gateway.gatewayType.iconUri} />
                                                <span>
                                                    {this.tagLetter}{this.idTag}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-9">
                                    <div className="row mb-1" style={{ height: '30px' }}>
                                        <h2 className="mb-0 col">{gateway.name}</h2>
                                        {buttonEdit}
                                    </div>
                                    <div className="row summaryInfo" style={{ height: '100%' }}>
                                        <div className="col-12 col-md-6">
                                            <h6 className="mb-1">{t('gatewayspages.general.productid')}: <span className="t3">{gateway.gatewayId}</span></h6>
                                            <h6 className="mb-1">{t('gatewayspages.general.model')}: <span className="t3">{gateway.gatewayType.model}</span></h6>
                                            {gateway.description ? <h6 className="mb-1">{t('gatewayspages.general.description')}: <span className="t3">{gateway.description}</span></h6> : (null)}
                                            {gateway.gatewayType.productRef ? (<h6 className="mb-1">REF: <span className="t3">{gateway.gatewayType.productRef}</span></h6>) : (null)}
                                        </div>
                                        <div className="col-12 col-md-6">
                                            {/*<h6 className="mb-1">{t('installationspages.installationTree.owner')}: <span className="t3">{gateway.email}</span></h6>*/}
                                            <h6 className="mb-1">PUUID: <span className="t3">{gateway.puuid}</span></h6>
                                            {lastDataTimestamp ?
                                                (
                                                    <h6 className="mb-1">{t('gatewayspages.general.latestdatareceived')}:  <Moment className="t3" format="D MMM YYYY HH:mm" withTitle>{lastDataTimestamp}</Moment></h6>
                                                ) : (
                                                    <h6 className="mb-1">{t('gatewayspages.general.latestdatareceived')}: <span className="t3">{t('nodata')}</span></h6>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return <div className="row loadingParent">< div className="loadingImg"></div></div >;
        }
    }
}
export default withTranslation()(withRouter(GatewaySummary))