import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AuthenticationContext from '../Authentication/types/AuthContextType';

class Error extends Component {
  static contextType = AuthenticationContext;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log('ERROR: ' + this.props.error);
  }

  render() {
    const { t } = this.props;

    if (this.props.errors.message != null && this.props.errors.message != undefined) {
      return (
        <div>
          <ErrorOutlineIcon style={{ margin: '0 auto', display: 'block' }} fontSize='large' />
          <div style={{ textAlign: 'center' }}>
            <h4
              style={{
                marginTop: '10px',
                marginBottom: 0,
                lineHeight: '12px',
                overflowWrap: 'anywhere',
                textTransform: 'capitalize',
              }}
            >
              {this.props.errors.message}
            </h4>
            <span className='t2 param10' style={{ fontSize: '10px !important' }}>
              ERROR {this.props.errors.stack}
            </span>
          </div>
        </div>
      );
    } else {
      if (this.props.errors.status != undefined && this.props.errors.status > 200) {
        let message = '';
        switch (this.props.errors.status) {
          case 400:
            message = 'Bad Request';
            break;
          case 401:
            message = 'Unauthorized';
            break;
          case 403:
            message = 'Forbidden';
            break;
          case 404:
            message = 'Not Found';
            break;
          case 400:
            message = 'Bad Request';
            break;
          case 405:
            message = 'Method Not Allowed';
            break;
          case 500:
            message = 'Internal Server Error';
            break;
          case 502:
            message = 'Bad Gateway';
            break;
          default:
        }
        return (
          <div>
            <ErrorOutlineIcon style={{ margin: '0 auto', display: 'block' }} fontSize='large' />
            <div style={{ textAlign: 'center' }}>
              <h1>ERROR {this.props.errors.status}</h1>
              <div>{message}</div>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <ErrorOutlineIcon style={{ margin: '0 auto', display: 'block' }} fontSize='large' />
            <div style={{ textAlign: 'center' }}>
              <h1>{this.props.error && this.props.error.title ? this.props.error.title : 'ERROR'}</h1>
              <div>ERROR {this.props.error && this.props.error.status ? this.props.error.status : ''}</div>
              {this.props.error &&
                this.props.error.detail &&
                this.props.error.detail.map((item, i) => (
                  <div key={i}>
                    {item}
                    <br />
                  </div>
                ))}
            </div>
          </div>
        );
      }
    }
  }
}
export default withTranslation()(Error);
