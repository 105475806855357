import axiosNode from '../axiosNode';
import axios from 'axios';
export default class BreadCrumbHttpClient {
  constructor(context, language, level, id) {
    this.context = context;
    this.debug = false;
    this.level = level;
    this.id = id;
    this.language = language;
  }

  getBreadCrumb = async () => {
    try {
      var accessToken = await this.context.getToken();

      let config = {
        headers: {
          Authorization: `${accessToken.accessToken}`,
        },
      };

      var response = await axios.get('breadcrumb/' + this.language + '/' + this.level + '/' + this.id, config);
      if (this.debug) {
        console.log('calling: ' + 'breadcrumb/' + this.language + '/' + this.level + '/' + this.id);
        console.log('response: ' + JSON.stringify(response));
      }
      return response.data;
    } catch (e) {
      return e;
    }
  };

  getBreadCrumbFromNode = async () => {
    try {
      const accessToken = await this.context.getToken();
      const config = {
        headers: {
          Authorization: `${accessToken.accessToken}`,
        },
      };
      const response = await axiosNode.get(`breadcrumb/${this.language}/${this.level}/${this.id}`, config);
      return response.data;
    } catch (e) {
      return e;
    }
  };
}
