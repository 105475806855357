import { PureComponent } from 'react';
import AuthenticationContext from '../../../../../Authentication/types/AuthContextType';
import HttpClient from '../../../../../HttpClient/HttpClient';

import Loader from '../../../../Common/Loader';
import OrganizationView from '../views/OrganizationView';
import { ListUser } from '@ComponentsRoot/Management/types/User';
import { ResponseAllUsers } from '@ComponentsRoot/UsersComponents/Tabs/UserProfile/types/ResponseTypes';
import { formatDateForLanguage } from '../../../../Common/utils/helpers';
import { withTranslation, WithTranslation } from 'react-i18next';

class OrganizationContainer extends PureComponent<
  WithTranslation,
  { loading: boolean; users: ListUser[]; originalUsers: ListUser[]; language: string }
> {
  static contextType = AuthenticationContext;
  private httpUser: HttpClient | null = null;

  constructor(props: WithTranslation) {
    super(props);
    this.state = {
      loading: true,
      users: [],
      originalUsers: [],
      language: this.props.i18n.language,
    };
  }

  async componentDidMount() {
    await this.getUsers();
  }

  async componentDidUpdate() {
    if (this.state.language !== this.props.i18n.language) {
      this.setState({ loading: true });
      const mapUsersWithNewLang = this.mapUsers(this.state.originalUsers, this.props.i18n.language);
      this.setState({ language: this.props.i18n.language, users: mapUsersWithNewLang, loading: false });
    }
  }

  mapUsers = (users: ListUser[], lang: string) => {
    if (!users.length) return users;
    return users.map((user) => ({
      ...user,
      nameToShow: `${user.givenName} ${user.surname}`,
      createdDateTime: formatDateForLanguage(user.createdDateTime, lang),
      deletedDateTime: formatDateForLanguage(user.deletedDateTime, lang),
      lastSignInDateTime: formatDateForLanguage(user.lastSignInDateTime, lang),
    }));
  };

  getUsers = async (lang: string = this.state.language) => {
    try {
      this.httpUser = new HttpClient(this.context);
      const response: ResponseAllUsers = await this.httpUser.userGetAll();
      if (response.content) {
        this.setState({ users: this.mapUsers(response.content, lang), originalUsers: response.content, loading: false });
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      this.setState({ loading: false });
    }
  };

  render() {
    return <>{this.state.loading ? <Loader /> : <OrganizationView users={this.state.users} />}</>;
  }
}

export default withTranslation()(OrganizationContainer);
