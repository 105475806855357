import React from "react";
import { InstallationsGeneralMainChartsViewProps } from "../types/InstallationsGeneralMainChartsTypes";

const InstallationsGeneralMainChartsView = (props: InstallationsGeneralMainChartsViewProps) => {
    return (
        <React.Fragment>
            <div className="row generalInfo span">
            </div>
        </React.Fragment>
    );
  };
  
  export default InstallationsGeneralMainChartsView;