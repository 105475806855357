import { PureComponent } from 'react';
import ProductsSummaryView from '../views/ProductsSummaryView';
import { ProductsSummaryContainerProps, ProductsSummaryContainerState } from '../types/ProductsSummary';

class ProductsSummaryContainer extends PureComponent<ProductsSummaryContainerProps, ProductsSummaryContainerState> {
  constructor(props: ProductsSummaryContainerProps) {
    super(props);
    this.state = {
      show: false,
      imgUrl: '',
      showPassword: false,
      prodName: '',
      gwId: '',
      prodDetail: '',
      prodRef: '',
      prodWarranty: '',
      installationName: '',
      imagen: '',
      measuresCode: '',
      enabled: false,
      urlDialog: '',
      dialogOpen: false,
      dataLoaded: false,
    };
  }
  componentDidMount = () => {
    if (this.props.product.content) {
      const { productType, designLine, gatewayId, Warranty, installation, technicalDrawings, enabled } =
        this.props.product.content;
      const prodName = !productType.name ? designLine : productType.name;
      const gwId = gatewayId && gatewayId !== 0 ? gatewayId : null;
      let prodDetail = productType.description.trim();
      const prodRef = !productType.sku ? productType.productRef : productType.sku;
      const prodWarranty = !Warranty ? 'No' : Warranty;
      const installationName = installation ? installation.name : null;
      const imagen = productType.iconUri ? productType.iconUri.replace('black', 'white') : '';
      const measuresCode =
        technicalDrawings && technicalDrawings.length && technicalDrawings[0].code ? technicalDrawings[0].code : '';
      let isEnabled = !gwId ? true : Boolean(enabled);

      this.setState({
        prodName,
        gwId,
        prodDetail,
        prodRef,
        prodWarranty,
        installationName,
        imagen,
        measuresCode,
        enabled: isEnabled,
        dataLoaded: true,
      });
      {this.props.componentFinished("ProductsSummaryContainer");}
    }
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleDialog = (url?: string) => {
    if (url) {
      this.setState({ urlDialog: url, dialogOpen: true });
    } else {
      this.setState({ urlDialog: '', dialogOpen: false });
    }
  };

  render() {
    return this.state.dataLoaded ? (
      <ProductsSummaryView
        {...this.state}
        product={this.props.product}
        notificationAlerts={this.props.notificationAlerts}
        handleClickShowPassword={this.handleClickShowPassword.bind(this)}
        handleDialog={this.handleDialog.bind(this)}
        handleEditProduct={this.props.handleEditProduct}
      />
    ) : (
      ''
    );
  }
}

export default ProductsSummaryContainer;
