import {
    DataTypeProvider

} from '@devexpress/dx-react-grid';

export function convertColumns({
    columns,
    allAuto,
    name
}) {
    const ret = { columns: [], columnExtensions: [], tableColumnExtensions: [], hiddenColumns:[], formatterProviders:[], defaultWidth:[]};
    
   
    function getDescendantProp(obj, desc) {
        var arr = desc.split(".");
        while (arr.length && (obj = obj[arr.shift()]));
        return obj;
    }
    let id = 0;
    //let r = rows;
    columns.forEach((c) => {
        let column = {
            name: c.dataField, title: c.text, getCellValue: row => getDescendantProp(row, c.dataField)
        };
        ret.columns.push(column);
        let extension = {
            columnName: c.dataField, sortingEnabled: c.sort
        };
        ret.columnExtensions.push(extension);
       



        let tableColumnExtension = {
            columnName: c.dataField, align: c.align , wordWrapEnabled: true
        };
        let width = 'auto';
        if (c.headerStyle().width != '0%' && !allAuto ) {

            width = c.headerStyle().width;//'auto'
        }
        else if (c.headerStyle().width != '0%' && allAuto && c.headerStyle().important) {
            width = c.headerStyle().width;
        }
        else if (allAuto) {
            width = 'auto';
        }
        tableColumnExtension['width'] = width;
        if (c.headerStyle().width == '0%') {
            width = 0;
        } else {
            width = c.headerStyle().width.replace('%', '0');
        }


        ret.defaultWidth.push({ columnName: c.dataField, width: (isNaN(width) || width == null ? 100 : width) });

        ret.tableColumnExtensions.push(tableColumnExtension);

        if (c.hidden) {
            ret.hiddenColumns.push(c.dataField);
        }
        if (c.formatter) {
            let o = <DataTypeProvider key={name + "-dataTypeProvider-" + id + (Math.random() * 1000) }
                formatterComponent={c.formatter}
                {...{ for: [c.dataField] }}
            />;
            id++;
             
            ret.formatterProviders.push(o);
        }
        
        //if (c.ghost && r) {
        //    r.forEach(function (element) {
        //            element[c.dataField] = "false";
        //        });
        //}
    });

    return ret;
}

