import React, { Component } from 'react';
import '../../../../App.css';
import '../../../refreshBorderAnimation.css';
import AuthenticationContext from '../../../../Authentication/types/AuthContextType';
import { withTranslation } from 'react-i18next';
import FirmwaresHttpClient from '../../../../HttpClient/FirmwaresHttpClient';
import ProductHttpClient from '../../../../HttpClient/ProductHttpClient';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import Alert from '@mui/material/Alert';

class ProductUpdatePackageForm extends Component {
  static contextType = AuthenticationContext;
  constructor(props) {
    super(props);
    this.selectUpdatePackageRef = React.createRef();
    this.selectBackupPackageRef = React.createRef();
    this.state = {
      activeStep: 0,
      PackagesSelect: null,
      fileName: '',
      dataLoaded: false,
      selectedOption: null,
    };
    this.firmware = {
      systemId: this.props.product.content.productType.systemId,
      productTypeId: this.props.product.content.productTypeId,
      name: '',
      description: '',
      repeat: 3,
      packageId: null,
      bckPackageId: null,
      content: null,
    };
  }

  componentDidMount() {
    this.setState({ activeStep: 1 });
    this.fillPackageSelect();

    // Set CustomValidity to selects
    setTimeout(() => {
      const selectUpdatePackage = this.selectUpdatePackageRef?.current?.inputRef;
      const selectBackupPackage = this.selectBackupPackageRef?.current?.inputRef;
      if (selectUpdatePackage && selectBackupPackage) {
        selectUpdatePackage.setCustomValidity(this.props.t('general.error_required'));
        selectBackupPackage.setCustomValidity(this.props.t('general.error_required'));
      }
    }, 1000);
  }

  handleChange(event, name) {
    const input = event.target;
    if (input.required) {
      input.setCustomValidity('');
    }
    this.setValue(event.target.value, name);
  }

  handleChangeSelect = (value, target, object, nameRef) => {
    this.firmware[object] = value;
    document.getElementById(target).value = value;
    const select = this[nameRef]?.current?.inputRef;
    select.setCustomValidity('');
  };

  async fillPackageSelect() {
    try {
      //Get smartcomponentPackage
      this.setState({ dataLoaded: false });
      this.http = new FirmwaresHttpClient(this.context, null);
      let data = await this.http.GetSmartComponentPackage(this.firmware.systemId, null, true, null, 'id', false);

      let select = data.content.map((obj) => ({ label: obj.name, value: obj.id }));
      this.firmware.content = false;
      this.setState({ PackagesSelect: select, dataLoaded: true });
    } catch (err) {
      console.log('error loading Firware Packages');
      console.log(err);
      this.setState({ PackagesSelect: null });
    }
  }

  getStepButtons(step) {
    const { t } = this.props;
    let bBack = null;
    let bNext = null;

    bBack = (
      <button
        onClick={() => this.close()}
        className='MuiButtonBase-root MuiButton-root MuiButton-text primary pull-left float-left'
        tabIndex='0'
        type='button'
      >
        <span className='MuiButton-label'>{t('actions.cancel')}</span>
        <span className='MuiTouchRipple-root'></span>
      </button>
    );
    bNext = (
      <button
        className='MuiButtonBase-root MuiButton-root MuiButton-text primary pull-right float-right'
        tabIndex='0'
        type='submit'
        form='packageUpdateForm'
      >
        <span className='MuiButton-label'>{t('actions.save')}</span>
        <span className='MuiTouchRipple-root'></span>
      </button>
    );

    return (
      <div className='col'>
        {bBack}
        {bNext}
      </div>
    );
  }
  close(content = null) {
    this.props.unmountMe(content);
  }
  setValue = (event, name) => {
    this.firmware[name] = event;
  };

  async saveData(e) {
    e.preventDefault();

    this.http = new ProductHttpClient(this.context, this.props.product.content.id, null, null, null, null, null);

    let datos = await this.http
      .SendFirmwarePackageCampaign(
        this.firmware.packageId.value,
        this.firmware.bckPackageId.value,
        this.firmware.repeat,
        this.firmware.name,
        this.firmware.description
      )
      .then((x) => {
        this.close(x);
      })
      .catch((error) => {
        this.setState({ message: { text: error.message, type: 'error' } });
        console.error('The Promise is rejected!', error);
      });
  }
  getStep1() {
    const { t } = this.props;
    return (
      <div>
        <form id='packageUpdateForm' onSubmit={(e) => this.saveData(e)}>
          {this.state.dataLoaded ? (
            <React.Fragment>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <h3>{t('forms.name')}*</h3>
                  <TextField
                    // onChange={(event) => { this.setValue(event.target.value, "name") }}
                    onChange={(e) => this.handleChange(e, 'name')}
                    onInvalid={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                    onInput={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                    required
                    size='small'
                    fullWidth
                    variant='outlined'
                    defaultValue={this.firmware.name}
                  />
                </div>

                <div className='col-12 col-md-6'>
                  <h3>{t('forms.description')}</h3>
                  <TextField
                    onChange={(e) => this.handleChange(e, 'description')}
                    size='small'
                    fullWidth
                    variant='outlined'
                    defaultValue={this.firmware.description}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <h3 className='pb-1'>{t('firmwareManagementPage.UpdatePackage')}*</h3>
                  <span className='t3' style={{ visibility: 'hidden' }}>
                    {t('firmwareManagementPage.BackupPackageInfo')}
                  </span>
                  <React.Fragment>
                    <Select
                      ref={this.selectUpdatePackageRef}
                      className='react-select-container'
                      classNamePrefix='react-select'
                      required
                      options={this.state.PackagesSelect}
                      onChange={(event) => {
                        this.handleChangeSelect(event, 'newPackageId', 'packageId', 'selectUpdatePackageRef');
                      }}
                    />
                    <input
                      id='newPackageId'
                      tabIndex={-1}
                      autoComplete='off'
                      style={{ opacity: 0, height: 0, position: 'absolute' }}
                      required={true}
                      defaultValue={this.firmware.packageId}
                    />
                  </React.Fragment>
                </div>
                <div className='col-12  col-md-6'>
                  <h3 className='pb-1'>{t('firmwareManagementPage.BackupPackage')}*</h3>
                  <span className='t3'>{t('firmwareManagementPage.BackupPackageInfo')}</span>
                  <React.Fragment>
                    <Select
                      id="selectBackupPackageRef"
                      ref={this.selectBackupPackageRef}
                      className='react-select-container'
                      classNamePrefix='react-select'
                      required
                      options={this.state.PackagesSelect}
                      onChange={(event) => {
                        this.handleChangeSelect(event, 'newbckPackageId', 'bckPackageId', 'selectBackupPackageRef');
                      }}
                      defaultValue={this.firmware.bckPackageId}
                    />
                    <input
                      id='newbckPackageId'
                      tabIndex={-1}
                      autoComplete='off'
                      style={{ opacity: 0, height: 0, position: 'absolute' }}
                      required={true}
                      defaultValue={this.firmware.bckPackageId}
                    />
                  </React.Fragment>
                </div>

                <div className='col-auto'>
                  <h3>{t('firmwareManagementPage.Retries')}*</h3>
                  <TextField
                    onChange={(e) => this.handleChange(e, 'repeat')}
                    onInvalid={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                    onInput={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                    required
                    size='small'
                    inputProps={{ min: '1', max: '10' }}
                    type='number'
                    fullWidth
                    variant='outlined'
                    defaultValue={this.firmware.repeat}
                  />
                </div>
              </div>
            </React.Fragment>
          ) : null}
        </form>
      </div>
    );
  }

  getStepContent(stepIndexPackage) {
    //const { t } = this.props;

    switch (stepIndexPackage) {
      case 0:
        return null;
      case 1:
        return this.getStep1();
      default:
        return 'Unknown stepIndex';
    }
  }
  showAlert() {
    let r = (
      <div className='mt-4'>
        <Alert severity={this.state.message.type}>{this.state.message.text}</Alert>
      </div>
    );
    return r;
  }
  render() {
    const { t } = this.props;

    const options = [];

    return (
      <React.Fragment>
        <div>
          <div className='prodChildNoZoom p-5'>
            {this.getStepContent(this.state.activeStep)}
            {this.state.message != null ? this.showAlert() : null}
          </div>
          <div className='row'>{this.getStepButtons(this.state.activeStep)}</div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(ProductUpdatePackageForm);
