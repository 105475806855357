import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AuthenticationContext from "../../Authentication/types/AuthContextType";
import Error from '../Error';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
class CustomTextField extends Component {
    static contextType = AuthenticationContext;

    constructor(props) {
        super(props);
        this.state = { dataLoaded: false};
    }

    render() {
        let dataLoaded = false;
        if (this.props.paramObj != null) {
            dataLoaded = true;
        //    this.setState();
        }
        const { t } = this.props;


        if (dataLoaded) {

            let disabled = this.props.paramObj.permissionType == 'R' || !this.props.paramObj.online;
            let serialNumber = this.props.paramObj.businessLabel == 'ProductSerialNumber' ? 'serialNumber' : '';
            let name = t(this.props.paramObj.table + ':' + this.props.paramObj.smartComponentTypeId + '.' + this.props.paramObj.businessLabel + '.name');
            try {

                return (

                    <div className={"flexContainer customStyle " + serialNumber}>
                        <span className="t2 col nobold" id={"l-" + this.props.paramObj.businessLabel}>
                            {name}
                        </span>
                        {this.props.paramObj.infoText ?
                            <div className='info-tooltip-parameter customTextField'>
                                <Tooltip title={this.props.paramObj.infoText}>
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            :""}
                            <span className="t2" id={'t-' + this.props.paramObj.businessLabel} style={{ marginLeft: 'auto' }}>  {this.props.parameterValue} </span>
                    </div>
                   
                );
            } catch (e) {
                return (<Error errors={e} />);
            }

        }
        else {
            return (<div className="row loadingParent">< div className="loadingImg"></div></div >);
        }
    }
}
export default withTranslation()(CustomTextField)