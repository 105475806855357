import React, { Component } from 'react';
import '../../../App.css';
import '../../ProductsStyle.css';
import AlertHttpClient from "../../../HttpClient/AlertHttpClient";
import ProductHttpClient from "../../../HttpClient/ProductHttpClient";
import AuthenticationContext from "../../../Authentication/types/AuthContextType";
import ProductsSummary from './ProductsSummary';
// import ProductsGeneral from '../Tabs/General/ProductsGeneral';
import { ProductsReporting } from '../Tabs/Reporting/ProductsReporting';
import { ProductsAlertsReporting } from '../Tabs/AlertReporting/ProductsAlertsReporting';
import ProductsParameters from '../Tabs/Parameters/ProductsParameters';
import ProductsSpareParts from '../Tabs/SpareParts/ProductsSpareParts';
import  ProductsTechnicalInformation  from '../Tabs/TechnicalInformation/ProductsTechnicalInformation';
import { withTranslation } from 'react-i18next';

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/material/styles';
import { withRouter } from '../../withRouter';
import Message from '../../Message/Message';
import ProductsGeneralContainer from '../Tabs/General/container/ProductsGeneralContainer';

function SetClasses() {
    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.background.paper,
            width: 500,
        },
    }));
    return useStyles();
}

function SetStyles() {
    const classes = SetClasses();
    return classes;
}

function SetTheme() {
    const theme = useTheme();
    return theme;
}



class ProductsTabs extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0, test: "", tabIndex: 0, _data: [], productTelemetry: this.props.productTelemetry, notificationAlerts: null, spareParts: null, technicalInfo: null,  message: null}

        if (this.props.match != undefined && this.props.match.params != undefined && this.props.match.params.id != undefined && this.props.location.pathname.split('/').length > 1)
            this.id = this.props.match.params.id == undefined ? parseInt(this.props.location.pathname.split('/')[2]) : this.props.match.params.id;
        else {
            if (window.location.pathname.split('/').length > 0)
                this.id = parseInt(window.location.pathname.split('/')[2]);
        }
    }

    componentDidMount() {

        this.props.showSelector(false);
        this.loadData();
    }

    updateParameters() {
        if (typeof this.props.updateParameters === 'function') {
            this.props.updateParameters();
        }
    }

    renderMessage = (type, content) => {
        this.setState({
          message: {
            type: type,
            content: content,
            }
        });
    };

    async loadData() {
        const { t } = this.props;
        let notifications = [];
        let tabList = null;
        let spareParts = null;
        let technicalInfo = null;
        this.httpAlerts = new AlertHttpClient(this.context, this.id, null, null, null);
        let data = await this.httpAlerts.GetProducedIndividualRulesTotals();

        notifications.push({ alerts: data.content, alertslist: [] });


        
        let prdId = this.props.product.content.productTypeId;
        this.http = new ProductHttpClient(this.context, this.id, null, null, null, null, null);
        let d = await this.http.GetTypeInformation(prdId);
        if (d.status == 200) {
            if (d.data.content?.hasSpareParts?.toString().toLowerCase() == "true") {
                tabList = (<Tab label={<h3 className="tabTitle">{t('productspages.spareparts.tabname')}</h3>} {...this.a11yProps(5)} />);
                spareParts = d.data.content;
                //this.setState({ tabList: t1, spareParts: d.data.content, test: "loaded" });
            } 
            technicalInfo = d.data.content;
        }


        this.setState({ notificationAlerts: notifications, tabList: tabList, spareParts: spareParts, technicalInfo: technicalInfo });
    }
    handleChange = (event, newValue) => {

        // This are the id's of the tabs we want to show the granularity selector on
        let show = false;
        this.props.showSelector(show)

        this.setState({ tabValue: newValue });

        let elem = document.getElementById("parentElementIsTop");
        if (newValue == 0 && elem != null) {
            elem.classList.remove("forceVisble");
        } else if (elem != null) {
            elem.classList.add("forceVisble");
        }
    };
    onChangeDate(value, groupby) {
        if (typeof this.props.onChangeDate === 'function') {
            this.props.onChangeDate(value, groupby);
        }
    }
    handleChangeIndex = (index) => {
        this.setState({ tabIndex: index });
    };

    componentDidUpdate(prevProps) {
        let productid = parseInt(window.location.pathname.split('/')[2]);
        if (this.id != productid) {
            this.loadData();
        }
        if (this.id != productid) {
            this.id = productid;
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.chartViewZones?.content !== prevState.chartViewZones?.content) {
            return ({ chartViewZones: nextProps.chartViewZones })
        }

        if (nextProps.productTelemetry !== prevState.productTelemetry) {
            return ({ productTelemetry: nextProps.productTelemetry });
        }

        return null
    }

    shouldComponentUpdate(nextProps, nextState) {
      
        let modals = document.getElementsByClassName("MuiDialog-root");
        let modalsMessage = document.getElementsByClassName("responseDialogRoot messageDialog");

        if (modalsMessage.length > 0) {
            //return false;
        }

        //Prevent on change freeze
        if (this.state.tabValue == nextState.tabValue) {
            //If tab selected is spareParts dont refresh the page.
            if (this.state.tabValue == 5) {
                return false;
            }
        }
        if (this.state.notificationAlerts != nextState.notificationAlerts) {
            return true;
        }

        if (this.props.product.content.id === nextProps.product.content.id &&
            this.props.product.content.updatedAt === nextProps.product.content.updatedAt &&
            this.props.product.content.lastTimeSeen === nextProps.product.content.lastTimeSeen &&
            this.state.tabValue === nextState.tabValue && this.props.dateRange === nextProps.dateRange &&
            this.props.groupBySelected === nextProps.groupBySelected &&
            JSON.stringify(this.props.productTelemetry) === JSON.stringify(nextProps.productTelemetry) &&
            JSON.stringify(this.state.chartViewZones) === JSON.stringify(nextProps.chartViewZones) &&
            JSON.stringify(this.props.parametersViewZones) === JSON.stringify(nextProps.parametersViewZones) ) {
                if(this.state.message === nextState.message){
                    return false;
                }else{
                    return true;
                }
                
        } else {
            return true;
        }
    }

    stopRefresh(val) {
        if (typeof this.props.stopRefresh === 'function') {
            this.props.stopRefresh(val);
        }
    }
    vRefresh(val) {
        if (typeof this.props.refresh === 'function') {
            this.props.refresh(val);
        }
    }

 
    a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    handleDialogClose = () => {
        this.setState({ message: null });       
      };

    render() {
        const { t } = this.props;

        function TabPanel(props) {
            const { children, value, index, ...other } = props;

            return (
                <div
                    role="tabpanel"
                    hidden={value !== index}
                    id={`full-width-tabpanel-${index}`}
                    aria-labelledby={`full-width-tab-${index}`}
                    {...other}
                >
                    {value === index && (
                        <Box p={3}>
                            <Typography component={'span'}>{children}</Typography>
                        </Box>
                    )}
                </div>
            );
        }

        TabPanel.propTypes = {
            children: PropTypes.node,
            index: PropTypes.any.isRequired,
            value: PropTypes.any.isRequired,
        };

       
        let isNotificationLoaded = this.state.notificationAlerts != null;
        return (
            <React.Fragment>
                {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}
                <div>
                    <AppBar position="static" color="default" className={"headerStickyCustom " + this.state.test}>
                        
                        <Tabs
                            value={this.state.tabValue}
                            onChange={this.handleChange}
                            indicatorColor='primary'
                            variant="fullWidth"
                            className="container"
                        >
                            <Tab label={<h3 className="tabTitle" > {t('productspages.general.tabname')}</h3 >} {...this.a11yProps(0)} />
                            <Tab label={<h3 className="tabTitle" > {t('productspages.reporting.tabname')}</h3 >} {...this.a11yProps(1)} />
                            <Tab label={<h3 className="tabTitle" > {t('productspages.alertreporting.tabname')}</h3 >} {...this.a11yProps(2)} />
                            <Tab label={<h3 className="tabTitle" > {t('productspages.parameters.tabname')}</h3 >} {...this.a11yProps(3)} />
                            <Tab label={<h3 className="tabTitle" > {t('productspages.technical.tabname')}</h3 >} {...this.a11yProps(4)} />
                            {this.state.tabList}
         
                        </Tabs>
                     
                    </AppBar>
                    <SwipeableViews
                        axis={SetTheme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={this.state.tabValue}
                        onChangeIndex={this.handleChangeIndex}
                        className="container products-swipeable"
                    >
                        <TabPanel value={this.state.tabValue} index={0} dir={SetTheme.direction}>                 
                            {/* <ProductsGeneral product={this.props.product} handleclick={this.handleClick.bind(this)} notificationAlerts={this.state.notificationAlerts } groupBySelected={this.props.groupBySelected} dateRange={this.props.dateRange} onChangeDate={this.onChangeDate.bind(this)} parametersViewZones={this.props.parametersViewZones} productTelemetry={this.props.productTelemetry} chartViewZones={this.state.chartViewZones} stopRefresh={this.stopRefresh.bind(this)} /> */}
                            <ProductsGeneralContainer product={this.props.product} handleEditProduct={this.props.handleEditProduct} notificationAlerts={this.state.notificationAlerts } groupBySelected={this.props.groupBySelected} dateRange={this.props.dateRange} onChangeDate={this.onChangeDate.bind(this)} parametersViewZones={this.props.parametersViewZones} productTelemetry={this.props.productTelemetry} chartViewZones={this.state.chartViewZones} stopRefresh={this.stopRefresh.bind(this)} />
                        </TabPanel>
                        <TabPanel value={this.state.tabValue} index={1}  dir={SetTheme.direction}>
                            <ProductsReporting product={this.props.product} groupBySelected={this.props.groupBySelected} dateRange={this.props.dateRange} onChangeDate={this.onChangeDate.bind(this)} chartViewZones={this.state.chartViewZones} productTelemetry={this.props.productTelemetry} />
                        </TabPanel>
                        <TabPanel value={this.state.tabValue} index={2} dir={SetTheme.direction}>
                            <ProductsAlertsReporting product={this.props.product} groupBySelected={this.props.groupBySelected} dateRange={this.props.dateRange} stopRefresh={this.stopRefresh.bind(this)} />
                        </TabPanel>
                        <TabPanel value={this.state.tabValue} index={3} dir={SetTheme.direction}>
                            <ProductsParameters product={this.props.product} parametersViewZones={this.props.parametersViewZones} productTelemetry={this.state.productTelemetry} updateParameters={this.updateParameters.bind(this)} stopRefresh={this.stopRefresh.bind(this)} refresh={this.vRefresh.bind(this)} renderMessage={this.renderMessage.bind(this)} />
                        </TabPanel>
                        <TabPanel value={this.state.tabValue} index={4} dir={SetTheme.direction}  >
                            <ProductsTechnicalInformation data={this.state.technicalInfo} product={this.props.product} groupBySelected={this.props.groupBySelected} dateRange={this.props.dateRange} onChangeDate={this.onChangeDate.bind(this)}  />
                        </TabPanel>
                        <TabPanel value={this.state.tabValue} index={5} dir={SetTheme.direction}  >
                            {this.state.spareParts ? <ProductsSpareParts spareParts={this.state.spareParts} /> : null }
                            
                        </TabPanel>
                    </SwipeableViews>
                    </div>
                   
            </React.Fragment>
        );
    }
}
export default withTranslation()(withRouter(ProductsTabs, [{ path: '/product/:id' }]));