// TODO REFACTOR, clean obsolete code

import { WithTranslation, withTranslation } from "react-i18next";
import { SWFirmwareContainerProps, SWFirmwareContainerState, SWFirmwareProps, TableRowItem } from "../types/SWFirmwareTypes";
import { ChangeEvent, Component } from "react";
import Form from 'react-bootstrap/Form';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SWFirmwareView from "../views/SWFirmwareView";
import FirmwaresHttpClient from "../../../../../../HttpClient/FirmwaresHttpClient";
import { DataPackage, SelectProductType, SmartComponentFirmware } from "../../types/FirmwareTypes";

export class SWFirmwareContainer extends Component<WithTranslation & SWFirmwareContainerProps, SWFirmwareContainerState> {

    constructor(props: WithTranslation & SWFirmwareContainerProps) {
        super(props);

        this.state = {
            idCompoDelete: null,
            showDialog: false,
            dataLoaded: false,
            page: localStorage.getItem('FirmwareTablePage') ? parseInt(localStorage.getItem('FirmwareTablePage') ?? "1") : 1,
            sizePerPage: localStorage.getItem('FirmwareTableSizePerPage') ? parseInt(localStorage.getItem('FirmwareTableSizePerPage') ?? "5") : 5,
            totalSize: 0,
            showModalDelete: false,
            selectProductTypes: [],
            selectedValue: null,
            data: [],
            allAssociatedPackagesId: [],
            step: 1,
            message: null,
            errors: null,
            allSmartComponentTypes: props.allSmartComponentTypes,
            systemIds: props.systemIds,
            dataPackagesFiltered: []
        };
        
        this.deleteFormatter = this.deleteFormatter.bind(this)
        this.checkFormatter = this.checkFormatter.bind(this)
        
        this.handleStepper = this.handleStepper.bind(this)
        this.handleSystemChange= this.handleSystemChange.bind(this)
        this.renderMessage = this.renderMessage.bind(this)
        this.showMessageInTable = this.showMessageInTable.bind(this)
        this.delete = this.delete.bind(this)
    }

    componentDidMount() {
        this.getFirmwares(true);
    }

    componentWillUnmount(){
        localStorage.removeItem("FirmwareTableSizePerPage");
        localStorage.removeItem("FirmwareTablePage");
    }

    onDeleteFormatterClick(row: SmartComponentFirmware){
        this.setState({ showModalDelete: true, idCompoDelete: row.id ?? 0  });
    }

    patchAvailableCheck = async (value: boolean, objCheck: TableRowItem) => {
        const { t } = this.props;

        const patchObjValue = [{
            op: "replace",
            path: "/available",
            value: String(value)            
            }]
        
        const http: FirmwaresHttpClient = new FirmwaresHttpClient(this.props.context)
        let data = await http.patchSmartComponentFirmware(objCheck.row.id, patchObjValue);
        if(data.content){
            this.renderMessage('success', t('firmwareManagementPage.FirmwareChanged.ok'));
        }else{
            {t('firmwareManagementPage.PackageChanged.ko')}
            this.renderMessage('success', t('firmwareManagementPage.FirmwareChanged.ko'));
        }
        this.getFirmwares();
    }  
    
    renderMessage(type: string, content: string){
        this.setState({
            message: {
              type: type,
              content: content,
            }
          });
    }

    async getFirmwares(firstLoad: boolean = false) {
        try {
            //Get Firmware
            this.setState({ dataLoaded: false });
            const http: FirmwaresHttpClient = new FirmwaresHttpClient(this.props.context)
            let allData = await http.GetSmartComponent();
            let allPackages = await http.GetSmartComponentPackage();
            let data = allData.content;
            let dataPackages = allPackages.content;
            const allAssociatedPackagesId: number[] = this.getAllAssociatedPackagesId(dataPackages)
            if (firstLoad) {
                let filtered = [
                    ...new Map(data.map((item) => [item.smartComponentType.id, item])).values(),
                ];
                let filteredProducts: SelectProductType[] = filtered.map((obj:any) => ({ label: obj.smartComponentType.name, value: obj.smartComponentType.id }));
                this.setState({ selectProductTypes: filteredProducts, allAssociatedPackagesId: allAssociatedPackagesId })
            }

            //If we already have a filter set, apply it to the data we show when refreshing the Firmwares
            if(this.state.selectedValue){
                let f = [];
                f = data.filter(obj => obj.smartComponentType.id == this.state.selectedValue!.value);    
                this.setState({ data: f, dataLoaded: true, dataPackagesFiltered: f });
            }
            else{
                this.setState({ data: data, dataLoaded: true });
            }
        }
        catch (err:any) {
            console.log("error loading Firwares")
            console.log(err)
            this.setState({ data: [], selectProductTypes: [], dataLoaded: false, errors: {'code': err.response.status, 'message':err.message} });
        }
    }

    getAllAssociatedPackagesId = (dataPackages: DataPackage[]) => {
        return [...new Set(dataPackages.flatMap((pckg) =>
            pckg.smartComponentFirmwares.map((firmware) => firmware.id)
        ))];
    }

    openDialog = () => {
        this.setState({ showDialog: true })
    }

    handleMessageClose = () => {
        this.setState({ message: null });
      };

      handleDialogClose = () => {
        this.setState({ showDialog: false });
      };

     handleCloseDelete = () => {
        this.setState({ showModalDelete: false });
    };

    async delete() {
        const { t } = this.props;
        const { idCompoDelete } = this.state
        try {
            const http: FirmwaresHttpClient = new FirmwaresHttpClient(this.props.context)
            const response = await http.deleteSmartComponent(idCompoDelete)
            if (response.status && response.status === 200) {
                this.setState({ showModalDelete: false });
                this.renderMessage('success', t('messages.successDeleteFirmware'));
                this.getFirmwares();
            }
        } catch (e) {
            this.setState({ showModalDelete: false });
            this.renderMessage('error', t('messages.errorDeleteFirmware'));
        }
    }

    
    handleStepper(step: number) {
        this.setState({ step: step });
    }

    showMessageInTable(message: string, messageType: string) {
        const { t } = this.props;
        this.renderMessage(messageType, t(message));
    }

    onSizePerPageChange = (sizePerPage: number) => {
        localStorage.setItem('FirmwareTableSizePerPage', sizePerPage.toString())
        this.setState({ sizePerPage: sizePerPage });
    }
    
    onPageChange = (newPage: number) => {
        localStorage.setItem('FirmwareTablePage', newPage.toString())
        this.setState({ page: newPage });
    }

    handleSystemChange = (event) => {
        this.updateTable(event);
        this.setState({ selectedValue: event });
    };

    updateTable(event) {
        if (event != null) {
            this.getFirmwares(event.value)
        } else {
            this.getFirmwares();
        }
    }

    getTableColumns (){
        const { t } = this.props;
        return [
                {
                    dataField: 'smartComponentType.name',
                    text: t('firmwareManagementPage.smartComponent'),
                    align: 'left',
                    sort: true,
                    headerStyle: () => {
                        return { width: "20%" };
                    }
                },
                {
                    dataField: 'smartComponentType.id',
                    text: t('firmwareManagementPage.typeIdComponent'),
                    align: 'left',
                    sort: true,
                    headerStyle: () => {
                        return { width: "20%" };
                    }
                },
                {
                    dataField: 'FirmwareVersion',
                    text: t('firmwareManagementPage.version'),
                    align: 'left',
                    sort: true,
                    hidden: false,
                    headerStyle: () => {
                        return { width: "15%" };
                    }
                },
                {
                    dataField: 'uri',
                    text: 'Blob Uri',
                    align: 'center',
                    sort: true,
                    headerStyle: () => {
                        return { width: "15%" };
                    },
                    hidden: true
                },
                {
                    dataField: 'orderNum',
                    text: t('firmwareManagementPage.order'),
                    align: 'center',
                    sort: true,
                    headerStyle: () => {
                        return { width: "15%" };
                    }
                },
                {
                    dataField: 'available',
                    text: t('firmwareManagementPage.available'),
                    align: 'center',
                    formatter: this.checkFormatter,
                    sort: true,
                    headerStyle: () => {
                        return { width: "15%" };
                    }
                },
                {
                    dataField: 'mandatory',
                    text: t('firmwareManagementPage.mandatory'),
                    align: 'center',
                    formatter: this.checkFormatter,
                    sort: true,
                    headerStyle: () => {
                        return { width: "15%" };
                    }
                },
                {
                    dataField: 'actions',
                    text: '',
                    align: 'center',
                    formatter: this.deleteFormatter,
                    headerStyle: () => {
                        return { width: "10%" };
                    }
                }
            ];
    }

    checkFormatter(objCheck: TableRowItem) {
        const { row, value, column } = objCheck
        var id = "custom-switch_" + row.id;
        return (
            <Form>
                <Form.Check
                    type="checkbox"
                    id={id}
                    label=""
                    defaultChecked={value}
                    disabled={column.name !== 'available'}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.patchAvailableCheck(e.target.checked, objCheck)}
                />
            </Form>
        );
    }

    deleteFormatter(objCheck: TableRowItem) {
        const {row} = objCheck
        if (!this.state.allAssociatedPackagesId.includes(row.id)) {
            var id = "btnDelete_" + row.id;
            return (
                <DeleteOutlineIcon id={id} style={{ width: '24px', cursor: "pointer" }} onClick={() => this.onDeleteFormatterClick(row)} />
            );
        }
    }

    getSWFirmwareTableTableProps(): SWFirmwareProps  {
        return {
            showDialog: this.state.showDialog,
            data: this.state.data,
            openDialog: this.openDialog,
            systemIds:this.state.systemIds,
            selectProductTypes: this.state.selectProductTypes,
            errors: this.state.errors,
            handleDialogClose: this.handleDialogClose,
            message: this.state.message,
            showModalDelete: this.state.showModalDelete,
            handleClose: this.handleDialogClose,
            confirmDelete: this.delete,
            handleCloseDelete: this.handleCloseDelete,
            step: this.state.step,
            totalSteps: 2,
            dataLoaded: this.state.dataLoaded,
            columns: this.getTableColumns(),
            dataPackagesFiltered: this.state.dataPackagesFiltered,
            page: this.state.page,
            handleStepper: this.handleStepper,
            showMessageInTable: this.showMessageInTable,
            allSmartComponentTypes: this.state.allSmartComponentTypes,
            sizePerPage: this.state.sizePerPage,
            onSizePerPageChange: this.onSizePerPageChange,
            onPageChange: this.onPageChange,
            handleSystemChange: this.handleSystemChange,
            selectedValue: this.state.selectedValue,
            handleMessageClose: this.handleMessageClose
        }
    }

    render() {
        return (
          <SWFirmwareView {...this.getSWFirmwareTableTableProps()}></SWFirmwareView>
        );
      }
}

export default withTranslation()(SWFirmwareContainer)