import { t } from 'i18next';
import Style from '../styles/GeneralNavMenu.module.scss';
import { Collapse, Navbar, NavbarToggler } from 'reactstrap';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import InboxIcon from '@mui/icons-material/Inbox';
import NotificationPreferences from "./../../NotificationPreferences";
import { WithTranslation, withTranslation } from 'react-i18next';
import { GeneralNavMenuViewProps } from '../types/GeneralNavMenuType';
import DropdownSites from './DropdownSites';
import DropdownInstallations from './DropdownInstallations';
import DropdownProducts from './DropdownProducts';
import DropdownGateways from './DropdownGateways';

const GeneralNavMenu = (props: GeneralNavMenuViewProps & WithTranslation) => {
    return (
        <div className={Style.GeneralNavMenu}>
    <div className={`generalNavMenu-content`}>
        {props.user && props.language ?  <header className=" headerNav secondHeaderNavbar" ref={props.setWrapperRef}>
            <div className="firstHeaderNavbar">                    
                <Navbar onPointerEnter={(e) => { props.showMenu(0) }} className="topNavBar navbar-expand-lg navbar-toggleable-lg ng-white headerSpace" light>
                    <div className="topHeader">
                        <div style={{ height: '13px', marginTop: '-5px' }}>
                            <Dropdown style={{ marginTop: '-6px' }} className="animateHover hoverLangMenu noBorder dropdownLanguages">
                                {props.isLaufen ? (
                                    <><Dropdown.Toggle style={{ marginTop: '1px' }} className="headerNav" id="dropdown-language" variant="transparent">{props.language}</Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className="t3 langItemMenu" onClick={() => props.dropDownChange('EN')}>EN</Dropdown.Item>
                                            <Dropdown.Item className="t3 langItemMenu" onClick={() => props.dropDownChange('ES')}>ES</Dropdown.Item>
                                            <Dropdown.Item className="t3 langItemMenu" onClick={() => props.dropDownChange('DE')}>DE</Dropdown.Item>
                                            <Dropdown.Item className="t3 langItemMenu" onClick={() => props.dropDownChange('FR')}>FR</Dropdown.Item>
                                            <Dropdown.Item className="t3 langItemMenu" onClick={() => props.dropDownChange('IT')}>IT</Dropdown.Item>
                                        </Dropdown.Menu></>
                                ) : (
                                    <><Dropdown.Toggle style={{ marginTop: '-2px' }} className="headerNav" id="dropdown-language" variant="transparent">{props.language}</Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className="t3 langItemMenu" onClick={() => props.dropDownChange('EN')}>EN</Dropdown.Item>
                                            <Dropdown.Item className="t3 langItemMenu" onClick={() => props.dropDownChange('ES')}>ES</Dropdown.Item>
                                        </Dropdown.Menu></>
                                )}
                            </Dropdown>
                        </div>

                        <div className="animateHover" style={{ height: '13px', marginTop: '-5px' }}>
                            {
                                props.isLaufen ? (
                                    <li className="centerIcon" style={{ marginTop: '2px' }}><a href="https://www.laufen.com" style={{ color: "#9a9a9a" }}>{t('topheader.laufensite')}</a></li>
                                ) : (
                                    <li><a style={{ color: "#9a9a9a", marginTop: '1px' }} href="https://www.roca.com">{t('topheader.rocasite')}</a></li>
                                )
                            }
                        </div>
                        <div className="noBorder animateHover">
                            {
                                props.isLaufen ? (
                                    <li className="centerIcon"><a style={{ color: "#9a9a9a" }} href="https://www.laufen.com/company">{t('topheader.laufencompany')}<img src={require('../../../assets/icons/external-18w.svg').default} /></a></li>
                                ) : (null)
                            }
                        </div>
                    </div>
                    <div className="espaciador"></div>
                    <div className="topHeader">
                        {
                            props.isLaufen ? (
                                <div className="animateHover">
                                    <li><a style={{ color: "#9a9a9a" }} href="https://www.laufen.com/spaces">{t('topheader.laufenspaces')}</a></li>
                                </div>
                            ) : (null)
                        }
                        {
                            props.isLaufen ? (
                                <div className="animateHover">
                                    <li><a style={{ color: "#9a9a9a" }} href="https://www.laufen.com/service">{t('topheader.laufenservices')}</a></li>
                                </div>
                            ) : (null)
                        }

                        <Dropdown
                            show={props.showDropdownSites}
                            onClick={()=>props.handleShowDropdownSites(true)}
                            onMouseLeave={()=>props.handleShowDropdownSites(false)}
                            className="noBorder animateHover hoverUserMenu userMenuContainer">
                            <Dropdown.Toggle variant="transparent" style={{ color: "#9a9a9a" }} id="dropdown-basic">
                                {props.user != null ? (
                                    <><Badge className="customBadge bg-none" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} overlap="rectangular" badgeContent={props.numReceived} style={{ color: "#9a9a9a" }}>
                                        <InboxIcon color="inherit" style={{ color: "#9a9a9a" }} />
                                    </Badge><a className="UsernameDropdown">
                                            {(props.user.givenName && props.user.givenName.length > 16) ? (props.user.givenName.substring(0, 14) + "...") : (props.user.givenName)}
                                            &nbsp;
                                            {(props.user.surnname && props.user.surname.length > 16) ? (props.user.surname.substring(0, 14) + "...") : (props.user.surname)}
                                        </a></>)
                                    : (null)
                                }
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <div className="row noBorder" style={{ margin: 0, padding: 0 }}>
                                    <div className="col-12 noBorder userMenuItem" style={{ display: "flex" }}>
                                        <Link to="/user"> <div className="t3 noBorder">{t('userpages.userArea')}&nbsp;&nbsp;&nbsp;{t('invitation.requesttitle')} <b>{props.numRequestsReceived}</b>/{t('invitation.invitationtitle')} <b>{props.numInvitationsReceived}</b></div></Link>
                                    </div>
                                    <div className="col-12 noBorder userMenuItem">
                                        <Link to="/home?action=changePassword" onClick={props.changePassword}><div style={{ marginTop: "2px" }} className="t3 noBorder">{t('userpages.changePassword')}</div></Link>
                                    </div>
                                    {/* <div className="col-12 noBorder userMenuItem">
                                        <div className="noBorder" style={{ margin: 0, padding: 0, cursor: 'pointer' }} onClick={() => this.changePreferences()}><div style={{ marginTop: "2px" }} className="t3 noBorder">{t('userpages.userPreferences')}</div></div>
                                    </div> */}
                                    {(props.userMe != null && props.userMe.userRoles != null && props.checkRoles()) ? (
                                        <div className="col-12 noBorder userMenuItem">
                                            <Link to="/management"><div style={{ marginTop: "2px" }} className="t3 noBorder">{t('userpages.management')}</div></Link>
                                        </div>
                                    ) : (null)}
                                    {(props.userMe != null && props.userMe.userRoles != null && props.checkRoles()) ? (
                                        <div className="col-12 noBorder userMenuItem">
                                            <Link to="/powerApps"><div style={{ marginTop: "2px" }} className="t3 noBorder">{t('dictionaryTool.menuDictionaryTool')}</div></Link>
                                        </div>
                                    ) : (null)}
                                     {props.checkRolesPowerBI() ? (
                                     <div className="col-12 noBorder userMenuItem">
                                            <Link to="/productBI"><div style={{ marginTop: "2px" }} className="t3 noBorder">{t('userpages.productBI')}</div></Link>
                                        </div>
                                     ) : null}
                                </div>

                                {props.user != null ? (
                                    <div className="row avatarZone" style={{ margin: 0, padding: 0 }}>
                                        <Avatar variant="square" className="userAvatar noBorder" srcSet={props.userThumbnailPhoto} alt={props.user.displayName} style={{ height: "60px", width: "60px", padding: 0 }} />
                                        <div style={{ height: "60px", width: "130px", padding: 0 }} className="noBorder userName">
                                            <p className="UsernameDropdown noBorder">
                                                {props.user.givenName && (props.user.givenName.length> 16) ? (props.user.givenName.substring(0, 14) + "...") : (props.user.givenName)}
                                                &nbsp;
                                                {props.user.givenName && props.user.surnname && (props.user.givenName.length + props.user.surname.length > 16) ? (<br />) : (null)}
                                                {(props.user.surnname && props.user.surname.length > 16) ? (props.user.surname.substring(0, 14) + "...") : (props.user.surname)}
                                            </p>
                                        </div>
                                        <div style={{ padding: 0 }} className="logoutButton">
                                            <Link to="/home?action=logout"><div style={{ padding: 0, textAlign: 'center' }} className="noBorder"><PowerSettingsNewIcon /></div></Link>
                                        </div>
                                    </div>) : (null)
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Navbar>
            </div>
            <div>
                <Navbar className="navbar-expand-lg navbar-toggleable-lg ng-white headerSpace pt-2 " style={{ paddingLeft: '60px', height: '90px' }} light>
                    <Link onPointerEnter={(e) => { props.showMenu(0) }}  to="/"><img style={props.isLaufen ? { height: "41px" } : { height: "31px" }} src={props.urlsMenu.logoUrl} /></Link>
                    <NavbarToggler onClick={props.toggleNavbar} />
                    <Collapse className="d-lg-inline-flex" isOpen={!props.collapsed} navbar>
                        <ul className="navbar-nav flex-grow botHeader">
                            <nav className="MegamenuNav">
                                <ul id="menu">

                                    <li><Link  to="/sites" onPointerEnter={(e) => { if (props.sitesExists) { props.showMenu(1) } else props.showMenu(0) }} className={[props.hover1, "headernav navitem pt-4"].join(' ')} onTouchEnd={props.handleTouchStart}>{t('sites')}</Link></li>
                                    <li><Link  to="/filter/installations" onPointerEnter={(e) => { if (props.installationsExists) { props.showMenu(2) } else props.showMenu(0) }} className={[props.hover2, "headernav navitem pt-4"].join(' ')} onTouchEnd={props.handleTouchStart}> {t('installations')} </Link></li>

                                    <li><Link  to="/filter/productsFilter" onPointerEnter={(e) => { if (props.productsExists) { props.showMenu(3) } else props.showMenu(0) }} className={[props.hover3, "headernav navitem pt-4"].join(' ')} onTouchEnd={props.handleTouchStart}> {t('products')} </Link></li>
                                    <li><Link  to="/filter/gateways" onPointerEnter={(e) => { if (props.gatewaysExists) { props.showMenu(4) } else props.showMenu(0) }} className={[props.hover4, "headernav navitem pt-4"].join(' ')} onTouchEnd={props.handleTouchStart}> {t('gateways')} </Link></li>

                                    <li onPointerEnter={(e) => { props.showMenu(0) }}><div style={{ width: '20px', height: '10px' }}></div></li>

                                    <li style={{ float: 'right', width: 'auto' }} onPointerEnter={(e) => { props.showMenu(0) }}>
                                        <div style={{ display: "flex", height: "50px" }}>
                                            <input onChange={props.searchChange} onKeyDown={props.searchHandleKeyDown} className={"d-none d-xl-inline " + (props.showSearch == true ? "" : " hidden")} type="text"></input>
                                            <Link  className="" to={"/filter/generalFilter?" + props.search} onClick={() => { props.showSearchOnClick(false) }}>
                                                <div className=" pt-2">{props.isLaufen ? (<img src={require('../../../assets/icons/search-24.svg').default} />) : (<img src={require('../../../assets/icons/search-24w.svg').default} />)}</div>
                                            </Link>
                                        </div>
                                    </li>
                                </ul>

                            </nav>

                        </ul>

                    </Collapse>
                </Navbar>
            </div>
            <div onMouseLeave={() => { props.showMenu(0) }} className={[props.display1, "mega"].join(' ')}>
                {<DropdownSites {...props}></DropdownSites>}
            </div>
            <div onMouseLeave={() => { props.showMenu(0) }} className={[props.display2, "mega"].join(' ')}>
                {<DropdownInstallations {...props}></DropdownInstallations>}
            </div>
            <div onMouseLeave={() => { props.showMenu(0) }} className={[props.display3, "mega"].join(' ')}>
                {<DropdownProducts {...props}></DropdownProducts>}
            </div>
            <div onMouseLeave={() => { props.showMenu(0) }} className={[props.display4, "mega"].join(' ')}>
                {<DropdownGateways {...props}></DropdownGateways>}
            </div>
            {
                props.showPreferences ? (<NotificationPreferences hide={()=>props.hideSharedPreferences} />) : (null)
            }
        </header>: ""}
    </div>
    </div>
        
    );
};

export default withTranslation()(GeneralNavMenu);