import Style from '../styles/EmptyBarChartView.module.scss';
import { EmptyBarchartProps } from '../types/ChartsComponents';
import ReactECharts from 'echarts-for-react';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const EmptyHeatMapChartView = (props: EmptyBarchartProps) => {
  const option = {
    "tooltip": {
      "position": "top"
    },
    "xAxis": {
      "type": "category",
      "data": null,
      "splitArea": {
        "show": true
      },
      "name": "Hours"
    },
    "yAxis": {
      "type": "category",
      "data": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      "splitArea": {
        "show": true
      },
      "name": "Week Days"
    },
    "visualMap": {
      "min": 0,
      "max": 10,
      "orient": "horizontal",
      "color": [
        "#003e5d",
        "#bfdfee"
      ],
      "left": "center",
      "pieces": [
        {
          "min": 0,
          "max": 5,
          "color": "#BFDFEE",
          "label": "low (0 to 5)"
        },
        {
          "min": 5,
          "max": 10,
          "color": "#60AED4",
          "label": "medium (5 to 10)"
        },
        {
          "min": 10,
          "max": 20,
          "color": "#007DBA",
          "label": "high (10 to 20)"
        },
        {
          "min": 20,
          "max": 150,
          "color": "#003E5D",
          "label": "intensive (>20)"
        }
      ]
    },
    "series": [
      {
        "name": "",
        "type": "heatmap",
        "data": [
          [
            0,
            0,
            "-"
          ],
          [
            1,
            0,
            "-"
          ],
          [
            2,
            0,
            "-"
          ],
          [
            3,
            0,
            "-"
          ],
          [
            4,
            0,
            "-"
          ],
          [
            5,
            0,
            "-"
          ],
          [
            6,
            0,
            "-"
          ],
          [
            7,
            0,
            "-"
          ],
          [
            8,
            0,
            "-"
          ],
          [
            9,
            0,
            "-"
          ],
          [
            10,
            0,
            "-"
          ],
          [
            11,
            0,
            "-"
          ],
          [
            12,
            0,
            "-"
          ],
          [
            13,
            0,
            "-"
          ],
          [
            14,
            0,
            "-"
          ],
          [
            15,
            0,
            "-"
          ],
          [
            16,
            0,
            "-"
          ],
          [
            17,
            0,
            "-"
          ],
          [
            18,
            0,
            "-"
          ],
          [
            19,
            0,
            "-"
          ],
          [
            20,
            0,
            "-"
          ],
          [
            21,
            0,
            "-"
          ],
          [
            22,
            0,
            "-"
          ],
          [
            23,
            0,
            "-"
          ],
          [
            0,
            1,
            "-"
          ],
          [
            1,
            1,
            "-"
          ],
          [
            2,
            1,
            "-"
          ],
          [
            3,
            1,
            "-"
          ],
          [
            4,
            1,
            "-"
          ],
          [
            5,
            1,
            "-"
          ],
          [
            6,
            1,
            "-"
          ],
          [
            7,
            1,
            "-"
          ],
          [
            8,
            1,
            "-"
          ],
          [
            9,
            1,
            "-"
          ],
          [
            10,
            1,
            "-"
          ],
          [
            11,
            1,
            "-"
          ],
          [
            12,
            1,
            "-"
          ],
          [
            13,
            1,
            "-"
          ],
          [
            14,
            1,
            "-"
          ],
          [
            15,
            1,
            "-"
          ],
          [
            16,
            1,
            "-"
          ],
          [
            17,
            1,
            "-"
          ],
          [
            18,
            1,
            "-"
          ],
          [
            19,
            1,
            "-"
          ],
          [
            20,
            1,
            "-"
          ],
          [
            21,
            1,
            "-"
          ],
          [
            22,
            1,
            "-"
          ],
          [
            23,
            1,
            "-"
          ],
          [
            0,
            2,
            "-"
          ],
          [
            1,
            2,
            "-"
          ],
          [
            2,
            2,
            "-"
          ],
          [
            3,
            2,
            "-"
          ],
          [
            4,
            2,
            "-"
          ],
          [
            5,
            2,
            "-"
          ],
          [
            6,
            2,
            "-"
          ],
          [
            7,
            2,
            "-"
          ],
          [
            8,
            2,
            "-"
          ],
          [
            9,
            2,
            "-"
          ],
          [
            10,
            2,
            "-"
          ],
          [
            11,
            2,
            "-"
          ],
          [
            12,
            2,
            "-"
          ],
          [
            13,
            2,
            "-"
          ],
          [
            14,
            2,
            "-"
          ],
          [
            15,
            2,
            "-"
          ],
          [
            16,
            2,
            "-"
          ],
          [
            17,
            2,
            "-"
          ],
          [
            18,
            2,
            "-"
          ],
          [
            19,
            2,
            "-"
          ],
          [
            20,
            2,
            "-"
          ],
          [
            21,
            2,
            "-"
          ],
          [
            22,
            2,
            "-"
          ],
          [
            23,
            2,
            "-"
          ],
          [
            0,
            3,
            "-"
          ],
          [
            1,
            3,
            "-"
          ],
          [
            2,
            3,
            "-"
          ],
          [
            3,
            3,
            "-"
          ],
          [
            4,
            3,
            "-"
          ],
          [
            5,
            3,
            "-"
          ],
          [
            6,
            3,
            "-"
          ],
          [
            7,
            3,
            "-"
          ],
          [
            8,
            3,
            "-"
          ],
          [
            9,
            3,
            "-"
          ],
          [
            10,
            3,
            "-"
          ],
          [
            11,
            3,
            "-"
          ],
          [
            12,
            3,
            "-"
          ],
          [
            13,
            3,
            "-"
          ],
          [
            14,
            3,
            "-"
          ],
          [
            15,
            3,
            "-"
          ],
          [
            16,
            3,
            "-"
          ],
          [
            17,
            3,
            "-"
          ],
          [
            18,
            3,
            "-"
          ],
          [
            19,
            3,
            "-"
          ],
          [
            20,
            3,
            "-"
          ],
          [
            21,
            3,
            "-"
          ],
          [
            22,
            3,
            "-"
          ],
          [
            23,
            3,
            "-"
          ],
          [
            0,
            4,
            "-"
          ],
          [
            1,
            4,
            "-"
          ],
          [
            2,
            4,
            "-"
          ],
          [
            3,
            4,
            "-"
          ],
          [
            4,
            4,
            "-"
          ],
          [
            5,
            4,
            "-"
          ],
          [
            6,
            4,
            "-"
          ],
          [
            7,
            4,
            "-"
          ],
          [
            8,
            4,
            "-"
          ],
          [
            9,
            4,
            "-"
          ],
          [
            10,
            4,
            "-"
          ],
          [
            11,
            4,
            "-"
          ],
          [
            12,
            4,
            "-"
          ],
          [
            13,
            4,
            "-"
          ],
          [
            14,
            4,
            "-"
          ],
          [
            15,
            4,
            "-"
          ],
          [
            16,
            4,
            "-"
          ],
          [
            17,
            4,
            "-"
          ],
          [
            18,
            4,
            "-"
          ],
          [
            19,
            4,
            "-"
          ],
          [
            20,
            4,
            "-"
          ],
          [
            21,
            4,
            "-"
          ],
          [
            22,
            4,
            "-"
          ],
          [
            23,
            4,
            "-"
          ],
          [
            0,
            5,
            "-"
          ],
          [
            1,
            5,
            "-"
          ],
          [
            2,
            5,
            "-"
          ],
          [
            3,
            5,
            "-"
          ],
          [
            4,
            5,
            "-"
          ],
          [
            5,
            5,
            "-"
          ],
          [
            6,
            5,
            "-"
          ],
          [
            7,
            5,
            "-"
          ],
          [
            8,
            5,
            "-"
          ],
          [
            9,
            5,
            "-"
          ],
          [
            10,
            5,
            "-"
          ],
          [
            11,
            5,
            "-"
          ],
          [
            12,
            5,
            "-"
          ],
          [
            13,
            5,
            "-"
          ],
          [
            14,
            5,
            "-"
          ],
          [
            15,
            5,
            "-"
          ],
          [
            16,
            5,
            "-"
          ],
          [
            17,
            5,
            "-"
          ],
          [
            18,
            5,
            "-"
          ],
          [
            19,
            5,
            "-"
          ],
          [
            20,
            5,
            "-"
          ],
          [
            21,
            5,
            "-"
          ],
          [
            22,
            5,
            "-"
          ],
          [
            23,
            5,
            "-"
          ],
          [
            0,
            6,
            "-"
          ],
          [
            1,
            6,
            "-"
          ],
          [
            2,
            6,
            "-"
          ],
          [
            3,
            6,
            "-"
          ],
          [
            4,
            6,
            "-"
          ],
          [
            5,
            6,
            "-"
          ],
          [
            6,
            6,
            "-"
          ],
          [
            7,
            6,
            "-"
          ],
          [
            8,
            6,
            "-"
          ],
          [
            9,
            6,
            "-"
          ],
          [
            10,
            6,
            "-"
          ],
          [
            11,
            6,
            "-"
          ],
          [
            12,
            6,
            "-"
          ],
          [
            13,
            6,
            "-"
          ],
          [
            14,
            6,
            "-"
          ],
          [
            15,
            6,
            "-"
          ],
          [
            16,
            6,
            "-"
          ],
          [
            17,
            6,
            "-"
          ],
          [
            18,
            6,
            "-"
          ],
          [
            19,
            6,
            "-"
          ],
          [
            20,
            6,
            "-"
          ],
          [
            21,
            6,
            "-"
          ],
          [
            22,
            6,
            "-"
          ],
          [
            23,
            6,
            "-"
          ]
        ],
        "label": {
          "show": false
        },
        "emphasis": {
          "itemStyle": {
            "shadowBlur": 10,
            "shadowColor": "rgba(0, 0, 0, 0.5)"
          }
        },
        "height": "350px",
        "width": "650px"
      }
    ]
  };

  return (
    <div className={Style.EmptyBarChartView}>
      <div className='container-EmptyBarChartView'>
        <div className='box-error'>
          {props.type === 'call' ? <ErrorOutlineIcon fontSize='large' /> : ''}
          {props.type === 'empty' ? <NotInterestedIcon fontSize='large' /> : ''}
          <p className='error-text'>{props.message}</p>
        </div>
        <div className='box-chart'>
          <ReactECharts style={{ height: '400px', width: '100%', margin: 0, padding: '10px' }} option={option} />
        </div>
      </div>
    </div>
  );
};

export default EmptyHeatMapChartView;
