import axios from "axios";
import i18n from '../i18n/i18n';

export default class HttpClient {


    constructor(context) {
        this.context = context;
        this.language = i18n.language;
        this.debug = false
    }

    GetKpiInformation = async (id, startDate, endDate, groupBy) => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        try {
            var response = await axios.get("gateways/" + this.language + "/kpiinformation/" + startDate + "/" + endDate + "/" + groupBy+ "/" + id, config);
            if (this.debug) {
                console.log("calling: " + "sites/" + this.language + "/" + this.siteID);
                console.log("response: " + JSON.stringify(response));
            }
            return response;
        }
        catch(e) {
            return e.response;
        }
    }


    GetGateway = async (id) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        try {
            var response = await axios.get("gateways/" + this.language + "/" + id, config);
            if (this.debug) {
                console.log("calling: " + "sites/" + this.language + "/" + this.siteID);
                console.log("response: " + JSON.stringify(response));
            }
            return response;
        }
        catch(e) {
            return e.response;
        }
    }

    GetGatewaysFilter = async (filter, page, sizePerPage) => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("gateways/" + this.language + "/" + page + "/" + sizePerPage + "/" + filter, config);
        if (this.debug) {
            console.log("calling: " + "gateways/" + this.language + "/" + page + "/" + sizePerPage + "/" + filter);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    GetGateways = async () => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("gateways/" + this.language, config);
        if (this.debug) {
            console.log("calling: " + "gateways/" + this.language);
            console.log("response: " + JSON.stringify(response));
        }

        if (response) {
            response.data.content = response.data.content.sort((a, b) => a.gatewayId.localeCompare(b.gatewayId));
        }

        return response.data
    }

    GetGatewaysMenu = async () => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("gateways/" + this.language + "/menu", config);
        if (this.debug) {
            console.log("calling: " + "gateways/" + this.language + "/menu");
            console.log("response: " + JSON.stringify(response));
        }

        return response.data
    }

    GetGatewayFirmware = async (id) => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("gateways/" + this.language + "/" + id + "/firmware", config);
        if (this.debug) {
            console.log("gateways/" + this.language + "/" + id + "/firmware");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    GetGatewayTwinsFirmware = async (id) => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("gateways/" + this.language + "/" + id + "/twins-firmware", config);
        if (this.debug) {
            console.log("gateways/" + this.language + "/" + id + "/twins-firmware");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    PutFirmwareVersion = async (twin) => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.put("gateways/" + this.language + "/desired-twins-properties", twin, config);
        if (this.debug) {
            console.log("gateways/" + this.language + "/desired-twins-properties");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    GetGatewayAvailableFirmwares = async (gatewayTypeId) => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("gateways/" + this.language + "/" + gatewayTypeId + "/availabeFirmwares", config);
        if (this.debug) {
            console.log("gateways/" + this.language + "/" + gatewayTypeId + "/availabeFirmwares");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    PatchGateway = async (elements, id) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }

        var response = await axios.post("gateways/" + this.language + "/" + id + "/updategateway/", elements, config);
        if (this.debug) {
            console.log("calling: gateways/" + this.language + "/" + this.id + "/updategateway/");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    DeleteGateways = async (id, puuid) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }
        var response;
        try {
            var body = {
                puuid: puuid
            }
            response = await axios.delete("gateways/" + this.language + "/" + id, {
                headers: {
                    Authorization: `${accessToken.accessToken}`
                },
                data: body
            });
            if (this.debug) {
                console.log("calling: gateways/" + this.language + "/gateways/" + id);
                console.log("response: " + JSON.stringify(response));
            }
        } catch (e) {
            response = e.response;
            throw e;
        }

        return response.data
    }
}