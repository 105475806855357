import ProductHttpClient from "../../../HttpClient/ProductHttpClient";
import InstallationHttpClient from "../../../HttpClient/InstallationsHttpClient";
import SiteHttpClient from "../../../HttpClient/SitesHttpClient";
import GatewaysHttpClient from "../../../HttpClient/GatewaysHttpClient";
import type { GeneralNavMenuContainer } from "../container/GeneralNavMenuContainer";
import { manageInfoUser } from "../../../components/Common/utils/helpers";
import type { AuthContextType } from "../../../Authentication/types/AuthContextType";
import { GatewaysItemContent, InstallationsItemContent, ProductsItemContent, SitesItemContent, UrlsMenu, User, UserMe } from "../types/GeneralNavMenuType";

class GeneralNavMenuConfig {
  private generalNavMenuContainer: GeneralNavMenuContainer;
  private http: ProductHttpClient;
  private httpInstallation: InstallationHttpClient;
  private httpSites: SiteHttpClient;
  private httpGateways: GatewaysHttpClient;

  private badgeInterval: string | number | NodeJS.Timeout | undefined;
  private context: AuthContextType;

  constructor(generalNavMenuContainer: GeneralNavMenuContainer) {
    this.generalNavMenuContainer = generalNavMenuContainer;
    this.context = this.generalNavMenuContainer.props.context

    this.http = new ProductHttpClient(this.context);
    this.httpInstallation = new InstallationHttpClient(this.context);
    this.httpSites = new SiteHttpClient(this.context);
    this.httpGateways = new GatewaysHttpClient(this.context);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleShowDropdownSites = this.handleShowDropdownSites.bind(this);
    this.dropDownChange = this.dropDownChange.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.handleShowDropdownSites = this.handleShowDropdownSites.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.checkRoles = this.checkRoles.bind(this);
    this.checkRolesPowerBI = this.checkRolesPowerBI.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.searchChange = this.searchChange.bind(this);
    this.searchHandleKeyDown = this.searchHandleKeyDown.bind(this);
    this.showSearchOnClick = this.showSearchOnClick.bind(this);
    this.hideSharedPreferences = this.hideSharedPreferences.bind(this);
  }

  setWrapperRef(node: any) {
    this.generalNavMenuContainer.setState({ wrapperRef: node });
  }

  async getMyProducts() {
    let data: ProductsItemContent = await this.http.ProductsGetMyProductsMenu();
    this.generalNavMenuContainer.setState({ myProducts: data });
  }

  async getMySites() {
    let data: SitesItemContent = await this.httpSites.GetSitesMenu();
    this.generalNavMenuContainer.setState({ mySites: data });
  }

  async getMyInstallations() {
    let data: InstallationsItemContent = await this.httpInstallation.InstallationGetInstallationsMenu();
    this.generalNavMenuContainer.setState({ myInstallations: data });
  }

  async getMyGateways() {
    let data: GatewaysItemContent = await this.httpGateways.GetGatewaysMenu();
    this.generalNavMenuContainer.setState({ myGateways: data });
  }

  async getGraphApiThumbnailPhoto() {
    const userThumbnailPhoto: string = await manageInfoUser(
      this.context,
      "userThumbnailData"
    );
    this.generalNavMenuContainer.setState({ userThumbnailPhoto });
  }
  async getGraphApiUser() {
    const user: User = await manageInfoUser(
      this.context,
      "userB2CData"
    );
    this.generalNavMenuContainer.setState({ user });
  }

  async getUserMe() {
    const userMe: UserMe = await manageInfoUser(
      this.context,
      "userBasicData"
    );
    this.generalNavMenuContainer.setState({ userMe });
  }

  emailBadge() {
    if (this.badgeInterval) clearInterval(this.badgeInterval);
    let _this = this;
    this.badgeInterval = setInterval(function () {
      _this.getGraphApiThumbnailPhoto();
      _this.getGraphApiUser();
    }, 60000);
  }

  dropDownChange(language: string) {
    this.context.setNewLanguage(
      language
    );
    this.generalNavMenuContainer.setState({ language });
  }

  handleClickOutside(event: React.SyntheticEvent) {
    if (
      this.generalNavMenuContainer.state.wrapperRef &&
      !this.generalNavMenuContainer.state.wrapperRef.contains(event.target)
    ) {
      this.generalNavMenuContainer.setState({
        showMega1: false,
        showMega2: false,
        showMega3: false,
        showMega4: false,
      });
    }
  }

  handleShowDropdownSites(show: boolean): any {
    this.generalNavMenuContainer.setState({ showDropdownSites: show });
  }

  showMenu(id: number) {
    this.generalNavMenuContainer.setState({
      showMega1: false,
      showMega2: false,
      showMega3: false,
      showMega4: false,
    });
    switch (id) {
      case 1:
        this.generalNavMenuContainer.setState({ showMega1: true });
        break;
      case 2:
        this.generalNavMenuContainer.setState({ showMega2: true });
        break;
      case 3:
        this.generalNavMenuContainer.setState({ showMega3: true });
        break;
      case 4:
        this.generalNavMenuContainer.setState({ showMega4: true });
        break;
      default:
        break;
    }
  }

  changePassword() {
    window.localStorage.setItem("changePassword", "true");
  }

  checkRoles() {
    let rolesPermited = [100, 110];
    this.generalNavMenuContainer.state.userMe!.userRoles.includes(100);
    return this.generalNavMenuContainer.state.userMe!.userRoles.some((role) =>
      rolesPermited.includes(role)
    );
  }

  checkRolesPowerBI() {
    let rolesPermited = [100, 830, 110];
    return this.generalNavMenuContainer.state.userMe!.userRoles.some((role) =>
      rolesPermited.includes(role)
    );
  }

  toggleNavbar() {
    this.generalNavMenuContainer.setState({
      collapsed: !this.generalNavMenuContainer.state.collapsed,
    });
  }


  handleTouchStart = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  searchChange = (e: React.FormEvent<HTMLInputElement>) => {
    const queryString = require("query-string").default;
    const parsed = { s:(<HTMLInputElement>e.target).value };
    const parsedString = queryString.stringify(parsed);

    this.generalNavMenuContainer.setState({
      search: parsedString,
    });
  };

  searchHandleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key.toUpperCase() === "ENTER") {
      (this.generalNavMenuContainer.props as any).navigate(
        "/filter/generalFilter?" + this.generalNavMenuContainer.state.search
      );
      this.generalNavMenuContainer.setState({
        showSearch: false,
      });
    }
  };

  showSearchOnClick(show: boolean) {
    this.generalNavMenuContainer.setState({
      showSearch: show,
    });
  }

  hideSharedPreferences() {
    this.generalNavMenuContainer.setState({ showPreferences: false });
  }

  getUrlMenus(): UrlsMenu {
    if(this.generalNavMenuContainer.isLaufen()){
      return {
        imageMenuProducts: "https://strgpiotcoredev.blob.core.windows.net/web-html/laufen/BannerProducts.png",
        imageMenuSites: "https://strgpiotcoredev.blob.core.windows.net/web-html/laufen/BannerSites.png",
        imageMenuInstallations:"https://strgpiotcoredev.blob.core.windows.net/web-html/laufen/BannerInstallations.png",
        imageMenuGateways:"https://strgpiotcoredev.blob.core.windows.net/web-html/laufen/BannerGateways.png",
        logoUrl:"https://strgpiotcoredev.blob.core.windows.net/b2c-html/assets/images/logo_laufen_standard.png",
      }
    } else {
      return {
        imageMenuProducts: "https://strgpiotcoredev.blob.core.windows.net/web-html/roca/BannerProducts.png",
        imageMenuSites: "https://strgpiotcoredev.blob.core.windows.net/web-html/roca/BannerSites.png",
        imageMenuInstallations: "https://strgpiotcoredev.blob.core.windows.net/web-html/roca/BannerInstallations.png",
        imageMenuGateways:"https://strgpiotcoredev.blob.core.windows.net/web-html/roca/BannerGateways.png",
        logoUrl: "https://strgpiotcoredev.blob.core.windows.net/b2c-html/assets/images/logo-roca-cabecera.png",
      }
    }
    
  }
}

export default GeneralNavMenuConfig;
