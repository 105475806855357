import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { t } from 'i18next';
import { IconButton } from 'rsuite';
import CloseIcon from '@mui/icons-material/Close';
import ModalAddHWStep0View from './ModalAddHWStep0View';
import ModalAddHWStep1View from './ModalAddHWStep1View';
import { ModalAddHardwareViewProps } from '../types/ModalAddHardwareTypes';
import Style from '../styles/ModalAddHardwareView.module.scss';

const ModalAddHardwareView = (props: ModalAddHardwareViewProps) => (
  <Dialog
    classes={{ paper: `${Style.ModalAddHardwareView} installationModal custom` }}
    disableEscapeKeyDown
    open={props.open}
    onClose={() => props.handleShowAddHWModal(false)}
  >
    <DialogActions className='jc-spaceBetween'>
      <h2 className='mb-0 ml-4'>{t('firmwareManagementPage.stepOfTotal', { step: props.step, total: props.totalSteps })}</h2>
      <IconButton
        className='close-btn'
        onClick={() => props.handleShowAddHWModal(false)}
        appearance='default'
        active
        icon={<CloseIcon />}
      />
    </DialogActions>

    <DialogContent>
      {props.step === 0 && (
        <ModalAddHWStep0View
          handleTypeHardware={props.handleTypeHardware}
          handleShowAddHWModal={props.handleShowAddHWModal}
          handleStep={props.handleStep}
          typeHardware={props.typeHardware}
        />
      )}
      {props.step === 1 && (
        <ModalAddHWStep1View
          handleStep={props.handleStep}
          handleSelectStep1={props.handleSelectStep1}
          handleSelecSmart_NoSmart={props.handleSelecSmart_NoSmart}
          saveHardware={props.saveHardware}
          handleTextFields={props.handleTextFields}
          isSaveDisabled={props.isSaveDisabled}
          typeHardware={props.typeHardware}     
          selectOptions={props.selectOptions}    
          selectStep1={props.selectStep1!}
          name={props.name}
          description={props.description}
          version={props.version}
          optionsSelect2={props.optionsSelect2}
          selectedSmartComponentType={props.selectedSmartComponentType}
          selectedNoSmartComponentType={props.selectedNoSmartComponentType}
          newHardwareFromNewPackage={props.newHardwareFromNewPackage}
        />
      )}
    </DialogContent>
  </Dialog>
);

export default ModalAddHardwareView;
