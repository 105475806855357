import { PureComponent } from 'react';
import AuthenticationContext from '../../../../Authentication/types/AuthContextType';
import _ from 'lodash';
import React from 'react';
import { Chart, Legend } from '../../types/ChartsComponents';
import BarChartContainer from '../charts/BarChartContainer';
import BarChartProductContainer from '../charts/BarChartProductContainer';
import GaugeChartContainer from '../charts/GaugeChartContainer';
import HeatmapChartContainer from '../charts/HeatmapChartContainer';
import PieChartContainer from '../charts/PieChartContainer';
import SankeyChartContainer from '../charts/SankeyChartContainer';
import LastValueChartContainer from '../charts/LastValueChartContainer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { withTranslation } from 'react-i18next';
import LastValueChartProductContainer from '../charts/LastValueChartProductContainer';

class GroupChartContainer extends PureComponent<any, any> {
  static contextType = AuthenticationContext;

  constructor(props: any) {
    super(props);
    this.state = {
      legend: this.props.legend,
      groupRendered: [],
      error: false,
    };
  }

  componentDidMount = async () => {
    if(this.props.group){
      let groupRendered: any = [];
      this.props.group.forEach((element) => {
        groupRendered[element.name] = false;
      });
      this.setState({ groupRendered });
    }
  };

  componentDidUpdate(prevProps: Readonly<any>) {
    if (!_.isEqual(prevProps.legend, this.props.legend)) {
      this.setState({ legend: this.props.legend });
    }
  }

  getChartComponentByType = (chart: Chart) => {
    const childrenProps = {
      chart,
      chartRepository: this.props.chartRepository,
      legendFilter: this.state.legend,
      dataPoints: chart.dataPoints,
      language: this.state.language,
      context: this.props.context,
      getLegendFromChart: this.props.getLegendFromChart,
      generalLegend: this.props.generalLegend,
      chartFinished: this.props.chartFinished,
      // setRenderFinished: this.setRenderFinished.bind(this),
    };

    switch (chart.chartType) {
      case 'pie':
        return <PieChartContainer {...childrenProps} />;
      case 'gauge':
        return <GaugeChartContainer {...childrenProps} />;
      case 'bar_echarts':
        if (this.props.page === 'product') {
          return <BarChartProductContainer {...childrenProps} />;
        } else {
          return <BarChartContainer {...childrenProps} />;
        }
      case 'sankey':
        return <SankeyChartContainer {...childrenProps} />;
      case 'heatmap_echarts':
        return <HeatmapChartContainer {...childrenProps} />;
      case 'lastvalue':
        if (this.props.page === 'product') {
          return <LastValueChartProductContainer {...childrenProps} />;
        } else {
          return <LastValueChartContainer {...childrenProps} />;
        }
      default:
        <p>{`Tipo de gráfico no soportado: ${chart.chartType}`}</p>;
        break;
    }
  };

  // setRenderFinished = (name, status) => {
  //   this.setState(prevState => {
  //     let groupRendered = { ...prevState.groupRendered };
  //     groupRendered[name] = status;  
  //     const allFalse = Object.values(groupRendered).every(item => item === false);  
  //     return { groupRendered, error: allFalse };
  //   });
  // };
  

  renderKPIsCharts = (group) => {
    return group.map((chart, key) => <React.Fragment key={key}>{this.getChartComponentByType(chart)}</React.Fragment>);
  };

  renderLegend = (legend: Legend) => {
    if (!Object.keys(legend).length) return null;
    const sortedLegend = Object.keys(legend).sort((a, b) => a.localeCompare(b));
    return (
      <div className='new-legend'>
        {sortedLegend.map((itemName, key) => {
          const item = legend[itemName];
          return (
            <div className={`child ${!item.status && 'notselected'}`} key={key} onClick={() => this.legendHandler(itemName)}>
              <div className='color' style={{ backgroundColor: item.color }}></div>
              <span className='t2'>{itemName}</span>
            </div>
          );
        })}
      </div>
    );
  };

  legendHandler = (itemName: string) => {
    const newLegend = {
      ...this.state.legend,
      [itemName]: { ...this.state.legend[itemName], status: !this.state.legend[itemName].status },
    };
    this.setState({ legend: newLegend });
  };

  getChartTootlip() {
    var { t, tooltip } = this.props;
    if (this.isProductUsesBarChart()) {
      tooltip = (
        <>
          {t('tooltips.averageBarChartDay')}
          <br />
          <br />
          {t('tooltips.averageBarChartWeekMonth')}
        </>
      );
    } else {
      tooltip = t(tooltip);
    }

    return tooltip;
  }

  isProductUsesBarChart() {
    if (this.props.group && this.props.group.length > 0) {
      var group = this.props.group[0];
      return (
        group.chartType.toLowerCase() === 'bar_echarts' &&
        group.title.toLowerCase() === 'product uses' &&
        this.props.page === 'product'
      );
    }
    return false;
  }

  render() {
    var tooltip = this.getChartTootlip();

    return (
      <>
        {this.props.group && this.state.groupRendered ? (
          this.state.error && this.state.page === "all" ? (
            // ''
            <p className='warning'>All Charts inside Group are empty or have errors</p>
          ) : (
            <div className={`chart-group ${this.props.lastValueGroup ? 'lastValueGroup' : ''}`}>
              <div className='title header-chart-group'>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {!this.props.lastValueGroup ? <h4>{this.props.title}</h4> : null}
                  {tooltip && (
                    <div
                      className='info-tooltip-parameter customTextField'
                      style={{ position: 'static', marginBottom: '5px', paddingTop: '15px', marginLeft: '5px' }}
                    >
                      <Tooltip title={tooltip}>
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </div>

                {this.state.legend && !this.props.hiddenLegend ? this.renderLegend(this.state.legend) : null}
              </div>
              <div className='charts-box'>{this.renderKPIsCharts(this.props.group)}</div>
              {this.props.lastValueGroup ? (
                <div
                  className={this.props.page !== 'product' ? '' : 'd-none'}
                  style={{ width: '50%', margin: '40px auto', height: '1px', backgroundColor: 'lightgray' }}
                ></div>
              ) : (
                ''
              )}
            </div>
          )
        ) : (
          ''
        )}
      </>
    );
  }
}

export default withTranslation()(GroupChartContainer);
