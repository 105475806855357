import { t } from 'i18next';
import { ModalAddPackageStep2ViewProps, OptionsSmartComponentTypes, SelectNumberOption } from '../types/ModalAddPackageTypes';
import Select from 'react-select';
import IconButton from '@mui/material/IconButton';
import UploadIcon from '@mui/icons-material/Upload';
import ModalAddHardwareContainer from '../../HardwareManagement/container/ModalAddHardwareContainer';
import { selectDropdownStyle } from '../../../../../config/ManagementConfig';

const ModalAddPackageStep2View = (props: ModalAddPackageStep2ViewProps) => (
  <div className='ModalAddPackageStep2View'>
    <div className='prodChildNoZoom p-5'>
      <h2>
        {props.systemId} - {props.name}
      </h2>
      {/* <span className="t2">{t('firmwareManagementPage.selectFirmwareComponentText')}</span> */}
      {/* <span className='t2'>Select</span> */}
      <div className='row'>
        <div className='col'>
          {props.optionsSmartCompoTypesBySystemId.map((smartCompoType, key) => (
            <div key={key} className='row'>
              <div className='col-12 col-xl-8'>
                <h3>{smartCompoType.name}</h3>
                <div className='row'>
                  <div className='col'>
                    <Select
                      className='react-select-container'
                      styles={selectDropdownStyle({height: 250})}
                      required
                      options={props.optionsSmartComponentTypes[smartCompoType.id].map((option: OptionsSmartComponentTypes) => ({
                        label: `${option.name} - ${option.version}`,
                        value: option.id,
                      }))}
                      onChange={(value) => {
                        props.handle2Selects(value as SelectNumberOption, 'selectedSmartCompoTypesBySystemId', smartCompoType.id);
                      }}
                      value={
                        props.selectedSmartCompoTypesBySystemId && props.selectedSmartCompoTypesBySystemId[smartCompoType.id]
                          ? props.selectedSmartCompoTypesBySystemId[smartCompoType.id]
                          : ''
                      }
                    />
                  </div>
                  <div className='col-auto'>
                    <IconButton
                      color='primary'
                      aria-label='upload picture'
                      component='label'
                      onClick={() => props.handleShowModalFromPackageWithSmartCompo(true, smartCompoType.id)}
                    >
                      <UploadIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {props.optionsNoSmartCompoBySystemId.length ? (
            <div className='row'>
              <div className='optional-box col-12 col-xl-8'>
                <h3>NO SMART COMPONENT (OPTIONAL)</h3>
                <div className='row'>
                  <div className='col'>
                    <Select
                      // isDisabled={this.props.package?.inUse}
                      className='react-select-container'
                      styles={selectDropdownStyle({height: 250})}
                      required
                      options={props.optionsNoSmartCompoBySystemId.map((noSmart) => ({
                        label: noSmart.name,
                        value: noSmart.id,
                      }))}
                      onChange={(value) => {
                        props.handle2Selects(value as SelectNumberOption, 'selectedNoSmartCompoBySystemId');
                      }}
                      value={
                        props.selectedNoSmartCompoBySystemId && props.selectedNoSmartCompoBySystemId
                          ? props.selectedNoSmartCompoBySystemId
                          : ''
                      }
                    />
                  </div>
                  <div className='col-auto'>
                    <IconButton
                      color='primary'
                      aria-label='upload picture'
                      component='label'
                      onClick={() => props.handleShowModalFromPackageWithNoSmartCompo(true)}
                    >
                      <UploadIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
    <div className='buttons row'>
      <div className='col'>
        <button
          onClick={() => props.handleStep('prev')}
          className='MuiButtonBase-root MuiButton-root MuiButton-text primary pull-left float-left'
          type='button'
        >
          <span className='MuiButton-label'>{t('actions.back')}</span>
          <span className='MuiTouchRipple-root'></span>
        </button>

        <button
          onClick={() => props.handleStep('next')}
          className={`${props.isNextStep2Disabled && 'disabled-mode'} MuiButtonBase-root MuiButton-root MuiButton-text primary pull-right float-right`}
          disabled={props.isNextStep2Disabled}
        >
          <span className='MuiButton-label'>{t('actions.next')}</span>
          <span className='MuiTouchRipple-root'></span>
        </button>
      </div>
    </div>

    {props.showAddHWModal && (
      <ModalAddHardwareContainer
        open={props.showAddHWModal}
        handleShowModalFromPackageWithSmartCompo={props.handleShowModalFromPackageWithSmartCompo}
        handleShowModalFromPackageWithNoSmartCompo={props.handleShowModalFromPackageWithNoSmartCompo}
        systemIds={props.systemIds}
        allSmartComponentTypes={props.allSmartComponentTypes}
        newHardwareFromNewPackage={props.newHardwareFromNewPackage}
        refreshAllNoSmartComponents={props.refreshAllNoSmartComponents}
      />
    )}
  </div>
);

export default ModalAddPackageStep2View;
