import { PureComponent } from 'react';
import AuthenticationContext from '../../../../Authentication/types/AuthContextType';
import _ from 'lodash';
import React from 'react';
import { Chart } from '../../types/ChartsComponents';
import BarChartContainer from '../charts/BarChartContainer';
import BarChartProductContainer from '../charts/BarChartProductContainer';
import GaugeChartContainer from '../charts/GaugeChartContainer';
import HeatmapChartContainer from '../charts/HeatmapChartContainer';
import PieChartContainer from '../charts/PieChartContainer';
import SankeyChartContainer from '../charts/SankeyChartContainer';
import GroupChartContainer from './GroupChartContainer';
import { withTranslation, WithTranslation } from 'react-i18next';

class GroupGeneralSitesChartContainer extends PureComponent<any, any> {
  static contextType = AuthenticationContext;

  constructor(props: any) {
    super(props);
    this.state = {
      generalLegend: null,
    };
  }

  // componentDidMount = async () => {
  //   if (this.props.groups && !this.state.legend) {
  //     this.getLegend();
  //   }
  // };

  // getLegend = () => {
  //   const mergedLegends = this.props.groups.reduce((merged, group) => {
  //     const { legend, charts } = group;
  //     if (legend) Object.assign(merged, legend);
  //     charts.forEach((chart) => chart.legend && Object.assign(merged, chart.legend));
  //     return merged;
  //   }, {});
  //   this.setState({ initialLegend: mergedLegends, legend: mergedLegends });
  // };

  getLegendFromChart = (legend) => {
    // get legend from chart and add to state
    if (!this.state.generalLegend) {
      this.setState({ generalLegend: legend });
    } else {
      const generalLegend = _.merge({}, this.state.generalLegend, legend);
      this.setState({ generalLegend });
    }
  };

  getChartComponentByType = (chart: Chart) => {
    const childrenProps = {
      chart,
      chartRepository: this.props.chartRepository,
      legendFilter: this.state.legend,
      dataPoints: chart.dataPoints,
      language: this.state.language,
      getLegendFromChart: this.getLegendFromChart.bind(this),
      generalLegend: this.state.generalLegend
    };
    switch (chart.chartType) {
      case 'pie':
        return <PieChartContainer {...childrenProps} />;
      case 'gauge':
        return <GaugeChartContainer {...childrenProps} />;
      case 'bar_echarts':
        if (this.props.page === 'product') {
          return <BarChartProductContainer {...childrenProps} />;
        } else {
          return <BarChartContainer {...childrenProps} />;
        }
      case 'sankey':
        return <SankeyChartContainer {...childrenProps} />;
      case 'heatmap_echarts':
        return <HeatmapChartContainer {...childrenProps} />;
      default:
        <p>{`Tipo de gráfico no soportado: ${chart.chartType}`}</p>;
        break;
    }
  };

  renderKPIsCharts = (group) => {
    return group.map((chart, key) => <React.Fragment key={key}>{this.getChartComponentByType(chart)}</React.Fragment>);
  };

  renderLegend = () => {
    if (!Object.keys(this.state.generalLegend).length) return null;
    const sortedLegend = Object.keys(this.state.generalLegend).sort((a, b) => a.localeCompare(b));
    return (
      <div className='general-site-legend'>
        {sortedLegend.map((itemName, key) => {
          const item = this.state.generalLegend[itemName];
          return (
            <div className={`child ${!item.status && 'notselected'}`} key={key} onClick={() => this.legendHandler(itemName)}>
              <div className='color' style={{ backgroundColor: item.color }}></div>
              <span className='t2'>{itemName}</span>
            </div>
          );
        })}
      </div>
    );
  };

  legendHandler = (itemName: string) => {
    const newLegend = {
      ...this.state.generalLegend,
      [itemName]: { ...this.state.generalLegend[itemName], status: !this.state.generalLegend[itemName].status },
    };
    this.setState({ generalLegend: newLegend });
  };

  renderGroups = () => {
    const groupChartArray: any[] = [];

    // All groups are iterated
    Object.values(this.props.groups).forEach((group: any) => {
      const groupTitle = this.props.t('charts:titles.' + group.charts[0].title);

      if (group.charts.length) {
        if (group.charts[0].chartType !== 'lastvalue' && group.charts.length === 1) {
          groupChartArray.push(this.getChartComponentByType(group.charts[0]));
        } else {
          groupChartArray.push(
            <GroupChartContainer
              chartRepository={this.props.chartRepository}
              group={group.charts}
              legend={this.state.legend}
              hiddenLegend={true}
              title={groupTitle}
              page={this.props.page}
              getLegendFromChart={this.getLegendFromChart.bind(this)}
              generalLegend={this.state.generalLegend}
            />
          );
        }
      }
    });
    return groupChartArray;
  };

  render() {
    return (
      <>
        {this.props.groups ? (
          <div className='chart-group general'>
            <div className='title header-chart-group'>{this.state.generalLegend ? this.renderLegend() : null}</div>
            <div className='charts-box'>{this.renderGroups()}</div>
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default withTranslation()(GroupGeneralSitesChartContainer);
