import { FormControl, IconButton, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { CustomSelectData } from '../types/CustomSliderTypes';

const CustomSelect = (props: CustomSelectData) => (
    <FormControl variant="standard">
    <Typography className="t2" id={'ld-' + props.businessLabel} gutterBottom>
        {props.name}
    </Typography>
    {props.infoText ?
        <div className='info-tooltip-parameter customSelect'>
            <Tooltip title={props.infoText}>
                <IconButton>
                    <InfoIcon />
                </IconButton>
            </Tooltip>
        </div>
    :""}
    <div onClick={() => props.handleDisabledClick({deviceSetting: props.isDeviceSettingsMode, readOnly: props.permissionType == 'R', online: props.online})}>
        <Select
            variant="standard"
            style={{ marginBottom: '5px', maxWidth: '100%' }}
            labelId="demo-mutiple-name-label"
            id={'d' + props.businessLabel}
            value={props.value}
            onChange={props.handleChange}
            disabled={ props.isDisabled() }>
            {(props.values.map((value) => (
                <MenuItem key={value.key} value={value.value}>{value.label}</MenuItem>
        )))}
        </Select>
    </div>
</FormControl>
);

export default CustomSelect;