import React, { Component } from 'react';
import '../../../App.css';
import '../../ProductsStyle.css';
import { withTranslation } from 'react-i18next';
import Moment from 'react-moment';
import AuthenticationContext from "../../../Authentication/types/AuthContextType";
import { Link } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Input from "@mui/material/Input";

class ProductsSummary extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            dataLoaded: false,
            show: false,
            imgUrl: null,
            showPassword: false
        }
    }
 
    getNotifications() {
        const { t } = this.props;
        const types = ['alarm', 'warning', 'rule'];
        const names = { "rule": t("productspages.alertreporting.rules"), "alarm": t("productspages.alertreporting.alarms"), "warning": t("productspages.alertreporting.warnings") };
        const name = { "rule": t("productspages.alertreporting.rule"), "alarm": t("productspages.alertreporting.alarm"), "warning": t("productspages.alertreporting.warning") };
        if (this.props.notificationAlerts == undefined || this.props.notificationAlerts == null) {
            return null;
        } else if (this.props.notificationAlerts.length <= 0) {
            return null;
        }
        let notifications = this.props.notificationAlerts[0].alerts;

        let arr = [];
        let idNotification = 0;
        types.forEach(element => {
            let obj = {};
            let data = notifications.find(o => o.type === element);
            if (data == null) {
                return;
            }
            let total = data.total != null ? data.total : 0;

            if (total <= 0) { return }

            arr.push(
                <div key={'idNotif'+idNotification} className="col-auto pl-0 pr-1">
                    <span className={"custom-button-span " + element} style={{ whiteSpace: "nowrap" }}>
                        {total + " " + (total > 1 ? names[element] : name[element])}
                    </span>
                </div>);
            idNotification++;
        });

        let ret = <div className="row mb-1">
            <div className="col-12 ">
                <div className="row m-0 listNotificationsItems">
                    {arr}
                </div>

            </div>
        </div>;

        return ret;
    }
    /*
     * type = 1, hoja de producto
     * type = 2, esquema de cotas
     */
    DrawTechDocs(texto, url, type, nombre, brand) {

        if (url == null) {
            return null;
        }
        switch (type) {

            case 1:
                return (
                    <div className="row">
                        <div className="col-12">
                            <img src="https://strgpiotcoredev.blob.core.windows.net/pictos-by-kpi/size_general-18.svg" style={{ width: '24px' }} />
                            <span onClick={() => this.openModal(url)} className="atributDesc animateHover" style={{ cursor: 'pointer', marginLeft: '5px' }} >{texto}</span>

                        </div>
                    </div>
                );
                break;
            case 2:

                if (this.props.product.content && this.props.product.content.technicalDrawings) {
                    for (var i = 0; i <= this.props.product.content.technicalDrawings.length - 1; i++) {
                        if (nombre = this.props.product.content.technicalDrawings[i].name) {

                            if (brand == "Roca") {
                                return (
                                    <div className="row">
                                        <div className="col-12">

                                            <img src="https://strgpiotcoredev.blob.core.windows.net/pictos-by-kpi/size_general-18.svg" style={{ width: '24px' }} />
                                            <span className="atributDesc animateHover" onClick={() => this.openModal("https://www.roca.es/RocaResourceServlet/resources/getTransformation?idObject=" + url + "&trName=TF_Mob_tecn")} style={{ cursor: 'pointer', marginLeft: '5px' }} >{texto}</span>

                                        </div>
                                    </div>
                                );
                            }
                            else if (brand = "Laufen") {

                                return (
                                    <div className="row">
                                        <div className="col-12">
                                            <img src="https://strgpiotcoredev.blob.core.windows.net/pictos-by-kpi/size_general-18.svg" style={{ width: '24px' }} />
                                            <span className="atributDesc animateHover" onClick={() => this.openModal("https://www.laufen.com/LaufenResourceServlet/resources/getTransformation?idObject=" + url + "&trName=TF_TechDrawing_High")} style={{ cursor: 'pointer', marginLeft: '5px' }} >{texto}</span>

                                        </div>
                                    </div>
                                );
                            }
                            break;
                        }
                    }
                }
                else {
                    return (null)
                }

                break;
        }
    }

    openModal(url) {
        this.setState({ show: true })
        this.setState({ imgUrl: url })
    }
    handleClick = () => {
        if (typeof this.props.buttonClick === 'function') {
            this.props.buttonClick();
        }
    };
    render() {

        let dataLoaded = this.props.product.content != undefined;
        let responsive = ["col-12 col-md-5", "col-12 col-md-7", "d-none"];

        let handleClickShowPassword = (event) => {
            this.setState({ showPassword: !this.state.showPassword })
        }

        let handleMouseDownPassword = (event) => {
            event.preventDefault();
        }

        if (this.props.product.content != undefined) {
            
            this.prodId = this.props.product.content.id;
            this.puuid = this.props.product.content.puuid;
            this.prodName = (this.props.product.content.productType.name == null ? this.props.product.content.designLine : this.props.product.content.productType.name);
            this.connectionState = this.props.product.content.connectionState;
            this.connectionStateUpdatedTime = this.props.product.content.connectionStateUpdatedTime;
            this.lastDataTimestamp = this.props.product.content.lastDataTimestamp;
            this.lastProcessedValue = this.props.product.content.lastProcessedValue;
            this.lastTimeSeen = this.props.product.content.lastTimeSeen;
            this.dimensions = this.props.product.content.dimensions;
            this.prodDetail = this.props.product.content.productType.description;
            this.gwId = this.props.product.content.gatewayId != undefined && this.props.product.content.gatewayId != 0 ? this.props.product.content.gatewayId : null;
            if (this.props.product.content.productType.description != null && this.props.product.content.productType.description.trim() != "") {
                this.prodDetail = this.props.product.content.productType.description.trim();
            }
            
            
            this.prodRef = this.props.product.content.productType.sku == null ? this.props.product.content.productType.productRef : this.props.product.content.productType.sku;
            this.prodWarranty = (this.props.product.content.Warranty == null ? 'No' : this.props.product.content.Warranty);
            this.prodContract = "Type of contract";
            //this.prodPath = "market / country / site / installation / product";
            this.prodCommercial = "Commercial Produc";
            this.alertDesc = "Alert Description";
            this.alertTime = "29/08/2020 13:45";
            this.productType = this.props.product.content.productType.name;
            this.letter = this.props.product.content.productType.letter;
            this.brand = this.props.product.content.productType.brand;
            this.image = this.props.product.content.productType.imageUri;
            this.enabled = this.props.product.content.enabled;
            if (!this.gwId) {
                this.enabled = true;
            }
            this.urlProduct = "/product/" + this.prodId;
            if (this.props.product.content.installation !== undefined && this.props.product.content.installation !== null) {
                this.installationId = this.props.product.content.installation.id;
                this.installationName = this.props.product.content.installation.name;
                this.urlInstallation = "/installation/" + this.installationId;
            } else {
                this.installationId = null;
                this.installationName = null;
                this.urlInstallation = null;
            }
            this.idTag = this.props.product.content.tag;
            this.registerStatus = this.props.product.content.registerStatus;
            this.tagLetter = this.props.product.content.productType.letter;
            this.icon = this.props.product.content.productType.iconUri;
            //this.urlSite = "/site/" + this.prodId;
        }
        if (this.dimensions != null) {
            responsive = ["col-12 col-md-4", "col-12 col-md-4", "col-12 col-md-4"];
        }
        let handleClose = () => {
            this.setState({ show: false })
        }

        const { t } = this.props;
        var estado = "disconnectedSquare";
        if (this.connectionState != null) {
            estado = this.connectionState + "Square"
        }
        let imagen = this.productType.iconUri;
        if (this.icon != undefined) {
            imagen = this.icon.replace("black", "white");
        }

        let measuresCode = (this.props.product.content && this.props.product.content.technicalDrawings && this.props.product.content.technicalDrawings[0] && this.props.product.content.technicalDrawings[0].code) ? this.props.product.content.technicalDrawings[0].code : ""
        let buttonEdit = null;
        let pin = null;
        if (this.props.product.content != undefined && this.props.product.content.installation?.hasOwnerAccess) {
            if (this.props.product.content.productType.brandCode == "11" && this.props.product.content.bluetoothPassword) {
                pin = 
               <div style={{display:'flex'}}>
                    <h6 className={"mb-1 " + this.props.product.content.bluetoothPassword ? "" : "d-none"} style={{ display: "flex", alignItems: "center", marginRight: '5px' }}>PIN: 
                        <span style={{ marginBottom: 0, padding: "0 5px" }} className="t3">{this.state.showPassword ? this.props.product.content.bluetoothPassword : this.props.product.content.bluetoothPassword.replace(/./g, '•')}</span>
                        <div onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} style={{ cursor: 'pointer'}}>
                            {this.state.showPassword ? <Visibility sx={{ fontSize: '1.2rem' }} /> : <VisibilityOff sx={{ fontSize: '1.2rem' }} />}
                        </div>
                    </h6>
                </div>
            }
            buttonEdit = <div className="edit-parent col-auto" style={{ alignItems: "center" }} ><Button className="primary w-sm-100" onClick={() => this.handleClick()}><img src={require('../../../assets/icons/edit-18.svg').default} /></Button></div>
        }

        let telemetryIcon = null;
        if (this.enabled === true) {
            telemetryIcon = <CheckIcon style={{ color: "green", height: '18px', marginTop: '-4px'}} />
        }
        else {
            telemetryIcon = <ClearIcon style={{ color: "red", height: '18px', marginTop: '-4px' }} />
        }
        const dialogHandleClose = (event, reason) => {
            if (reason == 'backdropClick') {
                return false;
            }
            handleClose();
        };
        return (
            <React.Fragment>
                {dataLoaded ? (
                    <div>
                        <Dialog classes={{ paper: "ruleModal" }}  disableEscapeKeyDown open={this.state.show} onClose={dialogHandleClose}>
                            <DialogContent>
                                <img style={{ width: '100%', height: 'auto' }} src={this.state.imgUrl} />
                            </DialogContent>
                            <DialogActions>
                                <Button className="primary pull-right" onClick={handleClose}>{t('actions.close')}</Button>
                            </DialogActions>
                        </Dialog>

                        <div className="row prodInfo" style={{  }}>
                            <div className="col-12">
                                
                                <div className="row prodInfo" style={{ alignItems: "stretch", paddingBottom: "10px" }}>
                                    <div className="col-12 col-md-3">
                                        <img style={{ maxWidth: "100%", maxHeight: "200px", margin: "0 auto", display: "block" }} src={this.image} />
                                        <div className="prodState">
                                            <div className={this.connectionState}></div>
                                            <div className="stateDiv">
                                                <div>
                                                    <img className={"state-" + this.connectionState} src="" />
                                                    <img src={imagen} />
                                                    <span>
                                                        {this.tagLetter}{this.idTag}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-9">
                                        <div className="row mb-1">
                                            <h2 className="mb-0 col">{this.prodName}</h2>
                                            {(this.gwId != null ?
                                                <div className="col-auto">
                                                    <Link to={"/gateway/" + this.gwId} className="goToGw">
                                                        <img style={{ height: "30px" }} src={require('../../../assets/icons/11_00.31_black.svg').default} />
                                                    </Link>
                                                </div>
                                                : null)}
                                            {buttonEdit}
                                        </div>
                                        <div className="row summaryInfo" style={{ height: '100%' }}>
                                            <div className={responsive[0] } style={{ alignSelf: 'top'}}>
                                                {this.getNotifications()}
                                                <h4 style={{ overflowWrap: 'anywhere' }} className="d-none"> {this.prodDetail}</h4>
                                                <h6 className="mb-1">{t('forms.name')}: <span className="t3">{this.props.product.content.name}</span> </h6>
                                                {this.prodRef ? (<h6 className="mb-1">REF: <span className="t3">{this.prodRef}</span></h6>) : (<div></div>)}
                                                <h6 className="mb-1">{t('installationspages.installationsummaryedit.family')}: <span className="t3">{t('letters.' + this.letter)}</span></h6>
                                                {this.props.product.content.description ? <h6 className="mb-1">{t('forms.description')}: <span className="t3">{this.props.product.content.description.replace(/\u00A0/g, " ")}</span> </h6> : (null)}
                                            </div>
                                            <div className={responsive[1]} style={{ alignSelf: 'top', marginTop: '6px' }}>
                                                <h6 className="mb-1">{t('installation')}: <span className="t3">{this.installationName}</span></h6>
                                                <h6 className="mb-1">PUUID: <span className="t3">{this.puuid}</span></h6>
                                                <h6 className="mb-1 d-none">{t('productspages.summary.warranty')}: <span className="t3">{this.prodWarranty}</span></h6>

                                                <h6 className="mb-1 d-none">TagId: <span className="t3">{this.idTag}</span></h6>
                                                {this.connectionState.toLowerCase() == 'online' ?
                                                    (
                                                        <h6 className="mb-1">{t('productspages.general.lastestdatareceived')}:  <Moment className="t3" format="D MMM YYYY HH:mm" withTitle>{this.lastProcessedValue}</Moment></h6>
                                                    ) : (
                                                        <h6 className="mb-1">{t('productspages.general.lastTimeSeen')}:  <Moment className="t3" format="D MMM YYYY HH:mm" withTitle>{this.lastTimeSeen}</Moment></h6>
                                                    )
                                                }

                                                <h6 className="mb-1" style={{lineHeight: "18px"}}>
                                                    {t('productspages.summary.telemetryenabled')}
                                                    {telemetryIcon}
                                                {/*    <span className="t3"><CustomCheckbox disabled={true} style={{ display: 'inline-flex', marginBottom: 'none!important', marginLeft: '8px'  }} parameterValue={this.enabled} paramObj={{ name: "", businessLabel: "disabletelemetry" }} toSend={this.valueChanged} /></span>*/}
                                                </h6>

                                                {pin}

                                                <h6 className="mb-1 d-none">{t('productspages.summary.registerstatus')}: <span className="t3">{this.idTag}</span></h6>
                                                
                                                {this.alert == "error"
                                                    ? (<div className="alertTxt">
                                                        <span className="row atributDesc"><a className="titleProperty">{t('productspages.general.alert')}:</a> {this.alertDesc}</span>
                                                        <span className="row atributDesc"><a className="titleProperty">{t('productspages.general.time')}:</a> {this.alertTime}</span>
                                                    </div>)
                                                    : (<div></div>)
                                                }
                                            </div>
                                            <div className={responsive[2] + " mt-3 mt-md-0"} style={{ alignSelf: 'top' }}>

                                                {this.dimensions != null ? (
                                                    <div>
                                                        <h6 className="mb-2">{t('productspages.summary.dimensions')}: {this.dimensions}</h6>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className='t2'>
                                                                    ({t('productspages.summary.long')},{t('productspages.summary.wide')},{t('productspages.summary.height')})
                                                                </div>
                                                            </div>

                                                            <div className="col-12 ">
                                                                <div className='t3'>
                                                                    {this.DrawTechDocs(t('productspages.summary.productsheet'), null, 1, this.brand)}
                                                                    {this.DrawTechDocs(t('productspages.summary.productmeasures'), measuresCode, 2, t('productspages.summary.productmeasureskey'), this.brand)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>)
                                                    :
                                                    (<div></div>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                        <div className="loadingParent"><div className="loadingImg"></div></div>
                    )}
            </React.Fragment>

        );
    }

}

export default withTranslation()(ProductsSummary);