import { Component } from 'react';
import AuthenticationContext from "../Authentication/types/AuthContextType";
import { Navbar } from 'reactstrap';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { withTranslation } from 'react-i18next';
import './GeneralNavMenu.css';

class NavFooter extends Component {
    
    static contextType = AuthenticationContext;

    constructor(props) {
        super(props);
        this.state = {
            language: this.props.i18n.language
        };        
    }

    componentDidUpdate() { 
        if (this.state.language !== this.props.i18n.language) {
            this.setState({ language: this.props.i18n.language });
        }
    }
   
    render() {
        const { t } = this.props;
        let brandCode = this.props.brandCode;
        if (brandCode == undefined) brandCode = this.context.brandCode;

        let isLaufen = (brandCode == 11 || brandCode == "11") ? true : false;
        let pinterest = isLaufen ? "https://www.pinterest.es/laufenbathrooms/" : "https://www.pinterest.com/roca_global/";
        let instagram = isLaufen ? "https://www.instagram.com/laufenbathrooms/" : "https://www.instagram.com/roca_global/";
        let facebook = isLaufen ? "https://www.facebook.com/LaufenBathrooms/" : "https://www.facebook.com/RocaGlobal";
        let youtube = isLaufen ? "https://www.youtube.com/user/laufenbathrooms1" : "https://www.youtube.com/roca";
        let logo = isLaufen ? "https://www.laufen.com/documents/2310412/3080467/LAUFEN_withoutBaseline_White_RGB_small.png/609a4c82-1543-b4d8-c3bd-48c550f3500e?t=1592490819446" : "https://strgpiotcoredev.blob.core.windows.net/b2c-html/assets/images/logo-roca-cabecera.png";
        let europaLogo =  "https://strgpiotcoredev.blob.core.windows.net/b2c-html/assets/images/red.es.png";

        let androidPlayStore = isLaufen ? "https://play.google.com/store/apps/details?id=ch.laufen.smartcontrol" : "https://play.google.com/store/apps/details?id=com.roca.connect"
        let iosAppStore = isLaufen ? "https://apps.apple.com/de/app/laufen-smartcontrol/id1562691458" : "https://apps.apple.com/es/app/roca-connect/id1664923230"       

        let termsuseLink = "/page/" + this.state.language + "/" + brandCode + "/terms-of-use";
        let serviceagreementLink = "/page/" + this.state.language + "/" + brandCode + "/service-agreement";
        let privacityLink = "/page/" + this.state.language + "/" + brandCode + "/privacy-declaration";
        let cookiesLink = "/page/" + this.state.language + "/" + brandCode + "/cookies-policy";

        return (

            <footer className="footerNav">
                <div className="footerBarSeparator"></div>


                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white headerSpace" light>
                    <Link tag={Link} to="/"><img style={{ height: "30px", margin: "20px 45px" }} src={logo} /></Link>
                </Navbar>

                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white headerSpace bottom-footer-navbar" light>
                    <div className="col p-0">
                        <div className="topHeader botfootNav col-sm-12" style={{ margin: "0 30px" }}>

                            <div className="row noBorder p-0">

                                <div >
                                    {
                                        this.props.isLogged ? (
                                            <li > <Link color="inherit" style={{ color: "#9a9a9a" }} to={termsuseLink}>{t('navbarfooter.termsuse')}</Link> </li>
                                        ) : (
                                            <li ><a style={{ color: "#9a9a9a" }} href={termsuseLink}>{t('navbarfooter.termsuse')}</a> </li>
                                        )
                                    }
                                </div>
                                <div >
                                    {
                                        this.props.isLogged ? (
                                            <li > <Link color="inherit" style={{ color: "#9a9a9a" }} to={serviceagreementLink}>{t('navbarfooter.serviceagreement')}</Link> </li>
                                        ) : (
                                            <li ><a style={{ color: "#9a9a9a" }} href={serviceagreementLink}>{t('navbarfooter.serviceagreement')}</a> </li>
                                        )
                                    }
                                </div>
                                <div >

                                    {
                                        this.props.isLogged ? (
                                            <li > <Link color="inherit" style={{ color: "#9a9a9a" }} to={privacityLink}>{t('navbarfooter.privacypolicy')}</Link> </li>
                                        ) : (
                                                <li><a style={{ color: "#9a9a9a" }} href={privacityLink}>{t('navbarfooter.privacypolicy')}</a></li>
                                            )
                                    }

                                </div>

                                <div>

                                    {
                                        this.props.isLogged ? (
                                            <li > <Link color="inherit" style={{ color: "#9a9a9a" }} to={cookiesLink}>{t('navbarfooter.cookiespolicy')}</Link> </li>
                                        ) : (
                                                <li><a style={{ color: "#9a9a9a" }} href={cookiesLink}>{t('navbarfooter.cookiespolicy')}</a></li>
                                        )
                                    }

                                </div>

                                <div className="noBorder">
                                    <li> <button id="ot-sdk-btn" className="ot-sdk-show-settings" style={{ color: "#9a9a9a", background: "none", border: "none", textTransform: "uppercase", fontSize: "11px", fontFamily: "AktivGrotesk-Bold,sans-serif!important", position: "relative", letterSpacing: "0.7pt",cursor:"pointer",padding:"0px" }}>{t('actions.cookieSettings')}</button></li>
                                </div>


                            </div>

                        </div>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-10">
                                <div className="row">
                                    <div className="col"><a href={androidPlayStore} target='_blank'> <li><img style={{ width: '18px' }} src={require('../assets/icons/android-os.svg').default} /></li></a></div>
                                    <div className="col"><a href={iosAppStore} target='_blank'> <li><img style={{ width: '18px' }} src={require('../assets/icons/mac-os.svg').default} /></li></a></div>
                                    <div className="col"></div>
                                    <div className="col"><a href={pinterest} target='_blank'> <li><img src={require('../assets/icons/pinterest-18.svg').default} /></li></a></div>
                                    <div className="col"><a href={instagram} target='_blank'> <li><img src={require('../assets/icons/instagram-18.svg').default} /></li></a></div>
                                    <div className="col"><a href={facebook} target='_blank'> <li><img src={require('../assets/icons/facebook-18.svg').default} /></li></a></div>
                                    <div className="col"><a href={youtube} target='_blank'> <li><img src={require('../assets/icons/youtube-18.svg').default} /></li></a></div>

                                </div>

                            </div>
                        </div>

                    </div>
                </Navbar>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white headerSpace" light>
                    <div className="topHeader" style={{ margin: "0 30px" }}>
                        <div className="noBorder">
                            {isLaufen ? (
                                <span className="topHeader copyNav">{parse(t('navbarfooter.copyrightLaufen'))} </span>
                            ): (
                                <span className="topHeader copyNav">{parse("&copy; Copyright 2021. Roca Sanitario, S.A")} </span>
                            )}
                        </div>

                    </div>
                    <div className="col">
                        <div className="row" style={{justifyContent:"center"}}>
                            
                            <div className="col-auto">
                                <div className="row">
                                    {!isLaufen ? <img style={{ height: "85px"}} src={europaLogo} />: ""}                                    
                                </div>
                            </div>
                        </div>
                    </div>

                </Navbar>
                <br /><br />


            </footer>

        );
    }
}


export default withTranslation()(NavFooter);