import axios from "axios";

export default class PagesHttpClient {


    constructor(context, culture, id, brand) {
        this.context = context;
        this.pageId = id;
        this.brand = brand;
        this.culture = culture
        this.debug = false
    }


    GetPage = async () => {
       
        let config = {}
        try {
            var response = await axios.get("pages/" + this.culture + "/" + this.pageId + "/" + this.brand, config);
            if (this.debug) {
                console.log("calling: " + "pages/" + this.culture + "/" + this.pageId + "/" + this.brand);
                console.log("response: " + JSON.stringify(response));
            }
            return response;
        }
        catch (e) {
            return e.response;
        }
    }
}