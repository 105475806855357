import React, { Component } from 'react';
import '../../../../App.css';
import SelectSearch, { AriaOnFocus } from 'react-select'
import AlertHttpClient from "../../../../HttpClient/AlertHttpClient";
import NoData from "../../../NoData";
import AuthenticationContext from "../../../../Authentication/types/AuthContextType";
import Form from 'react-bootstrap/Form';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import Moment from 'react-moment';
import { withTranslation } from 'react-i18next';

class ProductsConfigAlertRuleForm extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);        
        this.id = parseInt(window.location.pathname.split('/')[2]);
        this.state = {
            data: { },
            showModal: false,
            message: "Test",
            severitypopup: "success",
            loading: false,
            error: false
        };

        this.options = [];
        this.products = [];

        this.saveAlertRule = this.saveAlertRule.bind(this);
    }

    async saveAlertRule(e) {
        this.setState({loading: true})
        e.preventDefault();
        let name = document.getElementById("txtRuleName").value;
        let product = this.productValue ? this.productValue : this.props.product.content.id;
        let desc = document.getElementById("txtRuleDesc").value;
        let operator = this.operatorValue;
        let thresholdValue = document.getElementById("txtAlertValue").value;
        let agregation = this.agregationValue;
        let frecuency = this.frecuencyValue ?? "60";
        let active = document.getElementById("chkActive").checked;
        let increase = Math.round(thresholdValue);
        let onlyOnce = document.getElementById("chkOnlyOnce").checked;

        let parameterType = this.parameter.split('-')[0];
        let parameterId = this.parameter.split('-')[1];

        let businessLabel = this.options.find(o => o.value == this.parameter).businessLabel

        let data = {
            ProductId: product,
            Operator: operator,
            AggregationType: agregation,
            EvaluationFrequency: frecuency,
            ThresholdValue: thresholdValue,
            Severity: 30, // 30 is userError
            IsActive: active,
            Type: 'rule',
            Name: name,
            Description: desc,
            UserId: 0,
            IncreaseValue: increase,
            OnlyOnce: onlyOnce,
            Category: "custom",
            BusinessLabel: businessLabel
        };

        switch (parameterType.toLowerCase()) {
            case "kpi":
                data.DataRegisterId = null;
                data.ParameterId = null;
                data.KpiId = parameterId;
                break;
            case "dataregister":
                data.DataRegisterId = parameterId;
                data.ParameterId = null;
                data.kpiId = null;
                break;
            case "parameter":
                data.DataRegisterId = null;
                data.ParameterId = parameterId;
                data.kpiId = null;
                break;
        }

        const formulario = new FormData()
        formulario.append('data', JSON.stringify(data))
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        this.http = new AlertHttpClient(this.context, this.id, null, null, null);
        data = await this.http.SaveAlertRule(formulario);

        this.setState({ loading: false })
        if (data.errors == null) {
            this.setState({ open: true, message: "", severitypopup: "success" });
            this.props.onRefresh();
        }
    }
    
    componentDidMount() {

        this.getAlertTypes();
    }

    async getAlertTypes() {
        
        this.http = new AlertHttpClient(this.context, this.id, null, null, null);
        let data = await this.http.AlertsGetAlertTypes(this.props.product.content.productTypeId);

        if (!data || !data.content || data.content.length == 0) {
            this.setState({ data: data, totalSize: 0, error: true });
        }
        else {
            this.setState({ data: data, totalSize: data.content.length});
        }
    }
  
    render() {
        const { t } = this.props;
        let dataLoaded = this.state.data.content != undefined;

        const onSave = (ev) => {
            this.saveAlertRule(ev)
        }

        const selectSuggestedRule = (ev) => {
        }

        const onSelectChange = (field, value) => {
            switch (field) {
                case "operator":
                    this.operatorValue = value;
                    document.getElementById("operatorValidator").value = value;
                    break;
                case "severity":
                    this.severityValue = value;
                    break;
                case "product":
                    this.productValue = value;
                    break;
                case "agregation":
                    this.agregationValue = value;
                    document.getElementById("agregationValidator").value = value;
                    break;
                case "frecuency":
                    this.frecuencyValue = value;
                    break;
                case "parameter":
                    this.parameter = value;
                    document.getElementById("parameterValidator").value = value;
                    break;
                default:
                    break;
            }
        }

        let operators = [
            { value: "equal", label: t('productspages.alertreporting.equal') },
            { value: "geq", label: t('productspages.alertreporting.geq') },
            { value: "leq", label: t('productspages.alertreporting.leq') },
        ];
        let agregations = [
            { value: "LastHour", label: t('productspages.alertreporting.lh') },
            { value: "LastDay", label: t('productspages.alertreporting.ld') },
            { value: "LastWeek", label: t('productspages.alertreporting.lw') },
            { value: "LastMonth", label: t('productspages.alertreporting.lm') },
            { value: "LastYear", label: t('productspages.alertreporting.ly') },
            { value: "FromInstallationDate", label: t('productspages.alertreporting.fid') },
            { value: "CountEvery", label: t('productspages.alertreporting.ce') },
            { value: "Threshold", label: "Threshold" }
        ];

        const getTranslationIfExist = (keyTrans) => {
            return keyTrans !== t(`parameters:${keyTrans}`);
          };

        const getTranslationDataRegisterIfExist = (keyTrans) => {
            return keyTrans !== t(`dataRegisters:${keyTrans}`);
          };

        if (this.state.data.content) {

            let smartComponentTypeIds = [];

            if (this.props.product.content.productType.smartComponentTypeId) {
                smartComponentTypeIds.push(this.props.product.content.productType.smartComponentTypeId);
            } else if (this.props.product.content.productType.systemIds) {
                this.props.product.content.productType.systemIds.forEach(systemId => {
                    if (systemId.smartComponentTypeId) {
                        smartComponentTypeIds.push(systemId.smartComponentTypeId);
                    }
                });
            }

            this.options = this.state.data.content.map(function (type) {
                const label = type.businessLabel || type.name;

                smartComponentTypeIds.forEach(smartComponentTypeId => {
                    const translateKey = `${smartComponentTypeId}.${label}`;
                    const translationNameKey = `${translateKey}.name`;
                    const translationDescKey = `${translateKey}.description`;
            
                    const registerTranslation = key => getTranslationDataRegisterIfExist(key) ? t(`dataRegisters:${key}`) : type[key];
                    const parameterTranslation = key => getTranslationIfExist(key) ? t(`parameters:${key}`) : type[key];
            
                    if (registerTranslation(translationNameKey)) {
                        type.description = registerTranslation(translationDescKey);
                        type.name = registerTranslation(translationNameKey);
                    } else if (parameterTranslation(translationNameKey)) {
                        type.description = parameterTranslation(translationDescKey);
                        type.name = parameterTranslation(translationNameKey);
                    }
                });

                let id;
                if (type.parameterId)
                    id = "parameter-" + type.parameterId
                else if (type.kpiId)
                    id = "kpi-" + type.kpiId
                else if (type.dataRegisterId)
                    id = "dataRegister-" + type.dataRegisterId
                return { value: id, label: type.description ?? type.name, name: type.name, description: type.description, businessLabel: type.businessLabel }
            })
        }

        let value = ""

        const OptionLayout = props => {
            const { innerProps, innerRef } = props;
            return (
                <article ref={innerRef} {...innerProps} className={'custom-option'}>
                    <div className={"sub"}>{props.data.name}</div>
                    <h6 className={ 'desc' }>{(props.data.description == undefined ? ' ' : props.data.description)}</h6>
                </article>
            );
        };
        const OptionLayout2 = props => {
            const { innerProps, innerRef } = props;
            return (
                <article ref={innerRef} {...innerProps} className={'custom-option'}>
                    <div className={"sub"}>{props.data.label}</div>
                </article>
            );
        };

        return (
            <div>
                {dataLoaded ? (
                    <div >
                        {!this.state.error ? (
                            <div style={{ marginBottom: '62px'}}>
                                { this.state.loading? (
                                    <div className="row loadingParent"><div className="loadingImg"></div></div>
                                ) : (
                                        <form id="divForm" onSubmit={(e) => onSave(e)}>
                                            <div className="form-row" style={{ paddingBottom: "20px", margin: "20px" }}>

                                                <h2 className="form-group col-md-12"> {t("productspages.rules.createRule")}</h2>

                                                <div className="form-group col-md-12" style={{ paddingRight: "25px" }}>
                                                    <label className="t3" style={{ fontSize: "11px", margin: "0px" }} htmlFor="txtRuleName">{t('productspages.alertreporting.alertname')}:</label>
                                                    <input type="text" className="form-control" placeholder={t('productspages.alertreporting.placehoderalertname')} id="txtRuleName" required maxLength={30} />
                                                </div>
                                                <div className="form-group col-md-12" style={{ paddingRight: "25px" }}>
                                                    <label className="t3" style={{ fontSize: "11px", margin: "0px" }} htmlFor="txtRuleDesc">{t('forms.description')}:</label>
                                                    <input maxLength={255} type="text" className="form-control" placeholder={t('forms.description')} id="txtRuleDesc" required />
                                                </div>
                                            </div>
                                            <div className="form-row" style={{ paddingBottom: "20px", margin: "20px" }}>
                                                <h2 className="form-group col-md-12"> {t("productspages.rules.configuration")} </h2>
                                                <div className="form-group col-md-12" style={{ paddingRight: "25px" }}>
                                                    <label id="parameter-aria-label" htmlFor="aria-parameter-input" className="t3">
                                                        {t("productspages.rules.selectParameter")} 
                                                    </label>
                                                    <SelectSearch
                                                        aria-labelledby="parameter-aria-label"
                                                        inputId="aria-parameter-input"
                                                        name="aria-live-parameter"
                                                        className="custom-select-control"
                                                        options={this.options}
                                                        placeholder={t("productspages.rules.select") +"..." }
                                                        onChange={(event) => { onSelectChange("parameter", event.value) }}
                                                        components={{ Option: OptionLayout, IndicatorSeparator: () => null }}
                                                    />
                                                        
                                                    <input
                                                        id = "parameterValidator"
                                                        tabIndex={-1}
                                                        autoComplete="off"
                                                        style={{ opacity: 0, height: 0 , position:"absolute"}}
                                                        defaultValue={value}
                                                        required={ true } />
                                                </div>

                                                <div className="form-group col-md-6" style={{ paddingRight: "25px" }}>
                                                    <FormControl variant="standard" style={{ width: '100%', marginTop: "9.5px" }}>
                                                        
                                                        <label id="ddlOperatorLabel" htmlFor="aria-operator-input" className="t3">
                                                            {t('productspages.alertreporting.operator')}:
                                                        </label>
                                                        <SelectSearch
                                                            name="operator"
                                                            className="titleProperty1 custom-select-control"
                                                            inputId="aria-operator-input"
                                                            aria-labelledby="ddlOperatorLabel"
                                                            id="ddlOperator"
                                                            options={operators}
                                                            placeholder={t('productspages.alertreporting.placeholderoperator')}
                                                            onChange={(event) => { onSelectChange("operator", event.value) }}
                                                            components={{ Option: OptionLayout2, IndicatorSeparator: () => null}}
                                                        />
                                                        <input
                                                            id="operatorValidator"
                                                            tabIndex={-1}
                                                            autoComplete="off"
                                                            style={{ opacity: 0, height: 0 }}
                                                            defaultValue={value}
                                                            required={true} />
                                                        
                                                    </FormControl>
                                                </div>

                                                <div className="form-group col-md-6" style={{ paddingRight: "25px" }}>
                                                    <FormControl variant="standard" style={{ width: '100%', marginTop: "9.5px" }}>
                                                       
                                                        <label id="ddlAgregationLabel" htmlFor="aria-agregation-input" className="t3">
                                                            {t('productspages.alertreporting.agregationtype')}:
                                                        </label>
                                                        <SelectSearch                                     
                                                            options={agregations}
                                                            name="agregation"
                                                            className="titleProperty1 custom-select-control"
                                                            aria-labelledby="ddlAgregationLabel"
                                                            inputId="aria-agregation-input"
                                                            id="ddlAgregation"
                                                            onChange={(event) => {
                                                                onSelectChange("agregation", event.value)
                                                            }}
                                                            placeholder={t('productspages.alertreporting.placeholderagregationtype')}
                                                            components={{ Option: OptionLayout2, IndicatorSeparator: () => null }}
                                                            
                                                        />
                                                        <input
                                                            id="agregationValidator"
                                                            tabIndex={-1}
                                                            autoComplete="off"
                                                            style={{ opacity: 0, height: 0}}
                                                            defaultValue={value}
                                                            required={true} />
                                                    </FormControl>
                                                </div>
                                                <div className="form-group col-md-6" style={{ paddingRight: "25px" }}>
                                                    <label className="t3" style={{ fontSize: "11px", margin: "0px", marginTop: "9.5px" }} htmlFor="txtAlertValue">{t('productspages.alertreporting.alertvalue')}:</label>
                                                    <input type="number" step="any" className="form-control" placeholder={t('productspages.alertreporting.alertvalue')} id="txtAlertValue" required />
                                                </div>
                                                <div className="form-check col-md-4" style={{ marginRight: "25px", paddingTop: "35px" }}>
                                                    <div className="flexContainer">
                                                        <span className="t3"> {t('productspages.alertreporting.onlyonce')} </span>
                                                        <div>
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="chkOnlyOnce"
                                                                label=""
                                                                defaultChecked={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-check col-md-4" style={{ marginRight: "25px", paddingTop: "35px" }}>
                                                    <div className="flexContainer">
                                                        <span className="t3"> {t('productspages.alertreporting.active')} </span>
                                                        <div>
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="chkActive"
                                                                label=""
                                                                defaultChecked={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Button type="submit" className="primary active">
                                                {t('actions.create')}
                                            </Button>
                                            <Button className="primary" style={{float: "right"}} onClick={() => this.props.onClose()}>
                                                {t('actions.close')}
                                            </Button>
                                        </form>
                                )}
                            </div>
                        ) : (
                            <NoData/>
                        )}
                    </div>
                ) : (
                    <div className="loadingParent"><div className="loadingImg"></div></div>
                )}
            </div>
        );
    }
}

export default withTranslation()(ProductsConfigAlertRuleForm);