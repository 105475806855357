import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { t } from 'i18next';
import { ModalConfirmDeleteHardwareViewProps } from '../types/HWHardwareTypes';

const ModalConfirmDeleteHardwareView = (props: ModalConfirmDeleteHardwareViewProps) => (
  <Dialog disableEscapeKeyDown open={props.open} onClose={() => props.handleShowConfirmDeleteHWModal(false)}>
    <DialogTitle>{t('actions.deletename', { name: t('firmwareManagementPage.hardware') })}</DialogTitle>
    <DialogContent>
      <div>
        <span>{t('actions.confirmdelete', { name: t('firmwareManagementPage.hardware') })}</span>
      </div>
    </DialogContent>
    <DialogActions>
      <button className='primary' onClick={() => props.deleteHardware()}>
        {t('actions.yes')}
      </button>
      <button className='primary' onClick={() => props.handleShowConfirmDeleteHWModal(false)}>
        {t('actions.no')}
      </button>
    </DialogActions>
  </Dialog>
);

export default ModalConfirmDeleteHardwareView;
