import { Skeleton } from "@mui/material";
const ParametersSkeleton = () => {
  return (
    <div style={{ width: "100%", display: "table" }}>
      <div style={{ width: "100%", margin: "10px 0px" }}>
        <Skeleton variant="rectangular" height={350} />
      </div>
      
      <div style={{ display: "flex", justifyContent: "end", margin: "10px" }}>
        <Skeleton variant="rectangular" width={150} height={45} />
      </div>
    </div>
  );
};

export default ParametersSkeleton;
