import React, { Component } from 'react';
import '../../../../App.css';
import './ProductAlerts.css';
import { withTranslation } from 'react-i18next';
import 'react-multi-carousel/lib/styles.css';
import Form from 'react-bootstrap/Form';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import CheckIcon from '@mui/icons-material/Check';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import AlertHttpClient from '../../../../HttpClient/AlertHttpClient';
import ActiveNotificationsList from './../Notifications/ActiveNotificationsList';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import AuthenticationContext from '../../../../Authentication/types/AuthContextType';
import Message from '../../../Message/Message';

import * as moment from 'moment';
import ActiveNotification from '../Notifications/ActiveNotificationsCards.js';
import HistoricNotificationList from '../Notifications/HistoricNotificationList.js';
import Accordions from '@mui/material/Accordion';
import AccordionsSummary from '@mui/material/AccordionSummary';
import AccordionDetail from '@mui/material/AccordionDetails';

export class ProductAlerts extends Component {
  static contextType = AuthenticationContext;
  fullAlerts = [];

  originalAlerts = [];

  constructor(props) {
    super(props);
    this.id = parseInt(window.location.pathname.split('/')[2]);
    this.state = {
      alerts: [],
      loading: true,
      filter: {
        checked: true,
        rules: true,
        alerts: true,
        warnings: true,
      },
      loadHistoricNotifications: true,
      showHistory: false,
      noAlerts: false,
      message: null,
    };
  }

  renderMessage = (type, content) => {
    this.setState({
      message: {
        type: type,
        content: content,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({ message: null });
  };

  refreshNotifications() {
    this.setState({ loadHistoricNotifications: false }, () => {
      this.setState({ loadHistoricNotifications: true });
    });
  }

  componentDidMount() {
    //this.getAlerts();
  }

  async getAlerts() {
    try {
      this.http = new AlertHttpClient(this.context, this.id, null, null, null);
      let data = await this.http.GetActiveAlerts();

      if (data && data.content && data.content.length != 0) {
        // severity 0,10,30 red 20 orange, 40,50 blue
        // 0 critical
        // 10 error
        // 20 warning
        // 30 usererror
        // 40 notice
        // 50 info

        this.originalAlerts = [...data.content];

        let alerts = data.content.map((alert) => {
          let typeImage = '';
          let type = '';
          let code = '';
          let checked = alert.checkedUser ? true : false;

          switch (alert.severity) {
            case 0:
              code = 'C';
              type = 'productspages.alertreporting.critical';
              typeImage = 'alert';
              break;
            case 10:
              code = 'E';
              type = 'productspages.alertreporting.error';
              typeImage = 'alert';
              break;
            case 20:
              code = 'W';
              type = 'productspages.alertreporting.warning';
              typeImage = 'warning';
              break;
            case 30:
              code = 'UE';
              type = 'productspages.alertreporting.usererror';
              typeImage = 'error';
              break;
            case 40:
              code = 'N';
              type = 'productspages.alertreporting.notice';
              typeImage = 'rule';
              break;
            case 50:
              code = 'I';
              type = 'productspages.alertreporting.info';
              typeImage = 'rule';
              break;
            default:
              break;
          }

          const yourDate = new Date(alert.timestamp);

          return {
            id: alert.id,
            code: code,
            typeImage: typeImage,
            type: type,
            description: alert.description,
            date: moment(yourDate).format('YYYY-MM-DD HH:mm:ss'),
            checked: checked,
          };
        });

        this.fullAlerts = [...alerts];
        this.setState({ alerts: [...alerts], loading: false });
      } else {
        this.setState({ noAlerts: true, loading: false });
      }
      this.enableCards();
    } catch (err) {
      console.error(err);
      this.setState({ noAlerts: true });
      this.enableCards();
    }
  }

  toggleFullGrid() {
    let show = !this.state.showHistory;
    this.setState({ showHistory: show });
  }

  disableCards() {
    try {
      let alertCards = document.getElementsByClassName('card noSelect');
      if (alertCards) {
        for (let i = 0, all = alertCards.length; i < all; i++) {
          if (!alertCards[i].classList.contains('disabledCard')) alertCards[i].classList.add('disabledCard');
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  enableCards() {
    try {
      let alertCards = document.getElementsByClassName('card noSelect');
      if (alertCards) {
        for (let i = 0, all = alertCards.length; i < all; i++) {
          if (alertCards[i].classList.contains('disabledCard')) alertCards[i].classList.remove('disabledCard');
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  async alertChecked(ev) {
    let check = ev.target.checked;

    if (check) {
      const { t } = this.props;

      this.disableCards();
      let alert = this.originalAlerts.find((x) => x.id == ev.target.id);

      let close = alert.closedUser ? true : false;

      let obj = {
        check: true,
        close: close,
      };

      this.http = new AlertHttpClient(this.context, this.id, null, null, null);
      let data = await this.http.UpdateAlert(obj, alert.id);

      if (data.errors) this.renderMessage('error', t('general.error'));
      else this.renderMessage('success', t('actions.success'));

      this.getAlerts();
    }
  }

  async closeAlert(ev, alert) {
    const { t } = this.props;

    this.disableCards();
    let nAlert = this.originalAlerts.find((x) => x.id == alert.id);

    let check = alert.checkedUser ? true : false;

    let obj = {
      check: check,
      close: true,
    };

    this.http = new AlertHttpClient(this.context, this.id, null, null, null);
    let data = await this.http.UpdateAlert(obj, nAlert.id);

    if (data.errors) {
      this.renderMessage('error', t('general.error'));
    } else {
      this.renderMessage('success', t('actions.success'));    
    }

    this.getAlerts();
  }

  buildAlertCards() {
    const t = this.props.t;

    let listItems = [];
    if (this.state.loading) {
      listItems = [
        <div key={1} className='card noBoxShadow'>
          <div className='alert-card-container flexContainer'>
            <div className='row loadingParent'>
              <div className='loadingImg'></div>
            </div>
          </div>
        </div>,
      ];
    } else if (this.state.noAlerts) {
      listItems = [
        <div key={1} className='card'>
          <div className='alert-card-container flexContainer'>
            <div className='icon-col'>
              <div className='alarmState'>
                <div className='stateDiv'>
                  <div>
                    <img className={'state-noAlert'} src='' />
                    <CheckIcon style={{ color: 'white' }} />
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
            <div className='alert-card-content'>
              <h3 style={{ color: '#666666' }}>{t('productspages.alertreporting.noalert')}</h3>
            </div>
          </div>
        </div>,
      ];
    } else {
      if (this.state.alerts.length == 0) {
        listItems = [
          <div key={1} className='card'>
            <div className='alert-card-container flexContainer'>
              <div className='icon-col'>
                <div className='alarmState'>
                  <ReportProblemOutlinedIcon style={{ color: '#666666' }} />
                </div>
              </div>
              <div className='alert-card-content'>
                <h3 style={{ color: '#666666' }}>{t('productspages.alertreporting.noalertfilter')}</h3>
              </div>
            </div>
          </div>,
        ];
      } else {
        listItems = this.state.alerts.map((alert) => {
          return (
            <div key={alert.id} className='card noSelect'>
              <div className='alert-card-container flexContainer'>
                <div className='icon-col'>
                  <div className='alarmState'>
                    <div className='stateDiv'>
                      <div>
                        <img className={'state-' + alert.typeImage} src='' />
                        <ErrorIcon style={{ color: 'white' }} />
                        <span>{alert.code}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='alert-card-content'>
                  <h4 style={{ margin: 0, padding: 0 }}>{t(alert.type)}</h4>
                  <span className='t3' style={{ margin: 0, padding: 0, display: 'block' }}>
                    {' '}
                    {alert.description}{' '}
                  </span>

                  <div>
                    <h4 className='d-inline'>Date:</h4> <span className='d-inline t3'> {alert.date} </span>
                  </div>

                  <div className='flexContainer'>
                    <span className='t3'> Checked </span>
                    <Form>
                      <Form.Check
                        type='checkbox'
                        id={alert.id}
                        label=''
                        defaultChecked={alert.checked}
                        onChange={(evt) => this.alertChecked(evt, this.context)}
                        disabled={alert.checked}
                      />
                    </Form>
                  </div>
                  <IconButton
                    className='closeAlertButton'
                    onClick={(e) => this.closeAlert(e, alert)}
                    aria-label='close'
                    size='large'
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          );
        });
      }
    }
    return listItems;
  }

  onChangeFilter(evt) {
    let check = evt.target.checked;

    let filter = { ...this.state.filter };

    switch (evt.target.id) {
      case 'cf1':
        // checked
        filter.checked = check;
        break;
      case 'cf2':
        // rules
        filter.rules = check;
        break;
      case 'cf3':
        // alerts
        filter.alerts = check;
        break;
      case 'cf4':
        // warnings
        filter.warnings = check;
        break;
    }

    let nAlerts = [...this.fullAlerts];

    this.fullAlerts.forEach((alert, index) => {
      if (
        (!filter.checked && alert.checked) ||
        (!filter.rules && alert.typeImage == 'rule') ||
        (!filter.alerts && alert.typeImage == 'alert') ||
        (!filter.warnings && alert.typeImage == 'warning')
      ) {
        let i = nAlerts.findIndex((x) => x.id == alert.id);
        if (i != -1) nAlerts.splice(i, 1);
      }
    });

    this.setState({
      filter: filter,
      alerts: nAlerts,
    });
  }

  render() {
    const { t } = this.props;
    if (this.props.product) {
      return (
        <div className='notifications-group'>
          {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}
          <div>
            <ActiveNotification />
          </div>
          <div style={{ marginTop: '30px', width: '100%' }}>
            <ActiveNotificationsList product={this.props.product} refreshNotifications={this.refreshNotifications.bind(this)} />
          </div>
          <div className="productAlert" style={{ marginTop: '30px', width: '100%' }}>
            <Accordions classes={{ root: 'accordion-root-custom' }}>
              <AccordionsSummary
                expandIcon={<img src={require('../../../../assets/icons/chevron-down.svg').default} style={{ width: '24px' }} />}
                aria-controls='panel1a-content'
              >
                <h3>{t('productspages.alertreporting.notificationHistory')}</h3>
              </AccordionsSummary>
              <AccordionDetail>
                <div className='w-100'>{this.state.loadHistoricNotifications ? <HistoricNotificationList product={this.props.product} /> : null}</div>
              </AccordionDetail>
            </Accordions>
          </div>
        </div>
      );
    }
  }
}
export default withTranslation()(ProductAlerts);
