import React, { Component } from 'react';
import '../../../../App.css';
//import BootstrapTable from 'react-bootstrap-table-next';
//import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
//import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import '../Notifications/ActiveNotificationsCards.css';

import { Tooltip, Whisper, } from 'rsuite';

import AlertHttpClient from "../../../../HttpClient/AlertHttpClient";
import AuthenticationContext from "../../../../Authentication/types/AuthContextType";
import '../../../ProductsStyle.css';

import ProductsConfigAlertRuleForm from "./ProductsConfigAlertRuleForm";
import Message from "../../../Message/Message";

import Button from 'react-bootstrap/Button'

import moment from 'moment';
import Moment from 'react-moment';
import { withTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import Table from '../../../CustomComponents/ReactTable/Table';
import TableSkeleton from '../../../CustomComponents/ReactTable/TableSkeleton';
import { t } from 'i18next';


class ProductsConfigAlertRule extends Component {

    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.id = parseInt(window.location.pathname.split('/')[2]);
        this.filterDefault = function () {
            return(
                {
                    all: true,
                    active: false,
                    alarm: false,
                    rule: false,
                    warning: false
                }
            );
        };
        let filter = this.filterDefault();

        try {
            let localFilter = null;
            localFilter = localStorage.getItem("ProductsConfigAlertRuleFilter");
            if (localFilter != undefined && localFilter != null) {
                filter = JSON.parse(localFilter);
            }
        } catch (err) {

        }
        
        this.state = {
            _data: null,
            allData: null,
            page: 1,
            sizePerPage: 5,
            totalSize: 0,
            show: false,
            showModalDelete: false,
            showModalDeleteError: false,
            message: null,
            filter: filter
        };

        const { t } = props

        this.paginaActual = this.getCurrentPage();
        this.state.sizePerPage = this.getCurrentSizePerPage();
        
        this.columns =
            [{
                dataField: 'name',
                text: t('tables.ruleName'),
                align: 'left',
                sort: true,
                headerStyle: () => {
                    return { width: "15%" };
                },
                sortCaret: (order, column) => {
                    if (!order) return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort.png').default} />);
                    else if (order === 'asc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-asc.png').default} />);
                    else if (order === 'desc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-desc.png').default} />);
                    return null;
                }
            },
            {
                dataField: 'PUUID',
                text: 'Prod ID',
                align: 'left',
                sort: true,
                hidden: true,
                headerStyle: () => {
                    return { width: "0%" };
                }
            },
            {
                dataField: 'description',
                text: t('tables.description'),
                align: 'left',
                sort: true,
                headerStyle: () => {
                    return { width: "20%" };
                },
                sortCaret: (order, column) => {
                    if (!order) return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort.png').default} />);
                    else if (order === 'asc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-asc.png').default} />);
                    else if (order === 'desc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-desc.png').default} />);
                    return null;
                }
            },
            {
                dataField: 'individualRule.type',
                text: t('tables.type'),
                align: 'left',
                sort: true,
                hidden: false,
                formatter: this.TypeFormatter.bind(this),
                headerStyle: () => {
                    return { width: "8%"};
                },
                sortCaret: (order, column) => {
                    if (!order) return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort.png').default} />);
                    else if (order === 'asc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-asc.png').default} />);
                    else if (order === 'desc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-desc.png').default} />);
                    return null;
                }
            },
            // {
            //     dataField: 'businessLabel',
            //     text: t('tables.parameter'),
            //     align: 'left',
            //     sort: false,
            //     //formatter: this.parameterFormatter.bind(this),
            //     headerStyle: () => {
            //         return { width: "13%" };
            //     }
            // },
            // {
            //     dataField: 'operator',
            //     text: t('tables.operator'),
            //     align: 'center',
            //     sort: false,
            //     formatter: this.operationFormatter.bind(this),
            //     headerStyle: () => {
            //         return { width: "11%" };
            //     }
            // },
            // {
            //     dataField: 'thresholdValue',
            //     text:  t('tables.alertValue'),
            //     align: 'center',
            //     sort: true,
            //     formatter: this.thresholdFormatter.bind(this),
            //     headerStyle: () => {
            //         return { width: "10%" };
            //     },
            //     sortCaret: (order, column) => {
            //         if (!order) return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort.png').default} />);
            //         else if (order === 'asc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-asc.png').default} />);
            //         else if (order === 'desc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-desc.png').default} />);
            //         return null;
            //     }
            // },
            {
                dataField: 'dataRegisterId',
                text: 'dataRegisterId',
                align: 'center',
                sort: true,
                hidden: true,
                headerStyle: () => {
                    return { width: "0%" };
                },
                sortCaret: (order, column) => {
                    if (!order) return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort.png').default} />);
                    else if (order === 'asc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-asc.png').default} />);
                    else if (order === 'desc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-desc.png').default} />);
                    return null;
                }
            }, {
                dataField: 'parameterId',
                text: 'parameterId',
                align: 'center',
                sort: true,
                hidden: true,
                headerStyle: () => {
                    return { width: "0%" };
                },
                sortCaret: (order, column) => {
                    if (!order) return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort.png').default} />);
                    else if (order === 'asc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-asc.png'.default)} />);
                    else if (order === 'desc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-desc.png').default} />);
                    return null;
                }

            }, {
                dataField: 'kpiId',
                text: 'kpiId',
                align: 'center',
                sort: true,
                hidden: true,
                headerStyle: () => {
                    return { width: "0%" };
                },
                sortCaret: (order, column) => {
                    if (!order) return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort.png').default} />);
                    else if (order === 'asc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-asc.png').default} />);
                    else if (order === 'desc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-desc.png').default} />);
                    return null;
                }
            },

            // {
            //     dataField: 'aggregationType',
            //     text: t('tables.aggregationType'),
            //     align: 'left',
            //     sort: false,
            //     formatter: this.agregationFormatter.bind(this),
            //     headerStyle: () => {
            //         return { width: "12%" };
            //     }
            // },
            {
                dataField: 'category',
                text: t('tables.type'),
                align: 'left',
                sort: true,
                headerStyle: () => {
                    return { width: "8%" };
                }
            },
            {
                hidden: true,
                dataField: 'type',
                text: t('tables.type'),
                align: 'left',
                sort: true,
                headerStyle: () => {
                    return { width: "10%" };
                }
            },
            {
                dataField: 'active',
                text: t('tables.active'),
                align: 'left',
                sort: true,
                formatter: this.activeFormatter.bind(this),
                headerStyle: () => {
                    return { width: "9%" };
                }
            },
            {
                dataField: 'actions',
                text: t('productspages.parameters.actions'),
                align: 'center',
                formatter: this.DeleteFormatter.bind(this),
                headerStyle: () => {
                    return { width: "10%" };
                }
            }
        ];

        this.customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total" style={{ marginLeft: '5px' }}>
                {t('tables.customTotal', { from: from, to: to, size: size })}
            </span>
        );

        this.defaultSorted = [{
            dataField: 'date',
            order: 'desc'
        }];

        this.activeFormatter = this.activeFormatter.bind(this);
        this.refresh = this.refresh.bind(this);
        this.checkRule = this.checkRule.bind(this);
    }

    renderMessage = (type, content) => {
        this.setState({
          message: {
            type: type,
            content: content,
          }
        });
      };
    
      handleDialogClose = () => {
        this.setState({ message: null });
      };

    TypeFormatter(row, cell) {
        if (row) {
            if (row.row.type != undefined && row.row.type != null) {
                let iType = row.row.type.toLowerCase();
                const { t } = this.props;
                let rType = "";
                let tType = "";
                if (iType.includes("warning")) {
                    rType = "warning";
                    tType = t("productspages.alertreporting.warning");
                } else if (iType.includes("alarm")) {
                    rType = "alarm";
                    tType = t("productspages.alertreporting.alarm");
                } else if (iType.includes("parameter") || iType.includes("rule")) {
                    rType = "rule";
                    tType = t("productspages.alertreporting.rule");
                }

                return (<span className={rType + " custom-button-span"}>{tType != "" ? tType : row.row.type }</span>);
            } else {
                return (<span className={" custom-button-span"}></span>);
            }
        }
    }
    getCurrentPage() {
        let currentPage = 1
        try {
            currentPage = JSON.parse(localStorage.getItem('currentPageRule')) || 1;
        }
        catch(e){
            currentPage = 1;
        }
        return parseInt(currentPage);
    }
    getCurrentSizePerPage() {
        let currentSizePerPage = -1
        try {
            currentSizePerPage = JSON.parse(localStorage.getItem('sizePerPageRule'));
            if(currentSizePerPage == null || (currentSizePerPage > 0 && currentSizePerPage < 5)){
                currentSizePerPage = 5
            }
        }
        catch(e){
            currentSizePerPage = 5;
        }
        return parseInt(currentSizePerPage);
    }

    refresh(event) {
        this.setState({ show: false })
        this.getConfigAlertRules(this.getCurrentPage(), this.getCurrentSizePerPage(), this.state.filter);
    }

    componentDidMount() {
        this.getConfigAlertRules(this.getCurrentPage() - 1, this.getCurrentSizePerPage(), this.state.filter, true);
        //this.getConfigAlertRules(this.getCurrentPage(), this.state.sizePerPage);
    }
    handleTableChange = (type, { page, sizePerPage, test1, test2, test3 }) => {
        const currentIndex = (page - 1) * sizePerPage;

        switch (type) {
            case "search":

                break;
            case "pagination":
                this.getConfigAlertRules(page, sizePerPage, this.state.filter);
                break;

        }
    }
    filterChange(option, value = null) {
        let vfilter = null;

        if (option == "all") {
            vfilter = this.filterDefault();
            this.setState({ selectedValue: null });
            vfilter.all = true;
        }
        else {
            vfilter = this.state.filter;
            vfilter.all = false;
            if (value != null) {
                vfilter[option] = value == '' ? null : value;
            } else {
                let valOption = !vfilter[option];
                vfilter[option] = valOption;
            }

        }
        let allFalse = true;
        for (const item in vfilter) {
            if (vfilter[item]) {
                allFalse = false;
            }
        }
        if (allFalse) vfilter = this.filterDefault();
        
        localStorage.setItem("ProductsConfigAlertRuleFilter", JSON.stringify(vfilter));
        this.setState({ filter: vfilter });
        this.getConfigAlertRules(this.getCurrentPage(), this.getCurrentSizePerPage());
    }

    async getConfigAlertRules(page, sizePerPage, filter, firstLoad = false) {

        this.setState({ _data: null, totalSize: 0 });

        this.http = new AlertHttpClient(this.context, this.id, null, page, sizePerPage);
        let data = await this.http.AlertsGetConfigAlertsRules(page, sizePerPage);
        const dataWithTranslation = this.translateData(data);

        try {
            if (dataWithTranslation.content && dataWithTranslation.content.length == 0) {
                this.setState({ _data: dataWithTranslation.content, totalSize: 0, allData: dataWithTranslation.content });

            }
            else if (dataWithTranslation != undefined && dataWithTranslation.content != undefined && dataWithTranslation.content.length > 0) {
                this.aplyFilter(dataWithTranslation.content);
                this.setState({ page: page, sizePerPage: sizePerPage, allData: dataWithTranslation.content });
            }
        }
        catch (err) {
        //    console.log(err)
        }
    }

    getTranslationIfExist = (keyTrans) => {
        return keyTrans !== this.props.t(`alerts:${keyTrans}`);
      };
      
      translateData = (data) => {
        if (data && data.content && data.content.length) {
          data.content.forEach(row => {
            if (row.parameter) {
                const {smartComponentTypeId} = row
                const { businessLabel } = row.parameter;
                if (smartComponentTypeId && businessLabel) {
                row.parameter.name = this.getTranslationIfExist(`${smartComponentTypeId}.${businessLabel}.name`)
                  ? this.props.t(`alerts:${smartComponentTypeId}.${businessLabel}.name`)
                  : row.parameter.name;
      
                row.parameter.description = this.getTranslationIfExist(`${smartComponentTypeId}.${businessLabel}.description`)
                  ? this.props.t(`alerts:${smartComponentTypeId}.${businessLabel}.description`)
                  : row.parameter.description;
                } else {
                    console.log(row); // Fila con campos faltantes
                }
            }
          });
        }
        return data;
      };

    aplyFilter(data = null, vfilter = null, idRule = null, patch = null) {
        let data1 = data != null ? data : this.state.allData;
        let filter = vfilter != null ? vfilter : this.state.filter;
        let txtFilter = [];
        if (idRule != null && patch.path == '/isActive') {
            data1.map((obj) => { if (obj.id == idRule) obj.isActive = (patch.value.toLowerCase() === 'true')  })
        }

        
        if (filter.active) {
            data1 = data1.filter(x => x.isActive);
        }

        for (const item in filter) {
            if (filter[item] && item != "active" && item != 'all') {
                txtFilter.push(item);
            }
        }
        if (txtFilter.length > 0) {
            data1 = data1.filter(x => txtFilter.includes(x.type));
        }



        this.setState({ _data: data1, totalSize: data1.length });
    }
    parameterFormatter({ row, value }) {
        if (row) {
            let out = "-";
            try {
                switch (row.type.toLowerCase()) {
                    case "parameter":
                        out = row.parameter?.name ?? row.parameter?.description;
                        break;
                    case "kpi":
                        out = row.kpi?.name ?? row.kpi?.description;
                        break;
                    case "dataregister":
                        out = row.dataRegister?.name ?? row.dataRegister?.description;
                        break;

                    default:
                        break;

                }
            }
            catch (err) {
                out = "-"
            }
            return (
                <span>{out}</span>
            );
        }
    }

    agregationFormatter({ row, value }) {
        if (row) {
            let out = "-";
            switch (row.aggregationType) {
                case "LastHour":
                    out = this.props.t('productspages.alertreporting.lh');
                    break;
                case "LastDay":
                    out = this.props.t('productspages.alertreporting.ld');
                    break;
                case "LastWeek":
                    out = this.props.t('productspages.alertreporting.lw');
                    break;
                case "LastMonth":
                    out = this.props.t('productspages.alertreporting.lm');
                    break;
                case "LastYear":
                    out = this.props.t('productspages.alertreporting.ly');
                    break;
                case "FromInstallationDate":
                    out = this.props.t('productspages.alertreporting.fid');
                    break;
                case "CountEvery":
                    out = this.props.t('productspages.alertreporting.ce');
                    break;
                default:
                    out = row.aggregationType;
                    break;

            }
            return (
                <span>{out}</span>

            );
        }
    }

    operationFormatter({ row, value }) {
        if (row) {
            try {
                let out = "----";
                switch (row.operator) {
                    case "equal":
                        out = "=";
                        break;
                    case "geq":
                        out = ">=";
                        break;
                    case "leq":
                        out = "<=";
                        break;
                    default:
                        break;

                }
                return (
                    <span>{out}</span>

                );
            }
            catch (e) { }
        }
    }

    thresholdFormatter({ row, value }) {
        if (row) {
            return (
                //<span>{row.increaseValue != null && row.increaseValue != 0 ? row.increaseValue : row.thresholdValue}</span>
                <span>{row.thresholdValue != null && row.thresholdValue != 0 ? row.thresholdValue : row.increaseValue}</span>

            );
        }
    }

    DeleteFormatter({ row, value }) {
        if (row.category != 'default') {
            const onClick = (evt) => {
                this.setState({ showModalDelete: true, idRule: row.id ?? 0 });
            }
            let idEle = "btnDelete_";
            if (row.id) {
                idEle += row.id
            }
            return (

                <DeleteOutlineIcon id={idEle} style={{ width: '24px', cursor: "pointer" }} onClick={(evt) => onClick(evt)} />

            );
        }

    }

    async confirmDelete(t) {
        await this.Delete(this.state.idRule, t);
    }

    async Delete(idRule, t) {

        let data;
        try {
            this.http = new AlertHttpClient(this.context, this.id, null, null);
            data = await this.http.DeleteAlertRule(idRule, this.props.product.content.id)

            let messageObj = {};
            if (data.content != undefined && data.content) {
                messageObj.message = "deleted seccesfully"
                messageObj.type = "success"
                this.setState({ showModalDelete: false });
                this.getConfigAlertRules(this.getCurrentPage(), this.getCurrentSizePerPage(), this.state.filter);
            }
            else if (data.status != undefined) {

                if (data.status > 200) {
                    messageObj.message = "error deleting"
                    messageObj.type = "error"
                    this.setState({ showModalDelete: false, saving: false });
                }
                else {
                    messageObj.message = "deleted seccesfully"
                    messageObj.type = "success"
                    this.setState({ showModalDelete: false });
                    this.getConfigAlertRules(this.getCurrentPage(), this.getCurrentSizePerPage(), this.state.filter);
                }
            }
            else if (data.errors != undefined) {
                messageObj.message = "error deleting"
                messageObj.type = "error"
                this.setState({ showModalDelete: false, saving: false });
            }
            else if (data.startsWith("System.InvalidOperation")) {// Forbid
                messageObj.message = "error deleting"
                messageObj.type = "error"
                this.setState({ showModalDelete: false, saving: false });
            }
            else {//ok
                messageObj.message = "deleted seccesfully"
                messageObj.type = "success"
                this.setState({ showModalDelete: false });
                this.getConfigAlertRules(this.getCurrentPage(), this.getCurrentSizePerPage(), this.state.filter);
            }
            this.renderMessage(messageObj.type, messageObj.message);

        } catch (e) {
            this.setState({ showModalDelete: false, saving: false, showModalDeleteError: true });
            this.renderMessage("error", "error deleting");
        }
    }

    async checkRule(patch, idRule, checked) {
        this.http = new AlertHttpClient(this.context, this.id, null, null);
        let data = await this.http.UpdateAlert(idRule, this.props.product.content.id, patch)
        let messageObj = {};

        if (data.error || data.content == null) {
            messageObj.type="error"
            messageObj.message="error deleting"
        }
        else {
            messageObj.type="success"
            messageObj.message= !checked ? t('checkboxChecked') : t('checkboxUnchecked')
            this.aplyFilter(this.state._data, this.state.filter, idRule, patch );
        }
        this.renderMessage(messageObj.type, messageObj.message);
    }

    activeFormatter({ row, value }) {
        if (row) {
            const onChange = (evt) => {
            }
            const onClick = (evt) => {

                if (this.timeout) clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    let check = document.getElementById("chkActive-" + row.id)
                    if (check) {
                        let checked = !check.checked;
                        check.setAttribute("checked", checked)

                        let patch = { op: "replace", path: "/isActive", value: "" + !checked }
                        
                        this.checkRule(patch, row.id, checked);
                    }
                }, 100)
            }

            return (
                <div className="flexContainer">
                    <span className="t1"> </span>
                    <Form style={{ }} onClick={(evt) => onClick(row)}>
                        <Form.Check
                            id={"chkActive-" + row.id}
                            type="checkbox"
                            label=""
                            defaultChecked={row.isActive}
                            onChange={(evt) => onChange(evt, this.context)}
                        />
                    </Form>
                </div>
            );        }
    }

    handleChange(evt) {
        //his.setState({checkboxChecked: evt.target.checked });
        //debugger;
    }

    handleClick() {
        let val = !this.state.show;
        this.setState({ show: val });

        
        if (typeof this.props.stopRefresh === 'function') {
            //this.props.stopRefresh(true);
        }
        
    }

    RowDetail = ({ row }) => {
        return (
          <div className='row pl-5'>
            <div className='col-12 mb-2'>
              <h6>{this.props.t('productspages.general.moreinfo')}</h6>
            </div>
            <div className='col pl-5'>
              <div className='row'>
                <div className='col-12 pb-3 pl-2'>
                  <span style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.87)' }} className='t3'>
                  {this.props.t('productspages.parameters.parameter')} -&gt; {row.businessLabel}
                  </span>
                </div>
                <div className='col-12 pb-3 pl-2'>
                  <span style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.87)' }} className='t3'>
                  {this.props.t('productspages.parameters.aggregationType')} -&gt; {this.agregationFormatter({ row })}
                  </span>
                </div>
                <div className='col-12 pb-3 pl-2'>
                  <span style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.87)' }} className='t3'>
                  {this.props.t('productspages.parameters.operator')} -&gt; {this.operationFormatter({ row })}
                  </span>
                </div>
                <div className='col-12 pb-3 pl-2'>
                  <span style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.87)' }} className='t3'>
                  {this.props.t('productspages.parameters.alertValue')} -&gt; {this.thresholdFormatter({ row })}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      };

     onSizePerPageChange (sizePerPage) {
        localStorage.setItem('sizePerPageRule', sizePerPage);
        console.log("sizePerPage" + sizePerPage)
        this.setState({ sizePerPage: sizePerPage }, () => console.log("this.getCurrentSizePerPage()" + this.getCurrentSizePerPage()));
    }

    onPageChange(newPage) {
        this.paginaActual = newPage;
        localStorage.setItem('currentPageRule', newPage);
        this.setState({ page: newPage });
    }

    render() {
        //const { SearchBar } = Search;
       
        const { t } = this.props;
        let dataLoaded = this.state._data != null && this.props.product?.content != undefined;
        const pageButtonRenderer = ({
            page,
            active,
            disable,
            title,
            onPageChange
        }) => {
            const handleClick = (e) => {
                e.preventDefault();
                onPageChange(page);
            };
            const activeStyle = {};
            const isActive = active ? "active" : "";
            if (active) {
                activeStyle.margin = '5px';
            } else {
                activeStyle.margin = '5px';
            }

            return (
                <li className={"page-item paginate_button " + isActive}>
                    <a href="#" className="page-link" onClick={handleClick} style={activeStyle}>{page}</a>
                </li>
            );
        };


        this.options = {
            pageButtonRenderer,
            page: this.paginaActual,
            paginationSize: this.getCurrentSizePerPage(),
            pageStartIndex: 1,
            sizePerPage: this.getCurrentSizePerPage(),
            hidePageListOnlyOnePage: true,
            showTotal: true,
            paginationTotalRenderer: this.customTotal,
            disablePageTitle: true,
            sizePerPageList: [{
                text: '5', value: 5
            }, {
                text: '15', value: 15
            }, {
                text: t('tables.all'), value: this.state.totalSize
                }],
            onSizePerPageChange: (sizePerPage, page) => {
                localStorage.setItem('sizePerPageRule', sizePerPage);
                console.log("sizePerPage" + sizePerPage)
                this.setState({ sizePerPage: sizePerPage }, () => console.log("this.getCurrentSizePerPage()" + this.getCurrentSizePerPage()));
            },
            onPageChange: (newPage) => {
                this.paginaActual = newPage;
                localStorage.setItem('currentPageRule', newPage);
                this.setState({ page: newPage });
            },
        };

        const handleClose = () => {
            this.setState({ show: !this.state.show });
        };
        const handleCloseDelete = () => {
            this.setState({ showModalDelete: false });
        };
        const handleCloseError = () => {

            this.setState({ showModalDeleteError: false, showModalDelete: false });
        };
        function tooltip(text) {
            let t = text.toLowerCase();
            return (<Tooltip style={{ textTransform: "capitalize" }}>
                {t}
            </Tooltip>)
        };
        const filter = (<React.Fragment>
            <h6 className="d-inline-block mr-3 mb-0 col-auto pl-0" >{t('tables.filter')}:</h6>
            <input type="checkbox" className="btn-check" onClick={() => this.filterChange('all')} id="notifications-filter-all-1" defaultChecked={this.state.filter.all ? "checked" : ""} autoComplete="off" />
            <label className="btn btn-outline-secondary custom-button mr-2 mb-0" htmlFor="notifications-filter-all-1">{t("tables.all")}</label>
            <input type="checkbox" className="btn-check" onClick={() => this.filterChange('alarm')} id="notifications-filter-alarms-1" defaultChecked={this.state.filter.alarm ? "checked" : ""} autoComplete="off" />
            <label className="btn btn-outline-secondary custom-button mr-2 mb-0" htmlFor="notifications-filter-alarms-1">{t("productspages.alertreporting.alarms")}</label>
            <input type="checkbox" className="btn-check" onClick={() => this.filterChange('warning')} id="notifications-filter-warnings-1" defaultChecked={this.state.filter.warning ? "checked" : ""} autoComplete="off" />
            <label className="btn btn-outline-secondary custom-button mr-2 mb-0" htmlFor="notifications-filter-warnings-1">{t("productspages.alertreporting.warnings")}</label>
            <input type="checkbox" className="btn-check" onClick={() => this.filterChange('rule')} id="notifications-filter-rules-1" defaultChecked={this.state.filter.rule ? "checked" : ""} autoComplete="off" />
            <label className="btn btn-outline-secondary custom-button mr-2 mb-0" htmlFor="notifications-filter-rules-1">{t("productspages.alertreporting.rules")}</label>
            <div className="separator-small mr-2 mb-0"></div>
            <input type="checkbox" className="btn-check" onClick={() => this.filterChange('active')} id="notifications-filter-active-1" defaultChecked={this.state.filter.active ? "checked" : ""} autoComplete="off" />
            <label className="btn btn-outline-secondary custom-button mr-2 mb-0" htmlFor="notifications-filter-active-1">{t('tables.active')}</label>
        </React.Fragment>);

        return (
            <div className="productsConfigAlertRule" style={{ marginTop: '10px' }}>
                {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}
                    <div className="card-container">
                        <Dialog
                            classes={{ paper: "installationModal" }}
                            disableEscapeKeyDown
                            open={this.state.showModalDeleteError}
                            onClose={handleCloseError}>
                            <DialogTitle>{t('productspages.productedit.erroredittitle')}</DialogTitle>
                            <DialogContent>
                                <div>
                                    <span>{this.state.messageError}</span>

                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button className="primary" onClick={handleCloseError}>{t('actions.close')}</Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog disableEscapeKeyDown classes={{ paper: "ruleModal" }} open={this.state.show} onClose={handleClose}>
                            <DialogContent>
                                <ProductsConfigAlertRuleForm product={this.props.product} onClose={() => handleClose()} onRefresh={(e) => this.refresh(e)}>
                                </ProductsConfigAlertRuleForm>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            disableEscapeKeyDown
                            open={this.state.showModalDelete}
                            onClose={handleClose}>
                            <DialogTitle>{t('actions.deletename', { name: t('productspages.general.alert')})}</DialogTitle>
                            <DialogContent>
                                <div>
                                    <span>{t('actions.confirmdelete', { name: t('productspages.general.alert') })}</span>

                                </div>
                            </DialogContent>
                            <DialogActions>
                                <button className="primary" onClick={() => this.confirmDelete(t)}>{t('actions.yes')}</button>
                                <button className="primary" onClick={handleCloseDelete}>{t('actions.no')}</button>
                            </DialogActions>
                        </Dialog>
                        
                        <div className="row">
                            <div className="col">
                                <h3> {t('productspages.rules.manageNotifications')} </h3>
                            </div>
                            <div className="col-auto" style={{alignSelf:"center"}}>
                                <Whisper placement="bottom" trigger="hover" delayOpen={500} speaker={tooltip(t('productspages.rules.addRule'))}>
                                    <IconButton onClick={() => this.handleClick()} size="lg">
                                        <AddIcon />
                                    </IconButton >
                                </Whisper>

                            </div>
                        </div>
                       
                        { !dataLoaded ? <TableSkeleton /> : <Table name={"ProductsConfigAlertRule-locales"} resize={ true}  t={t} columns={this.columns} allAuto={ true} rows={this.state._data} filter={filter} search={false} page={this.getCurrentPage()} pageSize={this.getCurrentSizePerPage()} RowDetail={this.RowDetail.bind(this)} onSizePerPageChange={this.onSizePerPageChange.bind(this)} onPageChange={this.onPageChange.bind(this)}/>}
                        
                    </div>
            </div>
        );
    }

}

export default withTranslation()(ProductsConfigAlertRule);