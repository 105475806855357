import React, { Component } from 'react';
import '../../App.css';

import AuthenticationContext from '../../Authentication/types/AuthContextType';
import { withTranslation } from 'react-i18next';
import InstallationHttpClient from '../../HttpClient/InstallationsHttpClient';
import ChartHttpClient from '../../HttpClient/ChartHttpClient';
import InstallationsSummaryEdit from '../InstallationComponents/Main/InstallationsSummaryEdit';
import MenuItem from '@mui/material/MenuItem';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';
import BreadCrumb from '../BreadCrumb';
import Error from '../Error';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import { withRouter } from '../withRouter';
import Message from '../Message/Message';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import InstallationsTabs from '../InstallationComponents/Main/InstallationsTabs';

class Installations extends Component {
  static contextType = AuthenticationContext;
  constructor(props) {
    super(props);
    this.state = {
      language: this.props.i18n.language,
      _installation: [],
      _chartViewZones: [],
      groupBySelected: 'day',
      dateRange: null,
      calOpened: false,
      multi: false,
      errors: null,
      show: false,
      onTop: '',
      message: null,
    };
    this.onTop = '';
    this.dateRange = null;
    this.groupBy = 'day';
    if (
      (this.props.match != undefined && this.props.match.params.id != undefined) ||
      this.props.location.pathname.split('/').length > 1
    )
      this.id =
        this.props.match.params.id == undefined
          ? parseInt(this.props.location.pathname.split('/')[2])
          : this.props.match.params.id;

    this.dateRange = this.getCurrentDateRange();
    this.groupBy = this.getCurrentGroupBy();
  }

  renderMessage = (type, content) => {
    this.setState({
      message: {
        type: type,
        content: content,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({ message: null });
  };

  componentDidMount() {
    window.scrollTo({ top: 0 });

    localStorage.setItem('CurrentDateRange', null);
    localStorage.setItem('CurrentGroupBy', 'day');

    this.dateRange = null;
    this.groupBy = 'day';

    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if(this.state.language !== this.props.i18n.language){
      this.loadData();
      this.setState({ language: this.props.i18n.language });
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ errors: null });

      let idInstallation = parseInt(this.props.location.pathname.split('/')[2]);
      if (idInstallation != this.id) {
        this.id = idInstallation;
        this.loadData();
        this.dateRange = this.getCurrentDateRange();
        this.groupBy = this.getCurrentGroupBy();
      }
    }
  }
  loadData() {
    this.getInstallationData();
    this.getChartViewZones();
  }
  async getChartViewZones() {
    this.httpChart = new ChartHttpClient(this.context, null, null);
    var chartViewZones = await this.httpChart.InstallationsGetChartViewZones();
    if (chartViewZones !== undefined && chartViewZones.status == 200) this.setState({ _chartViewZones: chartViewZones.data });
  }

  notSite = (data) => {
    return data.data && data.data.content && data.data.content.parentId;
  };

  hasAccess = (data) => {
    return data.data && data.data.content && (data.data.content.hasAccess || data.data.content.hasOwnerAccess);
  };

  async getInstallationData() {
    try {
      this.httpInstallation = new InstallationHttpClient(this.context, this.id, null, null);
      var data = await this.httpInstallation.InstallationGetInstallationsById();
      if (data && data.status == 200 && this.hasAccess(data) && this.notSite(data)) {
        this.setState({
          _installation: data.data.content,
          dateRange: this.dateRange,
          groupBySelected: this.groupBy,
          errors: null,
        });

        if (data.errors != null) {
          this.setState({ errors: data.errors });
        }

        if (data.data.content?.floorPlanUri && data.data.content?.floorPlanUri != '') {
          localStorage.setItem('installationGeneral-ShowLayout', true);
        } else {
          localStorage.setItem('installationGeneral-ShowLayout', false);
        }
      } else {
        if (data.status == 401) {
          this.setState({ errors: 'Unauthorized' });
        } else {
          this.setState({
            errors:
              data.data && data.data.content && !data.data.content.parentId
                ? 'parentId error'
                : data.data
                ? data.data
                : data.status,
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  handleClick() {
    this.setState({ show: !this.state.show });
  }

  showSelector(show) {
    try {
      let buttons = document.getElementsByClassName('configButton');

      for (let btn of buttons) {
        if (btn.classList.contains('d-none')) {
          if (show) btn.classList.remove('d-none');
        } else if (!show) {
          btn.classList.add('d-none');
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  onChangeDate(value, groupBy) {
    this.dateRange = value;
    this.groupBy = groupBy;
    localStorage.setItem('CurrentDateRange', value);
    localStorage.setItem('CurrentGroupBy', groupBy);
  }

  getCurrentDateRange() {
    let currentDateRange = null;
    try {
      currentDateRange = JSON.parse(localStorage.getItem('CurrentDateRange')) || null;
    } catch (e) {
      currentDateRange = null;
    }
    return currentDateRange;
  }
  getCurrentGroupBy() {
    let currentGroupBy = 'day';
    try {
      currentGroupBy = JSON.parse(localStorage.getItem('CurrentGroupBy')) || 'day';
    } catch (e) {
      currentGroupBy = 'day';
    }
    return currentGroupBy;
  }

  render() {
    const { t } = this.props;

    const handleGranularity = (event, newGranularity) => {
      this.setState({ granularity: newGranularity });
    };
    const handleGroupBySelected = (event) => {
      this.setState({ groupBySelected: event.target.value });
    };
    const handleHomeIcon = (event) => {
      // this.setState({ groupBySelected: "week", dateRange: [moment().add(-6, 'd').toDate(), new Date()] })
      //this.setState({ groupBySelected: "week" })
      this.loadData();
      this.setState({ groupBySelected: this.groupBy, dateRange: this.dateRange });
    };

    const ranges = [
      {
        label: 'today',
        value: [new Date(), new Date()],
      },
      {
        label: 'last7Days',
        value: [moment().add(-6, 'd').toDate(), new Date()],
      },
      {
        label: 'Last Month',
        value: [moment().add(-1, 'm').toDate(), new Date()],
      },
    ];
    const scrollToTop = (event) => {
      event.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    const toggle = (event) => {
      if (!this.state.calOpened) {
        this.component.open();
      }
    };
    const openMenu = () => {
      this.selectItem.focus();
      this.setState({ multi: true });
    };
    const closeMenu = () => {
      this.setState({ multi: false });
    };

    const handleClose = (res) => {
      this.setState({ show: false, refreshAllComponents: true });
      if (res && res.id) {
        this.renderMessage('success', t('actions.success'));

        setTimeout(() => {
          this.getInstallationData();
        }, 1600);
      }
    };
    let top = '';

    const onRefresh = () => {
      this.setState({ refreshAllComponents: false, groupBySelected: this.groupBy, dateRange: this.dateRange });
    };

    let dataLoaded = this.state._chartViewZones.content != undefined && this.state._installation != undefined;
    let editmodecomponent;

    if (this.state.show) {
      editmodecomponent = (
        <InstallationsSummaryEdit
          type='installation'
          createSite={false}
          installation={this.state._installation}
          level='installations'
          onSaveClose={handleClose}
          id={this.id}
          mode='edit'
          showdelete={true}
        ></InstallationsSummaryEdit>
      );
    }

    const managePage = (errors) => {
      if (!errors) {
        return (
          <div className='row loadingParent'>
            <div className='loadingImg'></div>
          </div>
        );
      } else if (errors === 'parentId error') {
        return <Error errors={{ status: 404 }} error={'Installation not found'} />;
      } else {
        return <Error errors={errors} />;
      }
    };

    return (
      <div id='rootElement'>
        {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}

        {dataLoaded && this.state.errors == null ? (
          <div id='parentElementIsTop'>
            <Dialog classes={{ paper: 'installationModal' }} disableEscapeKeyDown open={this.state.show} onClose={handleClose}>
              <DialogActions className='jc-spaceBetween'>
                <h2 className='mb-0 ml-4'>{t('installationspages.installationsummaryedit.editInstallation')}</h2>
                <IconButton onClick={handleClose}>
                  <CloseIcon fontSize='large' />
                </IconButton>
              </DialogActions>
              {/*<DialogTitle>Edit installation</DialogTitle>*/}
              <DialogContent>{editmodecomponent}</DialogContent>
            </Dialog>
            <BreadCrumb level='installations' doubleSticky={true}></BreadCrumb>

            <div style={{ margin: '10px 0px', position: 'relative' }}>
              <div className='granularityParent'>
                <div className='buttonScroll configButton calendar d-none' onClick={toggle}>
                  <span>
                    <span className='t2'>
                      <img
                        alt='Calendario'
                        style={{ width: '24px' }}
                        src='https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/11_calendar.svg'
                      />
                      <DateRangePicker
                        isoWeek
                        format='dd-MM-yyyy'
                        appearance='subtle'
                        value={this.state.dateRange}
                        ranges={ranges}
                        onOpen={() => {
                          this.state.calOpened = true;
                        }}
                        onClose={() => {
                          this.state.calOpened = false;
                        }}
                        ref={(r) => {
                          this.component = r;
                        }}
                        onChange={(value) => {
                          this.setState({ dateRange: value });
                        }}
                        style={{ display: 'none' }}
                      />
                    </span>
                  </span>
                </div>
                <div className='buttonScroll configButton toFilters d-none'>
                  <span>
                    <span className='t2'>
                      <img
                        alt='Filter'
                        style={{ width: '24px', top: '104px', position: 'absolute', left: '7px' }}
                        src='https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/11_filters.svg'
                      />
                      <Select
                        variant='standard'
                        value={this.state.groupBySelected}
                        onChange={handleGroupBySelected}
                        ref={(node) => (this.selectItem = node)}
                        onFocus={openMenu}
                        onBlur={closeMenu}
                        className='granularityRoot'
                        MenuProps={{ classes: { paper: 'granularity' } }}
                      >
                        <MenuItem value='week'> Days </MenuItem>
                        <MenuItem value='month'> Weeks </MenuItem>
                      </Select>
                    </span>
                  </span>
                </div>
                <div className='buttonScroll resset' onClick={handleHomeIcon}>
                  <span>
                    <span className='t2'>
                      <img
                        alt='Resset'
                        style={{ width: '24px' }}
                        src='https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/11_arrow-clockwise.svg'
                      />
                    </span>
                  </span>
                </div>
                <div className='buttonScroll backToTop'>
                  <span>
                    <span onClick={scrollToTop} className='t2'>
                      <img
                        alt='Back to Top'
                        style={{ width: '24px' }}
                        src='https://strgpiotcoredev.blob.core.windows.net/pictos-by-product/11_chevron_up.svg'
                      />
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div>
              <InstallationsTabs
                showSelector={this.showSelector}
                data={this.state._installation}
                groupBySelected={this.state.groupBySelected}
                dateRange={this.state.dateRange}
                chartViewZones={this.state._chartViewZones}
                refreshAllComponents={this.state.refreshAllComponents}
                handleClick={this.handleClick.bind(this)}
                onRefresh={onRefresh}
                onChangeDate={this.onChangeDate.bind(this)}
              />
            </div>
          </div>
        ) : (
          managePage(this.state.errors)
        )}
      </div>
    );
  }
}

export default withTranslation()(withRouter(Installations, [{ path: '/installation/:id' }]));
