import PowerBIView from '../views/PowerBIView';
import powerBIRepository from '../utils/powerBIRepository';
import Loader from '../../Common/Loader';
import { EmbedInfo, EmbedTokenResponse, PowerBIContainerState } from '../types/PowerBITypes';
import AuthenticationContext from '../../../Authentication/types/AuthContextType';
import type { AuthContextType } from '../../../Authentication/types/AuthContextType';
import Error from '../../Error';
import { PureComponent } from 'react';

class PowerBIContainer extends PureComponent<{}, PowerBIContainerState> {
  static contextType = AuthenticationContext;

  private tokenRefreshInterval: NodeJS.Timeout | undefined;

  constructor(props: {}) {
    super(props);
    this.state = {
      loader: true,
      embed_info: null,
      error: false,
      reportLoaded: null,
      intervalRefreshMinutes: 15,
    };
  }

  checkRolesPowerBI = (ctx: AuthContextType) => {
    const rolesPermited = [100, 830, 110];
    return ctx.userData?.userBasicData.userRoles.some((role) => rolesPermited.includes(role));
  };

  setEmbedReport = (reportLoaded) => {
    this.setState({ reportLoaded });

    if (this.tokenRefreshInterval) {
      clearInterval(this.tokenRefreshInterval);
    }
    this.tokenRefreshInterval = setInterval(async () => {
      this.getToken(true);
    }, this.state.intervalRefreshMinutes * 60 * 1000);
  };

  getToken = async (refresh = false) => {
    const powerRepo = new powerBIRepository();
    const powerBiToken: string = await powerRepo.getPowerBiToken();
    if (!powerBiToken) return;

    const reportValue: EmbedInfo = await powerRepo.getReportValue(powerBiToken);
    if (!reportValue) return;

    const embedToken: EmbedTokenResponse = await powerRepo.getEmbedToken(powerBiToken, reportValue.datasetId, reportValue.id);
    if (!embedToken) return;

    if (refresh && this.state.reportLoaded) {
      await this.state.reportLoaded.setAccessToken(embedToken.token);
    }
    this.setState({
      loader: false,
      embed_info: {
        ...reportValue,
        token: embedToken.token,
      },
    });
  };

  async componentDidMount() {
    const ctx = this.context as AuthContextType;
    try {
      const isAuthorized = this.checkRolesPowerBI(ctx);
      if (!isAuthorized) {
        this.setState({ loader: false, error: 401 });
        return;
      }
      ctx.fullScreen();
      await this.getToken();
    } catch (error) {
      console.error('Error occurred:', error);
      this.setState({ loader: false, error: true });
    }
  }

  componentWillUnmount() {
    if (this.tokenRefreshInterval) {
      clearInterval(this.tokenRefreshInterval);
    }
  }

  render() {
    return (
      <>
        {this.state.error ? (
          <Error errors={{ status: this.state.error }} />
        ) : (
          <>
            {this.state.loader && !this.state.embed_info ? (
              <Loader />
            ) : (
              <PowerBIView setEmbedReport={this.setEmbedReport} embed_info={this.state.embed_info!} />
            )}
          </>
        )}
      </>
    );
  }
}

export default PowerBIContainer;
