import { PureComponent } from 'react';
import ModalAddHardwareView from '../views/ModalAddHardwareView';
import { ModalAddHardwareContainerProps, ModalAddHardwareContainerState } from '../types/ModalAddHardwareTypes';
import NoSmartComponentsHttpClient from '../../../../../../../HttpClient/NoSmartComponentsHttpClient';
import AuthenticationContext from '../../../../../../../Authentication/types/AuthContextType';
import { NoSmartComponentType, selectOption } from '../types/HWHardwareTypes';
import { SelectStringOption } from '../../PackageManagement/types/ModalAddPackageTypes';

class ModalAddHardwareContainer extends PureComponent<ModalAddHardwareContainerProps, ModalAddHardwareContainerState> {
  static contextType = AuthenticationContext;

  constructor(props: ModalAddHardwareContainerProps) {
    super(props);
    this.state = {
      step: 0,
      totalSteps: 1,
      typeHardware: '',
      allNoSmartComponentTypes: [],
      selectStep1: null,
      selectOptions: [],
      selectedSmartComponentType: null,
      selectedNoSmartComponentType: null,
      isSaveDisabled: true,
      name: null,
      description: null,
      version: null,
      optionsSelect2: [],
      loading: true,
    };

    this.handleStep.bind(this);
    this.handleTypeHardware.bind(this);
    this.handleSelectStep1.bind(this);
    this.handleSelecSmart_NoSmart.bind(this);
    this.saveHardware.bind(this);
    this.handleTextFields.bind(this);
    this.handleShowAddHWModal.bind(this);
  }

  async componentDidMount() {
    const fromNewPackage = await this.isNewHardwareFromNewPackage();
    if (!fromNewPackage) {
      const allNoSmartComponentTypes = await this.getAllNoSmartComponentTypes();
      this.setState({ allNoSmartComponentTypes });
    }
  }

  componentDidUpdate() {
    this.checkIfCanSaveHardware();
  }

  cleanFields = () => {
    this.setState({
      // typeHardware: '',
      selectStep1: null,
      // selectOptions: [],
      selectedSmartComponentType: null,
      selectedNoSmartComponentType: null,
      name: null,
      description: null,
      version: null,
    });
  };

  newHardwareBySystemIdSelectedInPackage = () => {
    const systemIdSelected = this.props.systemIds.find((item) => item.id === this.props.newHardwareFromNewPackage!.systemId)!;
    const optionsSelect2 = systemIdSelected.smartComponentTypes.map((smartCompo) => ({
      value: smartCompo.id,
      label: smartCompo.name,
    }));
    this.setState({
      step: 1,
      typeHardware: 'systemId',
      selectOptions: this.getSelectOptions('systemId'),
      optionsSelect2,
      selectStep1: systemIdSelected,
      selectedSmartComponentType: this.props.newHardwareFromNewPackage!.smartCompoTypeId,
      loading: false,
    });
  };

  newHardwareByNoSmartCompoTypeIdSelectedInPackage = async () => {
    const systemIdSelected = this.props.systemIds.find((item) => item.id === this.props.newHardwareFromNewPackage!.systemId)!;
    const allNoSmartComponentTypes = await this.getAllNoSmartComponentTypes();
    const uniqueSystemIds = new Set((allNoSmartComponentTypes as NoSmartComponentType[]).map((component) => component.systemId));
    const selectOptions: SelectStringOption[] = Array.from(uniqueSystemIds).map((systemId) => ({
      value: systemId,
      label: systemId,
    }));
    const optionsSelect2 = allNoSmartComponentTypes
      .filter((noSmartCompo) => noSmartCompo.systemId === this.props.newHardwareFromNewPackage!.systemId)
      .map((noSmartCompo) => ({
        value: noSmartCompo.id,
        label: noSmartCompo.name,
      }));
    this.setState({
      step: 1,
      typeHardware: this.props.newHardwareFromNewPackage!.selectedType!,
      selectOptions,
      optionsSelect2,
      selectStep1: systemIdSelected,
      selectedNoSmartComponentType: this.props.newHardwareFromNewPackage!.smartCompoTypeId,
      allNoSmartComponentTypes,
      loading: false,
    });
  };

  isNewHardwareFromNewPackage = async () => {
    const { newHardwareFromNewPackage } = this.props;
    if (!newHardwareFromNewPackage) {
      this.setState({ loading: false });
      return false;
    }
    const { selectedType } = newHardwareFromNewPackage;
    if (selectedType === 'systemId') {
      this.newHardwareBySystemIdSelectedInPackage();
      return true;
    } else if (selectedType === 'nosmartComponentTypeId') {
      await this.newHardwareByNoSmartCompoTypeIdSelectedInPackage();
      return true;
    }

    return false;
  };

  getSelectOptions = (typeHardware: string) => {
    if (typeHardware === 'systemId') {
      return this.props.systemIds.map((systemId) => ({ value: systemId.id, label: systemId.id }));
    } else if (typeHardware === 'smartComponentTypeId') {
      return this.props.allSmartComponentTypes.map((smartCompo) => ({ value: smartCompo.id, label: `${smartCompo.id} - ${smartCompo.name}` }));
    } else if (typeHardware === 'nosmartComponentTypeId') {
      const uniqueSystemIds = new Set(this.state.allNoSmartComponentTypes.map((component) => component.systemId));
      const uniqueOptions = Array.from(uniqueSystemIds).map((systemId) => ({
        value: systemId,
        label: systemId,
      }));
      return uniqueOptions;
    }
    return [];
  };

  getAllNoSmartComponentTypes = async () => {
    const httpNoSmartCompo = new NoSmartComponentsHttpClient(this.context);
    const allNoSmartComponentTypes = await httpNoSmartCompo.GetNoSmartComponentTypes();
    return allNoSmartComponentTypes && allNoSmartComponentTypes.content ? allNoSmartComponentTypes.content : [];
  };

  handleStep = (step: 'prev' | 'next') => {
    if (step === 'prev') {
      this.setState({ step: this.state.step - 1 });
      this.cleanFields();
    } else if (step === 'next') {
      this.setState({ step: this.state.step + 1 });
    }
  };

  handleTypeHardware = (typeHardware: string) => {
    this.setState({ typeHardware, selectOptions: this.getSelectOptions(typeHardware) });
  };

  handleSelectStep1 = (option: selectOption) => {
    const listOfTypeSelected = {
      systemId: this.props.systemIds,
      nosmartComponentTypeId: this.props.systemIds,
      smartComponentTypeId: this.props.allSmartComponentTypes,
    };
    const selectedItem = listOfTypeSelected[this.state.typeHardware].find((item) => item.id === option.value);
    let optionsSelect2: { value: string | number; label: string }[] | [] = [];
    if (this.state.typeHardware === 'systemId') {
      optionsSelect2 = selectedItem.smartComponentTypes.map((smartCompo) => ({
        value: smartCompo.id,
        label: smartCompo.name,
      }));
    } else if (this.state.typeHardware === 'nosmartComponentTypeId') {
      optionsSelect2 = this.state.allNoSmartComponentTypes
        .filter((noSmartCompo) => noSmartCompo.systemId === String(option.value))
        .map((noSmartCompo) => ({
          value: noSmartCompo.id,
          label: noSmartCompo.name,
        }));
    }

    this.setState({ selectStep1: selectedItem, optionsSelect2, selectedSmartComponentType: null });
  };

  handleSelecSmart_NoSmart = (optionSelected: selectOption) => {
    if (this.state.typeHardware === 'systemId') {
      this.setState({ selectedSmartComponentType: optionSelected.value });
    } else if (this.state.typeHardware === 'nosmartComponentTypeId') {
      this.setState({ selectedNoSmartComponentType: optionSelected.value });
    }
  };

  handleTextFields = (target: HTMLTextAreaElement | HTMLInputElement) => {
    const { name, value } = target;
    this.setState({ [(name as 'name') || 'description' || 'version']: value });
  };

  checkIfCanSaveHardware = () => {
    const { name, description, version, typeHardware, selectedSmartComponentType, selectedNoSmartComponentType } = this.state;
    const versionRegex = /^[0-9]{2}\.[0-9]{2}$/;
    const isVersionOK = version && versionRegex.test(version);
    const isBasicInfoFilled = name && description && version && isVersionOK;
    const isComponentTypeSelected =
      typeHardware === 'smartComponentTypeId' || selectedSmartComponentType || selectedNoSmartComponentType;
    this.setState({ isSaveDisabled: !(isBasicInfoFilled && isComponentTypeSelected) });
  };  

  saveHardware = async () => {
    const httpNoSmartCompo = new NoSmartComponentsHttpClient(this.context);
    const noSmartComponent = {
      noSmartComponentTypeId: this.state.selectedNoSmartComponentType,
      smartComponentTypeId:
        this.state.typeHardware === 'smartComponentTypeId' ? this.state.selectStep1?.id : this.state.selectedSmartComponentType,
      // systemId: this.state.typeHardware !== 'smartComponentTypeId' ? this.state.selectStep1?.id : null,
      name: this.state.name,
      description: this.state.description,
      version: this.state.version,
      available: true,
      mandatory: true,
    };
    const response = await httpNoSmartCompo.SaveNoSmartComponent(noSmartComponent);
    if (response && response.content) {
      this.props.refreshAllNoSmartComponents();
      this.handleShowAddHWModal(true);
    }
  };

  handleShowAddHWModal = (newHardwareSaved: boolean) => {
    if (this.props.handleShowAddHWModal) {
      // usecase from hardware table
      this.props.handleShowAddHWModal(false, true);
    } else if (this.state.typeHardware === 'systemId' && this.props.handleShowModalFromPackageWithSmartCompo) {
      // usecase from package table with smartCompo
      this.props.handleShowModalFromPackageWithSmartCompo(false, undefined, newHardwareSaved);
    } else if (this.state.typeHardware === 'nosmartComponentTypeId' && this.props.handleShowModalFromPackageWithNoSmartCompo) {
      // usecase from package table with noSmartCompo
      this.props.handleShowModalFromPackageWithNoSmartCompo(false, newHardwareSaved);
    }
  };

  render() {
    return (
      <>
        {!this.state.loading ? (
          <ModalAddHardwareView
            open={this.props.open}
            handleShowAddHWModal={this.handleShowAddHWModal}
            handleStep={this.handleStep}
            handleTypeHardware={this.handleTypeHardware}
            handleSelectStep1={this.handleSelectStep1}
            handleSelecSmart_NoSmart={this.handleSelecSmart_NoSmart}
            saveHardware={this.saveHardware}
            handleTextFields={this.handleTextFields}
            newHardwareFromNewPackage={this.props.newHardwareFromNewPackage}
            {...this.state}
          />
        ) : (
          ''
        )}
      </>
    );
  }
}

export default ModalAddHardwareContainer;
