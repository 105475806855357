import React from 'react';
import { withTranslation } from 'react-i18next';
import '../../../App.css';
import '../../ProductsStyle.css';

const GatewayInfoGrid = ({ gatewaykpis, t }) => {
  const checkTranslate = (name) => {
    const translated = t(`gatewayspages.statistics.${name}`);
    return translated !== `gatewayspages.statistics.${name}` ? translated : name;
  };

  const renderKpis = () => {
    return gatewaykpis
      .filter((kpi) => kpi.kpiName !== 'GWTheoricalPooling' && kpi.kpiName !== 'GatewayActualPollings')
      .map((kpi) => {
        if (kpi.value && kpi.value.length) {
          var name = checkTranslate(kpi.kpiName)
          return (
              <div className='card' key={kpi.kpiName}>
                <h4 className='kpiTitle'>{name}</h4>
                <h1>
                  {kpi.value && kpi.value.length && kpi.value[0].value && kpi.value[0].value !== undefined
                    ? cleanValue(kpi.value[0].value)
                    : 0}
                </h1>
              </div>
            );
        }
        return null;
      });
  };

  const renderFeaturedKpis = () => {
    return gatewaykpis
      .filter((kpi) => kpi.kpiName === 'GWTheoricalPooling' || kpi.kpiName === 'GatewayActualPollings')
      .map((kpi) => {
        var name = checkTranslate(kpi.kpiName)
          return (
            <div className='featured-card' key={kpi.kpiName}>
              <h4 className='kpiTitle'>{name}</h4>
              <h1>
                {kpi.value && kpi.value.length && kpi.value[0].value && kpi.value[0].value !== undefined ? cleanValue(kpi.value[0].value) : 0}
              </h1>
            </div>
          );
      });
  };

  const cleanValue = (value) => {
    if (/^-?\d+(\.\d+)?$/.test(value)) {
      const numberValue = Number(value);
      return numberValue % 1 === 0 ? numberValue : numberValue.toFixed(1);
    } else {
      return value;
    }
  };

  return (
    <>
      {gatewaykpis ? (
        <>
          <div className='featured'>{renderFeaturedKpis()}</div>
          <div className='cards'>{renderKpis()}</div>
        </>
      ) : (
        <div className='loadingParent'>
          <div className='loadingImg'></div>
        </div>
      )}
    </>
  );
};

export default withTranslation()(GatewayInfoGrid);
