import axiosNode from '../axiosNode';
import axios from "axios";
export default class TranslationsHttpClient {

  constructor() {}

  GetTranslationsFolderByLocale = async (accessToken, lang, folder) => {
    const config = {
      headers: {
        Authorization: `${accessToken.accessToken}`,
      },
    };
    const url = `translations/${lang.toLowerCase()}/${folder}`;

    try {
      //const response = await axiosNode.get(url, config);
      const response = await axios.get(url, config);
      return response;
    } catch (e) {
      return e.response;
    }
  };
}
