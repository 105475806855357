import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';
import '../../App.css';
import GatewaysHttpClient from "../../HttpClient/GatewaysHttpClient";
import AuthenticationContext from "../../Authentication/types/AuthContextType";
import BreadCrumb from "../BreadCrumb";


class GatewayList extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.Laufen = false;
        this.state = {
            selectedSiteIndex: null,
            selectedInstallationIndex: null,
            selectedProductIndex: null,
            search: "",
            redirect: false,
            myGateways: [],
            filteredGateways: [],
            filteredInstallations: [],
            filteredGatewaysMenu: [],
            filteredInstallationsMenu: [],
            actualMenuFilter: "",
            showMenu: false,
        };
    }

    componentDidMount() {
        this.httpGateways = new GatewaysHttpClient(this.context);
        this.getMyGateways();
        this.searchUrl();
        
    }
    searchUrl() {
        const queryString = require('query-string').default;
        const parsed = queryString.parse(this.props.location.search);

        this.setState({ search: parsed.s });

    }


    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    async getMyGateways() {
        this.setState({ myGateways: null });
        let data = await this.httpGateways.GetGateways();

        this.setState({ myGateways: data });
        this.setState({ filteredGateways: data.content });

        let installations = [];

        data.content.map(v => (v.installations.map(i => ( (i != null) ? installations.push(i) : "" ))));
        installations = this.getUnique(installations, 'id');

        this.setState({ myInstallations: installations });
        this.setState({ filteredInstallations: installations });

        this.generalFilter();
       
        let filteredMenu = this.state.filteredGateways.filter((ele, ind) => ind === this.state.filteredGateways.findIndex(elem => elem.gatewayType.brandName === ele.gatewayType.brandName && elem.gatewayType.model === ele.gatewayType.model))

        this.setState({ filteredGatewaysMenu: filteredMenu });

        let tree = this.listToTree(this.state.filteredInstallations.sort((a, b) => (a.id - b.id || a.parentId - b.parentId)), {});
        this.setState({ tree: tree });

        this.setState({ filteredInstallationsMenu: this.state.filteredInstallations });
    }
  
    getUnique(arr, index) {

        const unique = arr
            .map(e => e[index])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e])

            // eliminem els nulls
            .filter(x => x !== null);

        return unique;
    }


    filterGateways(list) {
        let inputVal = "";
        if (this.state.search != undefined) {
            inputVal = this.state.search.toLowerCase();
        }
        let ret = list.filter(
            function _(prod) {
                let equals = false;
                try {

                    
                    if (prod.gatewayType.model != null) {
                        if (prod.gatewayType.model.toLowerCase().includes(inputVal)) {
                            return true;
                        }
                    }
                    if (prod.gatewayType.brandName != null) {
                        if (prod.gatewayType.brandName.toLowerCase().includes(inputVal)) {
                            return true;
                        }
                    }
                    if (prod.sku != null) {
                        if (prod.productType.sku.toLowerCase().includes(inputVal)) {
                            return true;
                        }
                    }
                    if (prod.name != null) {
                        if (prod.name.toLowerCase().includes(inputVal)) {
                            return true;
                        }
                    }
                    if (prod.description != null) {
                        if (prod.description.toLowerCase().includes(inputVal)) {
                            return true;
                        }
                    }
                    if (prod.gatewayId != null) {
                        if (prod.gatewayId.toLowerCase().includes(inputVal)) {
                            return true;
                        }
                    }
                    if (prod.installations.find((elem, index) => elem.name.toLowerCase().includes(inputVal)) != undefined) {
                        return true;
                    }
                    if (prod.puuid != null) {
                        if (prod.puuid.toLowerCase().includes(inputVal)) {
                            return true;
                        }
                    }

                } catch (error) {
                    return false;
                }

                return false;
            }
        );
        return ret;
    }

    filterAllGateways(hidden, id = "", installationId = -1, menu = false) {
        let actualProds = [];
        hidden = false;

        if (hidden) {
            this.setState({ filteredGateways: [] });

        } else if (this.state.myGateways != null && this.state.myGateways.content != undefined) {

            actualProds = this.filterGateways(this.state.myGateways.content);

            if (id != "") {
                //id.split('-')[1]
                let porductsByBrand = actualProds.filter((elem, index) => elem.gatewayType.brandCode == id.split('$$')[0] && elem.gatewayType.model == id.split('$$')[1]);
                this.setState({ filteredGateways: porductsByBrand });
                actualProds = porductsByBrand;

            } else {
                this.setState({ filteredGateways: actualProds });
            }
        }



        if (installationId > -1) {

            let gate = actualProds.filter(g => g.installations.find((elem, index) => (elem != null) ? elem.id == installationId || elem.parentId == installationId : false));
            this.setState({ filteredGateways: gate });

        } else {
            this.setState({ filteredGateways: actualProds });
        }
        if (menu) {
            let filteredMenu = actualProds.filter((ele, ind) => ind === actualProds.findIndex(elem => elem.gatewayType.brandCode == ele.gatewayType.brandCode && elem.gatewayType.model == ele.gatewayType.model))
            this.setState({ filteredGatewayMenu: filteredMenu });
        }

    }

    generalFilter(target = "", menu = false) {

        if (target == 'resetDefault') {
            this.state.search = "";

            this.filterAllGateways(false, "", -1, true);
           
            this.state.actualMenuFilter = "sectionAPRODUCTS";

        } else if (target.split('-')[0] == "gate") {

            this.filterAllGateways(false, target.split('-')[1]);


        } else if (target.split('-')[0] == "instal") {

            this.filterAllGateways(false, "", target.split('-')[1]);

        } else if (target == "sectionAINSTALLATION") {

            this.filterAllGateways(false);

        } else if (target == "sectionAGATEWAYS") {

            this.filterAllGateways(false);
        } else {

            this.filterAllGateways(false, "", -1, menu);
        }


    }
    listToTree(list,) {

        let map = {}, node, roots = [], i;

        for (i = 0; i < list.length; i += 1) {
            map[list[i].id] = i; // initialize the map
            list[i].children1 = []; // initialize the children
        }

        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            if (node.parentId != 0) {
                if (map[node.parentId] != undefined) {
                    list[map[node.parentId]].children1.push(node);
                } else {
                    roots.push(node);
                }

            } else {
                roots.push(node);
            }
        }
        return roots;

    }

    createTree(data, isSub, lev) {
        let level = lev || 0;
        let children = [];
        if (isSub) {
            if (level < 3) // To prevent too much padding
                level++;
        }
        for (let i in data) {
            if (typeof (data[i].children1) === 'object') {
                if (data[i].children1 != []) {
                    children.push(
                        <li className={"filter-group level-" + (level)}>
                            <a id={"instal-" + data[i].id} onClick={this.filterMenu(null)} href='javascript.void(0)'
                                className={this.state.actualMenuFilter == "instal-" + data[i].id ? 'active t3' : 't3'}
                            >{data[i].name} </a>
                            {this.createTree(data[i].children1, true, level)}
                        </li>
                    );
                }

            }
        }
        return children;
    }


    filterMenu = (parameter) => (e) => {
        e.preventDefault();
        let target = e.target.id;
        if (target == this.state.actualMenuFilter) {
            target = "";
        }
        if (parameter != null) {
            target = parameter;
        }
        this.setState({ actualMenuFilter: target });
        this.state.actualMenuFilter = target;
        this.generalFilter(target);

    }

    render() {
        this.Laufen = this.context.brandCode == 11;
        const { t } = this.props;

        let dataLoadedInstal = this.state.filteredInstallationsMenu != undefined && this.state.myInstallations != undefined;
        let dataLoadedGateways = this.state.myGateways != null && this.state.myGateways.content != undefined;
        if (this.state.redirect) {
            let url = this.state.redirect;
            this.setState({ redirect: null });
            return <Navigate to={url} />
        }
      

        const menuCollapse = (e) => {
            e.preventDefault();
            this.setState({ showMenu: false });
        }
        const menuOpen = (e) => {
            e.preventDefault();
            this.setState({ showMenu: true });
        }

        const searchChange = (e) => {
            this.state.search = e.target.value;
            this.state.actualMenuFilter = "";
            this.generalFilter("", true);
        }

        const getInstallationName = (item) => {

            let installationName = " - "

            if (item.installations && item.installations.length > 0) {
                let installation = item.installations[0];
                for (let i = 0; i < item.installations.length; i++) {
                    if (installation != null) break;
                    installation = item.installations[i]
                }
                if (installation) installationName = installation.name
            }

            return (installationName)
        }

        const getNumberOfProducts = (item) => {
            let num = item.products?.length ?? 0
            return (num)
        }
        
        return (
            <div className="" style={{ margin: '10px 0' }}>
                <BreadCrumb empty={true}></BreadCrumb>
                <section className="portlet" id="portlet_RocaSearch">
                    <div className="portlet-content">
                        <div className="autofit-float autofit-row portlet-header">
                            <div className="autofit-col autofit-col-expand">
                            </div>
                            <div className="autofit-col autofit-col-end">
                                <div className="autofit-section">
                                </div>
                            </div>
                        </div>
                        <div className=" portlet-content-container">
                            <div className="portlet-body">
                                <section className="search-box">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="close-back"><a href="javascript:history.back()" className="js-close icon-cross-24">
                                                    <span><img alt="Close" style={{ height: '30px' }} src="close.svg" className="hidden" /></span>
                                                </a></p>
                                                <input className="search-box-input" id="searchBox" name="searchBox"
                                                    onChange={searchChange}
                                                    value={this.state.search}
                                                    onBlur={"this.placeholder='" + t('productspages.filterproducts.searchplaceholder') + "'"} onFocus="this.placeholder=''" placeholder={t('productspages.filterproducts.searchplaceholder')} type="text" autoFocus maxLength={50} />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section id="mainSection" className="search-box" style={{}}>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="d-block d-md-none advanced-search">
                                                    <p>
                                                        <a className="js-collapse-mobile-open" href="javascript.void(0);" onClick={menuOpen}>
                                                            <span className="pr-1"><img alt="Filtros" src="https://www.roca.es/o/roca-theme/images/icons/filters-24.svg" /></span> {t('productspages.filterproducts.titlesearch')} </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-3">
                                                <div className="search-filter">
                                                    <div className={"js-collapse-mobile collapse-mobile " + (this.state.showMenu == true ? 'active' : '')} id="category">
                                                        <div className="d-block d-md-none prev">
                                                            <p>
                                                                <a className="js-collapse-mobile-close arrow" href="javascript.void(0);" onClick={menuCollapse}> {t('productspages.filterproducts.returntoresults')}</a>
                                                            </p>
                                                        </div>
                                                        <ul id="sectionsUL" className="category accordion">


                                                            {dataLoadedGateways ? (
                                                                <li id="sectionLIPRODUCTS">
                                                                    <h2>
                                                                        <a id="sectionAGATEWAYS" className={this.state.actualMenuFilter == "sectionAGATEWAYS" ? 'active' : ''} onClick={this.filterMenu(null)}>{t('gateways')}
                                                                        </a>
                                                                    </h2>
                                                                    <ul id="sectionULPRODUCTS" className="subcategory collapse show"   >

                                                                        {

                                                                            this.state.filteredGatewaysMenu.map((item, i) => (
                                                                                <li>
                                                                                    <a id={"gate-" + item.gatewayType.brandCode + "$$" + item.gatewayType.model} onClick={this.filterMenu(null)} href='javascript.void(0)'
                                                                                        className={this.state.actualMenuFilter == "gate-" + item.gatewayType.brandCode + "$$" + item.gatewayType.model ? 'active t3' : 't3'}>{item.gatewayType.brandName}  - {item.gatewayType.model} </a>
                                                                                </li>

                                                                            ))}
                                                                    </ul>
                                                                </li>
                                                            ) : (<div className="loadingParent mb-3"><div className="loadingImg"></div></div>)
                                                            }



                                                            {dataLoadedInstal ? (
                                                                <li id="sectionLIINSTALLATION">
                                                                    <h2>
                                                                        <a id="sectionAINSTALLATION" onClick={this.filterMenu(null)} href='javascript.void(0)'
                                                                            className={this.state.actualMenuFilter == "sectionAINSTALLATION" ? 'active' : ''}
                                                                        >{t('installations')}
                                                                        </a>
                                                                    </h2>
                                                                    <ul id="sectionULINSTALLATION" className="subcategory collapse show">
                                                                        {this.createTree(this.state.tree, true, 0)}
                                                                    </ul>
                                                                </li>
                                                            ) : (
                                                                    <div className="loadingParent mt-3"><div className="loadingImg"></div></div>
                                                                )
                                                            }

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>



                                            <div id="resultsParentDiv" className="col-12 col-md-9">
                                                <div id="resultsDiv">
                                                    {dataLoadedGateways && this.state.filteredGateways.length > 0 ? (
                                                        <div id="productSectionDivCATEGORY069-02" className="product-list">
                                                            <div className="product-list__header">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <h2 id="productSectionH2CATEGORY069-02" className="uppercase">
                                                                            {t('gateways')} <span>({this.state.filteredGateways.length})</span></h2>
                                                                        <p className="cta"><a className={" cta-black t3"} onClick={this.filterMenu("resetDefault")} href='javascript.void(0)' >
                                                                            {t('productspages.filterproducts.seeall')}
                                                                        </a></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="product-list__body">
                                                                <div className="row">
                                                                    {

                                                                        this.state.filteredGateways.map((item, i) => (


                                                                            <div className="result-content col-12 col-md-6 col-lg-4 mb-3 item-left" data-url={"/gateway/" + item.id + ""}>
                                                                                <div className="product-card">
                                                                                    <div className="product-card__wrapper">
                                                                                        <div className="product-card__body">
                                                                                           
                                                                                            <div className="wrapper-producto">
                                                                                                <div className={"gw-status " + (item.deviceStatus != undefined ? item.deviceStatus?.toLowerCase() : "offline")  }></div>
                                                                                                <Link to={"/gateway/" + item.id + ""}>
                                                                                                    <div aria-label={item.name} className={item.gatewayType != undefined && item.gatewayType.imageUri != null ? "wrapper-img" : "d-none"} role="img" style={{ backgroundImage: 'url(' + item.gatewayType.imageUri + ')' }}>
                                                                                                        &nbsp;</div>
                                                                                                </Link>
                                                                                                <div className="wrapper-text">
                                                                                                    <h4 className="coleccion">
                                                                                                        <Link to={"/gateway/" + item.id + ""}> {item.gatewayId} </Link>
                                                                                                    </h4>
                                                                                                    <div style={{ height: '100%', minHeight: '120px', display: 'flex', alignItems: 'stretch' }}>
                                                                                                        <div className="title-producto">

                                                                                                            <h3 className="p-0 pb-2">
                                                                                                                <Link to={"/gateway/" + item.id + ""}>{item.name}</Link>
                                                                                                            </h3>
                                                                                                            
                                                                                                        </div>

                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <h5 className="d-inline">{t('installation')}:</h5> <span className="t2 d-inline">{getInstallationName(item)}</span>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <h5 className="d-inline">{t('products')}:</h5> <span className="t2 d-inline">{getNumberOfProducts(item)}</span>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>



                                                                        ))

                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (<div></div>)
                                                    }






                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>
                    </div>
                </section>
            </div>

        );
    }
}
export default withTranslation()(GatewayList);