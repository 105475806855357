import { PureComponent } from 'react';
import AuthenticationContext from '../../../../../Authentication/types/AuthContextType';

import UserProfileView from '../views/UserProfileView';
import HttpClient from '../../../../../HttpClient/HttpClient';
import InvitationHttpClient from '../../../../../HttpClient/InvitationsHttpClient';
import WorkOrdersHttpClient from '../../../../../HttpClient/WorkOrdersHttpClient';

import Loader from '../../../../Common/Loader';
import { User } from '@ComponentsRoot/Management/types/User';
import { withRouter } from '../../../../withRouter';
import {
  ResponseAddRole,
  ResponseAllRoles,
  ResponseGetAllProductionCenters,
  ResponseGetMeGraphApi,
} from '../types/ResponseTypes';
import { Role, RoleToAssign } from '../types/Role';
import {
  FactoriesNumberStatus,
  ObjUpdateFactory,
  ProfileType,
  UserPersonalProfileContainerPropsType,
} from '../types/UserProfileTypes';
import _ from 'lodash';
import { t } from 'i18next';
import type { AuthContextType } from '@RootPath/Authentication/types/AuthContextType';
import { manageInfoUser } from '../../../../Common/utils/helpers';

export class UserManageProfileContainer extends PureComponent<
  { profileType: ProfileType; personalAccount: boolean; params: { id: string } },
  UserPersonalProfileContainerPropsType
> {
  static contextType = AuthenticationContext;
  private httpUser: HttpClient | null = null;
  private invitationHttpClient: InvitationHttpClient | null = null;
  private workOrdersHttpClient: WorkOrdersHttpClient | null = null;

  constructor(props: { profileType: ProfileType; personalAccount: boolean; params: { id: string } }) {
    super(props);
    this.state = {
      loading: true,
      user: null,
      message: null,
      editModalStatus: false,
      allRoles: [],
      userRoleEditProdCenters: false,
      allProductionCenters: [],
      btnsNewCenter: false,
      loaderCenters: false,
      factoriesOfUser: [],
      initialFactoriesOfUser: [],
      centerModalStatus: false,
      refreshAfterMessage: false,
    };
  }

  async componentDidMount() {
    await this.getAllRoles();
    await this.getAllProductionCenters();
    const id = this.props.params.id;
    await this.getUserById(id);
  }

  showBtnsNewCenter = () => {
    this.setState({ btnsNewCenter: !this.state.btnsNewCenter });
  };

  refreshCenterFactories = async () => {
    await this.getUserById(this.props.params.id, false);
    this.setState({ loaderCenters: false, btnsNewCenter: false });
  };

  removeAllFactoriesToUser = async (centerId: number) => {
    this.setState({ loaderCenters: true });
    const centerSelected = this.state.user!.productionCenters!.find((center) => center.id === centerId);
    const factoriesToRemove: ObjUpdateFactory[] = [];
    centerSelected?.factories.map((factory) => {
      factoriesToRemove.push({
        userId: this.state.user!.id,
        factoryId: factory.id,
      });
    });
    try {
      await this.workOrdersHttpClient?.removeFactoryUser(factoriesToRemove);
      await this.refreshCenterFactories();
      this.renderMessage('success', t('userpages.factoriesUpdatedSuccessfully'), false);
    } catch (error) {
      this.renderMessage('error', t('userpages.factoriesUpdatedError'), false);
    }
  };

  manageFactoryToUser = async (centerId: number, factoryId: number, newStatus: boolean) => {
    const factoriesOfUser: FactoriesNumberStatus = { ...this.state.factoriesOfUser };
    factoriesOfUser[centerId][factoryId] = newStatus;
    this.setState({ factoriesOfUser });
  };

  hassDiffFactories = (centerId: number) => {
    const { factoriesOfUser, initialFactoriesOfUser } = this.state;
    return _.isEqual(factoriesOfUser[centerId], initialFactoriesOfUser[centerId]);
  };

  applyFactoriesToUser = async (centerId: number) => {
    this.setState({ loaderCenters: true });
    const { factoriesOfUser, initialFactoriesOfUser } = this.state;
    const factoryIds = Object.keys(factoriesOfUser[centerId]);

    const objAddFactories: ObjUpdateFactory[] = [];
    const objRemoveFactories: ObjUpdateFactory[] = [];

    factoryIds.forEach((factoryId) => {
      const factoryStatus: boolean = factoriesOfUser[centerId][factoryId];
      const initialFactoryStatus: boolean = initialFactoriesOfUser[centerId][factoryId];
      if (factoryStatus !== initialFactoryStatus) {
        if (factoryStatus === true) {
          objAddFactories.push({
            userId: this.state.user!.id,
            factoryId: Number(factoryId),
          });
        } else {
          objRemoveFactories.push({
            userId: this.state.user!.id,
            factoryId: Number(factoryId),
          });
        }
      }
    });
    if (objRemoveFactories.length || objAddFactories.length) {
      this.workOrdersHttpClient = new WorkOrdersHttpClient(this.context);
      try {
        objRemoveFactories.length && (await this.workOrdersHttpClient.removeFactoryUser(objRemoveFactories));
        objAddFactories.length && (await this.workOrdersHttpClient.setFactoryToUser(objAddFactories));
        await this.refreshCenterFactories();
        this.renderMessage('success', t('userpages.factoriesUpdatedSuccessfully'), false);
      } catch (error) {
        this.renderMessage('error', t('userpages.factoriesUpdatedError'), false);
      }
    }
  };

  renderMessage = (type: string, content: string, refresh: boolean = true) => {
    this.setState({ message: { type: type, content: content }, refreshAfterMessage: refresh });
  };

  getUserById = async (id: string, setLoading = true) => {
    if (!id) {
      this.setState({ loading: false });
      return;
    }
    // Optional loading
    if (setLoading && !this.state.loading) {
      this.setState({ loading: true });
    }
    //
    this.httpUser = new HttpClient(this.context);
    try {
      let response: ResponseGetMeGraphApi = await this.httpUser.userGetWithUserIdOrGuid(id);
      if (response.content) {
        let userData = { ...response.content, imgUser: '' };

        // Usecase with personalAccount in management
        if (this.props.personalAccount) {
          const userDataContext = this.context as AuthContextType;
          const userThumbnailPhoto = await manageInfoUser(userDataContext, "userThumbnailData");
          userData = { ...response.content, imgUser: userThumbnailPhoto };
        }

        // Usecase for ProductionCenters
        const userRoleEditProdCenters = this.checkUserRoleEditCenters(userData.userRoles as unknown as Role[]);
        const productionCenters = await this.getProductionCentersByUserId(userData.id);
        const factoriesOfUser: FactoriesNumberStatus = {};
        userData = { ...userData, productionCenters };
        this.state.allProductionCenters.forEach((center) => {
          factoriesOfUser[center.id] = factoriesOfUser[center.id] || {};
          center.factories.forEach((factory) => {
            factoriesOfUser[center.id][factory.id] = Boolean(
              productionCenters.find((pc) => pc.id === center.id)?.factories.find((f) => f.id === factory.id)
            );
          });
        });

        this.setState({
          user: userData,
          loading: false,
          message: null,
          editModalStatus: false,
          userRoleEditProdCenters: userRoleEditProdCenters,
          factoriesOfUser: factoriesOfUser,
          initialFactoriesOfUser: _.cloneDeep(factoriesOfUser),
          refreshAfterMessage: false,
        });
      } else {
        this.setState({ message: { type: 'error', content: t('userpages.errorFetchDataInfo') }, loading: false });
      }
    } catch (error) {
      this.setState({ message: { type: 'error', content: (error as Error).message }, loading: false });
    }
  };

  getProductionCentersByUserId = async (userId: User['id']) => {
    this.workOrdersHttpClient = new WorkOrdersHttpClient(this.context);
    let response: ResponseGetAllProductionCenters = await this.workOrdersHttpClient.getProductionCentersById(userId);
    return response.content;
  };

  getAllProductionCenters = async () => {
    this.workOrdersHttpClient = new WorkOrdersHttpClient(this.context);
    let response: ResponseGetAllProductionCenters = await this.workOrdersHttpClient.getAllProductionCenters();
    this.setState({ allProductionCenters: response.content });
  };

  closeMessage = async () => {
    this.setState({ message: null });
    if (this.state.refreshAfterMessage) {
      const id = this.props.params.id;
      await this.getUserById(id);
    }
  };

  setToggleEditModal = () => {
    this.setState({ editModalStatus: !this.state.editModalStatus });
  };

  setToggleCenterModal = (status: boolean) => {
    if (!status) {
      this.setState({
        factoriesOfUser: _.cloneDeep(this.state.initialFactoriesOfUser),
        centerModalStatus: status,
      });
    } else {
      this.setState({ centerModalStatus: status });
    }
  };

  getAllRoles = async () => {
    this.invitationHttpClient = new InvitationHttpClient(this.context);
    try {
      const response: ResponseAllRoles = await this.invitationHttpClient.getRoles();
      if (response.content) {
        this.setState({ allRoles: response.content });
      }
    } catch (error) {
      this.renderMessage('error', t('userpages.errorFetchRoles'), false);
    }
  };

  manageRole = async (value: boolean, roleCode: string) => {
    const roleSelected = this.state.allRoles.find((role) => String(role.code) === String(roleCode));
    if (roleSelected) {
      const newRole: RoleToAssign = {
        userId: this.state.user!.id,
        email: this.state.user!.email,
        roleId: roleSelected.id,
        code: Number(roleCode),
      };
      this.httpUser = new HttpClient(this.context);
      if (value) {
        this.addRole(newRole);
      } else {
        this.removeRole(newRole);
      }
    } else {
      // error
      this.renderMessage('error', t('userpages.errorManagingRole'), false);
    }
  };

  addRole = async (newRole: RoleToAssign) => {
    this.httpUser = new HttpClient(this.context);
    try {
      const response: ResponseAddRole = await this.httpUser.userAddRole(newRole);
      if (response.content) {
        this.renderMessage('success', response.content.response);
      }
    } catch (error) {
      this.renderMessage('error', t('userpages.errorAddingRole'), false);
    }
  };

  removeRole = async (newRole: RoleToAssign) => {
    this.httpUser = new HttpClient(this.context);
    try {
      const response: ResponseAddRole = await this.httpUser.userRemoveRole(newRole);
      if (response.content) {
        this.renderMessage('success', response.content.response);
      }
    } catch (error) {
      this.renderMessage('error', t('userpages.errorRemovingRole'), false);
    }
  };

  checkUserRoleEditCenters = (userRoles: Role[]) => {
    const rolesSelected = [110, 830]; // admin, iot admin o production manager
    return userRoles.some((userRole) => rolesSelected.includes(Number(userRole.code)));
  };

  render() {
    return (
      <>
        {this.state.loading ? (
          <Loader />
        ) : (
          <UserProfileView
            user={this.state.user as User}
            profileType={this.props.profileType}
            message={this.state.message}
            editModalStatus={this.state.editModalStatus}
            allRoles={this.state.allRoles}
            closeMessage={this.closeMessage}
            setToggleEditModal={this.setToggleEditModal}
            manageRole={this.manageRole}
            renderMessage={this.renderMessage}
            personalAccount={this.props.personalAccount}
            userRoleEditProdCenters={this.state.userRoleEditProdCenters}
            allProductionCenters={this.state.allProductionCenters}
            manageFactoryToUser={this.manageFactoryToUser}
            showBtnsNewCenter={this.showBtnsNewCenter}
            btnsNewCenter={this.state.btnsNewCenter}
            loaderCenters={this.state.loaderCenters}
            removeAllFactoriesToUser={this.removeAllFactoriesToUser}
            applyFactoriesToUser={this.applyFactoriesToUser}
            factoriesOfUser={this.state.factoriesOfUser}
            hassDiffFactories={this.hassDiffFactories}
            centerModalStatus={this.state.centerModalStatus}
            setToggleCenterModal={this.setToggleCenterModal}
          />
        )}
      </>
    );
  }
}

export default withRouter(UserManageProfileContainer);
