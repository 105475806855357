import React, { Component } from 'react';
import '../../../../App.css';
import './ActiveNotificationsCards.css';
import AlertHttpClient from "../../../../HttpClient/AlertHttpClient";
import AuthenticationContext from "../../../../Authentication/types/AuthContextType";
import moment from 'moment';
import Moment from 'react-moment';
import { withTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { withRouter } from '../../../withRouter';
import Table from '../../../CustomComponents/ReactTable/Table';
import TableSkeleton from '../../../CustomComponents/ReactTable/TableSkeleton';

class HistoricNotificationList extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        if (this.props.match != undefined && this.props.match.params != undefined && this.props.match.params.id != undefined && this.props.location.pathname.split('/').length > 1)
            this.id = this.props.match.params.id == undefined ? parseInt(this.props.location.pathname.split('/')[2]) : this.props.match.params.id;
        else {
            if (window.location.pathname.split('/').length > 0)
                this.id = parseInt(window.location.pathname.split('/')[2]);
        }

        this.state = {
            _data: {},
            page: 0,
            sizePerPage: 5,
            totalSize: 0
        };

        this.products = [];

        const { t } = props

        this.columns = [
            {
                dataField: 'individualRule.transactionalProduct',
                text: t('productspages.alertreporting.device'),
                classes: 'h-1',
                formatter: this.ProductFormatter.bind(this),
                hidden: this.props.isSite ? false : true,
                align: 'left',
                sort: false,
                headerStyle: () => {
                    return { width: "25%", important:true };
                }
            },
            {
                dataField: 'individualRule.description' + this.props.isSite ? '' : 'hidde',
                text: t('tables.notification'),
                classes: 'h-1',
                formatter: this.DescriptionFormatter.bind(this),
                hidden: this.props.isSite ? false : true,
                align: 'left',
                sort: false,
                headerStyle: () => {
                    return { width: "25%" };
                }
            },
            {
                dataField: 'individualRule.name',
                text: t('tables.notification'),
                hidden: this.props.isSite ? true : false,
                align: 'left',
                sort: false,
                headerStyle: () => {
                    return { width: "20%" };
                }
            },
             {
                dataField: 'individualRule.description' + this.props.isSite ? 'hidde': '',
                text: t('tables.description'),
                hidden: this.props.isSite ? true : false,
                align: 'left',
                sort: false,
                headerStyle: () => {
                    return { width: "20%" };
                }
            },
             {
                dataField: 'individualRule.type',
                text: t('tables.type'),
                align: 'left',
                sort: true,
                hidden: false,
                formatter: this.TypeFormatter.bind(this),
                headerStyle: () => {
                    return { width: (this.props.isSite ? "8%" : "12%") };
                },
                sortCaret: (order, column) => {
                    if (!order) return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort.png').default} />);
                    else if (order === 'asc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-asc.png').default} />);
                    else if (order === 'desc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-desc.png').default} />);
                    return null;
                }
            }, 
            {
                dataField: 'timestamp',
                text: t('tables.creationDate'),
                align: 'left',
                sort: true,
                formatter: this.DateFormatterTimestamp.bind(this),
                headerStyle: () => {
                    return { width: "15%" };
                },
                sortCaret: (order, column) => {
                    if (!order) return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort.png').default} />);
                    else if (order === 'asc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-asc.png').default} />);
                    else if (order === 'desc') return (<img className="caret-icon" src={require('../../../../assets/icons/14_sort-desc.png').default} />);
                    return null;
                }
            },
             {
                dataField: 'notificationCount',
                text: t('productspages.alertreporting.notificationCount'),
                align: 'left',
                sort: true,
                headerStyle: () => {
                    return { width: "15%" };
                }
            },
             {
                dataField: 'checkedUser',
                text: t("productspages.alertreporting.ack"),
                align: 'left',
                sort: true,
                formatter: this.ackFormatter.bind(this),
                headerStyle: () => {
                    return { width: "15%" };
                }
            }
        ];

        this.customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total" style={{ marginLeft: '5px' }}>
                {t('tables.customTotal', { from: from, to: to, size: size })}
            </span>
        );

        this.defaultSorted = [{
            dataField: 'timestamp',
            order: 'desc'
        }];

    }

    ackFormatter({ row, value }) {
        
        var id = "custom-switch_" + row.id;
        return (
            <Form>
                <Form.Check

                    type="checkbox"
                    id={id}
                    label=""
                    defaultChecked={row.checkedUser ? true : false}
                    disabled={true}
                />
            </Form>
        );
    }

    componentDidMount() {
        this.getProductHistoricalAlerts(this.getCurrentPage(), this.state.sizePerPage);
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        this.getProductHistoricalAlerts(page, sizePerPage);
    }

    async getProductHistoricalAlerts(page, sizePerPage) {

        this.setState({ dataLoaded: false });
        this.http = new AlertHttpClient(this.context, this.id, null, null, null, page, sizePerPage);
        let data = '';
        if (this.props.isSite) {
            data = await this.http.GetProducedIndividualRulesBySite({ isHistoric: true });
        } else {
            data = await this.http.GetProducedIndividualRules({ isHistoric: true });
        }
        const dataWithTranslation = this.translateData(data);
        this.setState({ _data: dataWithTranslation, dataLoaded: true, totalSize: data.pagination?.totalItems ?? 0, page: page, sizePerPage: sizePerPage});
    }

    getTranslationIfExist = (keyTrans) => {
        return keyTrans !== this.props.t(`alerts:${keyTrans}`);
      };
      
      translateData = (data) => {
        if (data && data.content && data.content.length && this.props?.product?.content?.productType?.smartComponentTypeId) {
        const smartComponentTypeId = this.props.product.content.productType.smartComponentTypeId;
          data.content.forEach(row => {
            if (row.individualRule) {
                const { businessLabel } = row.individualRule;
                if (smartComponentTypeId && businessLabel) {
                    row.individualRule.name = this.getTranslationIfExist(`${smartComponentTypeId}.${businessLabel}.name`)
                    ? this.props.t(`alerts:${smartComponentTypeId}.${businessLabel}.name`)
                    : row.individualRule.name;
        
                    row.individualRule.description = this.getTranslationIfExist(`${smartComponentTypeId}.${businessLabel}.description`)
                    ? this.props.t(`alerts:${smartComponentTypeId}.${businessLabel}.description`)
                    : row.individualRule.description;
                } else {
                    console.log(row); // Fila con campos faltantes
                }
            }
          });
        }
        return data;
      };

    DateFormatter({ row, value }) {
        if (row.timeActive == null) {
            return (<span></span>);
        }
        else {
            return (<span>{moment(row.timeActive).format("HH:mm:ss")}</span>);
        }

    }

    DateFormatterTimestamp({ row, value }) {
        if (row.timestamp == null) {
            return (<span></span>);
        }
        else {
            return (<span>{moment(row.timestamp).format("DD/MM/YYYY HH:mm")}</span>);
        }

    }


    DescriptionFormatter({ row, value }) {
        if (!this.props.isSite) {
            return row.individualRule.description;
        }
        let content = (
            <div className="t-container">
                <div className="box t2 bold">{row.individualRule.name}</div>
                <div className="box t2">{row.individualRule.description}</div>
            </div>
        );
        return content;

    }

    ProductFormatter({ row, value }) {
        let content = (
            <Link tag={Link} to={`/product/${row.transactionalProduct?.id}`} style={{ textDecoration: "none" }}>
                <div className="d-flex h-100">
                    <div style={{ width: "50px", minWidth: "50px", alignSelf: "center", position: "relative" }}>
                        <img src={row.transactionalProduct?.productType?.imageUri} style={{ width: "100%" }} />
                        <div className={"dotState " + row.transactionalProduct?.connectionState.toLowerCase()} style={{ position: "absolute", top: "5px", left: "-5px" }} ></div>
                    </div>
                    <div className="t-container ml-2">
                        <h4 className="box m-0" style={{ fontWeith: 'bold!important' }}>{row.transactionalProduct?.description}</h4>
                        <div className="box TreeDetailProduct m-0" >{row.transactionalProduct?.puuid}</div>
                    </div>
                </div>
            </Link>
        );
        return content;
    }


    TypeFormatter({ row, value }) {

        if (row) {
            if (row.individualRule.type != undefined && row.individualRule.type != null) {
                let iType = row.individualRule.type.toLowerCase();
                const { t } = this.props;
                let rType = "";
                let tType = "";
                if (iType.includes("warning")) {
                    rType = "warning";
                    tType = t("productspages.alertreporting.warning");
                } else if (iType.includes("alarm")) {
                    rType = "alarm";
                    tType = t("productspages.alertreporting.alarm");
                } else if (iType.includes("parameter") || iType.includes("rule")) {
                    rType = "rule";
                    tType = t("productspages.alertreporting.rule");
                }

                return (<span className={rType + " custom-button-span"}>{tType != "" ? tType : row.individualRule.type}</span>);
            } else {
                return (<span className={" custom-button-span"}></span>);
            }
        }
    }

    getCurrentPage() {
        let currentPage = 1
        try {
            currentPage = JSON.parse(localStorage.getItem('currentPageNotiHist')) || 1;
        }
        catch (e) {
            currentPage = 1;
        }
        return parseInt(currentPage);
    }
    getCurrentSizePerPage() {
        let currentSizePerPage = -1
        try {
            currentSizePerPage = JSON.parse(localStorage.getItem('sizePerPageNotiHist'));
            if(currentSizePerPage == null || (currentSizePerPage > 0 && currentSizePerPage < 5)){
                currentSizePerPage = 5
            }
        }
        catch(e){
            currentSizePerPage = 5;
        }
        return parseInt(currentSizePerPage);
    }

    onSizePerPageChange (sizePerPage) {
        localStorage.setItem('sizePerPageNotiHist', sizePerPage);
        this.setState({ sizePerPage: sizePerPage }, () => console.log("this.getCurrentSizePerPage()" + this.getCurrentSizePerPage()));
    }

    onPageChange (newPage) {
        this.paginaActual = newPage;
        localStorage.setItem('currentPageNotiHist', newPage);
        this.setState({ page: newPage });
    }


    render() {
        
        const { t } = this.props;
        if (this.state.dataLoaded) {
            const pageButtonRenderer = ({
                page,
                active,
                disable,
                title,
                onPageChange
            }) => {
                const handleClick = (e) => {

                    e.preventDefault();
                    onPageChange(page);
                };
                const activeStyle = {};
                const isActive = active ? "active" : "";
                if (active) {
                    activeStyle.margin = '5px';
                } else {
                    activeStyle.margin = '5px';
                }
                if (typeof page === 'string') {
                    activeStyle.margin = '5px';
                }


                return (
                    <li className={"page-item paginate_button " + isActive}>
                        <a href="#" className="page-link" onClick={handleClick} style={activeStyle}>{page}</a>
                    </li>
                );
            };

            this.options = {
                pageButtonRenderer,
                paginationSize: 5,
                pageStartIndex: 1,
                hidePageListOnlyOnePage: true,
                showTotal: true,
                paginationTotalRenderer: this.customTotal,
                disablePageTitle: true,
                sizePerPageList: [{
                    text: '5', value: 5
                }, {
                    text: '10', value: 10
                }, {
                    text: t('tables.all'), value: this.state.totalSize > 50 ? 50 : this.state.totalSize
                }]
            };
        }

        return (
            <div>
                {this.state.dataLoaded ? (
                    <React.Fragment>
                        <Table t={t} resize={true} name={"HistoricNotificationList-locales" + (this.props.isSite ? '-site':'')}  columns={this.columns} allAuto={ true} rows={this.state._data.content} search={false} page={this.getCurrentPage()} pageSize={this.getCurrentSizePerPage()}  onSizePerPageChange={this.onSizePerPageChange.bind(this)} onPageChange={this.onPageChange.bind(this)}/>
                    </React.Fragment>
                ) : (
                    <TableSkeleton />
                )}
            </div>

        );
    }
}

export default withTranslation()(withRouter(HistoricNotificationList, [{ path: '/product/:id' }]));