import { PureComponent } from 'react';
import AuthenticationContext from "../../../Authentication/types/AuthContextType";
import { ConfirmRegisterStateType } from '../types/ConfirmRegisterStateType';
import ConfirmRegisterView from '../views/ConfirmRegisterView';


class ConfirmRegisterContainer extends PureComponent<{}, ConfirmRegisterStateType> {
    static contextType = AuthenticationContext;
    constructor(props: {}) {
        super(props);
        this.state = {
            loading: false,
            brandLogo: '',
            brandUser: 'roca',
        };
    }

    componentDidMount() {
        let isLaufenUser = (window.location.href.includes("laufen") || window.location.href.includes("localhost") ); // 
        let brand: "roca" | "laufen" = "roca";
        if (isLaufenUser) brand = "laufen";

        let logoUrl = isLaufenUser ? "https://strgpiotcoredev.blob.core.windows.net/b2c-html/assets/images/logo_laufen_standard.png" : "https://strgpiotcoredev.blob.core.windows.net/b2c-html/assets/images/logo_roca_white.jpg";

        this.setState({ brandUser: brand, brandLogo: logoUrl });
    }
    
    render() {
        return (
            <>
                <ConfirmRegisterView {...this.state} />
            </>
        );
    }

}

export default ConfirmRegisterContainer;