import { Component } from "react";
import CustomSelect from "../view/CustomSelect";
import {
  CustomSelectData,
  CustomSelectProps,
  CustomSelectState,
} from "../types/CustomSliderTypes";
import { WithTranslation, withTranslation } from "react-i18next";
import CustomSelectConfig from "../config/CustomSelectConfig";

export class CustomSelectContainer extends Component<WithTranslation & CustomSelectProps, CustomSelectState> {
  private customSelectConfig: CustomSelectConfig;

  constructor(props: WithTranslation & CustomSelectProps) {
    super(props);
    this.customSelectConfig = new CustomSelectConfig(this);

    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    let data: CustomSelectData = {
      value: this.props.value == null ? "" : this.props.value,
      values: this.props.parameter.values.map(
        (value: { value: string; label: string; }, p: any) => ({key: p, value: value.value, label: value.label})
      ),
      id: this.props.parameter.id,
      name: this.props.t(this.props.parameter.table + ':' + this.props.parameter.smartComponentTypeId + '.' + this.props.parameter.businessLabel + '.name'),
      table: this.props.parameter.table,
      businessLabel: this.props.parameter.businessLabel,
      infoText: this.props.parameter.infoText,
      handleDisabledClick: this.customSelectConfig.handleDisabledClick,
      handleChange: this.customSelectConfig.handleChange,
      permissionType: this.props.parameter.permissionType,
      online: this.props.parameter.online,
      isDeviceSettingsMode: this.props.parameter.isDeviceSettingsMode,
      isDisabled: this.customSelectConfig.isDisabled
    };
    
    this.setState({ data });
  }

  render() {
    return this.state.data ? <CustomSelect {...this.state.data}></CustomSelect> : <div></div>;
  }
}

export default withTranslation()(CustomSelectContainer);
