import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { t } from 'i18next';

import ManagementContainer from '../container/ManagementContainer';
import HWSWContainer from '../../Management/Tabs/HWSW/container/HWSWContainer';

import Style from '../styles/ManagementView.module.scss';
import OrganizationContainer from '../Tabs/Organization/container/OrganizationContainer';

const ManagementView = (props: { tabValue: number; changeTab: ManagementContainer['handleChangeTab'] }) => {
  const a11yProps = (index: number) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  return (
    <section className={Style.managementView}>
      <div className='container container-managementView'>
        <div>
          <AppBar position='static' color='default' className='headerStickyCustom userpage'>
            <Tabs
              value={props.tabValue}
              onChange={props.changeTab}
              indicatorColor='primary'
              variant='fullWidth'
              className='container'
            >
              <Tab label={<h3 className='tabTitle'>{t('users')}</h3>} {...a11yProps(0)} />
              <Tab label={<h3 className='tabTitle'>{t('hw/sw')}</h3>} {...a11yProps(1)} />
            </Tabs>            
          </AppBar>

          <SwipeableViews axis={'x'} index={props.tabValue} className='container'>
            <TabPanel value={props.tabValue} index={0}>
              <OrganizationContainer />
            </TabPanel>
            <TabPanel value={props.tabValue} index={1}>
              <HWSWContainer />
            </TabPanel>
          </SwipeableViews>

        </div>
      </div>
    </section>
  );
};

const TabPanel = (props: { index: number; value: number; children: ReactNode }) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default ManagementView;