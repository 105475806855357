import UserRequests from '../views/UserRequests';
import { PureComponent } from 'react';

class UserRequestsContainer extends PureComponent<{}, {}> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    return <UserRequests />
  }
}

export default UserRequestsContainer;
