import { PureComponent } from 'react';
import AuthenticationContext from '../../../../Authentication/types/AuthContextType';
import ReactECharts from 'echarts-for-react';
import { t } from 'i18next';
import _ from 'lodash';
import { compareGeneralLegend, getColor } from '../../utils/chartComponentsUtils';
import { formatNumber, formatNumberAsString } from '../../utilsCharts';
import { Skeleton } from '@mui/material';
import EmptyPieChartView from '../../views/EmptyPieChartView';

class PieChartContainer extends PureComponent<any, any> {
  static contextType = AuthenticationContext;
  constructor(props: any) {
    super(props);
    this.state = {
      chartData: false,
      dataPoints: false,
      chartLegend: false,
      error: false,
    };
  }

  componentDidMount = () => {
    if (this.props.chart && !this.state.dataPoints && !this.state.chartData) {
      this.getDataPoints();
    }
  };

  componentDidUpdate() {
    if (!_.isEmpty(this.state.chartLegend) && !_.isEmpty(this.props.generalLegend)) {
      const newLegend = compareGeneralLegend(this.props.generalLegend, this.state.chartLegend);
      if (newLegend) {
        this.applyLegendFilterToChartData(newLegend);
      }
    }
  }

  units
  getDataPoints = async () => {
    try {
      const response = await this.props.chartRepository.manageChartType(this.props.chart);
      const dataPoints = response?.content;
      const deviceUnits = response?.deviceUnits;
      this.units = deviceUnits && deviceUnits != '' ? deviceUnits : this.props.chart.units
      if (!dataPoints) {
        this.setState({
          error: {
            type: 'call',
            message: t('translation:somethingWrong'),
          },
        });
        return null;
      } else if (!dataPoints.length) {
        this.setState({
          error: {
            type: 'empty',
            message: t('translation:nodata'),
          },
        });
        return null;
      }

      const decimals = this.props.chart.decimals;
      const colors: any[] = [];
      const values: any[] = [];
      let chartData: any = false;
      if (dataPoints.length) {
        dataPoints.forEach((dataPoint) => {
          const color = getColor(dataPoint.color).color;
          const value = { name: dataPoint.name, value: formatNumber(dataPoint.sum, decimals) };
          values.push(value);
          colors.push(color);
        });
        chartData = {
          values,
          colors,
          options: this.getPieOptions(values, colors),
        };
      }

      const chartLegend = this.props.chartRepository.manageLegend(dataPoints);
      this.props.getLegendFromChart(chartLegend);
      this.setState({ dataPoints, chartData, chartLegend });
    } catch (error: any) {
      this.setState({
        error: {
          type: 'call',
          message: error?.message ? error?.message : error,
        },
      });
    }
  };

  getPieOptions = (values, colors) => {
    let options: any = { ...this.props.chart.options };
    let units = t('charts:axisTitles.' + this.units.toLowerCase());
    let decimals = this.props.chart.decimals;
    const sum = formatNumberAsString(
      values.reduce((prev, current) => prev + current.value, 0),
      decimals
    );
    options.title = {
      text: sum + '\n' + '{liters|' + units + '}',
      left: 'center',
      top: 'center',
      textStyle: {
        rich: {
          liters: {
            fontSize: 13,
          },
        },
      },
    };

    options.series[0].name = t('charts:titles.' + this.props.chart.title);
    options.series[0].data = values;
    options.series[0].color = colors;

    options.tooltip = {
      formatter: function (params) {
        return `${params.seriesName}<br />
                ${params.name}: ${formatNumberAsString(params.data.value, decimals)} (${params.percent}%)`;
      },
    };

    let label = {
      alignTo: 'labelLine',
      formatter: function (params) {
        let percent = params.percent;
        if (percent == undefined) percent = 0;
        return `{name|${params.name}}\n\n{per|${percent}%} {liters|(${formatNumberAsString(
          params.data.value,
          decimals
        )} ${units})}`;
      },
      minMargin: 5,
      edgeDistance: '25%',
      lineHeight: 10,
      rich: {
        liters: {
          fontSize: 12,
          color: '#999',
        },
        per: {
          fontSize: 12,
          color: '#999',
          fontWeight: 'bold',
        },
      },
    };
    options.series[0].label = label;

    // delete width to make the chart responsive
    delete options.series[0].width;

    return options;
  };

  applyLegendFilterToChartData = (updatedLegend) => {
    // Cambiar el estado de chartData a false para obligar a renderizar el componente con los datos nuevos
    this.setState({ chartData: false }, () => {
      // Después de que el estado se haya actualizado, llenar chartData con los nuevos datos
      const colors: any[] = [];
      const values: any[] = [];
      this.state.dataPoints.forEach((dataPoint) => {
        if (this.props.generalLegend && this.props.generalLegend[dataPoint.name].status === true) {
          const color = getColor(dataPoint.color).color;
          const value = { name: dataPoint.name, value: dataPoint.sum };
          values.push(value);
          colors.push(color);
        }
      });
      const newChartData = {
        values,
        colors,
        options: this.getPieOptions(values, colors),
      };
      this.setState({ chartData: newChartData, chartLegend: updatedLegend });
    });
  };

  render() {
    return (
      <>
        {this.state.error ? (
          <EmptyPieChartView {...this.state.error} />
        ) : this.state.chartData ? (
          <div className='pie'>
            <ReactECharts style={{ height: '400px' }} option={this.state.chartData.options} />
          </div>
        ) : !this.state.dataPoints.length ? (
          // <div className='pie'>
          //   <Skeleton animation="wave" variant='rectangular' width={966} height={350} style={{ margin: '15px auto' }} />
          // </div>
          <div className='row loadingParent'>
            <div className='loadingImg'></div>
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default PieChartContainer;
