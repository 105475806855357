import {
  Dialog,
  DialogActions,
  IconButton,
  DialogContent,
  Button,
  Avatar,
  FormControl,
  Typography,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';
import Unsubscribe from '../../../../Unsubscribe/Unsubscribe';
import InputGroup from 'react-bootstrap/esm/InputGroup';
import Style from '../styles/UserProfileView.module.scss';
import { UserProfileEditPropsType } from '../types/UserProfileTypes';

const UserProfileEditView = (props: UserProfileEditPropsType) => {
  return (
    <div className={Style.userProfileView}>
      <div className='container-userProfileView'>
        <Dialog
          disableEscapeKeyDown
          classes={{ paper: 'installationModal' }}
          open={props.showModalUnsubscribe}
          onClose={() => props.handleUnsubscribe(false)}
        >
          <DialogActions className='jc-spaceBetween'>
            <h2 className='mb-0 ml-4'>{t('userpages.unsubscribeTitle')}</h2>
            <IconButton onClick={() => props.handleUnsubscribe(false)}>
              <CloseIcon fontSize='large' />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <div>
              <Unsubscribe />
            </div>
          </DialogContent>
        </Dialog>
        <form id='divForm'>
          <div id='userInfo' className='row prodChildNoZoom p-4 m-0'>
            <div className='col-12 col-xl-3'>
              {props.personalAccount && (
                <>
                  <div className='col-12 row pt-3 userAvatarContainer' style={{ justifyContent: 'center' }}>
                    <Avatar srcSet={props.user.imgUser} alt={props.user.displayName} sx={{ width: 85, height: 85 }} />
                    <div style={{ marginTop: '5px', width: '100%' }}>
                      <Button
                        style={{ margin: '0 auto', display: 'block' }}
                        // variant='light'
                        className='managmentBtnClass'
                        onClick={() => props.btnImageChange()}
                      >
                        <span className='spanRoca' style={{ color: '#949494' }}>
                          {t('installationspages.installationsummaryedit.uploadimage')}
                        </span>
                        <img
                          style={{ marginLeft: '5px', marginTop: '-5px' }}
                          src={require('../../../../../assets/icons/project-18.svg').default}
                        />
                      </Button>
                      <input
                        type='file'
                        id='inputUserThumbnailPhoto'
                        style={{ display: 'none' }}
                        onChange={props.handleImageChange}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className='col-12 col-xl-9'>
              <div className='row'>
                <div className='col-12 col-xl-6'>
                  <InputGroup>
                    <FormControl variant='standard' style={{ width: '100%', paddingTop: '0.35em' }}>
                      <Typography className='t1' id='t-email'>
                        {t('userpages.email')}
                      </Typography>
                      <TextField
                        variant='standard'
                        id='email'
                        name='email'
                        required
                        defaultValue={props.user.email}
                        style={{ marginTop: '-5px' }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </InputGroup>
                  <InputGroup>
                    <FormControl variant='standard' style={{ width: '100%', paddingTop: '0.35em' }}>
                      <Typography className='t1' id='t-title'>
                        {t('userpages.title')}
                      </Typography>
                      <Select
                        inputProps={{
                          onInvalid: (event) => {
                            (event.target as HTMLSelectElement).setCustomValidity(t('general.error_required'));
                          },
                        }}
                        variant='standard'
                        style={{ marginBottom: '5px', marginTop: '-5px' }}
                        labelId='demo-mutiple-title-label'
                        id='title'
                        name='title'
                        defaultValue=''
                        required
                        value={props.user.title}
                        onChange={(e) => props.userFieldChange(e.target.name, e.target.value)}
                      >
                        {/* <MenuItem value=''>{t('userpages.title')}</MenuItem> */}
                        <MenuItem value='MS'>{t('userpages.ms')}</MenuItem>
                        <MenuItem value='MR'>{t('userpages.mr')}</MenuItem>
                        <MenuItem value='Neutral title'>{t('userpages.neutral')}</MenuItem>
                      </Select>
                    </FormControl>
                  </InputGroup>
                  <InputGroup>
                    <FormControl variant='standard' style={{ width: '100%', paddingTop: '0.35em' }}>
                      <Typography className='t1' id='t-displayName'>
                        {t('userpages.displayName')}
                      </Typography>
                      <TextField
                        onInvalid={(e) => (e.target as HTMLSelectElement)!.setCustomValidity(t('general.error_required'))}
                        onInput={(e) => (e.target as HTMLSelectElement)!.setCustomValidity(t('general.error_required'))}
                        variant='standard'
                        id='displayName'
                        name='displayName'
                        required
                        defaultValue={props.user.displayName}
                        onChange={(e) => props.handleChange(e)}
                        style={{ marginTop: '-5px' }}
                      />
                    </FormControl>
                  </InputGroup>
                  <InputGroup>
                    <FormControl variant='standard' style={{ width: '100%', paddingTop: '0.35em' }}>
                      <Typography className='t1' id='t-givenName'>
                        {t('userpages.givenName')}
                      </Typography>
                      <TextField
                        onInvalid={(e) => (e.target as HTMLSelectElement)!.setCustomValidity(t('general.error_required'))}
                        onInput={(e) => (e.target as HTMLSelectElement)!.setCustomValidity(t('general.error_required'))}
                        variant='standard'
                        id='givenName'
                        name='givenName'
                        required
                        defaultValue={props.user.givenName}
                        onChange={(e) => props.handleChange(e)}
                        style={{ marginTop: '-5px' }}
                      />
                    </FormControl>
                  </InputGroup>
                  <InputGroup>
                    <FormControl variant='standard' style={{ width: '100%', paddingTop: '0.35em' }}>
                      <Typography className='t1' id='t-surname'>
                        {t('userpages.surname')}
                      </Typography>
                      <TextField
                        onInvalid={(e) => (e.target as HTMLSelectElement)!.setCustomValidity(t('general.error_required'))}
                        onInput={(e) => (e.target as HTMLSelectElement)!.setCustomValidity(t('general.error_required'))}
                        variant='standard'
                        id='surname'
                        name='surname'
                        required
                        defaultValue={props.user.surname}
                        onChange={(e) => props.handleChange(e)}
                        style={{ marginTop: '-5px' }}
                      />
                    </FormControl>
                  </InputGroup>
                  <InputGroup>
                    <FormControl variant='standard' style={{ width: '100%', paddingTop: '0.35em' }}>
                      <Typography className='t1' id='t-country'>
                        {t('Locale')}
                      </Typography>
                      <Select
                        disabled={!(props.profileType === 'personal' || props.personalAccount)}
                        variant='standard'
                        style={{ marginBottom: '5px', marginTop: '-5px' }}
                        labelId='demo-mutiple-name-label'
                        id='locale'
                        name='locale'
                        required
                        value={props.user.locale.toLocaleUpperCase()}
                        onChange={(e) => props.userFieldChange(e.target.name, e.target.value)}
                      >
                        {props.localeAvailable.map((lang) => (
                          <MenuItem value={lang}>{lang}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </InputGroup>
                </div>

                <div className='col-12 col-xl-6'>
                  <InputGroup>
                    <FormControl variant='standard' style={{ width: '100%', paddingTop: '0.35em' }}>
                      <Typography className='t1' id='t-telephoneNumber'>
                        {t('userpages.telephoneNumber')}
                      </Typography>
                      <TextField
                        onInvalid={(e) => (e.target as HTMLSelectElement)!.setCustomValidity(t('general.error_required'))}
                        onInput={(e) => (e.target as HTMLSelectElement)!.setCustomValidity(t('general.error_required'))}
                        variant='standard'
                        id='telephoneNumber'
                        required
                        name='telephoneNumber'
                        defaultValue={props.user.telephoneNumber}
                        onChange={(e) => props.handleChange(e)}
                        style={{ marginTop: '-5px' }}
                      />
                    </FormControl>
                  </InputGroup>
                  <InputGroup>
                    <FormControl variant='standard' style={{ width: '100%', paddingTop: '0.35em' }}>
                      <Typography className='t1' id='t-streetAdress'>
                        {t('userpages.streetAddress')}
                      </Typography>
                      <TextField
                        onInvalid={(e) => (e.target as HTMLSelectElement)!.setCustomValidity(t('general.error_required'))}
                        onInput={(e) => (e.target as HTMLSelectElement)!.setCustomValidity(t('general.error_required'))}
                        variant='standard'
                        id='streetAddress'
                        name='streetAddress'
                        required
                        defaultValue={props.user.streetAddress}
                        onChange={(e) => props.handleChange(e)}
                        style={{ marginTop: '-5px' }}
                      />
                    </FormControl>
                  </InputGroup>
                  <InputGroup>
                    <FormControl variant='standard' style={{ width: '100%', paddingTop: '0.35em' }}>
                      <Typography className='t1' id='t-postalCode'>
                        {t('userpages.postalCode')}
                      </Typography>
                      <TextField
                        onInvalid={(e) => (e.target as HTMLSelectElement)!.setCustomValidity(t('general.error_required'))}
                        onInput={(e) => (e.target as HTMLSelectElement)!.setCustomValidity(t('general.error_required'))}
                        variant='standard'
                        id='postalCode'
                        name='postalCode'
                        required
                        defaultValue={props.user.postalCode}
                        onChange={(e) => props.handleChange(e)}
                        style={{ marginTop: '-5px' }}
                      />
                    </FormControl>
                  </InputGroup>
                  <InputGroup>
                    <FormControl variant='standard' style={{ width: '100%', paddingTop: '0.35em' }}>
                      <Typography className='t1' id='t-city'>
                        {t('userpages.city')}
                      </Typography>
                      <TextField
                        onInvalid={(e) => (e.target as HTMLSelectElement)!.setCustomValidity(t('general.error_required'))}
                        onInput={(e) => (e.target as HTMLSelectElement)!.setCustomValidity(t('general.error_required'))}
                        variant='standard'
                        id='city'
                        name='city'
                        required
                        defaultValue={props.user.city}
                        onChange={(e) => props.handleChange(e)}
                        style={{ marginTop: '-5px' }}
                      />
                    </FormControl>
                  </InputGroup>
                  <InputGroup>
                    <FormControl variant='standard' style={{ width: '100%', paddingTop: '0.35em' }}>
                      <Typography className='t1' id='t-state'>
                        {t('userpages.state')}
                      </Typography>
                      <TextField
                        onInvalid={(e) => (e.target as HTMLSelectElement)!.setCustomValidity(t('general.error_required'))}
                        onInput={(e) => (e.target as HTMLSelectElement)!.setCustomValidity(t('general.error_required'))}
                        variant='standard'
                        id='state'
                        name='state'
                        required
                        defaultValue={props.user.state}
                        onChange={(e) => props.handleChange(e)}
                        style={{ marginTop: '-5px' }}
                      />
                    </FormControl>
                  </InputGroup>
                  <InputGroup>
                    <FormControl variant='standard' style={{ width: '100%', paddingTop: '0.35em' }}>
                      <Typography className='t1' id='t-country'>
                        {t('userpages.country')}
                      </Typography>
                      <Select
                        inputProps={{
                          onInvalid: (event) => {
                            (event.target as HTMLSelectElement).setCustomValidity(t('general.error_required'));
                          },
                        }}
                        variant='standard'
                        style={{ marginBottom: '5px', marginTop: '-5px' }}
                        labelId='demo-mutiple-name-label'
                        id='country'
                        name='country'
                        required
                        value={props.user.country}
                        onChange={(e) => props.userFieldChange(e.target.name, e.target.value)}
                      >
                        <MenuItem value=''>{t('userpages.country')}</MenuItem>
                        <MenuItem value='Afghanistan'>Afghanistan</MenuItem>
                        <MenuItem value='Åland Islands'>Åland Islands</MenuItem>
                        <MenuItem value='Albania'>Albania</MenuItem>
                        <MenuItem value='Algeria'>Algeria</MenuItem>
                        <MenuItem value='American Samoa'>American Samoa</MenuItem>
                        <MenuItem value='Andorra'>Andorra</MenuItem>
                        <MenuItem value='Angola'>Angola</MenuItem>
                        <MenuItem value='Anguilla'>Anguilla</MenuItem>
                        <MenuItem value='Antarctica'>Antarctica</MenuItem>
                        <MenuItem value='Antigua and Barbuda'>Antigua and Barbuda</MenuItem>
                        <MenuItem value='Argentina'>Argentina</MenuItem>
                        <MenuItem value='Armenia'>Armenia</MenuItem>
                        <MenuItem value='Aruba'>Aruba</MenuItem>
                        <MenuItem value='Australia'>Australia</MenuItem>
                        <MenuItem value='Austria'>Austria</MenuItem>
                        <MenuItem value='Azerbaijan'>Azerbaijan</MenuItem>
                        <MenuItem value='Bahamas'>Bahamas</MenuItem>
                        <MenuItem value='Bahrain'>Bahrain</MenuItem>
                        <MenuItem value='Bangladesh'>Bangladesh</MenuItem>
                        <MenuItem value='Barbados'>Barbados</MenuItem>
                        <MenuItem value='Belarus'>Belarus</MenuItem>
                        <MenuItem value='Belgium'>Belgium</MenuItem>
                        <MenuItem value='Belize'>Belize</MenuItem>
                        <MenuItem value='Benin'>Benin</MenuItem>
                        <MenuItem value='Bermuda'>Bermuda</MenuItem>
                        <MenuItem value='Bhutan'>Bhutan</MenuItem>
                        <MenuItem value='Bolivia'>Bolivia</MenuItem>
                        <MenuItem value='Bonaire'>Bonaire</MenuItem>
                        <MenuItem value='Bosnia and Herzegovina'>Bosnia and Herzegovina</MenuItem>
                        <MenuItem value='Botswana'>Botswana</MenuItem>
                        <MenuItem value='Bouvet Island'>Bouvet Island</MenuItem>
                        <MenuItem value='Brazil'>Brazil</MenuItem>
                        <MenuItem value='British Indian Ocean Territory'>British Indian Ocean Territory</MenuItem>
                        <MenuItem value='British Virgin Islands'>British Virgin Islands</MenuItem>
                        <MenuItem value='Brunei'>Brunei</MenuItem>
                        <MenuItem value='Bulgaria'>Bulgaria</MenuItem>
                        <MenuItem value='Burkina Faso'>Burkina Faso</MenuItem>
                        <MenuItem value='Burundi'>Burundi</MenuItem>
                        <MenuItem value='Cabo Verde'>Cabo Verde</MenuItem>
                        <MenuItem value='Cambodia'>Cambodia</MenuItem>
                        <MenuItem value='Cameroon'>Cameroon</MenuItem>
                        <MenuItem value='Canada'>Canada</MenuItem>
                        <MenuItem value='Cayman Islands'>Cayman Islands</MenuItem>
                        <MenuItem value='Central African Republic'>Central African Republic</MenuItem>
                        <MenuItem value='Chad'>Chad</MenuItem>
                        <MenuItem value='Chile'>Chile</MenuItem>
                        <MenuItem value='China'>China</MenuItem>
                        <MenuItem value='Christmas Island'>Christmas Island</MenuItem>
                        <MenuItem value='Cocos (Keeling) Islands'>Cocos (Keeling) Islands</MenuItem>
                        <MenuItem value='Colombia'>Colombia</MenuItem>
                        <MenuItem value='Comoros'>Comoros</MenuItem>
                        <MenuItem value='Congo'>Congo</MenuItem>
                        <MenuItem value='Congo (DRC)'>Congo (DRC)</MenuItem>
                        <MenuItem value='Cook Islands'>Cook Islands</MenuItem>
                        <MenuItem value='Costa Rica'>Costa Rica</MenuItem>
                        <MenuItem value='Côte d’Ivoire'>Côte d’Ivoire</MenuItem>
                        <MenuItem value='Croatia'>Croatia</MenuItem>
                        <MenuItem value='Cuba'>Cuba</MenuItem>
                        <MenuItem value='Curaçao'>Curaçao</MenuItem>
                        <MenuItem value='Cyprus'>Cyprus</MenuItem>
                        <MenuItem value='Czech Republic'>Czech Republic</MenuItem>
                        <MenuItem value='Denmark'>Denmark</MenuItem>
                        <MenuItem value='Djibouti'>Djibouti</MenuItem>
                        <MenuItem value='Dominica'>Dominica</MenuItem>
                        <MenuItem value='Dominican Republic'>Dominican Republic</MenuItem>
                        <MenuItem value='Ecuador'>Ecuador</MenuItem>
                        <MenuItem value='Egypt'>Egypt</MenuItem>
                        <MenuItem value='El Salvador'>El Salvador</MenuItem>
                        <MenuItem value='Equatorial Guinea'>Equatorial Guinea</MenuItem>
                        <MenuItem value='Eritrea'>Eritrea</MenuItem>
                        <MenuItem value='Estonia'>Estonia</MenuItem>
                        <MenuItem value='Ethiopia'>Ethiopia</MenuItem>
                        <MenuItem value='Falkland Islands'>Falkland Islands</MenuItem>
                        <MenuItem value='Faroe Islands'>Faroe Islands</MenuItem>
                        <MenuItem value='Fiji'>Fiji</MenuItem>
                        <MenuItem value='Finland'>Finland</MenuItem>
                        <MenuItem value='France'>France</MenuItem>
                        <MenuItem value='French Guiana'>French Guiana</MenuItem>
                        <MenuItem value='French Polynesia'>French Polynesia</MenuItem>
                        <MenuItem value='French Southern Territories'>French Southern Territories</MenuItem>
                        <MenuItem value='Gabon'>Gabon</MenuItem>
                        <MenuItem value='Gambia'>Gambia</MenuItem>
                        <MenuItem value='Georgia'>Georgia</MenuItem>
                        <MenuItem value='Germany'>Germany</MenuItem>
                        <MenuItem value='Ghana'>Ghana</MenuItem>
                        <MenuItem value='Gibraltar'>Gibraltar</MenuItem>
                        <MenuItem value='Greece'>Greece</MenuItem>
                        <MenuItem value='Greenland'>Greenland</MenuItem>
                        <MenuItem value='Grenada'>Grenada</MenuItem>
                        <MenuItem value='Guadeloupe'>Guadeloupe</MenuItem>
                        <MenuItem value='Guam'>Guam</MenuItem>
                        <MenuItem value='Guatemala'>Guatemala</MenuItem>
                        <MenuItem value='Guernsey'>Guernsey</MenuItem>
                        <MenuItem value='Guinea'>Guinea</MenuItem>
                        <MenuItem value='Guinea-Bissau'>Guinea-Bissau</MenuItem>
                        <MenuItem value='Guyana'>Guyana</MenuItem>
                        <MenuItem value='Haiti'>Haiti</MenuItem>
                        <MenuItem value='Heard Island and McDonald Islands'>Heard Island and McDonald Islands</MenuItem>
                        <MenuItem value='Honduras'>Honduras</MenuItem>
                        <MenuItem value='Hong Kong SAR'>Hong Kong SAR</MenuItem>
                        <MenuItem value='Hungary'>Hungary</MenuItem>
                        <MenuItem value='Iceland'>Iceland</MenuItem>
                        <MenuItem value='India'>India</MenuItem>
                        <MenuItem value='Indonesia'>Indonesia</MenuItem>
                        <MenuItem value='Iran'>Iran</MenuItem>
                        <MenuItem value='Iraq'>Iraq</MenuItem>
                        <MenuItem value='Ireland'>Ireland</MenuItem>
                        <MenuItem value='Isle of Man'>Isle of Man</MenuItem>
                        <MenuItem value='Israel'>Israel</MenuItem>
                        <MenuItem value='Italy'>Italy</MenuItem>
                        <MenuItem value='Jamaica'>Jamaica</MenuItem>
                        <MenuItem value='Jan Mayen'>Jan Mayen</MenuItem>
                        <MenuItem value='Japan'>Japan</MenuItem>
                        <MenuItem value='Jersey'>Jersey</MenuItem>
                        <MenuItem value='Jordan'>Jordan</MenuItem>
                        <MenuItem value='Kazakhstan'>Kazakhstan</MenuItem>
                        <MenuItem value='Kenya'>Kenya</MenuItem>
                        <MenuItem value='Kiribati'>Kiribati</MenuItem>
                        <MenuItem value='Korea'>Korea</MenuItem>
                        <MenuItem value='Kosovo'>Kosovo</MenuItem>
                        <MenuItem value='Kuwait'>Kuwait</MenuItem>
                        <MenuItem value='Kyrgyzstan'>Kyrgyzstan</MenuItem>
                        <MenuItem value='Laos'>Laos</MenuItem>
                        <MenuItem value='Latvia'>Latvia</MenuItem>
                        <MenuItem value='Lebanon'>Lebanon</MenuItem>
                        <MenuItem value='Lesotho'>Lesotho</MenuItem>
                        <MenuItem value='Liberia'>Liberia</MenuItem>
                        <MenuItem value='Libya'>Libya</MenuItem>
                        <MenuItem value='Liechtenstein'>Liechtenstein</MenuItem>
                        <MenuItem value='Lithuania'>Lithuania</MenuItem>
                        <MenuItem value='Luxembourg'>Luxembourg</MenuItem>
                        <MenuItem value='Macao SAR'>Macao SAR</MenuItem>
                        <MenuItem value='Macedonia, FYRO'>Macedonia, FYRO</MenuItem>
                        <MenuItem value='Madagascar'>Madagascar</MenuItem>
                        <MenuItem value='Malawi'>Malawi</MenuItem>
                        <MenuItem value='Malaysia'>Malaysia</MenuItem>
                        <MenuItem value='Maldives'>Maldives</MenuItem>
                        <MenuItem value='Mali'>Mali</MenuItem>
                        <MenuItem value='Malta'>Malta</MenuItem>
                        <MenuItem value='Marshall Islands'>Marshall Islands</MenuItem>
                        <MenuItem value='Martinique'>Martinique</MenuItem>
                        <MenuItem value='Mauritania'>Mauritania</MenuItem>
                        <MenuItem value='Mauritius'>Mauritius</MenuItem>
                        <MenuItem value='Mayotte'>Mayotte</MenuItem>
                        <MenuItem value='Mexico'>Mexico</MenuItem>
                        <MenuItem value='Micronesia'>Micronesia</MenuItem>
                        <MenuItem value='Moldova'>Moldova</MenuItem>
                        <MenuItem value='Monaco'>Monaco</MenuItem>
                        <MenuItem value='Mongolia'>Mongolia</MenuItem>
                        <MenuItem value='Montenegro'>Montenegro</MenuItem>
                        <MenuItem value='Montserrat'>Montserrat</MenuItem>
                        <MenuItem value='Morocco'>Morocco</MenuItem>
                        <MenuItem value='Mozambique'>Mozambique</MenuItem>
                        <MenuItem value='Myanmar'>Myanmar</MenuItem>
                        <MenuItem value='Namibia'>Namibia</MenuItem>
                        <MenuItem value='Nauru'>Nauru</MenuItem>
                        <MenuItem value='Nepal'>Nepal</MenuItem>
                        <MenuItem value='Netherlands'>Netherlands</MenuItem>
                        <MenuItem value='New Caledonia'>New Caledonia</MenuItem>
                        <MenuItem value='New Zealand'>New Zealand</MenuItem>
                        <MenuItem value='Nicaragua'>Nicaragua</MenuItem>
                        <MenuItem value='Niger'>Niger</MenuItem>
                        <MenuItem value='Nigeria'>Nigeria</MenuItem>
                        <MenuItem value='Niue'>Niue</MenuItem>
                        <MenuItem value='Norfolk Island'>Norfolk Island</MenuItem>
                        <MenuItem value='North Korea'>North Korea</MenuItem>
                        <MenuItem value='Northern Mariana Islands'>Northern Mariana Islands</MenuItem>
                        <MenuItem value='Norway'>Norway</MenuItem>
                        <MenuItem value='Oman'>Oman</MenuItem>
                        <MenuItem value='Pakistan'>Pakistan</MenuItem>
                        <MenuItem value='Palau'>Palau</MenuItem>
                        <MenuItem value='Palestinian Authority'>Palestinian Authority</MenuItem>
                        <MenuItem value='Panama'>Panama</MenuItem>
                        <MenuItem value='Papua New Guinea'>Papua New Guinea</MenuItem>
                        <MenuItem value='Paraguay'>Paraguay</MenuItem>
                        <MenuItem value='Peru'>Peru</MenuItem>
                        <MenuItem value='Philippines'>Philippines</MenuItem>
                        <MenuItem value='Pitcairn Islands'>Pitcairn Islands</MenuItem>
                        <MenuItem value='Poland'>Poland</MenuItem>
                        <MenuItem value='Portugal'>Portugal</MenuItem>
                        <MenuItem value='Puerto Rico'>Puerto Rico</MenuItem>
                        <MenuItem value='Qatar'>Qatar</MenuItem>
                        <MenuItem value='Réunion'>Réunion</MenuItem>
                        <MenuItem value='Romania'>Romania</MenuItem>
                        <MenuItem value='Russia'>Russia</MenuItem>
                        <MenuItem value='Rwanda'>Rwanda</MenuItem>
                        <MenuItem value='Saba'>Saba</MenuItem>
                        <MenuItem value='Saint Barthélemy'>Saint Barthélemy</MenuItem>
                        <MenuItem value='Saint Kitts and Nevis'>Saint Kitts and Nevis</MenuItem>
                        <MenuItem value='Saint Lucia'>Saint Lucia</MenuItem>
                        <MenuItem value='Saint Martin'>Saint Martin</MenuItem>
                        <MenuItem value='Saint Pierre and Miquelon'>Saint Pierre and Miquelon</MenuItem>
                        <MenuItem value='Saint Vincent and the Grenadines'>Saint Vincent and the Grenadines</MenuItem>
                        <MenuItem value='Samoa'>Samoa</MenuItem>
                        <MenuItem value='San Marino'>San Marino</MenuItem>
                        <MenuItem value='São Tomé and Príncipe'>São Tomé and Príncipe</MenuItem>
                        <MenuItem value='Saudi Arabia'>Saudi Arabia</MenuItem>
                        <MenuItem value='Senegal'>Senegal</MenuItem>
                        <MenuItem value='Serbia'>Serbia</MenuItem>
                        <MenuItem value='Seychelles'>Seychelles</MenuItem>
                        <MenuItem value='Sierra Leone'>Sierra Leone</MenuItem>
                        <MenuItem value='Singapore'>Singapore</MenuItem>
                        <MenuItem value='Sint Eustatius'>Sint Eustatius</MenuItem>
                        <MenuItem value='Sint Maarten'>Sint Maarten</MenuItem>
                        <MenuItem value='Slovakia'>Slovakia</MenuItem>
                        <MenuItem value='Slovenia'>Slovenia</MenuItem>
                        <MenuItem value='Solomon Islands'>Solomon Islands</MenuItem>
                        <MenuItem value='Somalia'>Somalia</MenuItem>
                        <MenuItem value='South Africa'>South Africa</MenuItem>
                        <MenuItem value='South Georgia and the South Sandwich Islands'>
                          South Georgia and the South Sandwich Islands
                        </MenuItem>
                        <MenuItem value='South Sudan'>South Sudan</MenuItem>
                        <MenuItem value='Spain'>Spain</MenuItem>
                        <MenuItem value='Sri Lanka'>Sri Lanka</MenuItem>
                        <MenuItem value='St Helena, Ascension, Tristan da Cunha'>St Helena, Ascension, Tristan da Cunha</MenuItem>
                        <MenuItem value='Sudan'>Sudan</MenuItem>
                        <MenuItem value='Suriname'>Suriname</MenuItem>
                        <MenuItem value='Svalbard'>Svalbard</MenuItem>
                        <MenuItem value='Swaziland'>Swaziland</MenuItem>
                        <MenuItem value='Sweden'>Sweden</MenuItem>
                        <MenuItem value='Switzerland'>Switzerland</MenuItem>
                        <MenuItem value='Syria'>Syria</MenuItem>
                        <MenuItem value='Taiwan'>Taiwan</MenuItem>
                        <MenuItem value='Tajikistan'>Tajikistan</MenuItem>
                        <MenuItem value='Tanzania'>Tanzania</MenuItem>
                        <MenuItem value='Thailand'>Thailand</MenuItem>
                        <MenuItem value='Timor-Leste'>Timor-Leste</MenuItem>
                        <MenuItem value='Togo'>Togo</MenuItem>
                        <MenuItem value='Tokelau'>Tokelau</MenuItem>
                        <MenuItem value='Tonga'>Tonga</MenuItem>
                        <MenuItem value='Trinidad and Tobago'>Trinidad and Tobago</MenuItem>
                        <MenuItem value='Tunisia'>Tunisia</MenuItem>
                        <MenuItem value='Turkey'>Turkey</MenuItem>
                        <MenuItem value='Turkmenistan'>Turkmenistan</MenuItem>
                        <MenuItem value='Turks and Caicos Islands'>Turks and Caicos Islands</MenuItem>
                        <MenuItem value='Tuvalu'>Tuvalu</MenuItem>
                        <MenuItem value='U.S. Outlying Islands'>U.S. Outlying Islands</MenuItem>
                        <MenuItem value='U.S. Virgin Islands'>U.S. Virgin Islands</MenuItem>
                        <MenuItem value='Uganda'>Uganda</MenuItem>
                        <MenuItem value='Ukraine'>Ukraine</MenuItem>
                        <MenuItem value='United Arab Emirates'>United Arab Emirates</MenuItem>
                        <MenuItem value='United Kingdom'>United Kingdom</MenuItem>
                        <MenuItem value='United States'>United States</MenuItem>
                        <MenuItem value='Uruguay'>Uruguay</MenuItem>
                        <MenuItem value='Uzbekistan'>Uzbekistan</MenuItem>
                        <MenuItem value='Vanuatu'>Vanuatu</MenuItem>
                        <MenuItem value='Vatican City'>Vatican City</MenuItem>
                        <MenuItem value='Venezuela'>Venezuela</MenuItem>
                        <MenuItem value='Vietnam'>Vietnam</MenuItem>
                        <MenuItem value='Wallis and Futuna'>Wallis and Futuna</MenuItem>
                        <MenuItem value='Yemen'>Yemen</MenuItem>
                        <MenuItem value='Zambia'>Zambia</MenuItem>
                        <MenuItem value='Zimbabwe'>Zimbabwe</MenuItem>
                      </Select>
                    </FormControl>
                  </InputGroup>
                </div>
              </div>
            </div>
          </div>
          <div className='form-row'>
            <div className='form-group col-md-6'>
              <Button className='primary active' onClick={(e) => props.saveData()}>
                {t('actions.save')}
              </Button>
              <Button className='primary active' onClick={() => props.handleUnsubscribe(true)}>
                {t('userpages.unsubscribe')}
              </Button>
            </div>
            <div className='form-group col-md-6'>
              <Button className='primary pull-right' onClick={() => props.closeModal()}>
                {t('actions.close')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserProfileEditView;
