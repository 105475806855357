import { Skeleton } from "@mui/material";
const ProductsThermalDisinfectionSkeleton = () => {
  return (
    <div style={{ width: "100%", display: "table" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Skeleton variant="rectangular" width={250} height={45} />
        <Skeleton variant="rectangular" width={450} height={45} />
      </div>
      <div style={{ width: "100%", margin: "10px 0px" }}>
        <Skeleton variant="rectangular" height={150} />
      </div>
    </div>
  );
};

export default ProductsThermalDisinfectionSkeleton;
