import { t } from 'i18next';
import { ModalAddPackageStep1ViewProps, SelectStringOption } from '../types/ModalAddPackageTypes';
import { TextField } from '@mui/material';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import { FormGroup } from 'reactstrap';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Tooltip from 'react-bootstrap/Tooltip';
import { selectDropdownStyle } from '../../../../../config/ManagementConfig';

const renderTooltip = (text: string) => (props) =>
  (
    <Tooltip id='button-tooltip' target='{target.current}' position='fixed' {...props}>
      {text}
    </Tooltip>
  );

const ModalAddPackageStep1View = (props: ModalAddPackageStep1ViewProps) => (
  <div className='ModalAddPackageStep1View'>
    <div className='prodChildNoZoom p-5'>
      <div className='row'>
        <div className='col-12 col-md-6'>
          <h3>{t('firmwareManagementPage.packageName')}*</h3>
          <TextField
            onChange={(event) => {
              props.handleValueTextField(event.target.value, 'name');
            }}
            required
            size='small'
            fullWidth
            variant='outlined'
            value={props.name}
          />
        </div>
        <div className='col-12 col-md-3'>
          <h3>{t('firmwareManagementPage.systemId')}*</h3>
          <Select
            className='react-select-container'
            // isDisabled={this.props.package?.inUse}
            styles={selectDropdownStyle({height: 200})}
            required
            options={props.allSystemIds.map((systemId) => ({ value: systemId.id, label: systemId.id }))}
            onChange={(option) => {
              props.handleSystemId(option as SelectStringOption);
            }}
            value={{ value: props.systemId, label: props.systemId }}
          />
        </div>
        <div className='col-12 col-md-3'>
          <div className='tooltip-custom'>
            <h3>{t('firmwareManagementPage.version')}* </h3>
            <OverlayTrigger
              placement='top'
              delay={{ show: 0, hide: 400 }}
              overlay={renderTooltip(t('firmwareManagementPage.PackageVersionFormat', { format: '01.23' }))}
            >
              <div style={{ position: 'relative' }}>
                <HelpIcon className='help-icon' fontSize='medium'></HelpIcon>
              </div>
            </OverlayTrigger>
          </div>

          <TextField
            // inputProps={{ inputMode: 'numeric', pattern: '^[0-9]{1,2}[.][0-9]{2}$' }}
            onChange={(event) => {
              props.handleValueTextField(event.target.value, 'version');
            }}
            required
            size='small'
            fullWidth
            variant='outlined'
            value={props.version}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <h3>{t('forms.description')}</h3>
          <TextField
            size='small'
            fullWidth
            variant='outlined'
            onChange={(event) => {
              props.handleValueTextField(event.target.value, 'description');
            }}
            value={props.description}
          />
        </div>
      </div>
      <div className='row'>
        <div className=''>
          <FormGroup>
            <div>
              <div className='form-check pl-0' style={{ paddingTop: '35px' }}>
                <div className='flexContainer'>
                  <div className='col-12 col-md-6 d-flex' style={{ alignItems: 'center' }}>
                    <div>
                      <Form.Check
                        className='p-0 checkStep1'
                        type='checkbox'
                        label=''
                        // defaultChecked={this.package.available}
                        onChange={(event) => {
                          props.handleCheck(event.target.checked, 'available');
                        }}
                        checked={props.available}
                      />
                    </div>
                    <span className='t3 mb-0'> {t('firmwareManagementPage.available')} </span>
                  </div>
                  <div className='col-12 col-md-6 d-flex mt-4 mt-md-0' style={{ alignItems: 'center' }}>
                    <div>
                      <Form.Check
                        className='p-0 checkStep1'
                        type='checkbox'
                        label
                        // defaultChecked={this.package.production}
                        onChange={(event) => {
                          props.handleCheck(event.target.checked, 'production');
                        }}
                        checked={props.production}
                      />
                    </div>
                    <span className='t3 mb-0'> {t('firmwareManagementPage.production')} </span>
                  </div>
                </div>
              </div>
            </div>
          </FormGroup>
        </div>
      </div>
    </div>

    <div className='buttons row'>
      <div className='col'>
        <button
          onClick={() => props.handleStep('prev')}
          className='MuiButtonBase-root MuiButton-root MuiButton-text primary pull-left float-left'
          type='button'
        >
          <span className='MuiButton-label'>{t('actions.back')}</span>
          <span className='MuiTouchRipple-root'></span>
        </button>

        <button
          onClick={() => props.handleStep('next')}
          className={`${
            props.isNextStep1Disabled && 'disabled-mode'
          } MuiButtonBase-root MuiButton-root MuiButton-text primary pull-right float-right`}
          disabled={props.isNextStep1Disabled}
        >
          <span className='MuiButton-label'>{t('actions.next')}</span>
          <span className='MuiTouchRipple-root'></span>
        </button>
      </div>
    </div>
  </div>
);

export default ModalAddPackageStep1View;
