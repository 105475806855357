import { PureComponent } from 'react';
import AuthenticationContext from "../../../Authentication/types/AuthContextType";
import { WelcomeStateType } from '../types/WelcomeStateType';
import WelcomeView from '../views/WelcomeView';


class WelcomeContainer extends PureComponent<{}, WelcomeStateType> {
  static contextType = AuthenticationContext;
  constructor(props: {}) {
    super(props);
    this.state = {
      isError: false,
      error: null,
      redirect: false,
      brandLogo: '',
      brandDescription: 'Roca Smart',
      brandUser: 'roca',
      dataLoaded: false
    };
  }

  componentDidMount() {
    let isLaufenUser = (window.location.href.includes("laufen") || window.location.href.includes("localhost") );
    let brand: "roca" | "laufen" = "roca";
    let brandDescription = "Roca Smart";
    let logoUrl = "https://strgpiotcoredev.blob.core.windows.net/b2c-html/assets/images/logo_roca_white.jpg";
    if (isLaufenUser) {
      brand = "laufen";
      brandDescription = "Laufen Smart";
      logoUrl = "https://strgpiotcoredev.blob.core.windows.net/b2c-html/assets/images/logo_laufen_standard.png";
    }

    let isError = window.location.hash && window.location.hash.includes("error") ? true : false;
    let error :any | null;
    let redirect = false;

    //welcome#error=access_denied&error_description=AADB2C90091:+El+usuario+ha+cancelado+la+introducción+de+información+afirmada+por+él+mismo.
    if (window.location.hash.includes("AADB2C90091")) {
      redirect = true;
      window.location.href = "/";
    }
    if (isError) {
      let errorTitle = '';
      let errorMsg = '';
      try {
        errorTitle = window.location.hash.split('=')[1];
        errorTitle = errorTitle.split('&')[0];

        errorMsg = window.location.hash.split('%3a')[1];
        errorMsg = errorMsg.replaceAll('+', ' ');
        errorMsg = errorMsg.split('%0d%0a')[0];
      }
      catch (err) {
        errorTitle = "Error";
        errorMsg = "Error creating account";
      }

      error = errorTitle ? { message: errorTitle, stack: errorMsg } : null;
    }
    

    this.setState({ brandUser: brand, brandLogo: logoUrl, dataLoaded: true, error:error, isError: isError, redirect: redirect, brandDescription: brandDescription });
    // setTimeout(function () {
    //   this.setState({ dataLoaded: true });
    // }.bind(this), 3000)
  }

  render() {
    return (
      <>
        <WelcomeView {...this.state} />
      </>
    );
  }

}

export default WelcomeContainer;