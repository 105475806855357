import { PureComponent, ReactNode } from 'react';
import Loader from '../../components/Common/Loader';
import * as CryptoJS from 'crypto-js';
import * as msal from '@azure/msal-browser';
import _ from 'lodash';
import { cryptoConstants } from '../helpers/constants';
import HttpClient from '../../HttpClient/HttpClient';
import { MSAL_CONFIG } from '../config/MSALConfig';
import LoginContainer from './LoginContainer';
import { MSALConfigType } from '../types/AzureTypes';
import { AzureConnectState } from '../types/AzureTypes';
import Style from '../styles/AzureConnect.module.scss';

class AzureConnect extends PureComponent<{ children: ReactNode }, AzureConnectState> {
  private httpClient: HttpClient | null = null;

  constructor(props: { children: ReactNode }) {
    super(props);
    this.state = {
      loading: true,
      MSAL_CONFIG: MSAL_CONFIG as MSALConfigType,
      MSALInstance: null,
      MSALInstanceEditProfile: null,
      MSALInstanceResetPassword: null,
      MSALInstanceChangePassword: null,
      brandCode: null,
      brandName: null,
    };
  }

  componentDidMount = async () => {
    await this.loadMsalConfig();
  };

  decryptUsingAES256 = (decString: string) => {
    var decrypted = CryptoJS.AES.decrypt(decString, cryptoConstants.key, {
      keySize: 128 / 8,
      iv: cryptoConstants.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  };

  b2cConfig = async () => {
    this.httpClient = new HttpClient();
    var data = await this.httpClient.getConfigB2c();
    var decrypted = this.decryptUsingAES256(data);
    return JSON.parse(decrypted);
  };

  loadMsalConfig = async () => {
    let newMsalConfig: MSALConfigType = _.cloneDeep(this.state.MSAL_CONFIG);

    let b2c = await this.b2cConfig();

    if (b2c.brandCode === '11') {
      import('../../resources/styles/LayoutLaufen.css');
    } else {
      import('../../resources/styles/LayoutRoca.css');
    }

    newMsalConfig.scopes.push(b2c.scope);
    newMsalConfig.loginRequest.scopes.push(b2c.scope);
    newMsalConfig.tokenRequest.scopes = [...newMsalConfig.scopes];

    newMsalConfig.msalConfig.auth.clientId = b2c.clientId;
    newMsalConfig.msalConfig.auth.authority = b2c.authority + b2c.signUpSignIn;
    newMsalConfig.msalConfig.auth.knownAuthorities = [b2c.authorityDomain];
    newMsalConfig.msalConfig.auth.redirectUri = window.location.origin;

    newMsalConfig.msalConfigEditProfile.auth.clientId = b2c.clientId;
    newMsalConfig.msalConfigEditProfile.auth.authority = b2c.authority + b2c.editProfile;
    newMsalConfig.msalConfigEditProfile.auth.knownAuthorities = [b2c.authorityDomain];

    newMsalConfig.msalConfigResetPassword.auth.clientId = b2c.clientId;
    newMsalConfig.msalConfigResetPassword.auth.authority = b2c.authority + b2c.forgotPassword;
    newMsalConfig.msalConfigResetPassword.auth.knownAuthorities = [b2c.authorityDomain];

    newMsalConfig.msalConfigChangePassword.auth.clientId = b2c.clientId;
    newMsalConfig.msalConfigChangePassword.auth.authority = b2c.authority + b2c.changePassword;
    newMsalConfig.msalConfigChangePassword.auth.knownAuthorities = [b2c.authorityDomain];
    newMsalConfig.msalConfigChangePassword.auth.redirectUri = '/';

    const MSALInstance = new msal.PublicClientApplication(newMsalConfig.msalConfig);
    const MSALInstanceEditProfile = new msal.PublicClientApplication(newMsalConfig.msalConfigEditProfile);
    const MSALInstanceResetPassword = new msal.PublicClientApplication(newMsalConfig.msalConfigResetPassword);
    const MSALInstanceChangePassword = new msal.PublicClientApplication(newMsalConfig.msalConfigChangePassword);

    this.setState({
      MSAL_CONFIG: newMsalConfig,
      MSALInstance,
      MSALInstanceEditProfile,
      MSALInstanceResetPassword,
      MSALInstanceChangePassword,
      brandCode: b2c.brandCode,
      brandName: b2c.brandName,
      loading: false,
    });
  };

  renderLocation = () => {
    return (
      <section className={Style.AzureConnect}>
        {window.location.hostname.includes('-dev.') && <div className='environment'>dev</div>}
        {window.location.hostname.includes('-test.') && <div className='environment bg-test'>test</div>}
        {window.location.hostname.includes('localhost') && <div className='environment bg-local'>local</div>}
      </section>
    );
  };

  render() {
    const { loading, ...MSALCONFIG } = this.state;
    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <>
            {this.renderLocation()}
            <LoginContainer
              MSAL_CONFIG={MSALCONFIG.MSAL_CONFIG!}
              MSALInstance={MSALCONFIG.MSALInstance!}
              MSALInstanceChangePassword={MSALCONFIG.MSALInstanceChangePassword!}
              MSALInstanceEditProfile={MSALCONFIG.MSALInstanceEditProfile!}
              MSALInstanceResetPassword={MSALCONFIG.MSALInstanceResetPassword!}
              brandCode={MSALCONFIG.brandCode!}
              brandName={MSALCONFIG.brandName!}
              children={this.props.children}
            />
          </>
        )}
      </>
    );
  }
}

export default AzureConnect;
