import { Button, MenuItem, Select } from '@mui/material';
import { UserUnitsViewProps } from '../types/UserConfigUnitsTypes';
import Style from '../styles/UserUnitsView.module.scss';
import { t } from 'i18next';

const UserUnitsView = (props: UserUnitsViewProps) => {
  return (
    <div className={Style.userUnitsView}>
      <div className='container-userUnitsView'>
        <h2> {t('userpages.configUnits.title')}</h2>
        <table className='table'>
          <thead>
            <tr className='title-table'>
              <th scope='col' className='label-box'>
                {t('userpages.configUnits.name')}
              </th>
              <th scope='col' className='select-box'>
                {t('userpages.configUnits.unit')}
              </th>
              <th scope='col' className='select-box'>
                {t('userpages.configUnits.code')}
              </th>
            </tr>
          </thead>
          <tbody>
            {props.editUserMeasureUnits?.map((unit, key) => {
              const measure = props.allMeasures?.find((measure) => measure.id === unit.measureTypeId);
              const measureUnits = measure?.measureUnits;

              return (
                <>
                  {measureUnits && (
                    <tr key={key}>
                      <th scope='col' className='label-box'>
                        {unit.measureTypeName}
                      </th>
                      <th scope='col' className='select-box'>
                        <Select
                          variant='standard'
                          value={unit.measureUnitId}
                          onChange={(e) => props.postChanges(measure.id, Number(e.target.value))}
                        >
                          {measureUnits.map((unit, key) => (
                            <MenuItem key={key} value={unit.id}>
                              {unit.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </th>
                      <th scope='col' className='label-box'>
                        {unit.measureUnitCode}
                      </th>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserUnitsView;
