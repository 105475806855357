import { UserTabsContainer } from '@ComponentsRoot/UsersComponents/container/UserTabsContainer';
import UserInvitations from '../views/UserInvitations';
import { PureComponent } from 'react';

class UserInvitationsContainer extends PureComponent<{}, {}> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    return <UserInvitations />;
  }
}

export default UserInvitationsContainer;
