import React, { Component } from 'react';
import '../../../../App.css';
import DateSelector from '../../../CustomComponents/CustomDateSelector';
import KPIsChartContainer from '../../../ChartsComponents/container/KPIsChartContainer';
export class ProductsReporting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dateRange: this.props.dateRange,
            groupBySelected: this.props.groupBySelected,
        }
    }
    groupBySelected = null;
    componentDidMount() {
        document.getElementsByClassName('dateSelector').onscroll = function () {
            this.classList.toggle("notop", this.scrollTop > 90);
        }
    }


    handleGranularityChange(value) {
        this.groupBySelected = value;
    }
    handleDateRangeChange(value) {
        if (typeof this.props.onChangeDate === 'function') {
            this.props.onChangeDate(value, this.groupBySelected);
        }
        this.setState({ dateRange: value, groupBySelected: this.groupBySelected });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.chartViewZones?.content !== prevState.chartViewZones?.content) {
            return ({ chartViewZones: nextProps.chartViewZones })
        }
        return null
    }

    render() {
        let heatMapToolTip = 'tooltips.heatMapProducts';

    return (
        <React.Fragment>
            <div className='productsReporting'>
                <div className="row w-100 pt-2 m-0">                   
                <KPIsChartContainer page="product" product={this.props.product} groupBySelected={this.state.groupBySelected} dateRange={this.state.dateRange} chartViewZones={this.state.chartViewZones} pageZonePosition="Products-Reporting-Zone0" showLegend={false} tooltip={heatMapToolTip} />
                </div>
                <div className="sticky-div">
                    <DateSelector
                        style={{ justifyContent: "right" }}
                        className={"col-12 w-100 d-flex p-0"}
                        defaultGranularity={this.props.groupBySelected}
                        onDateChange={this.handleDateRangeChange.bind(this)}
                        onGranularityChange={this.handleGranularityChange.bind(this)}
                        timeframes={['custom', 'day', 'week', 'month', 'year']}
                        defaultDate={this.props.dateRange}
                    />
                </div>
                <div className="row w-100 pt-2 m-0">                    
                    <KPIsChartContainer page="product" product={this.props.product} groupBySelected={this.state.groupBySelected} dateRange={this.state.dateRange} chartViewZones={this.props.chartViewZones} pageZonePosition="Products-Reporting" showLegend={false} />
                </div>
                <KPIsChartContainer auxClassName="lastValue-group" page="product" className="InstallationReportingKPIlastValues" cols="col-3" product={this.props.product} groupBySelected={this.state.groupBySelected} dateRange={this.state.dateRange} productTelemetry={this.props.productTelemetry} chartViewZones={this.props.chartViewZones} pageZonePosition="Products-Reporting-LastValues" groupedByTitle={false} />
            </div>
        </React.Fragment>
    );
  }

}
