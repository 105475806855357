import Style from '../styles/HWHardwareView.module.scss';
import Table from '../../../../../../CustomComponents/ReactTable/Table';
import { t } from 'i18next';
import { HWHardwareViewProps } from '../types/HWHardwareTypes';
import { filter, secondaryFilter, tableColumnsHWHardware } from '../config/HWHardwareTableConfig';
import ModalAddHardwareContainer from '../container/ModalAddHardwareContainer';
import { NoSmartComponent, SystemId, SmartComponentType } from '../../../types/FirmwareTypes';
import ModalConfirmDeleteHardwareView from './ModalConfirmDeleteHardwareView';

const HWHardwareView = (props: HWHardwareViewProps) => {
  return (
    <div className={Style.HWHardwareView}>
      <div className='HWHardwareView-content'>
        <div className='card-container mt-4'>
          <div className='p-4'>
            <div className='row'>
              <div className='col'>
                <h3>{t('firmwareManagementPage.hardwareManagement')}</h3>
              </div>
              <button
                onClick={() => props.handleShowAddHWModal(true)}
                className='MuiButtonBase-root MuiButton-root MuiButton-text primary pull-right float-right'
                tabIndex={0}
                type='button'
              >
                <span className='MuiButton-label'>{t('firmwareManagementPage.addHardware')}</span>
                <span className='MuiTouchRipple-root'></span>
              </button>
            </div>
            <Table
              name={'HWHardware-table'}
              filter={filter(props.filter, props.handleChangeFilter)}
              secondaryFilter={secondaryFilter(props.secondaryFilter, props.handleChangeSecondaryFilter, props.noSmartsFiltered as NoSmartComponent[])}
              t={t}
              resize={true}
              columns={tableColumnsHWHardware(props.patchAvailableCheck, props.handleShowConfirmDeleteHWModal)}
              rows={props.noSmartsFiltered}
              page={props.page}
              pageSize={props.sizePerPage}
              onSizePerPageChange={props.onSizePerPageChange}
              onPageChange={props.onPageChange}
            />
          </div>
        </div>
      </div>

      {props.showAddHWModal && (
        <ModalAddHardwareContainer
          open={props.showAddHWModal}
          handleShowAddHWModal={props.handleShowAddHWModal}
          systemIds={props.systemIds as SystemId[]}
          allSmartComponentTypes={props.allSmartComponentTypes as SmartComponentType[]}
          refreshAllNoSmartComponents={props.refreshAllNoSmartComponents}
        />
      )}

      {props.showConfirmDeleteHWModal && (
        <ModalConfirmDeleteHardwareView
          open={props.showConfirmDeleteHWModal}
          handleShowConfirmDeleteHWModal={props.handleShowConfirmDeleteHWModal}        
          deleteHardware={props.deleteHardware} 
        />
      )}
    </div>
  );
};

export default HWHardwareView;
