import { Component } from 'react';
import '../../App.css';
import AuthenticationContext from '../../Authentication/types/AuthContextType';
import UserHttpClient from '../../HttpClient/HttpClient';
import SitesHttpClient from '../../HttpClient/SitesHttpClient';
import GatewaysHttpClient from '../../HttpClient/GatewaysHttpClient';
import InvitationsHttpClient from '../../HttpClient/InvitationsHttpClient';
import SiteInstallationGatewayTransferOwnership from '../SiteInstallationGatewayTransferOwnership';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import Send from '@mui/icons-material/Send';
import Mail from '@mui/icons-material/Mail';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Message from '../Message/Message';
import Table from '../CustomComponents/ReactTable/Table';
import { manageInfoUser } from '../Common/utils/helpers';

class TransferOwnershipGateways extends Component {
  static contextType = AuthenticationContext;
  constructor(props) {
    super(props);
    this.id = this.props.id;
    this.state = {
      gateways: {},
      invitations: {},
      numGateways: 0,
      page: 1,
      sizePerPage: 5,
      showSendTransferOwnership: false,
      message: null,
      data: {},
      gatewayId: '',
    };

    this.paginaActual = 1;

    if (this.props.userPage != null && this.props.userPage) {
      this.userPage = this.props.userPage;
    } else {
      this.userPage = false;
    }

    const { t } = props;

    this.columns = [
      {
        dataField: 'id',
        text: 'Id',
        align: 'left',
        sort: true,
        hidden: true,
        headerStyle: () => {
          return { width: '0%' };
        },
      },
      {
        dataField: 'name',
        text: t('gateway'),
        align: 'left',
        sort: true,
        headerStyle: () => {
          return { width: '20%' };
        },
      },
      {
        dataField: 'GatewayId',
        text: 'GatewayId',
        align: 'left',
        sort: true,
        hidden: true,
        headerStyle: () => {
          return { width: '0%' };
        },
      },
      {
        dataField: 'IsSendedInvitationFormatter',
        text: t('invitation.invitationSent'),
        align: 'left',
        sort: true,
        hidden: false,
        formatter: this.IsSendedInvitationFormatter.bind(this),
        headerStyle: () => {
          return { width: '20%' };
        },
      },
      {
        dataField: 'TransferOwnerShipFormatter',
        text: ' ',
        align: 'center',
        formatter: this.TransferOwnerShipFormatter.bind(this),
        headerStyle: () => {
          return { width: '12%' };
        },
      },
    ];

    this.customTotal = (from, to, size) => (
      <span className='react-bootstrap-table-pagination-total' style={{ marginLeft: '5px' }}>
        {t('tables.customTotal', { from: from, to: to, size: size })}
      </span>
    );

    this.defaultSorted = [
      {
        dataField: 'date',
        order: 'desc',
      },
    ];
  }

  renderMessage = (type, content) => {
    this.setState({
      message: {
        type: type,
        content: content,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({ message: null });
  };

  DateFormatter({ row, value }) {
    if (row.expiredAt == null) {
      return <span></span>;
    } else {
      return <span>{moment(row.expiredAt).format('DD/MM/YYYY')}</span>;
    }
  }

  IsSendedInvitationFormatter({ row, value }) {
    let idGateway = row.id;
    let invitations = this.state.invitations.content;
    let filterObj = invitations.filter(function (e) {
      return e.gatewayId == idGateway;
    });
    let out = '';
    filterObj.forEach((element) => (out += element.receiverMail + ' - ' + element.state + ' | '));
    if (out) {
      return (
        <div>
          <Mail title='Send Invitation' style={{ width: '24px' }} />
          <span>{out}</span>
        </div>
      );
    } else {
      return null;
    }
  }

  CellEmpty({ row, value }) {
    return <span></span>;
  }

  TransferOwnerShipFormatter({ row, value }) {
    const onClick = (evt) => {
      let gatewayId = evt.currentTarget.id.split('_')[1];

      this.setState({ showSendTransferOwnership: true, gatewayId: gatewayId });
    };
    let idEle = 'btnTransferOwnership_' + row.id;

    let idGateway = row.id;
    let invitations = this.state.invitations.content;
    let filterObj = invitations.filter(function (e) {
      return e.gatewayId == idGateway && e.state != 'Declined';
    });
    if (filterObj.length > 0) {
      return null;
    } else {
      return (
        <Send
          id={idEle}
          alt='Send transfer ownership Invitation'
          style={{ width: '24px', cursor: 'pointer' }}
          onClick={(evt) => onClick(evt)}
        />
      );
    }
  }

  async componentDidMount() {
    this.httpUser = new UserHttpClient(this.context);
    this.httpSite = new SitesHttpClient(this.context);
    this.httpGateway = new GatewaysHttpClient(this.context);
    this.httpInvitation = new InvitationsHttpClient(this.context);
    await this.getGateways(this.state.page, this.state.sizePerPage);
    await this.getInvitations('type=GatewayTransferOwnership', 0, 5);
  }

  handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;
    this.getGateways(page, sizePerPage);
  };

  async getGateways(page, sizePerPage) {
    this.setState({ gateways: {} });
    let me = await this.getMe();
    let data = await this.getMeOwershipGateways(me.userId, page, sizePerPage);
    this.setState({ gateways: data, numGateways: data.content.length });
  }

  async getInvitations(filter, page, sizePerPage) {
    this.setState({ invitations: {} });
    let data = await this.httpInvitation.getInvitationsSentFilter(filter, page, sizePerPage);
    this.setState({ invitations: data });
  }

  async getMe() {
    const userMe = await manageInfoUser(this.context, "userBasicData");
    if (userMe){
      return userMe;
    }else {
      this.setState({
        showSendTransferOwnership: false,
      });
    this.renderMessage('error', t('messages.errorGettingUser'));
    }
  }

  async getMeOwershipGateways(userId, page, sizePerPage) {
    const { t } = this.props;
    let data = await this.httpGateway.GetGatewaysFilter('userId=' + userId, page, sizePerPage);
    if (data.errors == null) {
      return data;
    } else {
      this.setState({ showSendTransferOwnership: false });
      this.renderMessage('error', t('messages.errorGettingGateways'));
    }
  }

  render() {
    const { t } = this.props;
    let dataLoaded = this.state.gateways.content != undefined && this.state.invitations.content != undefined;

    const pageButtonRenderer = ({ page, active, disable, title, onPageChange }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      const activeStyle = {};
      const isActive = active ? 'active' : '';
      if (active) {
        activeStyle.margin = '5px';
      } else {
        activeStyle.margin = '5px';
      }
      if (typeof page === 'string') {
        activeStyle.margin = '5px';
      }

      return (
        <li className={'page-item paginate_button ' + isActive}>
          <a href='#' className='page-link' onClick={handleClick} style={activeStyle}>
            {page}
          </a>
        </li>
      );
    };

    this.options = {
      pageButtonRenderer,
      page: this.paginaActual,
      paginationSize: this.state.sizePerPage,
      pageStartIndex: 1,
      sizePerPage: this.state.sizePerPage,
      hidePageListOnlyOnePage: true,
      showTotal: true,
      paginationTotalRenderer: this.customTotal,
      disablePageTitle: true,
      sizePerPageList: [
        {
          text: '5',
          value: 5,
        },
        {
          text: '10',
          value: 10,
        },
        {
          text: t('tables.all'),
          value: this.state.numGateways,
        },
      ],
      onSizePerPageChange: (sizePerPage, page) => {
        this.setState({ sizePerPage: sizePerPage }, () => console.log('this.state.sizePerPage' + this.state.sizePerPage));
      },
      onPageChange: (newPage) => {
        this.paginaActual = newPage;
        this.setState({ page: newPage });
      },
    };

    const handleClose = async (data) => {
      this.setState({ showSendTransferOwnership: false });
      await this.getGateways(this.state.page, this.state.sizePerPage);
      await this.getInvitations('type=GatewayTransferOwnership', 0, 5);
    };

    return (
      <div className='col' style={{ margin: '10px' }}>
        <div className='row col-12'>
          {dataLoaded ? (
            <div className='col chartContainer'>
              {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}
              <Table
                name={'TransferOwnershipGateways-locales'}
                t={t}
                resize={true}
                columns={this.columns}
                rows={this.state.gateways.content}
                search={true}
                page={this.state.page}
                pageSize={this.state.sizePerPage}
              />
              {/*<ToolkitProvider*/}
              {/*        keyField="name"*/}
              {/*        data={this.state.gateways.content}*/}
              {/*        columns={this.columns}*/}
              {/*    >*/}
              {/*        {*/}
              {/*            props => (*/}
              {/*                <div>*/}
              {/*                    <BootstrapTable bootstrap4 style={{ margin: '10px' }}*/}

              {/*                        {...props.baseProps}*/}
              {/*                        pagination={paginationFactory(this.options)}*/}
              {/*                        rowClasses="table"*/}
              {/*                        headerWrapperClasses="table"*/}
              {/*                        wrapperClasses="table-responsive"*/}
              {/*                        onTableChange={this.handleTableChange}*/}
              {/*                    />*/}
              {/*                </div>*/}
              {/*            )*/}
              {/*        }*/}
              {/*    </ToolkitProvider>*/}

              <Dialog disableEscapeKeyDown open={this.state.showSendTransferOwnership} onClose={handleClose}>
                <DialogTitle>Send transfer ownership invitation</DialogTitle>
                <DialogContent>
                  <div>
                    <SiteInstallationGatewayTransferOwnership
                      id={this.state.gatewayId}
                      onSaveClose={handleClose}
                      type='gateway'
                    />
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          ) : (
            <div className='loadingParent'>
              <div className='loadingImg'></div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(TransferOwnershipGateways);
