import Style from '../styles/ModalProdLocationZoomView.module.scss';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AutoScale2 from '../../../../AutoScale2';
import { t } from 'i18next';
import { ModalProdLocationZoomViewProps } from '../types/ProductsGeneralInfoLocation';
import { CSSProperties } from 'react';
import NoData from '../../../../NoData';

const ModalProdLocationZoomView = (props: ModalProdLocationZoomViewProps) => {
  return (
    <Modal size='lg' show={props.showModal} onHide={() => props.handleShowModal(false)} className='zoomContainer'>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className={Style.ModalProdLocationZoomView}>
        <div className='modalProdLocationZoomView-content'>
          <div className='col-12 myContainer'>
            <AutoScale2>
              <div className='modalZoomLocation'>
                <div className='col'>
                  <div className='ZoomProdModalChild'>
                    <div className='box-modal'>
                      <div className='inbox-modal'>
                        {props.placed ? (
                          <div style={props.placed as CSSProperties} id='drop' className='not-drag-drop'>
                            <div className='prodInstallationManagment centerObj'>
                              <div className={props.productConnectionState}>
                                <div className='stateDiv'>
                                  <div>
                                    <img className={'state-' + props.product.content.connectionState} src='' />
                                    <img src={props.iconUri} />
                                    <span>
                                      {props.letter}
                                      {props.product.content.tag}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {props.floorPlanUri ? (
                          <canvas
                            id='inner-dropzone'
                            className='dropzone'
                            style={{
                              backgroundImage: `url('${props.floorPlanUri}')`,
                            }}
                          ></canvas>
                        ) : (
                          <div className='noData-box'>
                            <NoData />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </AutoScale2>
          </div>
          <div className='col-12 pt-4 pr-4 pl-4 '>
            {props.product.content.puuid ? (
              <h6 className='mb-1'>
                {t('productspages.general.productid')}: <span className='t2'>{props.product.content.puuid}</span>
              </h6>
            ) : null}
            {props.product.content.productType.brand ? (
              <h6 className='mb-1'>
                {t('productspages.general.brand')}: <span className='t2'>{props.product.content.productType.brand}</span>{' '}
              </h6>
            ) : null}
            {props.product.content.productType.name ? (
              <h6 className='mb-1'>
                {t('productspages.general.producttype')}: <span className='t2'>{props.product.content.productType.name}</span>
              </h6>
            ) : null}
            {props.product.content.installation ? (
              <h6 className='mb-1'>
                {t('installation')}: <span className='t2'>{props.product.content.installation.name}</span>{' '}
              </h6>
            ) : null}
            {props.product.content.gatewayName ? (
              <h6 className='mb-1'>
                {t('productspages.general.gatewayname')}: <span className='t2'>{props.product.content.gatewayName}</span>{' '}
              </h6>
            ) : null}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className='primary' onClick={() => props.handleShowModal(false)}>
          {t('actions.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalProdLocationZoomView;
