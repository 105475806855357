import axios from "axios";
import i18n from '../i18n/i18n';

export default class HttpClient {


    constructor(context) {
        this.context = context;
        this.language = i18n.language;
        this.debug = false        
    }

    getRoles = async () => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.get("invitations/" + this.language + "/roles", config);
        if (this.debug) {
            console.log("calling: " + "invitations/" + this.language + "/Roles");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data

    }

    getRequestsSent = async (page, pagesize) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.get("invitations/sent/" + this.language + "/requests/"+ page + "/" + pagesize, config);
        if (this.debug) {
            console.log("calling getInvitations: invitations/sent/" + this.language + "/" + "invitations/requests/" + page + "/" + pagesize);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }
    getRequestsSentInstallation = async (id, page, pagesize) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.get("invitations/sent/" + this.language + "/installation/requests/" + id + "/" + page + "/" + pagesize, config);
        if (this.debug) {
            console.log("calling getInvitations: invitations/sent/" + this.language + "/" + "invitations/requests/" + page + "/" + pagesize);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    getRequestsReceivedFilter = async (page, pagesize,filter) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.get("invitations/received/" + this.language + "/requests/"+ page + "/" + pagesize+ "/" + filter, config);
        if (this.debug) {
            console.log("calling getInvitations: invitations/received/" + this.language + "/" + "invitations/requests/" + page + "/" + pagesize+ "/" + filter);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    getRequestsReceived = async (page, pagesize) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.get("invitations/received/" + this.language + "/requests/"+ page + "/" + pagesize, config);
        if (this.debug) {
            console.log("calling getInvitations: invitations/received/" + this.language + "/" + "invitations/requests/" + page + "/" + pagesize);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }
    getRequestsReceivedInstallation = async (id, page, pagesize) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.get("invitations/received/" + this.language + "/installation/requests/" + id + "/" + page + "/" + pagesize, config);
        if (this.debug) {
            console.log("calling getInvitations: invitations/received/" + this.language + "/" + "invitations/requests/" + page + "/" + pagesize);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    deleteRequest = async (idInvitation) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }

        var response = await axios.delete("invitations/" + this.language + "/request/" + idInvitation, config);
        if (this.debug) {
            console.log("calling: " + this.language + "/" + idInvitation);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    AcceptRequest = async (idInvitation) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "application/json",
            }
        }
        let patch = {};
        patch.op = "replace";
        patch.path = "/state";
        patch.value = "Accepted";
        let listPatch = [];
        listPatch.push(patch);
        var response = await axios.patch("invitations/" + this.language + "/request/" + idInvitation, JSON.stringify(listPatch), config);
        if (this.debug) {
            console.log("calling: " + this.language + "/" + idInvitation);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    RejectRequest = async (idInvitation) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "application/json",
            }
        }
        let patch = {};
        patch.op = "replace";
        patch.path = "/state";
        patch.value = "Declined";
        let listPatch = [];
        listPatch.push(patch);
        var response = await axios.patch("invitations/" + this.language + "/request/" + idInvitation, listPatch, config);
        if (this.debug) {
            console.log("calling: " + this.language + "/" + idInvitation);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }


    PostRequest = async (elements) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }

        var response;
        try {
            response = await axios.post("invitations/" + this.language + "/requests/", elements, config);
            if (this.debug) {
                console.log("calling: invitations/" + this.language + "/requests/");
                console.log("response: " + JSON.stringify(response));
            }
        }
        catch (e) {
            response = e.response;
        }

        return response.data
    }


    getInvitationsReceivedInstallation = async (id,page,pagesize) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.get("invitations/received/" + this.language + "/installation/" + id + "/" + page + "/" + pagesize, config);
        if (this.debug) {
            console.log("calling getInvitations: invitations/received/" + this.language + "/installation/" + id + "/" + page + "/" + pagesize);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    getInvitationsReceived = async (page,pagesize) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.get("invitations/received/" + this.language + "/" + page + "/" + pagesize, config);
        if (this.debug) {
            console.log("calling getInvitations: invitations/received/" + this.language + "/" + page + "/" + pagesize);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    getInvitationsReceivedFilter = async (page,pagesize,filter) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("invitations/received/" + this.language + "/" + page + "/" + pagesize+ "/" + filter, config);
        if (this.debug) {
            console.log("calling getInvitations: invitations/received/" + this.language + "/" + page + "/" + pagesize + "/" + filter);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }
    

    getInvitationsSentInstallation = async (id,page,pagesize) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.get("invitations/sent/" + this.language + "/installation/" + id + "/" + page + "/" + pagesize, config);
        if (this.debug) {
            console.log("calling getInvitations: invitations/sent/" + this.language + "/installation/" + id + "/" + page + "/" + pagesize);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    getInvitationsSent = async (page,pagesize) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.get("invitations/sent/" + this.language + "/" + page + "/" + pagesize, config);
        if (this.debug) {
            console.log("calling getInvitations: invitations/sent/" + this.language + "/" + page + "/" + pagesize);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    getInvitationsSentFilter = async (filter,page,pagesize) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.get("invitations/sent/" + this.language + "/" + page + "/" + pagesize+"/"+filter, config);
        if (this.debug) {
            console.log("calling getInvitations: invitations/sent/" + this.language + "/" + page + "/" + pagesize+"/"+filter);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    deleteInvitation = async (idInvitation) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }

        var response = await axios.delete("invitations/" + this.language + "/" + idInvitation, config);
        if (this.debug) {
            console.log("calling: " + this.language + "/" + idInvitation);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    AcceptInvitation = async (idInvitation) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "application/json",
            }
        }
        let patch = {};
        patch.op = "replace";
        patch.path = "/state";
        patch.value = "Accepted";
        let listPatch = [];
        listPatch.push(patch);
        var response = await axios.patch("invitations/" + this.language + "/" + idInvitation, JSON.stringify(listPatch), config);
        if (this.debug) {
            console.log("calling: " + this.language + "/" + idInvitation);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    RejectInvitation = async (idInvitation) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "application/json",
            }
        }
        let patch = {};
        patch.op = "replace";
        patch.path = "/state";
        patch.value = "Declined";
        let listPatch = [];
        listPatch.push(patch);
        var response = await axios.patch("invitations/" + this.language + "/" + idInvitation, listPatch, config);
        if (this.debug) {
            console.log("calling: " + this.language + "/" + idInvitation);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    PostInvitation = async (elements) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }

        var response;
        try {
            response = await axios.post("invitations/" + this.language + "/", elements, config);
            if (this.debug) {
                console.log("calling: invitations/" + this.language + "/");
                console.log("response: " + JSON.stringify(response));
            }
        }
        catch (e) {
            response = e.response;
        }

        return response.data
    }

}