import moment from "moment";
import { GeneralNavMenuViewProps } from "../types/GeneralNavMenuType";
import { WithTranslation } from "react-i18next";
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

const DropdownSites = (props: GeneralNavMenuViewProps & WithTranslation) => {

    let dataLoaded = props.mySites.content != undefined;

    let urls = props.urlsMenu
    if (dataLoaded) {
        let listitems = props.mySites.content.map((item, i) => {
            if (i < 4) {
                let img = "";
                if (props.isLaufen) {
                    img = item.avatarUri ? item.avatarUri : "https://strgpiotcoredev.blob.core.windows.net/web-html/laufen/DefaultSite.jpg"
                } else {
                    img = item.avatarUri ? item.avatarUri : "https://strgpiotcoredev.blob.core.windows.net/web-html/roca/DefaultSite.jpg"
                }


                let address = ""

                if (item.address && item.address != "") {
                    address += item.address
                }
                if (item.postalCode && item.postalCode != "") {
                    if (address.length > 1) address += " - "
                    address += item.postalCode
                }
                if (item.city && item.city != "") {
                    if (address.length > 1) address += ", "
                    address += item.city
                }
                if (item.country && item.country != "") {
                    if (address.length > 1) address += ", "
                    address += item.country
                }

                let itemBody = <div className="row installationRow">
                    <div className="col-2">
                        <div className="menuIcon" style={{ backgroundImage: "url('" + img + "')" }} > </div>
                    </div>
                    <div className="col-8" style={{ display: "flex", alignItems: "center" }}>
                        {
                            (!item.hasOwnerAccess && (item.hasAccessExpirationAt != null && new Date(item.hasAccessExpirationAt) < new Date())) ?
                                (
                                    <div className="col-8">
                                        <div className="menuSpan menuInstallationTitle noUnderLine "><h4 style={{ float: 'left' }}>{item.name}</h4></div>
                                        <div className="menuSpan t3"> <ReportProblemOutlinedIcon /> {t('errors.expiredAt') + moment(item.hasAccessExpirationAt).format("DD/MM/YYYY")} </div>
                                    </div>
                                ) : (
                                    <div className="col-8">
                                        <div className="menuSpan menuInstallationTitle noUnderLine "><h4 style={{ float: 'left' }}>{item.name}</h4></div>
                                        <div className="menuSpan t3 d-none"> {item.description} </div>
                                        <div className="menuSpan t3 d-none"> {address} </div>
                                    </div>
                                )
                        }

                    </div>
                    <div className="col-2" style={{ padding: 0, display: "flex", alignItems: "center", paddingRight: "15px" }}>
                        <div style={{ flexBasis: "100%" }} className="magaItemsParent left" >
                            <div className="t3" style={{ width: '100%', marginBottom: 0 }}> {item.aggregatedInfo.totalDevices} </div>
                            <img src={require('../../../assets/icons/11_00.03_black.svg').default} />
                        </div>

                        <div style={{ flexBasis: "100%", textAlign: "center" }} className="magaItemsParent">
                            <div className="t3" style={{ width: '100%', marginBottom: 0 }}> {item.aggregatedInfo.totalUsers} </div>
                            <img src={require('../../../assets/icons/PersonOutlineIcon.svg').default} />

                        </div>

                        <div style={{ flexBasis: "100%", textAlign: "right" }} className="magaItemsParent right">
                            <div className="t3" style={{ width: '100%', float: 'right', marginBottom: 0 }}> {item.aggregatedInfo.gatewayDevices} </div>
                            <img src={require('../../../assets/icons/11_00.31_black.svg').default} />
                        </div>
                    </div>
                </div>
                if (!item.hasOwnerAccess && (item.hasAccessExpirationAt != null && new Date(item.hasAccessExpirationAt) < new Date())) {
                    return (
                        <div key={"div-" + item.id} className="disabledInstallationRow">
                            {itemBody}
                        </div>
                    )
                }
                else {
                    return (
                        <Link key={"div-" + item.id} style={{ textDecoration: "none" }} onClick={() => { props.showMenu(0) }}  to={`/site/${item.id}`}>
                            {itemBody}
                        </Link>
                    )
                }
            }
        })


        return (
            <div className="row ">
                <div className="col-8 header-top">
                    <div style={{ marginLeft: '20%' }}>
                        <h2 style={{marginTop: '25px', padding: '0px'}}>{t('recentlyViewed')}</h2>
                    </div>
                    <div style={{ marginLeft: '20%' }}>
                        {listitems}
                    </div>
                </div>

                <div className="col-4" style={{ margin: 0, padding: 0 }} >
                    <div className="header-top mb-0" style={{ margin: '24px', padding: 0, backgroundImage: 'url("' + urls.imageMenuSites + '")', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain' }} ></div>
                    <div className="d-flex" style={{ justifyContent: "center" }}>  <Link style={{ textDecoration: "none" }} className="m2 linkGoTo" to={`/sites`}> {t('actions.gotoSites')}  <img src={require('../../../assets/icons/arrow_right-24.svg').default} /> </Link> </div>
                </div>

            </div>
        )
    } else {
        return <div></div>
    }
};

export default DropdownSites;