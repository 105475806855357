import axios from "axios";
import i18n from '../i18n/i18n';

export default class HttpClient {


    constructor(context, id, kpiName, granularity, groupBy,page, pagesize) {
        this.context = context;
        this.productID = id;
        this.kpiName = kpiName;
        this.granularity = granularity;
        this.groupBy = groupBy;
        this.language = i18n.language;
        this.debug = false; 
        this.page = page;
        this.pagesize = pagesize;
    }

    addTimezone = (timeStamp, timeZoneOffset) => {
        const sign = timeZoneOffset.includes('-') ? 'min' : 'plus';
        return `${timeStamp}${sign}${(timeZoneOffset.replace('+','').replace('-','')).substring(0, 5)}`;
    };

    ProductsGetProduct = async () => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        try {
            var response = await axios.get("products/" + this.language + "/" + this.productID, config);
            if (this.debug) {
                console.log("calling: " + "products/" + this.language + "/" + this.productID);
                console.log("response: " + JSON.stringify(response));
            }
            return response;
        } catch (e) {
            return e.response;
        }
    }
    GetTypeInformation = async (productType) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        try {
            var response = await axios.get("products/" + this.language + "/types/" + productType, config);
            if (this.debug) {
                console.log("calling: " + "products/" + this.language + "/" + productType);
                console.log("response: " + JSON.stringify(response));
            }
            return response;
        } catch (e) {
            return e.response;
        }
    }

    ProductsGetTelemetry = async () => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        try {
            var response = await axios.get("products/" + this.language + "/" + this.productID + "/telemetry", config);
            if (this.debug) {
                console.log("calling: " + "products/" + this.language + "/" + this.productID + "/telemetry");
                console.log("response: " + JSON.stringify(response));
            }
            return response;
        }
        catch (e) {
            return e.response;
        }
    }

    ProductsGetParametersViewZones = async () => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        try {
            var response = await axios.get("products/" + this.language + "/" + this.productID + "/viewparameterszone", config);
            if (this.debug) {
                console.log("calling: " + "products/" + this.language + "/" + this.productID + "/viewparameterszone");
                console.log("response: " + JSON.stringify(response));
            }
            return response;

        } catch (e) {
            return e.response;
        }

    }

    ProductsGetHistoricalAlerts = async () => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response;
        try {
            response = await axios.get("products/" + this.language + "/" + this.productID + "/historicalalerts/" + this.page + "/" + this.pagesize, config);
            if (this.debug) {
                console.log("calling ProductsGetParametertsUser: " + "products/" + this.language + "/" + this.productID + "/historicalalerts/" + this.page + "/" + this.pagesize);
                console.log("response: " + JSON.stringify(response));
            }
        } catch (e) {
            response = e.response;
        }


        return response.data
    }

    ProductsUploadFile= async (data) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }

        var response = await axios.post("products/" + this.language + "/uploadFile", data, config);
        if (this.debug) {
            console.log("calling ProductsUploadFile: " + "products/" + this.language + "/uploadFile");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    ProductAlertChecked = async (id, state) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`
            }
        }

        var data = { id: parseInt(id), name: "", code: "", date: "", timeactive: "", checked: state };
        var response = await axios.put("products/" + this.language + "/" + this.productID + "/alertchecked/", data, config);
        if (this.debug) {
            console.log("calling ProductAlertChecked: " + "products/" + this.language + "/" + this.productID + "/alertchecked/");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    IndividualRuleChecked = async (id, state) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`
            }
        }

        var data = { id: parseInt(id), name: "", code: "", date: "", timeactive: "", checked: state };
        var response = await axios.put("products/" + this.language + "/" + this.productID + "/individualRuleChecked/", data, config);
        if (this.debug) {
            console.log("calling ProductAlertChecked: " + "products/" + this.language + "/" + this.productID + "/alertchecked/");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    ProductsGetMyProducts = async () => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("products/" + this.language, config);
        if (this.debug) {
            console.log("calling: " + "products/" + this.language);
            console.log("response: " + JSON.stringify(response));
        }

        if (response) {
            if(response?.data?.content){
                response.data.content.forEach((i) => { i.productType.name = i.productType.name ?? ""; i.productType.letter = i.productType.letter ?? ""; i.tag = i.tag ?? 0 })
                response.data.content = response.data.content.sort((a, b) => a.productType.name.localeCompare(b.productType.name) || a.productType.letter.localeCompare(b.productType.letter) || a.tag - b.tag || a.installation.name.localeCompare(b.installation.name));
            }           
        }

        return response.data

    }

    ProductsGetMyProductsMenu = async () => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("products/" + this.language+"/menu", config);
        if (this.debug) {
            console.log("calling: " + "products/" + this.language);
            console.log("response: " + JSON.stringify(response));
        }

        if (response) {
            if(response?.data?.content){
                response.data.content.forEach((i) => { i.productType.name = i.productType.name ?? ""; i.productType.letter = i.productType.letter ?? ""; i.tag = i.tag ?? 0 })
            }
        }

        return response.data

    }

    ProductsGetSystems = async () => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("products/" + this.language + "/systems", config);
        if (this.debug) {
            console.log("calling: " + "products/systems" + this.language);
            console.log("response: " + JSON.stringify(response));
        }

        return response.data

    }

    ProductKPIDatapoints = async () => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("products/" + this.language + "/" + this.productID + "/data-points/" + this.kpiName + "/" + this.granularity + "/" + this.groupBy, config);
        if (this.debug) {
            console.log("calling ProductsGetParametertsUser: " + "products/" + this.language + "/" + this.productID + "/" + this.kpiName + "/" + this.granularity + "/" + this.groupBy + "/data-points");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data

    }

    PatchInstallationsProductsLocation = async (elements) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }

        var response = await axios.post("products/" + this.language + "/" + this.productID + "/productsposition/", elements, config);
        if (this.debug) {
            console.log("calling: products/" + this.language + "/" + this.productID + "/productsposition/");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    PatchProduct = async (elements) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }

        var response = await axios.post("products/" + this.language + "/" + this.productID + "/updateproduct/", elements, config);
        if (this.debug) {
            console.log("calling: products/" + this.language + "/" + this.productID + "/updateproduct/");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    DeleteProduct = async (id, puuid) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }
        var response;
        try {
            var body = {
                name: puuid
            }
            response = await axios.delete("products/" + this.language + "/" + id, {
                headers: {
                    Authorization: `${accessToken.accessToken}`
                },
                data: body
            });
            if (this.debug) {
                console.log("calling: products/" + this.language + "/" + id);
                console.log("response: " + JSON.stringify(response));
            }
        } catch (e) {
            response = e.response;
        }

        return response.data
    }

    DirectMethod = async (elements) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }
        try {
            var response = await axios.post("products/" + this.language + "/direct-method", elements, config);
            if (this.debug) {
                console.log("calling: products/" + this.language + "/direct-method/");
                console.log("response: " + JSON.stringify(response));
            }
        } catch (e) {
            response = e.response;
        }

        return response.data
    }

    CloudToDevice = async (elements) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }
        try {
            var response = await axios.post("products/" + this.language + "/cloud-to-device", elements, config);
            if (this.debug) {
                console.log("calling: products/" + this.language + "/cloud-to-device/");
                console.log("response: " + JSON.stringify(response));
            }
        } catch (e) {
            response = e.response;
        }

        return response.data
    }

    ThermalDisinfectionCertificate = async (startTimeStamp, endTimeStamp, brand, zeroValues, timeZone) => {
        const accessToken = await this.context.getToken();      
        const config = {
          headers: {
            Authorization: `${accessToken.accessToken}`
          }
        };      
        const url = `products/${this.language}/${this.productID}/thermal-disinfection-certificate/${this.addTimezone(startTimeStamp, timeZone)}/${this.addTimezone(endTimeStamp, timeZone)}/${brand}/${zeroValues}`;
      
        try {
          const response = await axios.get(url, config);      
          if (this.debug) {
            console.log("calling ThermalDisinfectionCertificate: " + url);
            console.log("response: " + JSON.stringify(response));
          }      
          return response.data;
        } catch (e) {
          return e.response;
        }
      }
      


    SendFirmwarePackageCampaign = async (packageId, backUpPackageId, numberOfRetries, name = null, description = null) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`
            }
        }
        let body = {
            ProductId: this.productID,
            smartComponentFirmwarePackageId: packageId,
            smartComponentFirmwareCampaignBackUpId: backUpPackageId,
            numberOfRetries: numberOfRetries,
            name: name,
            description: description
        }

        var response = await axios.post("products/" + this.language + "/" + this.productID + "/SendFirmwarePackageCampaign", body, config);
        if (this.debug) {
            console.log("calling ThermalDisinfectionCertificate: " + "products/" + this.language + "/" + this.productID + "/SendFirmwarePackageCampaign");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    GetAllSmartComponentTypes = async () => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("products/" + this.language + "/smartcomponenttypes", config);
        if (this.debug) {
            console.log("calling: " + "products/systems" + this.language);
            console.log("response: " + JSON.stringify(response));
        }

        return response.data

    }
}