import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';
import '../../App.css';
import '../ProductsComponents/products.css';
import AuthenticationContext from "../../Authentication/types/AuthContextType";
import InstallationHttpClient from "../../HttpClient/InstallationsHttpClient";
import ProductHttpClient from "../../HttpClient/ProductHttpClient";
import SitesHttpClient from "../../HttpClient/SitesHttpClient";
import BreadCrumb from "../BreadCrumb";


class FilterProducts extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.Laufen = false;
        this.state = {
            selectedSiteIndex: null,
            selectedInstallationIndex: null,
            selectedProductIndex: null,
            search: "",
            redirect: false,
            mySites: [],
            myInstallations: [],
            myProducts: [],
            selectedSites: [],
            selectedInstallations: [],
            filteredSites: [],
            filteredInstallations: [],
            filteredProducts: [],
            filteredProductsMenu:[],
            filteredInstallationsMenu: [],
            actualMenuFilter:"",
            showMenu: false,
        };
    }
    
    async componentDidMount() {
        this.http = new ProductHttpClient(this.context);
        this.httpInstallation = new InstallationHttpClient(this.context);
        this.httpSites = new SitesHttpClient(this.context);
        await this.getMyProducts();
        await this.getMySites();
        await this.getMyInstallations();
        this.searchUrl();
        
    }
    searchUrl() {
        const queryString = require('query-string').default;
        const parsed = queryString.parse(this.props.location.search);
        if (parsed.s === undefined) {
            parsed.s = "";
        }
        this.setState({ search: parsed.s });
        
    }


    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    
    async getMyProducts() {
        var datos = await this.http.ProductsGetMyProducts();
        this.setState({ myProducts: datos });
        this.setState({ filteredProducts: datos.content });

        var uniqueProdType = [];
        datos.content.map(prod => {
           
           if (uniqueProdType.findIndex(obj => obj.productType.id === prod.productType.id ) === -1) {
            uniqueProdType.push(prod);
            }
        });
    
        this.generalFilter();
        let filteredMenu = this.state.filteredProducts.filter((ele, ind) => ind === this.state.filteredProducts.findIndex(elem => elem.productType.brand === ele.productType.brand && elem.productType.name === ele.productType.name))
        this.setState({ filteredProductsMenu: filteredMenu });

    }
    async getMyInstallations() {
        var datos = await this.httpInstallation.InstallationGetInstallations();
        this.setState({ myInstallations: datos.content });
        this.setState({ filteredInstallations: datos.content });

        this.generalFilter();

        let tree = this.listToTree(this.state.filteredInstallations.sort((a, b) => (a.id - b.id || a.parentId - b.parentId)), {});
        this.setState({ tree: tree });

        this.setState({ filteredInstallationsMenu: this.state.filteredInstallations });

    }
    async getMySites() {
        var datos = await this.httpSites.GetSites();
        this.setState({ mySites: datos });
        this.setState({ filteredSites: datos.content });
    }
    
    filterInstallations(selectSites) {
        var actualInstal = this.state.myInstallations.filter(
            function _(instal) {
                var equalsSiteID = false;
                if (selectSites.length == 0) equalsSiteID = true;
                selectSites.map((siteID) => {
                    if (instal.siteId == siteID) equalsSiteID = true;
                });
                return equalsSiteID;
            }
        );
        this.setState({ filteredInstallations: actualInstal })
    }
    
    filterProducts(list){
        var inputVal = "";
        if (this.state.search != undefined) {
            inputVal = this.state.search.toLowerCase();
        }
        let ret =  list.filter(
            function _(prod) {
                var equals = false;
                try {

                    if (prod.productType.letter != null) {
                        if (prod.productType.letter.toLowerCase().includes(inputVal)) {
                            return true;
                        }
                        if (prod.productType.letter.toLowerCase() + prod.id == inputVal) {
                            return true;
                        }
                    }
                    if (prod.designLine != null) {
                        if (prod.designLine.toLowerCase().includes(inputVal)) {
                            return true;
                        }
                    }
                    if (prod.productType.name != null) {
                        if (prod.productType.name.toLowerCase().includes(inputVal)) {
                            return true;
                        }
                    }
                    if (prod.productType.sku != null) {
                        if (prod.productType.sku.toLowerCase().includes(inputVal)) {
                            return true;
                        }
                    }
                    if (prod.installation.name != null) {
                        if (prod.installation.name.toLowerCase().includes(inputVal)) {
                            return true;
                        }
                    }
                    if (prod.name != null) {
                        if (prod.name.toLowerCase().includes(inputVal)) {
                            return true;
                        }
                    }

                    if (prod.puuid != null) {
                        if (prod.puuid.toLowerCase().includes(inputVal)) {
                            return true;
                        }
                    }

                } catch (error) {
                    return false;
                }

                return false;
            }
        );
        return ret;
    }
    filterAllProducts(hidden, id = "", menu = false) {
        var actualProds = [];
        if(hidden){
            this.setState({ filteredProducts: [] });
            
        }else  if(this.state.myProducts.content != undefined){
            var inputVal = "";
            if (this.state.search != undefined) {
                inputVal = this.state.search.toLowerCase();
            }

            actualProds = this.filterProducts(this.state.myProducts.content);
           
            if(id != ""){
                //id.split('-')[1]
                var porductsByBrand = actualProds.filter((elem, index) => elem.productType.brand == id.split('$$')[0] && elem.productType.name == id.split('$$')[1] );
                this.setState({ filteredProducts: porductsByBrand });
                actualProds = porductsByBrand;
            }else{
                this.setState({ filteredProducts: actualProds });
            }
        }
        if (menu) {
            let filteredMenu = actualProds.filter((ele, ind) => ind === actualProds.findIndex(elem => elem.productType.brand === ele.productType.brand && elem.productType.name === ele.productType.name))
            this.setState({ filteredProductsMenu: filteredMenu });
        }
        
    }
    filterAllInstallations(hidden, id = -1,menu = false){
        var actualInstal = [];
        if (hidden) {
            this.setState({ filteredInstallations: [] });
        }else if(this.state.mySites.content != undefined){
           
            var inputVal = "";
            if (this.state.search != undefined) {
                inputVal = this.state.search.toLowerCase();
            }
            actualInstal = this.state.myInstallations.filter(
                function _(instal) {
                    var equals = false;
                    if (instal.name != null) {
                        if (instal.name.toLowerCase().includes(inputVal)) {
                            return true;
                        }
                        if(instal.products.length > 0 &&  instal.products != undefined){
                          
                            let prods = instal.products.filter(
                                function _(prod) {
                                    if (prod.name != null) {
                                        if (prod.name.toLowerCase().includes(inputVal)) {
                                           
                                            return true;
                                        }
                                    }
        
                                    if (prod.puuid != null) {
                                        if (prod.puuid.toLowerCase().includes(inputVal)) {
                                            return true;
                                        }
                                    }
                                    if (prod.description != null) {
                                        if (prod.description.toLowerCase().includes(inputVal)) {
                                            return true;
                                        }
                                    }
                                    try{
                                        if (prod.productType.letter != null) {
                                            if (prod.productType.letter.toLowerCase().includes(inputVal)) {
                                                return true;
                                            }
                                            if (prod.productType.letter.toLowerCase() + prod.id == inputVal) {
                                                return true;
                                            }
                                        }
                                        if (prod.designLine != null) {
                                            if (prod.designLine.toLowerCase().includes(inputVal)) {
                                                return true;
                                            }
                                        }
                                        if (prod.productType.name != null) {
                                            if (prod.productType.name.toLowerCase().includes(inputVal)) {
                                                return true;
                                            }
                                        }
                                        if (prod.productType.sku != null) {
                                            if (prod.productType.sku.toLowerCase().includes(inputVal)) {
                                                return true;
                                            }
                                        }
                                    }catch(error){
                                        //console.log(error);
                                    }
                                }
                            );
                          if(prods.length > 0){
                              return true;
                          }
                           

                        }
                        
                    }
                    

                    return false;
                }
            );
            if(id > -1){
                var installations = actualInstal.filter((elem, index) => elem.id == id || elem.parentId == id);
                actualInstal = installations;
                this.setState({ filteredInstallations: installations });
            }else{
                this.setState({ filteredInstallations: actualInstal });
            }
            
        }
        if (menu) {
            let tree = this.listToTree(actualInstal.sort((a, b) => (a.id - b.id || a.parentId - b.parentId)), {});
            this.setState({ tree: tree });
            this.setState({ filteredInstallationsMenu: actualInstal });
        }
        
    }
    setActiveSites() {
        this.state.mySites.content.map((site) => {
            document.getElementById("site-" + site.id).className = "list-group-item list-group-item-action";
            this.state.selectedSites.map((selectedSite) => {
                if (site.id == selectedSite) document.getElementById("site-" + site.id).className = "list-group-item list-group-item-action active";
            });
        });
        this.filterInstallations(this.state.selectedSites);
        this.setState({ selectedInstallations: [] });
        this.filterProducts(this.state.selectedInstallations);
        document.getElementById("installationsNameFilter").value = "";
        this.state.myInstallations.map((instal) => {
            var elem = document.getElementById("installation-" + instal.id);
            if (elem) elem.className = "list-group-item list-group-item-action";
        });
    }

    setActiveInstallations() {
        this.state.myInstallations.map((instal) => {
            document.getElementById("installation-"+instal.id).className = "list-group-item list-group-item-action";
            this.state.selectedInstallations.map((selectedInstal) => {
                if (instal.id == selectedInstal) document.getElementById("installation-"+instal.id).className = "list-group-item list-group-item-action active";
            });
        });
        this.filterProducts(this.state.selectedInstallations);
    }

    generalFilter(target = "",menu = false) {

        var inputVal = "";
        if (this.state.search != undefined) {
            inputVal = this.state.search.toLowerCase();
        }
        if( target.split('-')[0]  == "prod"){
                
            this.filterAllProducts( false, target.split('-')[1]);
            this.filterAllInstallations(true);

        }else if( target.split('-')[0]  == "instal"){
            this.filterAllInstallations(false, target.split('-')[1]);
            this.filterAllProducts(true);
            
        }else if( target == "sectionAINSTALLATION"){
            this.filterAllProducts(true);
            this.filterAllInstallations(false);
        }else if( target == "sectionAPRODUCTS"){
            this.filterAllProducts(false);
            this.filterAllInstallations(true);
        }else{
            this.filterAllProducts(false,"",menu);
            this.filterAllInstallations(false,-1,menu);
        }

        
    }

    listToTree(list,) {

        var map = {}, node, roots = [], i;

        for (i = 0; i < list.length; i += 1) {
            map[list[i].id] = i; // initialize the map
            list[i].children1 = []; // initialize the children
        }

        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            if (node.parentId != 0) {
                if (map[node.parentId] != undefined) {
                    list[map[node.parentId]].children1.push(node);
                } else {
                    roots.push(node);
                }

            } else {
                roots.push(node);
            }
        }
        return roots;

    }

    createTree(data, isSub, lev) {
        let level = lev || 0;
        let children = [];
        if (isSub) {
            if (level < 3) // To prevent too much padding
                level++;
        }
        for (let i in data) {
            if (typeof (data[i].children1) === 'object') {
                if (data[i].children1 != []) {
                    children.push(
                        <li className={"filter-group level-" + (level)}>
                            <a id={"instal-" + data[i].id} onClick={this.filterMenu(null)} href='javascript.void(0)'
                                className={this.state.actualMenuFilter == "instal-" + data[i].id ? 'active t3' : 't3'}
                            >{data[i].name} </a>
                            {this.createTree(data[i].children1, true, level)}
                        </li>
                    );
                }

            }
        }
        return children;
    } 
    filterMenu = (parameter) => (e) => {
        e.preventDefault();
        let target = e.target.id;
        if (target == this.state.actualMenuFilter) {
            target = "";
        }
        if (parameter != null) {
            target = parameter;
        }
        this.setState({ actualMenuFilter: target });
        this.state.actualMenuFilter = target;
        this.generalFilter(target);

    }
    render() {
        this.Laufen = this.context.brandCode == 11;
        const {t} = this.props;
        let searchTerm ="";
        let dataLoadedInstal = this.state.mySites.content != undefined;
        let dataLoadedProds = this.state.myProducts.content != undefined;
        
        let imageInstallation = this.Laufen ? "https://strgpiotcoredev.blob.core.windows.net/pictos-by-kpi/11_LAUFEN_Default_Installation.jpeg" : "https://strgpiotcoredev.blob.core.windows.net/pictos-by-kpi/14_ROCA_Default_Installation.jpeg";
        if (this.state.redirect) {
            var url = this.state.redirect;
            this.setState({ redirect: null });
            return <Navigate to={url} />
        }
        function setSearchTerm(val){
            searchTerm = val;
        }

       
        const selectSite = (e) => {
            var siteID = e.target.id.split('-')[1];
            if (this.state.selectedSites.some(id => (id === siteID))) {
                this.state.selectedSites = this.state.selectedSites.filter((item) => item !== siteID);
            }
            else this.state.selectedSites.push(siteID);

            this.setActiveSites();
        }
        
        const menuCollapse = (e) => {
            e.preventDefault();
            this.setState({ showMenu: false });
        }
        const menuOpen = (e) => {
            e.preventDefault();
            this.setState({ showMenu: true });
        }
       
        const searchChange = (e) => {
            this.state.search = e.target.value;
            this.state.actualMenuFilter = "";
            this.generalFilter("",true);
        }
       
        return (
            <div className="" style={{ margin: '10px 0' }}>
                <BreadCrumb empty={true}></BreadCrumb>
                <section className="portlet" id="portlet_RocaSearch">
                    <div className="portlet-content">
                        <div className="autofit-float autofit-row portlet-header">
                            <div className="autofit-col autofit-col-expand">
                            </div>
                            <div className="autofit-col autofit-col-end">
                                <div className="autofit-section">
                                </div>
                            </div>
                        </div>
                        <div className=" portlet-content-container">
                            <div className="portlet-body">
                                <section className="search-box">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="close-back"><a href="javascript:history.back()" className="js-close icon-cross-24">
                                                    <span><img alt="Close" style={{ height: '30px' }} src="close.svg" className="hidden"/></span>
                                                </a></p>
                                                <input className="search-box-input" id="searchBox" name="searchBox"
                                                    onChange={searchChange}
                                                    value={this.state.search}
                                                    onBlur={"this.placeholder='" + t('productspages.filterproducts.searchplaceholder') + "'"} onfocus="this.placeholder=''" placeholder={t('productspages.filterproducts.searchplaceholder')} type="text" autoFocus maxLength={50} />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section id="mainSection" className="search-box" style={{}}>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="d-block d-md-none advanced-search">
                                                    <p>
                                                        <a className="js-collapse-mobile-open"  href="javascript.void(0);" onClick={menuOpen}>
                                                            <span className="pr-1"><img alt="Filtros" src="https://www.roca.es/o/roca-theme/images/icons/filters-24.svg" /></span> {t('productspages.filterproducts.titlesearch')} </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-3">
                                                <div className="search-filter">
                                                    <div className={"js-collapse-mobile collapse-mobile " + (this.state.showMenu == true ? 'active':'')} id="category">
                                                        <div className="d-block d-md-none prev">
                                                            <p>
                                                                <a className="js-collapse-mobile-close arrow" href="javascript.void(0);" onClick={menuCollapse}> {t('productspages.filterproducts.returntoresults')}</a>
                                                            </p>
                                                        </div>
                                                        <ul id="sectionsUL" className="category accordion">


                                                            {dataLoadedProds ? (
                                                                <li id="sectionLIPRODUCTS">
                                                                    <h2>
                                                                        <a id="sectionAPRODUCTS" className={this.state.actualMenuFilter == "sectionAPRODUCTS" ? 'active' : ''} onClick={this.filterMenu(null)}>{t('products')}
                                                                        </a>
                                                                    </h2>
                                                                    <ul id="sectionULPRODUCTS" className="subcategory collapse show"   >

                                                                        {
                                                                        
                                                                        this.state.filteredProductsMenu.map((item, i) => (
                                                                            <li  >
                                                                                <a id={"prod-" + item.productType.brand + "$$" + item.productType.name} onClick={this.filterMenu(null)}  href='javascript.void(0)' 
                                                                                    className={this.state.actualMenuFilter == "prod-" + item.productType.brand + "$$" + item.productType.name ? 'active t3': 't3'}>{item.productType.brand}  - {item.productType.name} </a>
                                                                            </li>
                                                                      
                                                                    ))}
                                                                    </ul>
                                                                </li>
                                                            ) : (<div className="loadingParent mb-3"><div className="loadingImg"></div></div>)
                                                            }



                                                            {dataLoadedInstal? (
                                                                <li id="sectionLIINSTALLATION">
                                                                    <h2>
                                                                        <a id="sectionAINSTALLATION" onClick={this.filterMenu(null)} href='javascript.void(0)' 
                                                                            className={this.state.actualMenuFilter == "sectionAINSTALLATION" ? 'active': ''}
                                                                        >{t('installations')}
                                                                            </a>
                                                                    </h2>
                                                                    <ul id="sectionULINSTALLATION" className="subcategory collapse show">
                                                                        {this.createTree(this.state.tree, true, 0)}
                                                                    </ul>
                                                                </li>
                                                            ) : (
                                                                    <div className="loadingParent mt-3"><div className="loadingImg"></div></div>
                                                                )
                                                            }
  

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>


                                        
                                            <div id="resultsParentDiv" className="col-12 col-md-9">
                                                <div id="resultsDiv">
                                                            {dataLoadedProds && this.state.filteredProducts.length > 0 ? (
                                                                <div id="productSectionDivCATEGORY069-02" className="product-list">
                                                                <div className="product-list__header">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <h2 id="productSectionH2CATEGORY069-02" className="uppercase">
                                                                            {t('products')} <span>({this.state.filteredProducts.length })</span></h2>
                                                                        <p className="cta"><a className={this.state.actualMenuFilter == "sectionAPRODUCTS" ? "hidden t3" : "t3" + " cta-black"} onClick={this.filterMenu("sectionAPRODUCTS")}  href='javascript.void(0)' >
                                                                            {t('productspages.filterproducts.seeall')}
                                                                                </a></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="product-list__body">
                                                               <div className="row">
                                                                {
                                                                
                                                                    this.state.filteredProducts.map((item, i) => (

                                                                
                                                                        <div className="result-content col-12 col-md-6 col-lg-4 mb-3 item-left" data-url={ "/product/" + item.id + "" }>
                                                                        <div className="product-card prodChildNoZoom">
                                                                        <div className="product-card__wrapper">
                                                                            <div className="product-card__body">
                                                                                <button className="js-activate" />
                                                                                <div className="wrapper-producto">
                                                                                    <div className="tags">
                                                                                        <ul className="promo">
                                                                                            <li className="black" style={{ display: 'none' }}>
                                                                                                <span>N</span>
                                                                                            </li>
                                                                                        </ul>
                                                                                            </div>  <Link to={"/product/" + item.id + ""}>
                                                                                                <div aria-label={item.name} className={item.productType.imageUri != null ? "wrapper-img" : "d-none"} role="img" style={{ backgroundImage: 'url(' + item.productType.imageUri +')' }}>
                                                                                                &nbsp;</div>
                                                                                    </Link>
                                                                                    <div className="wrapper-text">
                                                                                        <h4 className="coleccion">
                                                                                        <Link to={"/product/" + item.id + ""}>{item.designLine != null?item.designLine:item.productType.name} - {item.productType.letter}{item.tag != null? item.tag:0} </Link>
                                                                                        </h4>
                                                                                        <div style={{ height: '100%', minHeight: '120px', display:'flex', alignItems:'stretch'}}>
                                                                                            <div className="title-producto">
                                                                                                
                                                                                                <h3 className="p-0 pb-2">
                                                                                                <Link to={"/product/" + item.id + ""}>{item.productType.name}</Link>
                                                                                                </h3>
                                                                                                <h4 className="t3">
                                                                                                <Link to={"/installation/" + item.installation.id + ""}  className="installation">{item.installation.name}</Link>
                                                                                                </h4>
                                                                                            </div>
                                                                                            
                                                                                        </div>
                                                                                        <div className="dades" style={{ height: '19px' }}>
                                                                                            <p  className="small">
                                                                                                REF:
                                                                                                    <span>{item.productType.sku}</span>
                                                                                            </p>
                                                                                            
                                                                                            
                                                                                        </div>
                                                                                        <div className="dades" style={{ height: '19px' }}>
                                                                                            <p  className="small">
                                                                                                PUUID:
                                                                                                    <span>{item.puuid}</span>
                                                                                            </p>
                                                                                            
                                                                                            
                                                                                        </div>
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            


                                                            ))
                                                           
                                                            }
                                                                </div>
                                                            </div>
                                                            </div>
                                                            ) : (<div></div>)
                                                            }


                                                    {dataLoadedInstal && this.state.filteredInstallations.length > 0? (
                                                                <div className="product-list">
                                                                <div className="product-list__header">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <h2 className="capitalize">
                                                                            {t('installations')} <span>({this.state.filteredInstallations.length})</span></h2>
                                                                        <p className="cta"><a className={this.state.actualMenuFilter == "sectionAINSTALLATION" ? "hidden t3" : "t3" + " cta-black"} onClick={this.filterMenu("sectionAINSTALLATION")} href='javascript.void(0)' >{t('productspages.filterproducts.seeall')} </a></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="product-list__body">
                                                               <div className="row">
                                                                {
                                                                    this.state.filteredInstallations.map((item, i) => (

                                                             
                                                                        <div className="result-content col-12 col-md-6 col-lg-4 mb-3 item-left" data-url={ "/installation/" + item.id + "" }>
                                                                    <div className="product-card">
                                                                        <div className="product-card__wrapper">
                                                                            <div className="product-card__body">
                                                                                <button className="js-activate" />
                                                                                <div className="wrapper-producto">
                                                                                    <div className="tags">
                                                                                        <ul className="promo">
                                                                                            <li className="black" style={{ display: 'none' }}>
                                                                                                <span>N</span>
                                                                                            </li>
                                                                                        </ul>
                                                                                            </div><Link to={"/installation/" + item.id + ""}>
                                                                                                <div aria-label={item.name} className={(item.avatarUri != null ? item.avatarUri : imageInstallation) != null ? "wrapper-img" : "d-none"} role="img" style={{ backgroundImage: 'url(' + (item.avatarUri != null ? item.avatarUri : imageInstallation)+')', backgroundSize: "contain", backgroundRepeat:"no-repeat", backgroundPosition:"center" }}>
                                                                                                &nbsp;</div>
                                                                                    </Link>
                                                                                    <div className="wrapper-text">
                                                                                        <p className="coleccion">
                                                                                            <Link to={"/installation/" + item.id + ""}>{item.name}</Link>
                                                                                        </p>
                                                                                        <div style={{ height: 'auto', minHeight: '168px'}}>
                                                                                            <h3 className="title-installation">
                                                                                                
                                                                                                
                                                                                                <Link to={"/installation/" + item.id + ""}>{item.address}</Link>
                                                                                                
                                                                                           
                                                                                            </h3>
                                                                                            <p>
                                                                                                        {t('products')} - {item.products != undefined? item.products.length:0}
                                                                                            </p>
                                                                                            <p>
                                                                                                        {t('users')} - {item.userInstallations != undefined? item.userInstallations.length:0}
                                                                                            </p>
                                                                                        </div>
                                                                                      
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            


                                                            ))
                                                           
                                                            }
                                                                </div>
                                                            </div>
                                                            </div>
                                                            ) : (<div></div>)
                                                            }

                                                        

                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>
                    </div>
                </section>
            </div>

        );
    }
}
export default withTranslation()(FilterProducts);