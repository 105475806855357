import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../../App.css';
import AuthenticationContext from "../../Authentication/types/AuthContextType";
import CustomCheckbox from '../ParametersComponents/CustomCheckbox';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Moment from 'react-moment';

class FilterCard extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false
        };
        this.Laufen = false;
    }
    componentDidMount() {
        this.Laufen = this.context.brandCode == 11;
        this.setState({ dataLoaded: true });
    }
    calculateSignalLevel(rssi, numLevels) {
        const MIN_RSSI = -100;
        const MAX_RSSI = -55;

        if (rssi <= MIN_RSSI) {
            return 0;
        } else if (rssi >= MAX_RSSI) {
            return numLevels - 1;
        } else {
            let inputRange = (MAX_RSSI - MIN_RSSI);
            let outputRange = (numLevels - 1);
            return parseInt(((rssi - MIN_RSSI) * outputRange / inputRange));
        }
    }

    renderTooltip = (text) => (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    rssiBars(rssi) {

        let level = 4;
        if (rssi != null && rssi < 0) {
            level = this.calculateSignalLevel(rssi, 4);

            return (<div style={{ position: "absolute", top: "20px", left: "20px", zIndex: 1 }}>
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={this.renderTooltip(rssi + " dBm")}
                >

                    <img src={require('../../assets/icons/bar-chart-fill-' + level + ".svg").default} />
                </OverlayTrigger>
            </div>);

        } else {
            return "";
        }
    }

    render() {
        const { t } = this.props;
        let card;
        let dataURL = "";
        let cardType = this.props.type;
        let imageHeader;

        if (cardType != null) {
           
            switch (cardType) {
                case "sites":
                    dataURL = "/site/" + this.props.content.id;
                    break;
                case "products":
                    dataURL = "/product/" + this.props.content.id;
                    break;
                case "gateways":
                    dataURL = "/gateway/" + this.props.content.id;
                    break;
                case "installations":
                    dataURL = "/installation/" + this.props.content.id;
                    break;
                case "gatewayProducts":
                    dataURL = "/product/" + this.props.content.id;
                    break;
                default:
                    
                    break;
            }
            card = this.props.content;

            if (card.avatarUri != null) {
                imageHeader = card.avatarUri;
                imageHeader = imageHeader.replaceAll(" ", "%20").replaceAll(/\(/g, '%28').replaceAll(/\)/g, '%29');
            }
        } 

        const getInstallationName = (item) => {

            let installationName = " - "

            if (item.installations && item.installations.length > 0) {
                let installation = item.installations[0];
                for (let i = 0; i < item.installations.length; i++) {
                    if (installation != null) break;
                    installation = item.installations[i]
                }
                if (installation) installationName = installation.name
            }

            return (installationName)
        }

        const getNumberOfProducts = (item) => {
            let num = item.products?.length ?? 0
            return (num)
        }

        let imageInstallation = this.Laufen ? "https://strgpiotcoredev.blob.core.windows.net/pictos-by-kpi/11_LAUFEN_Default_Installation.jpeg" : "https://strgpiotcoredev.blob.core.windows.net/pictos-by-kpi/14_ROCA_Default_Installation.jpeg";
        let imageSite = this.Laufen ? "https://strgpiotcoredev.blob.core.windows.net/web-html/laufen/DefaultSite.jpg" : "https://strgpiotcoredev.blob.core.windows.net/web-html/roca/DefaultSite.jpg";
        const renderTooltip = (text) => (props) => (
            <Tooltip id="button-tooltip" {...props}>
                {text}
            </Tooltip>
        );


        return (
            
            <div className={"result-content  item-left " + (this.props.noMargin ? '' : 'mb-3 ') + (this.props.customFlex != null && this.props.customFlex != undefined ? this.props.customFlex : 'col-12 col-md-6 col-lg-4')} data-url={dataURL}>
                {this.state.dataLoaded ? 
                    <div className={!this.props.shadow ? '' : 'product-card'}>
                        <div className="product-card__wrapper">

                        {(cardType == "products") &&

                            <div className="product-card__body">
                                <button className="js-activate" />
                                <div className="wrapper-producto">
                                    <Link to={"/product/" + card.id + ""}>
                                        <div aria-label={card.name} className= "wrapper-img" role="img" style={{ backgroundImage: 'url(' + card.productType.imageUri + ')' }}>
                                            &nbsp;</div>
                                    </Link>
                                    <div className="wrapper-text">
                                        <h4 className="coleccion">
                                            <Link to={"/product/" + card.id + ""}>{card.productType.name != null ? card.productType.name : card.designLine}</Link>
                                        </h4>
                                        <div style={{ height: '100%', minHeight: '120px', display: 'flex', alignItems: 'stretch' }}>
                                            <div className="title-producto">

                                                <h3 className="p-0 pb-2">
                                                    <Link to={"/product/" + card.id + ""}>{card.name}</Link>
                                                </h3>
                                                <h4 className="t3">
                                                    <Link to={"/installation/" + card.installation.id + ""} className="installation">{card.installation.name}</Link>
                                                </h4>
                                            </div>

                                        </div>
                                        <div className="dades" style={{ height: '19px' }}>
                                            <p className="small">
                                                REF:
                                                <span>{card.productType.sku}</span>
                                            </p>


                                        </div>
                                        <div className="dades" style={{ height: '19px' }}>
                                            <p className="small">
                                                PUUID:
                                                <span>{card.puuid}</span>
                                            </p>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        }
                        {cardType == "gateways" &&

                                <div className="product-card__body">

                                    <div className="wrapper-producto">
                                        <div className={"gw-status " + (card.deviceStatus != undefined ? card.deviceStatus?.toLowerCase() : "offline")}></div>
                                        <Link to={"/gateway/" + card.id + ""}>
                                            <div aria-label={card.name} className="wrapper-img" role="img" style={{ backgroundImage: 'url(' + card.gatewayType.imageUri + ')' }}>
                                                &nbsp;</div>
                                        </Link>
                                        <div className="wrapper-text">
                                            <h4 className="coleccion">
                                                <Link to={"/gateway/" + card.id + ""}> {card.gatewayId} </Link>
                                            </h4>
                                            <div style={{ height: '100%', minHeight: '120px', display: 'flex', alignItems: 'stretch' }}>
                                                <div className="title-producto">

                                                    <h3 className="p-0 pb-2">
                                                        <Link to={"/gateway/" + card.id + ""}>{card.name}</Link>
                                                    </h3>

                                                </div>

                                            </div>
                                            <div>
                                                <h5 className="d-inline">{t('installation')}:</h5> <span className="t2 d-inline">{getInstallationName(card)}</span>
                                            </div>
                                            <div>
                                                <h5 className="d-inline">{t('products')}:</h5> <span className="t2 d-inline">{getNumberOfProducts(card)}</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            }

                        {cardType == "gatewayProducts" &&

                            <div className={"product-card__body " + (card.enabled ? "" : "efect-gray")}>
                                <button className="js-activate" />
                                <div className={"wrapper-producto " + (card.enabled ? "" : "efect-gray")} >
                                    <div className="tags">
                                        <div className="prodState" style={{ top: "10px", right: "10px", width: "auto" }}>

                                            <div className="stateDiv">
                                                <div>
                                                    <img className={"state-" + card.deviceStatus} src="" />
                                                    <img src={card.productType.iconUri} style={{ filter: "invert(100%)" }} />
                                                    <span>
                                                        {card.productType.letter}{card.tag != null ? card.tag : 0}
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        {this.rssiBars(card.lastSignal)}
                                    </div>  <Link style={{ textDecoration: 'none' }} to={"/product/" + card.id + ""}>
                                        <div aria-label={card.name} className="wrapper-img" role="img" style={{ backgroundImage: 'url(' + card.productType.imageUri + ')', minHeight:'50px' }}>
                                            &nbsp;</div>
                                    </Link>
                                    <div className="wrapper-text">
                                        <h4 className="coleccion">
                                            <Link to={"/product/" + card.id + ""}>{card.name != "" ? card.name : card.productType.name}  </Link>
                                        </h4>
                                        <div style={{ height: '100%', minHeight: '120px', display: 'flex', alignItems: 'stretch' }}>
                                            <div className="title-producto" style={{ width: "100%" }}>
                                                <h3 className="p-0 pb-2 d-none">


                                                    <Link to={"/product/" + card.id + ""}>{card.name != "" ? card.name : ""}</Link>
                                                </h3>
                                                <h5 className="t5">
                                                    <CustomCheckbox disabled={true} style={{}} parameterValue={card.enabled} paramObj={{ table: "gateways", smartComponentTypeId: "0", name: "Telemetry enabled", businessLabel: "EnabledTelemetry" }} toSend={this.valueChanged} />
                                                </h5>
                                                <span className={"t3 " + (card.lastProcessedFrame == null ? "d-none" : "")}>
                                                    {card?.deviceStatus?.toLowerCase() == 'online' ?
                                                        (
                                                            <div><b>{t('productspages.general.lastestdatareceived')}: </b><br /> <Moment className="t3" format="D MMM YYYY HH:mm" withTitle>{card.lastProcessedValue == null ? card.lastProcessedFrame : card.lastProcessedValue}</Moment></div>
                                                        ) : (
                                                            <div><b>{t('productspages.general.lastTimeSeen')}: </b><br/> <Moment className="t3" format="D MMM YYYY HH:mm" withTitle>{card.lastTimeSeen}</Moment></div>
                                                        )
                                                    }
                                                </span>


                                            </div>
                                        </div>
                                        <div className="dades" style={{ height: '19px' }}>
                                            <p className="small">
                                                REF: <span>{card.productType.sku}</span>
                                            </p>
                                        </div>
                                        <div className="dades" style={{ height: '19px' }}>
                                            <p className="small">
                                                PUUID: <span>{card.puuid}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
  

                        }
                        {cardType == "installations" &&
                            <div className="product-card__body">
                                <button className="js-activate" />
                                <div className="wrapper-producto">
                                    <Link to={"/installation/" + card.id + ""}>
                                        <div aria-label={card.name} className="wrapper-img" role="img" style={{ backgroundImage: 'url(' + (imageHeader != null ? imageHeader : imageInstallation) + ')', backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                                            &nbsp;</div>
                                    </Link>
                                    <div className="wrapper-text">
                                        <p className="coleccion">
                                            <Link to={"/installation/" + card.id + ""}>{card.name}</Link>
                                        </p>
                                        <div style={{ height: 'auto', minHeight: '168px' }}>
                                            <h3 className="title-installation">
                                                <Link to={"/installation/" + card.id + ""}>{card.address}</Link>
                                            </h3>
                                            <p>
                                                {t('products')} - {card.products != undefined ? card.products.length : 0}
                                            </p>
                                            <p>
                                                {t('users')} - {card.userInstallations != undefined ? card.userInstallations.length : 0}
                                            </p>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        }
                        {cardType == "sites" &&
                            <div className="product-card__body">
                                <button className="js-activate" />
                                <div className="wrapper-producto">
                                    <Link to={"/site/" + card.id + ""}>
                                        <div aria-label={card.name} className="wrapper-img" role="img" style={{ backgroundImage: 'url(' + (imageHeader != null ? imageHeader : imageSite) + ')', backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                                            &nbsp;</div>
                                    </Link>
                                    <div className="wrapper-text">
                                        <p className="coleccion">
                                            <Link to={"/site/" + card.id + ""}>{card.name}</Link>
                                        </p>
                                        <div style={{ height: 'auto', minHeight: '168px' }}>
                                            <h3 className="title-installation">
                                                <Link to={"/site/" + card.id + ""}>{card.address ? "" : card.adress}</Link>
                                            </h3>
                                            <p>
                                                {t('installations')} - {card.installations != undefined ? card.installations.length : 0}
                                            </p>
                                            <p>
                                                {t('users')} - {card.userInstallations != undefined ? card.userInstallations.length : 0}
                                            </p>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        }
                        {cardType == "spareParts" &&
                            <div id={"sparePartId-"+card.id} className="sparepart-item mx-4" data-filters="all" data-coordinates={card.cords}>
                                <div className="sparepart-picture">
                                    {card.avatarUri ? <img src={card.avatarUri}></img>:null}
								</div>
                                <div className="sparepart-info">

                                    <p className="colection ft6">{card.colection}</p>

                                    <p className="description sparePartDesc">
                                        {card.description}
                                        {/*<a href="#"></a>*/}
									</p>
                                    <p className="reference sparePartRef">
                                        {t('tables.itemNumber')}: {card.fullContent?.displayCode }
									</p>
								</div>
                                <div className="sparepart-detail">

                                    <div className="sparepart-detail-item">
                                        <div className="sparepart-item-index">
                                            <div className="spare-part-position" data-filters="all">
                                                {card.pos}
										    </div>
									    </div>
                                        <div className="wrap-details" data-filters="all">
                                            <div className="details-body">


                                                <div className="specs"><p>{card.specs}</p></div>

                                                <div className="icons">

											    </div>
										    </div>
									    </div>
								    </div>

								</div>
							</div>
                           
                        }
                    </div>
                    </div>
                : null}
            </div>
        );
    }
}
export default withTranslation()(FilterCard);