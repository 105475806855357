import 'bootstrap/dist/css/bootstrap.css';
import { adaptV4Theme } from '@mui/material/styles';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material';
import './i18n/i18n';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import AzureConnect from './Authentication/container/AzureConnect';

// import AuthenticationContext from './Authentication/types/AuthContextType';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const container = document.getElementById('root');
const root = createRoot(container);
const isProduction = process.env.NODE_ENV === 'production';

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: '#00538b',
      },
    },
  })
);

console.log('Is Production: ' + isProduction);
console.log('Version: ' + packageInfo.version);

root.render(
  <CacheBuster
    currentVersion={packageInfo.version}
    isEnabled={isProduction} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
  >
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename={baseUrl}>
          <AzureConnect>
            {/* <AuthenticationContext.Consumer>{(authContextValue) => <App authContextValue={authContextValue} />}</AuthenticationContext.Consumer> */}
            <App />
          </AzureConnect>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  </CacheBuster>
);

registerServiceWorker();
