import axios from "axios";
import i18n from '../i18n/i18n';

export default class HttpClient {


    constructor(context, id) {
        this.context = context;
        this.siteId = id;
        this.language = i18n.language;
        this.debug = false
    }


    GetSite = async () => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        try {
            var response = await axios.get("sites/" + this.language + "/" + this.siteId, config);
            if (this.debug) {
                console.log("calling: " + "sites/" + this.language + "/" + this.siteID);
                console.log("response: " + JSON.stringify(response));
            }
            return response;
        }
        catch(e) {
            return e.response;
        }
    }

    GetSitesFilter = async (filter, page, sizePerPage) => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("sites/" + this.language + "/" + page + "/" + sizePerPage + "/" + filter, config);
        if (this.debug) {
            console.log("calling: " + "sites/" + this.language + "/" + page + "/" + sizePerPage + "/" + filter);
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    GetSites = async () => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("sites/" + this.language, config);
        if (this.debug) {
            console.log("calling: " + "sites/" + this.language);
            console.log("response: " + JSON.stringify(response));
        }

        if (response) {
            response.data.content.forEach((i) => { i.name = i.name ?? " " })
            response.data.content = response.data.content.sort((a, b) => a.name.localeCompare(b.name));
        }

        return response.data
    }

    GetSitesMenu = async () => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("sites/" + this.language + "/menu", config);
        if (this.debug) {
            console.log("calling: " + "sites/" + this.language);
            console.log("response: " + JSON.stringify(response));
        }

        if (response) {
            response.data.content.forEach((i) => { i.name = i.name ?? " " })
        }
        return response.data
    }

    
    PostSite = async (elements) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }

        var response = await axios.post("sites/" + this.language + "/sites/", elements, config);
        if (this.debug) {
            console.log("calling: sites/" + this.language + "/sites/");
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

    DeleteSites = async (id) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
                "content-type": "multipart/form-data",
            }
        }
        var response;
        try {

            response = await axios.delete("sites/" + this.language + "/" + id, config);
            if (this.debug) {
                console.log("calling: sites/" + this.language + "/" + id);
                console.log("response: " + JSON.stringify(response));
            }
        } catch (e) {
            response = e.response;
            throw e;
        }

        return response.data
    }
}