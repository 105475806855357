import { t } from 'i18next';
import { ModalAddHWStep1ViewProps } from '../types/ModalAddHardwareTypes';
import { FormControl, TextField } from '@mui/material';
import Select from 'react-select';
import { selectOption } from '../types/HWHardwareTypes';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import { selectDropdownStyle } from '../../../../../config/ManagementConfig';

const renderTooltip = (text: string) => (props) =>
  (
    <Tooltip id='button-tooltip' target='{target.current}' position='fixed' {...props}>
      {text}
    </Tooltip>
  );

const ModalAddHWStep1View = (props: ModalAddHWStep1ViewProps) => (
  <div className='ModalAddHWStep1View'>
    <div className='prodChildNoZoom p-5'>
      <div className='row'>
        <div className='col'>
          {props.typeHardware === 'smartComponentTypeId' ? (
            <h3>{t(`firmwareManagementPage.smartComponentTypeId`)}*</h3>
          ) : (
            <h3>{t(`firmwareManagementPage.systemId`)}*</h3>
          )}
          <FormControl fullWidth>
            <Select
              isDisabled={!!(props.newHardwareFromNewPackage && props.selectStep1)}
              className='react-select-container'
              //classNamePrefix='react-select'
              styles={selectDropdownStyle({height: 200})}
              required
              options={props.selectOptions.map((option) => ({ value: option.value, label: option.label }))}
              onChange={(option) => {
                props.handleSelectStep1(option as selectOption);
              }}
              defaultValue={
                props.selectStep1 && props.selectOptions.length
                  ? props.selectOptions.find((option) => option.value === props.selectStep1.id)
                  : null
              }
            />
          </FormControl>
        </div>

        {props.typeHardware === 'systemId' && props.selectStep1 ? (
          <div className='col'>
            <h3>{t(`firmwareManagementPage.smartComponentType`)}*</h3>
            <FormControl fullWidth>
              <Select
                isDisabled={!!(props.newHardwareFromNewPackage && props.selectedSmartComponentType)}
                className='react-select-container'
                options={props.optionsSelect2}
                styles={selectDropdownStyle({height: 200})}
                required
                onChange={(option) => {
                  props.handleSelecSmart_NoSmart(option as selectOption);
                }}
                value={
                  props.selectedSmartComponentType
                    ? props.optionsSelect2.find((option) => option.value === props.selectedSmartComponentType)
                    : null
                }
              />
            </FormControl>
          </div>
        ) : (
          ''
        )}

        {props.typeHardware === 'nosmartComponentTypeId' && props.selectStep1 ? (
          <div className='col'>
            <h3>{t(`firmwareManagementPage.nosmartComponentTypeId`)}*</h3>
            <FormControl fullWidth>
              <Select
                className='react-select-container'
                options={props.optionsSelect2}
                styles={selectDropdownStyle({height: 200})}
                required
                onChange={(option) => {
                  props.handleSelecSmart_NoSmart(option as selectOption);
                }}
                value={
                  props.selectedNoSmartComponentType
                    ? props.optionsSelect2.find((option) => option.value === props.selectedNoSmartComponentType)
                    : null
                }
              />
            </FormControl>
          </div>
        ) : (
          ''
        )}
      </div>

      <div className='row '>
        <div className='col-12 col-md-4'>
          <h3>{t('firmwareManagementPage.name')}*</h3>
          <TextField
            onChange={(event) => {
              props.handleTextFields(event.target);
            }}
            name='name'
            required
            size='small'
            fullWidth
            variant='outlined'
            value={props.name}
          />
        </div>

        <div className='col-12 col-md-4'>
          <h3>{t('firmwareManagementPage.description')}*</h3>
          <TextField
            name='description'
            required
            size='small'
            fullWidth
            variant='outlined'
            onChange={(event) => {
              props.handleTextFields(event.target);
            }}
            value={props.description}
          />
        </div>
        <div className='col-12 col-md-4'>
          <div className='tooltip-custom'>
            <h3>{t('firmwareManagementPage.version')}* </h3>
            <OverlayTrigger
              placement='top'
              delay={{ show: 0, hide: 400 }}
              overlay={renderTooltip(t('firmwareManagementPage.PackageVersionFormat', { format: '01.23' }))}
            >
              <div style={{ position: 'relative' }}>
                <HelpIcon className='help-icon' fontSize='medium'></HelpIcon>
              </div>
            </OverlayTrigger>
          </div>
          <TextField
            name='version'
            required
            size='small'
            fullWidth
            variant='outlined'
            onChange={(event) => {
              props.handleTextFields(event.target);
            }}
            value={props.version}
          />
        </div>
      </div>
    </div>

    <div className='buttons row'>
      <div className='col'>
        <button
          onClick={() => props.handleStep('prev')}
          className={`${
            props.newHardwareFromNewPackage && 'disabled-mode'
          } MuiButtonBase-root MuiButton-root MuiButton-text primary pull-left float-left`}
          type='button'
          disabled={Boolean(props.newHardwareFromNewPackage)}
        >
          <span className='MuiButton-label'>{t('actions.back')}</span>
          <span className='MuiTouchRipple-root'></span>
        </button>

        <button
          onClick={() => props.saveHardware()}
          className={`${
            props.isSaveDisabled && 'disabled-mode'
          } MuiButtonBase-root MuiButton-root MuiButton-text primary pull-right float-right`}
          disabled={props.isSaveDisabled}
        >
          <span className='MuiButton-label'>{t('actions.save')}</span>
          <span className='MuiTouchRipple-root'></span>
        </button>
      </div>
    </div>
  </div>
);

export default ModalAddHWStep1View;
