import React, { Component } from 'react';
import '../../App.css';

import { withTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import AuthenticationContext from '../../Authentication/types/AuthContextType';
import GatewaysHttpClient from '../../HttpClient/GatewaysHttpClient';

import * as jsonpatch from 'fast-json-patch/index.mjs';

import TextField from '@mui/material/TextField';
import Message from '../Message/Message';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

class GatewayEdit extends Component {
  static contextType = AuthenticationContext;

  constructor(props) {
    super(props);
    this.id = this.props.id;

    this.saveData = this.saveData.bind(this);
    this.confirmSave = this.confirmSave.bind(this);

    this.state = {
      open: false,
      showModalError: false,
      showModalDelete: false,
      showModalDeleteError: false,
      showModalSave: false,
      saving: false,
      message: null,
    };

    this.confirmDelete = this.confirmDelete.bind(this);
  }

  async componentDidMount() {
    await this.getGateway();
  }

  renderMessage = (type, content) => {
    this.setState({
      message: {
        type: type,
        content: content,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({ message: null });
  };

  async getGateway() {
    try {
      this.setState({ gateway: null, _errors: null });
      let data = await this.http.GetGateway(this.id);
      if (data.status == 200) {
        this.setState({ gateway: data.data.content });
        if (data.errors != null) {
          this.setState({ _errors: data.errors });
        }
      } else {
        this.setState({ _errors: data });
      }
    } catch (err) {}
  }
  async deleteGateway(id, t) {
    let data;
    try {
      this.http = new GatewaysHttpClient(this.context, this.id);

      var puuid = document.getElementById('txtNameDelete').value;
      if (puuid.toLowerCase() == this.props.gateway.puuid.toLowerCase()) {
        data = await this.http.DeleteGateways(id, puuid);
        if (data?.content == true) {
          document.getElementById('redirectToParent').click();
        }
      } else {
        this.setState({ showModalDelete: false, saving: false });
        this.renderMessage('error', 'Gateway PUUID and PUUID entered do not match.');
      }
    } catch (e) {
      if (e.response.status != undefined && e.response.status > 200) {
        this.setState({
          showModalDelete: false,
          saving: false,
          showModalDeleteError: true,
          messageError:
            e.response.data?.errors == undefined
              ? t('installationspages.installationsummaryedit.forbid')
              : e.response.data?.errors[0],
        });
      } else if (e.response.errors != undefined) {
        this.setState({
          showModalDelete: false,
          saving: false,
          showModalDeleteError: true,
          messageError:
            e.response.data?.errors == undefined
              ? t('installationspages.installationsummaryedit.forbid')
              : e.response.data?.errors[0],
        });
      } else if (e.response.startsWith('System.InvalidOperation')) {
        // Forbid
        this.setState({
          showModalDelete: false,
          saving: false,
          showModalDeleteError: true,
          messageError:
            e.response.data?.errors == undefined
              ? t('installationspages.installationsummaryedit.forbid')
              : e.response.data?.errors[0],
        });
      }
    }

    return data;
  }
  componentDidUpdate(prevProps) {}

  async confirmDelete(t) {
    await this.deleteGateway(this.id, t);
  }

  async saveData(e, t) {
    e.preventDefault();
    var nombre = document.getElementById('txtName').value;
    var desc = document.getElementById('txtDescription').value;

    let gateway = {};
    gateway.name = nombre;
    gateway.description = desc;

    let oldGateway = {
      name: this.props.gateway.name,
      description: this.props.gateway.description,
    };

    var patch = jsonpatch.compare(oldGateway, gateway);
    gateway = {};
    gateway.id = this.id;
    gateway.data = patch;

    const formulario = new FormData();
    formulario.append('patchoptions', JSON.stringify(gateway));
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    let data;
    switch (this.props.mode) {
      case 'add':
        break;
      case 'edit':
        data = await this.SendPatch(formulario);
        break;
    }

    if (data.errors == null) {
      this.setState({ showModalSave: false });
      this.props.onSaveClose();
    }
  }

  async SendPatch(formulario) {
    let data;
    this.http = new GatewaysHttpClient(this.context, this.id);
    data = await this.http.PatchGateway(formulario, this.id);
    return data;
  }

  async confirmSave(e, t) {
    e.preventDefault();
    await this.saveData(e, t);
    this.setState({ showModalSave: false });
  }

  render() {
    const { t } = this.props;

    const handleClose = () => {
      this.setState({ showModalSave: false });
    };
    const handleCloseDelete = () => {
      this.setState({ showModalDelete: false });
    };
    const handleCloseModal = () => {
      this.props.onSaveClose();
    };

    const handleCloseError = () => {
      this.props.onSaveClose();
    };
    const deleteGateway = () => {
      this.setState({
        showModalDelete: true,
        title: t('actions.deletename', { name: t('gateway') }),
        question: t('actions.confirmdelete', { name: t('gateway') }),
      });
    };
    let dataLoaded = this.props.gateway && this.props.gateway != {};

    return (
      <React.Fragment>
        {dataLoaded ? (
          <div>
            <Dialog disableEscapeKeyDown open={this.state.showModalDeleteError} onClose={handleCloseError}>
              <DialogTitle>{t('productspages.productedit.erroredittitle')}</DialogTitle>
              <DialogContent>
                <div>
                  <span>{this.state.messageError}</span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button color='primary' onClick={handleCloseError}>
                  {t('actions.close')}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog disableEscapeKeyDown open={this.state.showModalDelete} onClose={handleCloseDelete}>
              <DialogTitle>{this.state.title}</DialogTitle>
              <DialogContent>
                <div>
                  <span>{this.state.question}</span>
                  <br />
                  <span>{t('gatewayspages.general.deleteGW')}</span>
                  <TextField
                    variant='standard'
                    id='txtNameDelete'
                    label={this.props.gateway.puuid}
                    placeholder='puuid'
                    helperText=''
                    fullWidth
                    margin='normal'
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button color='primary' onClick={() => this.confirmDelete(t)}>
                  {t('actions.yes')}
                </Button>
                <Button color='primary' onClick={handleCloseDelete}>
                  {t('actions.no')}
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog disableEscapeKeyDown open={this.state.showModalSave} onClose={handleClose}>
              <DialogTitle>{t('productspages.productedit.changeinstallationtitle')}</DialogTitle>
              <DialogContent>
                <div>
                  <span>{t('productspages.productedit.changeinstallationmessage')}</span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button className='primary' onClick={(e) => this.saveData(e, t)}>
                  {t('actions.yes')}
                </Button>
                <Button className='primary' onClick={handleClose}>
                  {t('actions.no')}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog disableEscapeKeyDown open={this.state.showModalError} onClose={handleCloseError}>
              <DialogTitle>{t('productspages.productedit.erroredittitle')}</DialogTitle>
              <DialogContent>
                <div>
                  <span>{this.state.messageError}</span>
                </div>
              </DialogContent>
              <DialogActions>
                <Button className='primary' onClick={handleCloseError}>
                  {t('actions.close')}
                </Button>
              </DialogActions>
            </Dialog>

            <form id='divForm' onSubmit={(e) => this.confirmSave(e, t)}>
              <div className='form-row'>
                <div className='form-group col-md-12'>
                  <TextField
                    onInvalid={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                    onInput={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                    onChange={(e) => e.target.setCustomValidity('')}
                    variant='standard'
                    id='txtName'
                    label={t('forms.name')}
                    placeholder={t('forms.name')}
                    helperText=''
                    fullWidth
                    required
                    margin='normal'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={this.props.gateway.name}
                  />
                </div>
                <div className='form-group col-md-12'>
                  <TextField
                    variant='standard'
                    id='txtDescription'
                    label={t('forms.description')}
                    placeholder={t('forms.description')}
                    helperText=''
                    fullWidth
                    margin='normal'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={this.props.gateway.description}
                  />
                </div>
              </div>
              <div className='form-row'>
                {this.state.message && <Message {...this.state.message} onClose={this.handleDialogClose} />}
                <div className='form-group col-6'>
                  <Button className='primary active d-inline col-12 m-0' onClick={deleteGateway}>
                    {t('actions.delete')}
                  </Button>
                  <Link
                    id='redirectToParent'
                    to={{
                      pathname: '/filter/gateways',
                      message: t('actions.deleted', { name: t('gateway') + ' "' + this.props.gateway.name + '" ' }),
                    }}
                    style={{ display: 'none' }}
                  ></Link>
                </div>
                <div className='form-group col-6'>
                  <Button className='primary pull-right  col-12 m-0' onClick={handleCloseModal}>
                    {t('actions.close')}
                  </Button>
                </div>
                <div className='form-group col-12'>
                  <Button type='submit' className='primary active d-inline col-12 m-0'>
                    {t('actions.save')}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className='loadingParent'>
            <div className='loadingImg'></div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(GatewayEdit);
