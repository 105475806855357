import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { t } from 'i18next';
import { ModalAddHWStep0ViewProps } from '../types/ModalAddHardwareTypes';

const ModalAddHWStep0View = (props: ModalAddHWStep0ViewProps) => (
  <div className='ModalAddHWStep0View'>
    <div className='prodChildNoZoom p-5'>
      <h2>{t('firmwareManagementPage.addSmartComponentHardware')}</h2>
      <div className='row'>
        <div className='col'>
          <h3>{t('firmwareManagementPage.selectType')}</h3>
          <FormControl>
            <RadioGroup
              aria-labelledby='demo-controlled-radio-buttons-group'
              name='controlled-radio-buttons-group'
              value={props.typeHardware}
              onChange={(event) => {
                props.handleTypeHardware(event.target.value);
              }}
            >
              <FormControlLabel
                value='systemId'
                control={<Radio required />}
                label={t('firmwareManagementPage.systemId')}
                //   disabled={this.props.modeForm === 'edit'}
              />
              <FormControlLabel
                value='smartComponentTypeId'
                control={<Radio required />}
                label={t('firmwareManagementPage.smartComponentTypeId')}
                //   disabled={this.props.modeForm === 'edit'}
              />
              <FormControlLabel
                value='nosmartComponentTypeId'
                control={<Radio required />}
                label={t('firmwareManagementPage.nosmartComponentTypeId')}
                //   disabled={this.props.modeForm === 'edit'}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </div>
    <div className='buttons row'>
      <div className='col'>
        <button
          onClick={() => props.handleShowAddHWModal(false)}
          className='MuiButtonBase-root MuiButton-root MuiButton-text primary pull-left float-left'
          type='button'
        >
          <span className='MuiButton-label'>{t('actions.cancel')}</span>
          <span className='MuiTouchRipple-root'></span>
        </button>

        <button
          onClick={() => props.handleStep('next')}
          className={`${!props.typeHardware && 'disabled-mode'} MuiButtonBase-root MuiButton-root MuiButton-text primary pull-right float-right`}
          disabled={!props.typeHardware}
        >
          <span className='MuiButton-label'>{t('actions.next')}</span>
          <span className='MuiTouchRipple-root'></span>
        </button>
      </div>
    </div>
  </div>
);

export default ModalAddHWStep0View;
