import { Dialog, DialogActions, IconButton, DialogContent, Button, Select, MenuItem } from '@mui/material';
import Close from '@mui/icons-material/Close';

import Dropdown from 'react-bootstrap/Dropdown';
import { UserFactoriesType } from '../types/UserProfileTypes';
import Loader from '../../../../Common/Loader';
import { useState } from 'react';
import Style from '../styles/UserFactoriesView.module.scss';
import { ProductionCenter } from '../types/ProductionCenter';
import Form from 'react-bootstrap/Form';
import { t } from 'i18next';

const UserFactoriesView = (props: UserFactoriesType) => {
  const [centerSelected, setCenterSelected] = useState(-1);
  const centerAvailables = props
    .allProductionCenters!.filter((center) => !props.productionCenters?.find((pcenter) => pcenter.id === center.id))
    .filter((center) => center.factories.length).length;

  const handlerOpenModal = (centerId: number) => {
    setCenterSelected(centerId);
    props.setToggleCenterModal(true);
  };

  const renderSelectCenter = () => {
    const centersToSelect = props.allProductionCenters!.filter(
      (center) => !props.productionCenters?.find((pcenter) => pcenter.id === center.id)
    );
    if (centersToSelect.filter((center) => center.factories.length).length)
      return (
        <div className='select-btns'>
          <div className='inside-box'>
            <p className='title'>{t('userpages.centers')}</p>
            <Select className='react-select-container' onChange={(e) => handlerOpenModal(e.target.value as number)}>
              {centersToSelect
                .filter((center) => center.factories.length)
                .map((center, key) => (
                  <MenuItem key={key} value={center.id}>{center.name}</MenuItem>
                ))}
            </Select>
          </div>
        </div>
      );
  };

  const renderContentEditCenter = (allInfoCenterSelected: ProductionCenter) => {
    if (props.factoriesOfUser && props.factoriesOfUser[allInfoCenterSelected.id]) {
      return (
        <div className='center-factory-box' key={allInfoCenterSelected.id}>
          <div className='assigned-box'>
            {allInfoCenterSelected.factories && allInfoCenterSelected.factories.length && (
              <Form>
                {allInfoCenterSelected.factories.map((factory, key) => (
                  <div className='factory-box' key={key}>
                    <Form.Check
                      type='checkbox'
                      label={factory.name}
                      defaultChecked={props.factoriesOfUser[allInfoCenterSelected.id][factory.id]}
                      onChange={(e) => props.manageFactoryToUser(allInfoCenterSelected.id, factory.id, e.target.checked)}
                    />
                  </div>
                ))}
                <Button
                  className={`edit-btn primary w-sm-100 btn-add-center status-${props.btnsNewCenter}`}
                  disabled={props.hassDiffFactories(allInfoCenterSelected.id)}
                  onClick={() => {
                    props.applyFactoriesToUser(allInfoCenterSelected.id), props.setToggleCenterModal(!props.centerModalStatus);
                  }}
                >
                  {t('userpages.accept')}
                </Button>
              </Form>
            )}
          </div>
        </div>
      );
    }
  };

  const renderEditCenter = () => {
    const centerSelectedAllData = props.allProductionCenters!.find((center) => center.id === centerSelected);
    if (!centerSelectedAllData) return <></>;
    return (
      <>
        <Dialog
          classes={{ paper: Style.userFactoriesView }}
          disableEscapeKeyDown
          open={props.centerModalStatus}
          onClose={() => props.setToggleCenterModal(false)}
        >
          <DialogActions className='jc-spaceBetween'>
            <h2 className='title-modal'>{centerSelectedAllData!.name}</h2>
            <IconButton onClick={() => props.setToggleCenterModal(!props.centerModalStatus)}>
              <Close fontSize='large' />
            </IconButton>
          </DialogActions>
          <DialogContent>{renderContentEditCenter(centerSelectedAllData as ProductionCenter)}</DialogContent>
        </Dialog>
      </>
    );
  };

  return (
    <>
      {!props.loaderCenters ? (
        <div className='all-factories'>
          {props.productionCenters && props.productionCenters.length ? (
            <div className='general-container'>
              <p>
                <b>{t('userpages.centers')}</b>
              </p>
              <div className='factories-container'>
                {props.productionCenters.map((center, key) => (
                  <div className='center-factory-box' key={key}>
                    <div className='assigned-box'>
                      <div className='name-box'>
                        <p>{t('userpages.center')}: {center.name}</p>
                        <Dropdown className='noBorder dropdown-center'>
                          <Dropdown.Toggle
                            disabled={!props.userRoleEditProdCenters}
                            variant='transparent'
                            style={{ color: '#9a9a9a' }}
                            id='dropdown-basic'
                          >
                            <div className='box-dots'>
                              <img
                                className={`${!props.userRoleEditProdCenters && 'disabled-mode'}`}
                                src={require('../../../../../assets/icons/three-dots.svg').default}
                                loading="eager"
                              />
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <div className='row noBorder' style={{ margin: 0, padding: 0 }}>
                              <div className='col-12 noBorder userMenuItem' onClick={() => handlerOpenModal(center.id)}>
                                <p>{t('userpages.edit')}</p>
                              </div>
                              <div
                                className='col-12 noBorder userMenuItem'
                                onClick={() => props.removeAllFactoriesToUser!(center.id)}
                              >
                                <p>{t('userpages.remove')}</p>
                              </div>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                      {center.factories && center.factories.length && (
                        <>
                          {center.factories.map((factory, key) => (
                            <div className='factory-box' key={key}>
                              <p className='title'>{t('userpages.factory')}: {factory.name}</p>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className='container-newFactories'>
                {props.btnsNewCenter && centerAvailables ? (
                  <div className={`select-factories ${!props.userRoleEditProdCenters && 'disabled-mode'}`}>
                    {renderSelectCenter()}
                  </div>
                ) : (
                  ''
                )}
                {props.userRoleEditProdCenters && centerAvailables ? (
                  <Button
                    className={`primary w-sm-100 btn-add-center status-${props.btnsNewCenter}`}
                    onClick={props.showBtnsNewCenter}
                  >
                    {props.btnsNewCenter ? t('userpages.cancel') : t('userpages.addCenter')}
                  </Button>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            <div className='select-factories'>{renderSelectCenter()}</div>
          )}
        </div>
      ) : (
        <Loader />
      )}
      {centerSelected !== -1 && renderEditCenter()}
    </>
  );
};

export default UserFactoriesView;