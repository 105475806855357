import axios from "axios";
import i18n from '../i18n/i18n';

export default class HttpClient {


    constructor(context, timeZoneOffset) {
        this.context = context;
        this.language = i18n.language;
        this.debug = false; 
        this.timeZoneOffset = timeZoneOffset ? timeZoneOffset: "00:00"
    }

    addTimezone = (timeStamp) => {
      return timeStamp;
      const sign = this.timeZoneOffset.includes('-') ? 'min' : 'plus';
      return `${timeStamp}${sign}${(this.timeZoneOffset.replace('+','').replace('-','')).substring(0, 5)}`;
    };

    Site = async (id, kpiName, groupBy, granularity, aggregateBy, calculation, startTimeStamp, endTimeStamp, type, filter) => {
        const accessToken = await this.context.getToken();      
        const config = {
          headers: {
            Authorization: `${accessToken.accessToken}`,
          },
        };
        const url = `sites/${this.language}/${id}/graphic-data-points/${kpiName}/${groupBy}/${granularity}/${aggregateBy}/${calculation}/${this.addTimezone(startTimeStamp)}/${this.addTimezone(endTimeStamp)}/${type}`;

        try {
          const response = await axios.post(url, filter, config);
      
          if (this.debug) {
            console.log("graphic-data-points: " + url);
            console.log("response: " + JSON.stringify(response));
          }
      
          return response;
        } catch (e) {
          return e.response;
        }
      };
      

      SiteChartsDatapoints = async (id, kpiName, kpiType, groupBy, granularity, aggregateBy, calculation, startTimeStamp, endTimeStamp, type, filter, deviceUnits) => {
        const accessToken = await this.context.getToken();      
        const config = {
          headers: {
            Authorization: accessToken.accessToken,
            "x-device-units": JSON.stringify(deviceUnits)
          },
        };      
          const url = `sites/${this.language}/${id}/graphic-data-points/${kpiName}/${kpiType}/${groupBy}/${granularity}/${aggregateBy}/${calculation}/${this.addTimezone(startTimeStamp)}/${this.addTimezone(endTimeStamp)}/${type}`;
      
        try {
          const response = await axios.post(url, filter, config);      
          if (this.debug) {
            console.log("graphic-data-points: " + url);
            console.log("response: " + JSON.stringify(response));
          }      
          return response;
        } catch (e) {
          return e.response;
        }
      };
      


      SiteChartsStatisticalMeasures = async (id, kpiName, kpiType, aggregateBy, startTimeStamp, endTimeStamp, groupBy, filter, deviceUnits) => {
        const accessToken = await this.context.getToken();      
        const config = {
          headers: {
            Authorization: accessToken.accessToken,
            "x-device-units": JSON.stringify(deviceUnits)
          },
        };      
        const url = `sites/${this.language}/${id}/statistical-measures/${kpiName}/${kpiType}/${aggregateBy}/${this.addTimezone(startTimeStamp)}/${this.addTimezone(endTimeStamp)}/${groupBy}`;
      
        try {
          const response = await axios.post(url, filter, config);      
          if (this.debug) {
            console.log("statistical-measures: " + url);
            console.log("response: " + JSON.stringify(response));
          }      
          return response;
        } catch (e) {
          return e.response;
        }
      };
      

      SitesChartsSankey = async (kpiName, kpiType, brandCode, filterByBrandCode, startTimeStamp, endTimeStamp, deviceUnits, filter = [], ) => {
        const accessToken = await this.context.getToken();      
        const config = {
          headers: {
            Authorization: accessToken.accessToken,
            "x-device-units": JSON.stringify(deviceUnits)
          },
        };      
        const url = `sites/${this.language}/sankey/${kpiName}/${kpiType}/${this.addTimezone(startTimeStamp)}/${this.addTimezone(endTimeStamp)}/${brandCode}/${filterByBrandCode}`;
      
        try {
          const response = await axios.post(url, filter, config);      
          if (this.debug) {
            console.log("sankey: " + url);
            console.log("response: " + JSON.stringify(response));
          }      
          return response;
        } catch (e) {
          return e.response;
        }
      };
      

    SitesGetSmartComponentTypeIds = async () => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        var response = await axios.get("sites/" + this.language+"/smartcomponenttypeids", config);
        if (this.debug) {
            console.log("calling: " + "sites/" + this.language );
            console.log("response: " + JSON.stringify(response));
        }
        return response.data
    }

}