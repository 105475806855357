export function roundedDecimals(values, decimals) {
  if (!values || !decimals || (decimals?.length === 0 && decimals?.round === 0)) return values;
  if (!values.length) values = [values];

  const roundedValues = values.map((value) => {
    if (value.data && value.data.length) {
      value.data = value.data.map((element) => {
        if (Array.isArray(element)) {
          return element.map((el) => {
            return applyDecimals(el, decimals);
          });
        }
        return applyDecimals(element, decimals);
      });
    }
    return value;
  });
  return roundedValues;
}

export function applyDecimals(value, decimals) {
  // check if value exist && number with decimals
  if (decimals && !(decimals?.length === 0 && decimals?.round === 0) && value && !isNaN(value) && value % 1) {
    return calculateDecimals(value, decimals);
  }
  return value == undefined ? 0 : value;
}

function calculateDecimals(value, decimals) {
  const { round, length } = decimals;
  const newValue = length ? manipulateValueToLength(value, length): value;
  let roundedValue = newValue;

  if(round){
    switch (round) {
      case 0: // rounding down
        roundedValue = Math.floor(roundedValue);
        break;
      case 0.5: // rounding to 0.5
        // only if decimal.length > 1
        if (roundedValue.toString().split('.')[1] && roundedValue.toString().split('.')[1].length > 1) {
          roundedValue = Math.floor(roundedValue) + 0.5;
        }
        break;
      case 1: // rounding up
        roundedValue = Math.ceil(roundedValue);
        break;
      default: // in any other case, the original value is returned.
        break;
    }
  }
  return parseFloat(roundedValue);
}

function manipulateValueToLength(value, length) {
  var valueStr = value.toString();
  var valueSplit = valueStr.replace(',', '.').split('.'); // replace "," -> "." and split
  var decimal = valueSplit[1];

  // if decimal < length add zeros
  if (decimal.length < length) {
    decimal += '0'.repeat(length - decimal.length);
  }
  // if decimal > length remove chars
  else if (decimal.length > length) {
    decimal = decimal.substr(0, length);
  }

  return parseFloat(valueSplit[0] + '.' + decimal);
}

export function formatNumberAsString(number, decimals) {
  var round = decimals !== undefined ? (decimals.round ?? -1) : -1;
  var length = decimals !== undefined ? (decimals.length ?? 0) : 0

  if (typeof number === 'string'){
    number = Number(number)
  } else if (isNaN(number)) {
    number = 0;
  } 

  number = roundNumber(number, round)

  let parts = number.toFixed(length).toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parts.join('.');
}

export function formatNumber(number, decimals) {
  var round = decimals !== undefined ? (decimals.round ?? -1) : -1;
  var length = decimals !== undefined ? (decimals.length ?? 0) : 0

  if (typeof number === 'string'){
    number = Number(number)
  } else if (isNaN(number)) {
    number = 0;
  } 

  number = roundNumber(number, round)
  return Number(number.toFixed(length));
}

function roundNumber(number, round){
  if(round !== undefined){
    switch (round) {
      case 0: // rounding down
        return Math.floor(number);
      case 0.5: // rounding to 0.5
        // only if decimal.length > 1
        if (number.toString().split('.')[1] && number.toString().split('.')[1].length > 1) {
          return Math.floor(number) + 0.5;
        }
        break;
      case 1: // rounding up
        return Math.ceil(number);
      default: // in any other case, the original value is returned.
        return number;
    }
  }
  return number
}