import Style from '../styles/ProductsGeneralView.module.scss';
import { ProductsGeneralViewProps } from '../types/ProductGeneralTypes';
import KPIsChartContainer from '../../../../ChartsComponents/container/KPIsChartContainer';
import DateSelector from '../../../../CustomComponents/CustomDateSelector';
import { isEmpty } from 'lodash';
import { t } from 'i18next';
import ProductsGeneralInfoLocationContainer from '../container/ProductsGeneralInfoLocationContainer';
import ProductsSummaryContainer from '../../../../ProductsComponents/container/ProductsSummaryContainer';
import ProductsGeneralSkeletonView from './ProductsGeneralSkeletonView';

const ProductsGeneralView = (props: ProductsGeneralViewProps) => {
  return (
    <div className={Style.ProductsGeneralView}>
      <div className='productsGeneralView-content'>
      {/* <ProductsGeneralSkeletonView dev/> */}
        {!props.allChildComponentsRendered && <ProductsGeneralSkeletonView />}
        <div className={`box-content ${!props.allChildComponentsRendered && 'v-hidden'}`}>
          {props.notificationAlerts ? (
            <div className='summaryProduct mb-4'>
              <ProductsSummaryContainer
                product={props.product}
                notificationAlerts={props.notificationAlerts}
                handleEditProduct={props.handleEditProduct}
                id={props.id}
                componentFinished={props.componentFinished}
              />
            </div>
          ) : null}
          <div className='col generalInfo'>
            <div className='row mb-5'>
              <div className='col-12 col-lg-4 squareOf4'>
                <ProductsGeneralInfoLocationContainer product={props.product} componentFinished={props.componentFinished} />
                <h3>KPI</h3>
                {!isEmpty(props.productTelemetry) && (
                  <KPIsChartContainer
                    page='product'
                    pageZonePosition='Products-General-LastValues'
                    product={props.product}
                    groupBySelected={props.groupBySelected}
                    dateRange={props.dateRange}
                    chartViewZones={props.chartViewZones}
                    productTelemetry={props.productTelemetry}
                    componentFinished={props.componentFinished}
                  />
                )}
              </div>
              <div className='col-12 col-lg-8 pl-lg-5 dateSelector'>
                <div className='box-date'>
                  <div className='in-box-date'>
                    <div>
                      <h3 className='title col-6 p-0'>{t('general.statistics')}</h3>
                    </div>

                    <div className='sticky-div generalProduct'>
                      <DateSelector
                        className='col-12 w-100 d-flex p-0'
                        timeframes={['custom', 'day', 'week', 'month', 'year']}
                        defaultGranularity={props.groupBySelected}
                        onDateChange={props.handleDateRangeChange}
                        onGranularityChange={props.handleGranularityChange}
                        defaultDate={props.dateRange}
                        // componentFinished={props.componentFinished}
                      />
                    </div>
                  </div>

                  <KPIsChartContainer
                    page='product'
                    pageZonePosition='Products-General-MainCharts'
                    product={props.product}
                    groupBySelected={props.groupBySelected}
                    dateRange={props.dateRange}
                    productTelemetry={props.productTelemetry}
                    chartViewZones={props.chartViewZones}
                    componentFinished={props.componentFinished}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsGeneralView;
