import React, { useMemo, useState } from 'react';
import {
    AzureMap,
    AzureMapDataSourceProvider,
    AzureMapLayerProvider,
    AzureMapsProvider,
    AzureMapPopup,
    AzureMapHtmlMarker,
} from 'react-azure-maps';
import { withTranslation } from 'react-i18next';
import { AuthenticationType, data, MapMouseEvent, PopupOptions } from 'azure-maps-control';


const SitesMap = (props) => {

    const { t } = props;
    const openPopup = (e) => {

        if (e.target) {
            setIsVisible(true);
            const prop = e.target.options;
            setPopupOptions({
                ...popupOptions,
                position: new data.Position(
                    prop.position[0],
                    prop.position[1],
                ),
                pixelOffset: [0, -18],
            });
            if (prop.text)
                setPopupProperties({
                    ...prop.text,
                });
        }
    };

    const eventToMarker = [{ eventName: 'mousemove', callback: openPopup }];

    function azureHtmlMapMarkerOptions(point) {
        return {
            position: point.location,
            text: point,
            title: point.name,
        };
    }
    function getPointColor(point) {

        if (point.notifications == null) {
            return '';
        }
        let types = [];
        let max = { val: -1, type: '' };
        if (point.notifications.alerts.length > 0) {
            types = ['alarm', 'rule', 'warning'];
        }

        types.map(function (elem) {
            let obj = point.notifications.alerts.find(e => e.type == elem);
            if (obj != undefined) {
                if (max.val < obj.total) {
                    max.val = obj.total;
                    max.type = elem;
                }
            }
        });
        return (max.val > -1 ? 'filterColor' + max.type : '');
    }
    function MarkerIcon({ pPoint }) {
        let color = getPointColor(pPoint)
        return <img src={require('../../assets/icons/geo.svg').default} className={"geoIcon " + color + " prdEventId-" + (pPoint.id ? pPoint.id : '')} />
    }
    const renderPoint = (point) => {
        const rendId = Math.random();
        /*
        return (
            <AzureMapFeature
                key={rendId}
                id={rendId.toString()}
                
                coordinate={point.location}
                properties={{
                    title: point.name,
                    icon: 'pin-round-blue',
                }}
            />
        );*/

        // We will be changing to HtmlMarkers so we can interact with the locations directly
        return (

            <AzureMapHtmlMarker
                key={rendId}
                id={rendId.toString()}
                //markerContent={<RoomIcon />}
                markerContent={<MarkerIcon pPoint={point} />}
                type="Point"
                options={{ ...azureHtmlMapMarkerOptions(point) }}
                events={eventToMarker}

                coordinate={point.location}
                properties={{
                    title: point.name,
                    icon: 'pin-round-blue',
                }}
            />

        );
    };


    const memoizedOptions = {
        textOptions: {
            textField: ['get', 'title'], //Specify the property name that contains the text you want to appear with the symbol.
            offset: [0, 1.2],
        },
    };

    let center = props.startingLocation || [0, 40]

    let zoom = 1;

    if (props.autoZoom) {

        if (props.locations.length == 1) {
            zoom = 9;
            center = [props.locations[0].location[0], props.locations[0].location[1]]
        }
        else if (props.locations.length > 1) {

            let first = true;

            let maxX, minX, maxY, minY;
            props.locations.forEach((point) => {
                if (first) {
                    maxX = point.location[0]
                    minX = point.location[0]
                    maxY = point.location[1]
                    minY = point.location[1]
                    first = false;
                }
                else {
                    if (point.location[0] > maxX) maxX = point.location[0]
                    else if (point.location[0] < minX) minX = point.location[0]

                    if (point.location[1] > maxY) maxY = point.location[1]
                    else if (point.location[1] < minY) minY = point.location[1]
                }
            })

            let xDist = maxX - minX
            let yDist = maxY - minY

            center = [(maxX + minX) / 2, (maxY + minY) / 2]

            if (xDist < 1 && yDist < 0.4) {
                zoom = 9
            }
            else if (xDist < 2 && yDist < 0.8) {
                zoom = 8
            }
            else if (xDist < 3.9 && yDist < 1.8) {
                zoom = 7
            }
            else if (xDist < 8.5 && yDist < 3.5) {
                zoom = 6
            }
            else if (xDist < 20 && yDist < 6.8) {
                zoom = 5
            }
            else if (xDist < 40 && yDist < 14) {
                zoom = 4
            }
            else if (xDist < 80 && yDist < 24) {
                zoom = 3
            }
            else if (xDist < 170 && yDist < 60) {
                zoom = 2
            }

        }
    }
    const [isVisible, setIsVisible] = useState(false);
    const [markers] = useState(props.locations);
    const [markersLayer] = useState('SymbolLayer');
    const [layerOptions] = useState(memoizedOptions);
    const [popupOptions, setPopupOptions] = useState({});
    const [popupProperties, setPopupProperties] = useState({});

    const option = {
        center: center,
        zoom: zoom,
        view: 'Auto',
        authOptions: {
            authType: AuthenticationType.subscriptionKey,
            subscriptionKey: 'xlUv8b1iNmVh1uccKVhZhV44rlryS6vl9Yx6mto8mwo'
        },
        showFeedbackLink: false,
        showLogo: false
    };

    const memoizedMarkerRender = useMemo(
        () => markers.map((marker) => renderPoint(marker)),
        [markers],
    );



    function popupContent() {
        let types = [];
        let content = [];
        let contentList = [];
        if (popupProperties.notifications != null) { types = ['alarm', 'rule', 'warning'] }
        let name = { "rule": t("productspages.alertreporting.rules"), "alarm": t("productspages.alertreporting.alarms"), "warning": t("productspages.alertreporting.warnings") };
        let keyP = 0;
        types.map(function (elem) {
            let obj = popupProperties.notifications.alerts.find(e => e.type == elem);
            let icon = elem + "_icon_color.svg";

            //obj = obj == undefined ? '' : (<div className="notificationAlarms mr-3"><div className={elem + " mr-2"}>{obj.total}</div><span>{elem}</span></div>);
            obj = obj == undefined ? '' : (<div key={'key-'+keyP} className="notificationAlarms mr-3"> <img src={require('../../assets/icons/' + icon)} className="img-fluid" /><span>{name[elem]}</span></div>);
            content.push(obj);
            keyP++;
        });
        if (popupProperties.notifications != null) {
            popupProperties.notifications.alertslist.map(function (elem) {
                let obj = (<div className="row mb-3"><div key={'key-' + keyP} className={"list-point col " + elem.individualRule.type}></div> <span className="col t2">{elem.individualRule.description ? elem.individualRule.description : elem.individualRule.name}</span></div>);
                contentList.push(obj);
                keyP++;
            });
        }

        return (
            <div style={styles.popupStyles} className="notificationMapPopup">
                <h4>{popupProperties.name}</h4>
                <span className="t2 w-100">{popupProperties.address}</span>

                <div className="d-flex mt-3">
                    {content}
                </div>
                <hr style={styles.hr} />
                <div className="col notificationListContent">
                    {contentList}
                </div>
            </div>
        );
    }
    function onClickMap(e) {
        setIsVisible(false);
    }

    return (<>

        <AzureMapsProvider>
            <div className="azureMaps" style={{ height: '100%', width: '100%' }}>
                <AzureMap options={option} events={{ click: onClickMap }} >
                    <AzureMapDataSourceProvider
                        id={'sitesMap AzureMapDataSourceProvider'}
                        options={{ cluster: true, clusterRadius: 2 }}
                    >
                        <AzureMapLayerProvider
                            id={'sitesMap AzureMapLayerProvider'}
                            options={layerOptions}
                            type={markersLayer}

                        />
                        {memoizedMarkerRender}

                    </AzureMapDataSourceProvider>


                    <AzureMapPopup
                        isVisible={isVisible}
                        options={popupOptions}
                        popupContent={popupContent()}
                    />
                </AzureMap>
            </div>
        </AzureMapsProvider>

    </>);
};

const styles = {
    map: {
        height: 500,
    },
    popupStyles: {
        padding: '20px',
        color: 'black',
    },
    hr: {
        margin: '10px 0',
    },
};

export default withTranslation()(SitesMap);