import { PureComponent } from 'react';
import AuthenticationContext, { AuthContextType } from '../../../../../Authentication/types/AuthContextType';
import Loader from '../../../../Common/Loader';
import UserPreferencesView from '../views/UserPreferencesView';
import UserHttpClient from '../../../../../HttpClient/UserHttpClient';
import { ResponseNotificationsPreferences, PreferenceGroup, Preferences } from '../types/NotificationsPreferencesTypes';
import { MessageType } from '@ComponentsRoot/Management/types/MessageType';
import { User } from '@ComponentsRoot/Management/types/User';
import {
  Measurement,
  UserMeasure,
  UserUnitObjUpdate,
  responseAllMeasures,
} from '../../UserConfigUnits/types/UserConfigUnitsTypes';
import { findDifferences, manageInfoUser } from '../../../../Common/utils/helpers';
import MethodsHttpClient from '../../../../../HttpClient/MethodsHttpClient';
import _ from 'lodash';

class UserPreferencesContainer extends PureComponent<
  {},
  {
    loading: boolean;
    // preferences: Preferences[];
    preferences: { initialData: Preferences[]; editData: Preferences[] } | null;
    message: MessageType | null;
    user: User | null;
    allMeasures: Measurement[] | null;
    userMeasureUnits: { initialData: UserMeasure[]; editData: UserMeasure[] } | null;
    saveDisabled: boolean;
  }
> {
  constructor(props: {}) {
    super(props);
    this.state = {
      loading: true,
      preferences: null,
      message: null,
      user: null,
      allMeasures: null,
      userMeasureUnits: null,
      saveDisabled: true,
    };
  }
  static contextType = AuthenticationContext;

  componentDidMount = async () => {
    await this.getNotificationPreferences();
    await this.getAllMeasureUnits();
    await this.getUserInfo();
  };

  postChanges = async (measureId: number, unitId: number) => {
    const { userMeasureUnits, allMeasures } = this.state;
    const measureSelected = userMeasureUnits!.editData.find((measure) => measure.measureTypeId === measureId);
    const unitSelected = allMeasures!
      .find((measure) => measure.id === measureId)
      ?.measureUnits.find((unit) => unit.id === unitId);

    const newEditData = [...userMeasureUnits!.editData];
    const measureIndex = newEditData.findIndex((measure) => measure.measureTypeId === measureId);

    newEditData[measureIndex] = {
      ...measureSelected!,
      measureUnitId: unitSelected!.id,
      measureUnitName: unitSelected!.name,
      measureUnitCode: unitSelected!.code,
    };
    this.setState({
      userMeasureUnits: { initialData: userMeasureUnits!.initialData, editData: newEditData },
    });
  };

  getAllMeasureUnits = async () => {
    // get all measures
    const httpMethods = new MethodsHttpClient(this.context);
    let data: responseAllMeasures = await httpMethods.getMeasureUnits();
    this.setState({ allMeasures: data.content });
  };

  getUserInfo = async () => {
    const userDataContext = this.context as AuthContextType;
    const userB2CData = await manageInfoUser(userDataContext, 'userB2CData');
    const userBasicData = await manageInfoUser(userDataContext, 'userBasicData');
    this.setState({
      user: userB2CData,
      loading: false,
      userMeasureUnits: {
        initialData: userBasicData.userMeasureUnits,
        editData: _.cloneDeep(userBasicData.userMeasureUnits),
      },
    });
  };

  getNotificationPreferences = async () => {
    const context = this.context;
    const httpUser = new UserHttpClient(context);
    try {
      let data: ResponseNotificationsPreferences = await httpUser.GetNotificationPreferences();
      this.setState({
        preferences: {
          initialData: data.content,
          editData: _.cloneDeep(data.content),
        },
      });
    } catch (error) {
      this.setState({ message: { type: 'error', content: 'Error fetching preferences' } });
    }
  };

  handleChange = (code: string) => {
    let updatedPreferences = JSON.parse(JSON.stringify(this.state.preferences?.editData));
    let type = code.split('-')[0],
      channel = code.split('-')[1];

    let newChannel = true;
    updatedPreferences.forEach((preference) => {
      if (preference.type === type) {
        newChannel = false;
        if (preference.channels.includes(channel)) {
          preference.channels = preference.channels.filter((e) => e !== channel);
        } else {
          preference.channels.push(channel);
        }
      }
    });

    if (newChannel) {
      updatedPreferences.push({
        type: type,
        channels: [channel],
      });
    }

    this.setState({
      preferences: {
        initialData: this.state.preferences!.initialData,
        editData: updatedPreferences,
      },
    });
  };

  managePreferences = () => {
    const preferences: PreferenceGroup = {
      alarmPreferences: [],
      warningPreferences: [],
      rulePreferences: [],
    };
    if (this.state.preferences && this.state.preferences.editData) {
      this.state.preferences.editData.forEach((preference) => {
        switch (preference.type) {
          case 'alarm':
            preferences.alarmPreferences = preference.channels;
            break;
          case 'warning':
            preferences.warningPreferences = preference.channels;
            break;
          case 'rule':
            preferences.rulePreferences = preference.channels;
            break;
        }
      });
    }
    return preferences;
  };

  updatePreferences = async () => {
    try {
      this.setState({ loading: true });
      if (!_.isEqual(this.state.preferences!.initialData, this.state.preferences!.editData)) {
        await this.updateNotifications();
      }

      if (!_.isEqual(this.state.userMeasureUnits!.initialData, this.state.userMeasureUnits!.editData)) {
        await this.updateUserMeasureUnits();
      }
      this.setState({ message: { type: 'success', content: 'succesfully updated' }, loading: false });
    } catch (error) {
      this.setState({ message: { type: 'error', content: 'error updating preferences' }, loading: false });
    }
  };

  updateUserMeasureUnits = async () => {
    const diff = findDifferences(this.state.userMeasureUnits!.editData!, this.state.userMeasureUnits!.initialData!);
    if (diff.length > 0) {
      const measureToUpdate: UserUnitObjUpdate = { UserMeasureUnits: [] };
      (diff as UserMeasure[]).forEach((measure: UserMeasure) => {
        measureToUpdate.UserMeasureUnits.push({
          measureTypeId: measure.measureTypeId,
          measureUnitId: measure.measureUnitId,
        });
      });
      const context = this.context as AuthContextType;
      const httpUser = new UserHttpClient(context);
      try {
        let data: { content: UserMeasure[]; errors?: string } = await httpUser.PostMeasureUnits(
          this.state.user?.id,
          measureToUpdate
        );
        this.setState({ userMeasureUnits: { initialData: data.content, editData: data.content } });
        context.setNewMeasures(data.content);
      } catch (error) {
        console.log(error);
      }
    }
  };

  updateNotifications = async () => {
    const context = this.context;
    const httpUser = new UserHttpClient(context);
    try {
      let data: ResponseNotificationsPreferences = await httpUser.PostNotificationPreferences(this.state.preferences?.editData);
      this.setState({ preferences: { initialData: data.content, editData: data.content } });
    } catch (error) {
      console.log(error);
    }
  };

  closeMessage = () => {
    this.setState({ message: null });
  };

  calculateSaveDisabled = () => {
    return (
      _.isEqual(this.state.preferences!.initialData, this.state.preferences!.editData) &&
      _.isEqual(this.state.userMeasureUnits!.initialData, this.state.userMeasureUnits!.editData)
    );
  };

  render() {
    return (
      <>
        {this.state.loading ? (
          <Loader />
        ) : (
          <UserPreferencesView
            message={this.state.message}
            closeMessage={this.closeMessage}
            preferences={this.managePreferences()}
            handleChange={this.handleChange}
            updatePreferences={this.updatePreferences}
            user={this.state.user!}
            allMeasures={this.state.allMeasures!}
            editUserMeasureUnits={this.state.userMeasureUnits!.editData}
            postChanges={this.postChanges}
            saveDisabled={this.calculateSaveDisabled()}
          />
        )}
      </>
    );
  }
}

export default UserPreferencesContainer;
