import { GeneralNavMenuViewProps } from "../types/GeneralNavMenuType";
import { WithTranslation } from "react-i18next";
import { t } from 'i18next';
import { Link } from 'react-router-dom';

const DropdownProducts = (props: GeneralNavMenuViewProps & WithTranslation) => {
    let urls = props.urlsMenu

    let dataLoaded = props.myProducts.content != undefined;
    if (dataLoaded) {
        let listitems = props.myProducts.content.map((item, i) => {
            if (i < 4) {
                return (
                    <div className="installationRow" key={"div-" + item.id} >
                        <Link onClick={() => { props.showMenu(0) }}  className="linkitem noUnderLine productRow" style={{ marginBottom: '0px!important' }} to={`/product/${item.id}`}>
                            <div className="row" style={{ marginTop: '3px' }}>
                                <div className="col-2">
                                    <div className="menuIcon" style={{ backgroundImage: 'url("' + item.productType.imageUri + '")' }} > </div>
                                </div>
                                <div className="col-8" style={{ display: "flex", alignItems: "center" }}>
                                    <div className="col-6">
                                        <div className="menuSpan menuInstallationTitle noUnderLine "><h4 className="menuSpan" style={{ float: 'left' }}> {item.productType.name == null ? item.designLine : item.productType.name} -{item.productType.letter}{item.tag}</h4></div>
                                    </div>
                                    <div className="col-2">
                                        <div className="menuSpan t3">{item.installation.name}</div>
                                    </div>
                                    <div className="col-8">
                                        <div className="menuSpan t3">{item.puuid}</div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )
            }
        })

        return (
            <div className="row ">

                <div className="col-8 header-top">
                    <div style={{ marginLeft: '20%' }}>
                        <h2 style={{marginTop: '25px', padding: '0px'}}>{t('recentlyViewed')}</h2>
                    </div>
                    <div style={{ marginLeft: '20%' }}>
                        {listitems}
                    </div>
                </div>

                <div className="col-4" style={{ margin: 0, padding: 0 }} >
                    <div className="header-top mb-0" style={{ margin: '24px', padding: 0, backgroundImage: 'url("' + urls.imageMenuProducts + '")', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain' }} ></div>
                    <div className="d-flex" style={{ justifyContent: "center" }}>  <Link style={{ textDecoration: "none" }} className="m2 linkGoTo" to={`/filter/productsFilter`}> {t('actions.goToProducts')} <img src={require('../../../assets/icons/arrow_right-24.svg').default} />  </Link> </div>
                </div>
            </div>
        )
    } else {
        return <div></div>
    }
};

export default DropdownProducts;