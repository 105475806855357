import axios from "axios";
import i18n from '../i18n/i18n';

export default class ChartHttpClient {


    constructor(context) {
        this.context = context;
        this.language = i18n.language;
        this.debug = false;
    }

    ProductsGetChartViewZones = async () => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        try {
            var response = await axios.get("charts/" + this.language + "/viewchartszone/Product", config);
            if (this.debug) {
                console.log("calling: " + "charts/" + this.language + "//api/v{version}/{culture}/charts/{page}/viewchartszone/Product");
                console.log("response: " + JSON.stringify(response));
            }
            return response;

        } catch (e) {
            return e.response;
        }

    }

    InstallationsGetChartViewZones = async () => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        try {
            var response = await axios.get("charts/" + this.language + "/viewchartszone/Installation", config);
            if (this.debug) {
                console.log("calling: " + "charts/" + this.language + "//api/v{version}/{culture}/charts/{page}/viewchartszone/Installation");
                console.log("response: " + JSON.stringify(response));
            }
            return response;

        } catch (e) {
            return e.respone;
        }

    }
    SitesGetChartViewZones = async () => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        try {
            var response = await axios.get("charts/" + this.language + "/viewchartszone/Site", config);
            if (this.debug) {
                console.log("calling: " + "charts/" + this.language + "//api/v{version}/{culture}/charts/{page}/viewchartszone/Installation");
                console.log("response: " + JSON.stringify(response));
            }
            return response;

        } catch (e) {
            return e.respone;
        }

    }

    PictoByKPI = async (kpi,brandCode) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        try {
            var response = await axios.get("charts/" + this.language + "/picto-by-kpi/" + kpi + "/" + brandCode, config);
            if (this.debug) {
                console.log("calling: /api/v{version}/{culture}/charts/picto-by-kpi");
                console.log("response: " + JSON.stringify(response));
            }
            return response

        } catch (e) {
            return e.response;
        }

    }

}