import axios from "axios";
import i18n from '../i18n/i18n';

export default class HttpClient {


    constructor(context) {
        this.context = context;
        this.language = i18n.language;
        this.debug = false;
    }


    GetSmartComponentPackage = async (systemId = null, userId = null, available = null,production = null, orderBy = null, asc = null) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        let filter = {
            systemId: systemId,
            userId: userId,
            available: available,
            production: production,
            orderBy: orderBy,
            asc: asc
        }
        var response = await axios.post("firmwares/" + this.language + "/smartComponentPackage/" ,filter, config);
        if (this.debug) {
            console.log("calling Firmwares " + "firmwares/" + this.language + "/smartComponentPackage/" + systemId);
            console.log("response smartcomponent packages: " + JSON.stringify(response));
        }
        return response.data
    }

    PostSmartComponentPackage = async (systemId, smartComponentTypeId, packageVersion, packageName, packageDescription, packageIsProduction) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        let content = {
            PackageVersion: packageVersion,
            name: packageName,
            description: packageDescription,
            production: packageIsProduction,
            systemId: !!systemId,
            smartComponentTypeId: !!smartComponentTypeId
        }

        var response = await axios.post("firmwares/" + this.language + "/smartComponentPackage/" + (systemId ? systemId: smartComponentTypeId), content, config);
        if (this.debug) {
            console.log("calling Firmwares " + "firmwares/" + this.language + "/smartComponentPackage");
            console.log("response post smartcomponent packages: " + JSON.stringify(response));
        }
        return response.data
    }
    SmartcomponentPackageDependencyes = async (dependency) => {
        let accessToken = await this.context.getToken();

        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.post("firmwares/" + this.language + "/smartcomponent-package-dependency", { dependency: dependency }, config);
        if (this.debug) {
            console.log("calling Firmwares " + "firmwares/" + this.language + "/smartcomponent-package-dependency");
            console.log("response post smartcomponent packages: " + JSON.stringify(response));
        }
        return response.data
    }

    GetSmartComponent = async (smartComponentTypeId = "-1") => {
        let accessToken = await this.context.getToken();

        if (smartComponentTypeId == null || smartComponentTypeId == "") {
            smartComponentTypeId = "-1";
        }
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }

        var response = await axios.get("firmwares/" + this.language + "/getSmartComponent" + "/" + smartComponentTypeId, config);
        if (this.debug) {
            console.log("calling Firmwares " + "firmwares/" + this.language + "/smartComponent");
            console.log("response get smartcomponent: " + JSON.stringify(response));
        }
        return response.data
    }

    patchSmartComponent = async (smartComponentTypeId, patchObjValue) => {
        let accessToken = await this.context.getToken();      
        let config = {
          headers: {
            Authorization: `${accessToken.accessToken}`,
          }
        };      
        try {
          const response = await axios.post(`firmwares/${this.language}/patchSmartComponent-package/${smartComponentTypeId}`, patchObjValue, config);
          return response.data;
        } catch (error) {
          // Handle error
          console.error("Error patching smart component:", error);
          throw error;
        }
      };

      patchSmartComponentFirmware = async (smartComponentFirmwareId, patchObjValue) => {
        let accessToken = await this.context.getToken();      
        let config = {
          headers: {
            Authorization: `${accessToken.accessToken}`,
          }
        };      
        try {
          const response = await axios.post(`firmwares/${this.language}/patchSmartComponent-firmware/${smartComponentFirmwareId}`, patchObjValue, config);
          return response.data;
        } catch (error) {
          // Handle error
          console.error("Error patching smart component:", error);
          throw error;
        }
      };

    PostSmartComponent = async (smartComponentTypeId, firmwareVersion, data) => {

        let accessToken = await this.context.getToken();

        let apiOptions = await axios.get("/firmwares/GetApiOptions")
        
        let url = apiOptions.data.baseUrl + apiOptions.data.version + "/" + this.language + apiOptions.data.firmwaresUrl + "/smartcomponent" + "?smartComponentTypeId=" + smartComponentTypeId + "&firmwareVersion=" + firmwareVersion

        // let url = "https://azapp-rgpiotcore-webapi-dev.azurewebsites.net/api/v1/es/firmwares" + "/smartcomponent" + "?smartComponentTypeId=" + smartComponentTypeId + "&firmwareVersion=" + firmwareVersion 

        var formData = new FormData()
        formData.append("file", data.get("file"), data.get("file").name);

        let config = {
            headers: {
                Authorization: "Bearer " + accessToken.accessToken.toString(),
            }
        }

        await axios.post(url, formData, config);
    }

    deleteSmartComponent = async (smartComponentId) => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        return await axios.delete("firmwares/" + this.language + "/deleteSmartComponent" + "/" + smartComponentId, config);
    }

    deleteSmartComponentPackageDependencyId = async (dependencyId) => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        return await axios.delete("firmwares/" + this.language + "/deleteSmartComponent-package-dependency" + "/" + dependencyId, config);
    }

    deleteSmartComponentPackage = async (packageId) => {
        let accessToken = await this.context.getToken();
        let config = {
            headers: {
                Authorization: `${accessToken.accessToken}`,
            }
        }
        return await axios.delete("firmwares/" + this.language + "/deleteSmartComponent-package" + "/" + packageId, config);
    }
    
}