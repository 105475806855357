// TODO REFACTOR separate container/view

import React, { Component } from 'react';
import '../../../../../../App.css';
import '../../../../../refreshBorderAnimation.css';
import AuthenticationContext from "../../../../../../Authentication/types/AuthContextType";
import { withTranslation } from 'react-i18next';
import FirmwaresHttpClient from "../../../../../../HttpClient/FirmwaresHttpClient";
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import UploadIcon from '@mui/icons-material/Upload';
import Select from 'react-select';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { selectDropdownStyle } from '../../../../config/ManagementConfig';

class SWFirmwareAddForm extends Component {

    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.selectNewFirmwareSmartComponentRef = React.createRef();
        this.selectNewFirmwareSysIdRef = React.createRef();
        this.newFirmwareSmartComponentTypeRef = React.createRef();

        this.state = {
            activeStep: this.props.activeStep ? this.props.activeStep: 0,
            smartComponentSelect: null,
            fileName: "",
            systemIds: [],
            smartComponentTypesId: []
        };
        this.firmware = {
            systemId: this.props.systemId ? this.props.systemId : null,
            smartComponent: this.props.smartComponent ? this.props.smartComponent : null,
            name: "",
            version: "",
            file: null,
            mandatory: false,
            available: true,
            smartComponentTypeSelected: this.props.smartComponentTypeSelected ? this.props.smartComponentTypeSelected: ""
        }

        if (!this.props.systemId) {
            this.systemIdsOptions = this.fillSystemIdSelect();
        }

    }

    componentDidMount() {
    //   this.setState({ activeStep: 0 });
      this.fillTitleStep(this.state.activeStep);
      this.fillSmartComponentIdSelect()
    }

    fillSmartComponentIdSelect() {
        //We map them to fit select component
        let sysIds = null
        if(this.props.systemIds){
            sysIds = this.props.systemIds.map(obj => ({ label: obj.id, value: obj.id }));
        }
        let smartComponentTypesId = this.props.allSmartComponentTypes.map(smartCompo => ({ label: `${smartCompo.id} - ${smartCompo.name}`, value: smartCompo.id }));        
        this.setState({ systemIds: sysIds, smartComponentTypesId });
    }

    fillSystemIdSelect() {
        //We map them to fit select component
        if(!this.props.systemIds){
            return null
        }
        let sysIds = this.props.systemIds.map(obj => ({ label: obj.id, value: obj.id }));
        return sysIds;
}

    getStepButtons(step) {
        const { t } = this.props;
        let bBack = null;
        let bNext = null;

        if (step === 0 || this.props.skipType) {
            bBack = (<button onClick={() => this.close()} className="MuiButtonBase-root MuiButton-root MuiButton-text primary pull-left float-left" tabIndex="0" type="button">
            <span className="MuiButton-label">{t('actions.cancel')}</span>
            <span className="MuiTouchRipple-root"></span>
        </button>);           
        }else{
            bBack = (<button onClick={this.handleFirmwareBack} className="MuiButtonBase-root MuiButton-root MuiButton-text primary pull-left float-left" tabIndex="0" type="button">
                <span className="MuiButton-label">{t('actions.back')}</span>
                <span className="MuiTouchRipple-root"></span>
            </button>);   
        }

        if (step == 1) {
            bNext = (<button className="MuiButtonBase-root MuiButton-root MuiButton-text primary pull-right float-right" tabIndex="0" onClick={async (e) => await this.saveData(e)}>
                <span className="MuiButton-label">{t('actions.save')}</span>
                <span className="MuiTouchRipple-root"></span>
            </button>);
        } else {
            bNext = (<button className="MuiButtonBase-root MuiButton-root MuiButton-text primary pull-right float-right" tabIndex="0" type="submit" form="FirmwareStep1Form">
                <span className="MuiButton-label">{t('actions.next')}</span>
                <span className="MuiTouchRipple-root"></span>
            </button>);
        }

        return <div className="col">
            {bBack}
            {bNext}
        </div>;
    }
    close(reload = false) {
        this.props.unmountMe(reload);
    }
    setValue = (event, name) => {
        this.firmware[name] = event;
    };

    fillSmartComponentSelect(systemId) {
        const { t } = this.props;
        let content = this.props.systemIds.filter((obj) => obj.id == systemId);
        let ret = null;
        if (content.length > 0) {
            content = content[0].smartComponentTypes.map(obj => ({ label: obj.name, value: obj.id }));
            ret = (<React.Fragment>
                <Select className="react-select-container"
                    ref={this.selectNewFirmwareSmartComponentRef}
                    required
                    styles={selectDropdownStyle({height: 200})}
                    disabled={this.firmware.smartComponent ? true : false}
                    options={content}
                    onChange={(event) => {
                        this.selectedChange(event, "newFirmwareSmartComponent", "smartComponent", "selectNewFirmwareSmartComponentRef")
                    }}
                    defaultValue={this.firmware.smartComponent == null ? { label: "", value: "" } : this.firmware.smartComponent}

                />
                <input
                    id="newFirmwareSmartComponent"
                    tabIndex={-1}
                    autoComplete="off"
                    style={{ opacity: 0, height: 0, position: "absolute" }}
                    required={true}
                    defaultValue={this.firmware.smartComponent?.value}
                />
            </React.Fragment>)
            this.setState({ smartComponentSelect: ret})
        }
    }



        fileUpload = (e) => {
            e.preventDefault();
            let reader = new FileReader();
            let file = e.target.files[0];
            
            if (file) {
                // Check if file has blank spaces
              if (/\s/.test(file.name)) {
                this.props.showMessageInTable("messages.errorBlankSpaces", "error");
                e.target.value = ''; 
                return;
              }
              
              reader.onloadend = () => {
                this.firmware.file = file;
                this.setState({ fileName: file.name });
              }
              
              reader.readAsDataURL(file);
            }
          }
          

    handleFormSubmit = (e) => {
        this.handleFirmwareNext();
        e.preventDefault();
    };

    handleFirmwareNext = () => {        
        let s = this.state.activeStep; 
        s++;
        this.fillTitleStep(s);
        this.setState({ activeStep: s });
        // this.cleanMessage();
    };

    handleFirmwareBack = () => {
        let s = this.state.activeStep - 1;        
        this.fillTitleStep(s);
        this.setState({ activeStep: s });        
        // this.cleanMessage();
    };

    selectedChange = (value, target, object, nameRef) => {
        this.firmware[object] = value;
        document.getElementById(target).value = value;
        const select = this[nameRef]?.current?.inputRef;
        // select.setCustomValidity('');
    };

    setTypeFirmware = (value) =>{
        this.firmware = {
            systemId: this.props.systemId ? this.props.systemId : null,
            smartComponent: this.props.smartComponent ? this.props.smartComponent : null,
            name: "",
            version: "",
            file: null,
            mandatory: false,
            available: true,
            type_firmware: value
        }  
        this.setState({ type_firmware: value });
    }
    

    async saveData(e) {
        e.preventDefault();
        const formulario = new FormData()
        formulario.append('file', this.firmware.file)
        this.http = new FirmwaresHttpClient(this.context, null);
        const componentSelected = this.firmware.smartComponent && this.firmware.smartComponent.value ? this.firmware.smartComponent.value: this.firmware.smartComponentTypeSelected;
        try {
            let datos = await this.http.PostSmartComponent(componentSelected, this.firmware.version, formulario).then(x => {
                this.props.showMessageInTable("messages.successAddFirmware", "success");
                if (this.props.systemId) {
                    if(x){
                        this.props.unmountMe(true, x.content);
                    }
                    else{
                        this.props.unmountMe(true);
                    }
                } else {
                    this.close(true);
                }
            });
        } catch (err) {
            if (err.request?.status == 409) {
                this.props.showMessageInTable("firmwareManagementPage.FirmwareVersionExists", "error");
            }
            else{
                this.props.showMessageInTable("messages.errorAddFirmware", "error");
            }
        }
    }

    renderTooltip = (text) => (props) => (
        <Tooltip id="button-tooltip" target="{target.current}" position="fixed" {...props}>
            {text}
        </Tooltip>
    );

    getSmartCompoTypeWithId = (id) => {
        if(!id){
            return null
        }
        return this.props.allSmartComponentTypes.find(smartCompo => smartCompo.id === id).name;
    }

    getStep0() {
        const { t } = this.props;
        return (
            <div>
                <form id="FirmwareStep1Form" onSubmit={(e) => this.handleFormSubmit(e)}>
                    <h2>{t('firmwareManagementPage.addFirmware')}</h2>
                    <div className="row">
                        <div className="col">
                            <h3>{t('firmwareManagementPage.firmwareSelectTypeQuestion')}</h3>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby='demo-controlled-radio-buttons-group'
                                    name='controlled-radio-buttons-group'
                                    value={this.firmware.type_firmware ? this.firmware.type_firmware: ""}
                                    onChange={(event) => { this.setTypeFirmware(event.target.value) }}>
                                    <FormControlLabel
                                        value='SystemId'
                                        control={<Radio required={true} />}
                                        label={t('actions.yes')}
                                        disabled={this.props.modeForm === "edit"}
                                    />
                                    <FormControlLabel
                                        value='SmartComponentType'
                                        control={<Radio required={true} />}
                                        label={t('actions.no')}
                                        disabled={this.props.modeForm === "edit"}
                                    />                                    
                                </RadioGroup>
                            </FormControl>                     
                        </div>
                    </div>
                </form>
            </div>);
        }

    getStep1() {
        const { t } = this.props;

        if(this.firmware.type_firmware === "SystemId" || this.props.type_firmware === "SystemId"){
        setTimeout(() => {
            const selectNewFirmwareSmartComponent = this.selectNewFirmwareSmartComponentRef?.current?.inputRef;
            const selectNewFirmwareSysId = this.selectNewFirmwareSysIdRef?.current?.inputRef;
            if (selectNewFirmwareSmartComponent && selectNewFirmwareSysId) {
            selectNewFirmwareSmartComponent.setCustomValidity(this.props.t('general.error_required'));
            selectNewFirmwareSysId.setCustomValidity(this.props.t('general.error_required'));
            }
        }, 1000);
        return (
            <div>    
                <h2>{t('firmwareManagementPage.addSmartComponentFirmware')}</h2>
                <form id="FirmwareStep1Form" onSubmit={(e) => this.handleFormSubmit(e)}>
                    <div className="row">
                        <div className="col">
                            <h3>{t('firmwareManagementPage.systemId')}*</h3>
                            {this.props.systemId ?
                                <TextField size="small" fullWidth variant="outlined" defaultValue={this.firmware.systemId.label} disabled />
                                :
                                <React.Fragment>                               
                                    <Select
                                        ref={this.selectNewFirmwareSysIdRef}
                                        className="react-select-container"
                                        required
                                        styles={selectDropdownStyle({height: 200})}
                                        options={this.systemIdsOptions}
                                        disabled={this.props.systemId ? true : false}
                                        onChange={(event) => {

                                            this.firmware.smartComponent = null;
                                            this.selectedChange(event, "newFirmwareSysId", "systemId", "selectNewFirmwareSysIdRef")
                                            this.setState({ smartComponentSelect: null }, () => {
                                                this.fillSmartComponentSelect(event.value);
                                            });

                                        }}
                                        defaultValue={this.firmware.systemId}

                                    />
                                    <input
                                        id="newFirmwareSysId"
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{ opacity: 0, height: 0, position: "absolute" }}
                                        required={true}
                                        defaultValue={this.firmware.systemId?.value}
                                    />
                                </React.Fragment>
                            }
                        </div>
                        {this.state.smartComponentSelect || this.props.smartComponent ?
                            <div className="col-12 col-md-6">
                                <h3>{t('firmwareManagementPage.smartComponentTypeId')}*</h3>
                                {this.props.smartComponent ?
                                    <TextField size="small" fullWidth variant="outlined" defaultValue={this.firmware.smartComponent.label} disabled />
                                    :
                                    <React.Fragment>
                                        {this.state.smartComponentSelect}
                                    </React.Fragment>
                                }
                            </div>
                            :
                            null
                        }    

                    </div>

                    <div className="row " >

                        <div className="col-12 col-md-6">

                            <h3>{t('firmwareManagementPage.uploadFile')}*</h3>
                            <div className="row">
                                <div className="col">
                                    <TextField size="small" fullWidth variant="outlined" value={this.state.fileName} disabled onChange={(event) => { setName(event.target.value); }} />
                                </div>
                                <div className="col-auto">
                                    <IconButton color="primary" aria-label="upload picture" component="label">
                                        <input required hidden accept="file/*" type="file" onChange={(event) => this.fileUpload(event)} />
                                        <UploadIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <h3>{t('firmwareManagementPage.version')}* <OverlayTrigger placement="top" delay={{ show: 0, hide: 400 }} overlay={this.renderTooltip(t('firmwareManagementPage.PackageVersionFormat', { format: '01.23' }))}><span style={{ position: "relative" }}><HelpIcon style={{ position: "absolute", top: '50%', left: '50%', transform: 'translate(15%, -45%)' }} fontSize="medium"></HelpIcon></span></OverlayTrigger></h3>
                            <TextField
                                onInvalid={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                                onInput={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                                onChange={(event) => { 
                                    event.target.setCustomValidity('');
                                    this.setValue(event.target.value, "version") 
                                }}
                                required
                                size="small"
                                fullWidth
                                variant="outlined"
                                defaultValue={this.firmware.version}
                                inputProps={{ inputMode: 'numeric', pattern: '^[0-9]{1,2}[.][0-9]{2}$' }}/>
                        </div>
                    </div>


                </form>
        </div>);
        }else if(this.firmware.type_firmware === "SmartComponentType" || this.props.type_firmware === "SmartComponentType" ){
            setTimeout(() => {
                const newFirmwareSmartComponentType = this.newFirmwareSmartComponentTypeRef?.current?.inputRef;
                if (newFirmwareSmartComponentType) {
                    newFirmwareSmartComponentType.setCustomValidity(this.props.t('general.error_required'));
                }
            }, 1000);
            return (
                <div>    
                    <h2>{t('firmwareManagementPage.addSmartComponentFirmware')}</h2>
                    <form id="FirmwareStep1Form" onSubmit={(e) => this.handleFormSubmit(e)}>
                        <div className="row">
                            <div className="col">
                            <h3>{t('firmwareManagementPage.smartComponentTypeId')}*</h3>    
    
                                {this.props.smartComponent && this.props.smartComponent.value ?
                                    <TextField size="small" fullWidth variant="outlined" defaultValue={this.firmware.smartComponent.label} disabled />
                                    :
                                    <React.Fragment>                               
                                        <Select
                                            ref={this.newFirmwareSmartComponentTypeRef}
                                            className="react-select-container"
                                            required
                                            styles={selectDropdownStyle({height: 200})}
                                            options={this.state.smartComponentTypesId}
                                            onChange={(event) => {
                                                this.selectedChange(event.value, "newFirmwareSmartComponentType", "smartComponentTypeSelected", "newFirmwareSmartComponentTypeRef")
                                            }}
                                            defaultValue={{ label: this.getSmartCompoTypeWithId(this.firmware.smartComponentTypeSelected), value: this.firmware.smartComponentTypeSelected ? this.firmware.smartComponentTypeSelected: "" }}
    
                                        />
                                        <input
                                            id="newFirmwareSmartComponentType"
                                            tabIndex={-1}
                                            autoComplete="off"
                                            style={{ opacity: 0, height: 0, position: "absolute" }}
                                            required={true}
                                            defaultValue={this.firmware.smartComponentTypeSelected}
                                        />
                                    </React.Fragment>
                                }
                            </div>   
    
                        </div>
    
                        <div className="row " >
    
                            <div className="col-12 col-md-6">
    
                                <h3>{t('firmwareManagementPage.uploadFile')}*</h3>
                                <div className="row">
                                    <div className="col">
                                        <TextField size="small" fullWidth variant="outlined" value={this.state.fileName} disabled onChange={(event) => { setName(event.target.value); }} />
                                    </div>
                                    <div className="col-auto">
                                        <IconButton color="primary" aria-label="upload picture" component="label">
                                            <input required hidden accept="file/*" type="file" onChange={(event) => this.fileUpload(event)} />
                                            <UploadIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
    
                            <div className="col-12 col-md-6">
                                <h3>{t('firmwareManagementPage.version')}* <OverlayTrigger placement="top" delay={{ show: 0, hide: 400 }} overlay={this.renderTooltip(t('firmwareManagementPage.PackageVersionFormat', { format: '01.23' }))}><span style={{ position: "relative" }}><HelpIcon style={{ position: "absolute", top: '50%', left: '50%', transform: 'translate(15%, -45%)' }} fontSize="medium"></HelpIcon></span></OverlayTrigger></h3>
                                <TextField
                                    onInvalid={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                                    onInput={(e) => e.target.setCustomValidity(this.props.t('general.error_required'))}
                                    onChange={(event) => { 
                                      event.target.setCustomValidity('');
                                      this.setValue(event.target.value, "version") 
                                    }}
                                    required
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    defaultValue={this.firmware.version}
                                    inputProps={{ inputMode: 'numeric', pattern: '^[0-9]{1,2}[.][0-9]{2}$' }}/>
                            </div>
                        </div>
    
    
                    </form>
                </div>);
        }        
    };

    getStepContent(stepIndexPackage) {
        //const { t } = this.props;

        switch (stepIndexPackage) {
            case 0:
                return this.getStep0();
            case 1:
                return this.getStep1();
            default:
                return 'Unknown stepIndex';
        }
    };

    fillTitleStep(step) {
        if (typeof this.props.handleStepper === 'function') {
            this.props.handleStepper(step);
        }
    }

    render() {

        const { t } = this.props

        const options = [];


        return (

            <React.Fragment>

                <div>
                    <div className="prodChildNoZoom p-5">

                        {this.getStepContent(this.state.activeStep)}

                    </div>
                    <div className="row">
                        {this.getStepButtons(this.state.activeStep)}
                    </div>


                </div>

            </React.Fragment>
        );
    }

}

export default withTranslation()(SWFirmwareAddForm);