import { PureComponent } from 'react';
import _ from 'lodash';
import Axios from 'axios';
import Loader from '../../components/Common/Loader';
import LandingContainer from '../../components/Landing/container/LandingContainer';
import AuthGate from '../config/AuthGate';
import AuthenticationContext, { AuthInfo, UserBasicData, UserData } from '../types/AuthContextType';
import { LoginContainerProps, LoginContainerState } from '../types/LoginType';
import { Layout } from '../../components/Layout';
import { calculateExpirationTime } from '../../components/Common/utils/helpers';
import Moment from 'react-moment';
import moment from 'moment';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UserMeasure } from '@ComponentsRoot/UsersComponents/Tabs/UserConfigUnits/types/UserConfigUnitsTypes';
import i18next, { t } from 'i18next';
import TranslationsHttpClient from '../../HttpClient/TranslationsHttpClient';
import Message from '../../components/Message/Message';

export class LoginContainer extends PureComponent<WithTranslation & LoginContainerProps, LoginContainerState> {
  private AuthGate: AuthGate;

  constructor(props: WithTranslation & LoginContainerProps) {
    super(props);
    this.AuthGate = new AuthGate(this);
    this.state = {
      loading: true,
      loadingInitialLanguage: true,
      currentLanguageFetching: '',
      languagesFetched: [],
      freeAccess: false,
      isAuthenticated: false,
      locationHref: window.location.href,
      authInfo: null,
      userData: null,
      accessToken: null,
      userPending: null,
      message: null,
      userPreferences: {
        measures: [],
        language: 'EN',
      },
      fullScreen: false,
    };
  }

  componentDidMount = async () => {
    this.checkRedirects(); // check if there are any redirects in the url (invitation, request, etc)
    await this.AuthGate.checkAccess();
    this.setState({ loading: false, loadingInitialLanguage: false });
    if (this.state.isAuthenticated && this.state.authInfo && this.state.accessToken) {
      await this.getDataToCache();
    }
  };

  async componentDidUpdate(_prevProps: Readonly<LoginContainerProps>, prevState: Readonly<LoginContainerState>): Promise<void> {
    if (this.state.isAuthenticated && this.state.authInfo) {
      if (!this.state.accessToken) {
        await this.AuthGate.getToken();
      } else if (this.state.accessToken !== prevState.accessToken) {
        await this.getDataToCache();
        await this.getTranslations(this.props.i18n.language, true);
      }

      if (this.state.userPreferences?.measures.length && this.state.userPreferences?.language !== this.props.i18n.language) {
        this.setState({
          userPreferences: { ...this.state.userPreferences, language: this.props.i18n.language },
        });
      }
    }
  }

  getDataToCache = async () => {
    // get all data to persist in context
    const allUserData = await this.getAllUserData();
    await this.getInvitationsAndRequestsPendingReceived();
    if (allUserData) {
      await this.getUserPreferences(allUserData.userBasicData);
    }
  };

  getTranslations = async (language: string, firstTime: boolean = false) => {
    // Check if the language is valid; if not, set it to 'EN'
    const validLanguages = ['EN', 'ES', 'FR', 'DE', 'IT'];
    if (!validLanguages.includes(language.toUpperCase())) {
      language = 'EN';
    }

    // If the language has already been fetched, exit the function
    const { languagesFetched } = this.state;
    if (languagesFetched.includes(language)) {
      return;
    }

    // Update the state to indicate that the language is being fetched
    this.setState({ currentLanguageFetching: language, loadingInitialLanguage: firstTime });

    const translationsHttpClient = new TranslationsHttpClient();  
    const translationFolders = [
      'translation',
      'charts',
      'commands',
      'parameters',
      'parametersLabels',
      'parametersPage',
      'dataRegisters',
      'dataRegistersLabels',
      'gateways',
    ];

    // Load translations for each folder asynchronously
    const fetchPromises = translationFolders.map((folder) =>
      translationsHttpClient.GetTranslationsFolderByLocale(this.state.accessToken, language, folder)
    );
    const responses = await Promise.all(fetchPromises);

    // Add each fetched translation bundle to i18next
    responses.forEach((response, index) => {
      const folder = translationFolders[index];
      i18next.addResourceBundle(language, folder, response.data, true, true);
    });

    // Add language to the list of loaded languages and update state
    this.setState({
      languagesFetched: [...languagesFetched, language],
      currentLanguageFetching: '',
      loadingInitialLanguage: false,
    });

    // Update the language in AuthGate
    await this.AuthGate.setNewLanguage(language);
  };

  isOldDataAccount = (expiresOn: string) => {
    if (!expiresOn) return true;
    const currentTime = String(new Date().getTime());
    return currentTime > expiresOn;
  };

  setStateIfIsDifferent<K extends keyof LoginContainerState>(name: K, newState: LoginContainerState[K]) {
    if (!_.isEqual(newState, this.state[name])) {
      this.setState({ [name]: newState } as Pick<LoginContainerState, K>);
    }
  }

  getData = async (url: string) => {
    const config = {
      headers: {
        Authorization: this.state.accessToken?.accessToken,
      },
    };
    try {
      const response = await Axios.get(url, config);
      return response.data;
    } catch (error) {
      console.error('Error al obtener datos:', error);
      return null;
    }
  };

  patchLocale = async (lang: string) => {
    let config = {
      headers: {
        Authorization: this.state.accessToken?.accessToken,
      },
    };
    const patch = {
      op: 'replace',
      path: '/locale',
      value: lang.toLocaleLowerCase(),
    };
    try {
      await Axios.put('users/me', patch, config);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  getAllUserData = async () => {
    let userData: UserData = JSON.parse(localStorage.getItem('allUserData')!);
    if (userData && !this.isOldDataAccount(userData.expiresOn) && !this.state.userData) {
      this.setStateIfIsDifferent('userData', userData);
      return userData;
    } else {
      try {
        const userBasicData: UserData['userBasicData'] = (await this.getData('users/me')).content;
        const userB2CData: UserData['userB2CData'] = (await this.getData('users/me/graphApi')).content;
        const userThumbnailData: UserData['userThumbnailData'] = (await this.getData('users/me/graphApi/thumbnail-photo'))
          .content;

        if (userBasicData && userB2CData && userThumbnailData) {
          const newUserData = {
            userBasicData,
            userB2CData,
            userThumbnailData,
            expiresOn: String(calculateExpirationTime(24 * 60 * 60 * 1000)), // 24 hours in milliseconds
          };

          localStorage.setItem('allUserData', JSON.stringify(newUserData));
          this.setStateIfIsDifferent('userData', newUserData);
          return newUserData;
        } else {
          return null;
        }
      } catch (error) {
        console.error('Error al obtener datos del usuario:', error);
        return null;
      }
    }
  };

  async getInvitationsAndRequestsPendingReceived() {
    const userPending = JSON.parse(localStorage.getItem('userPending')!);
    if (userPending) {
      if (!this.state.userPending) {
        this.setStateIfIsDifferent('userPending', userPending);
        return userPending;
      }
    } else {
      let invitations = await this.getData('invitations/received/EN/0/1000/state=Pending&state=NUL');
      let requests = await this.getData('invitations/received/EN/requests/0/1000/state=Pending&state=NULL');

      if (invitations && requests) {
        const userPending = {
          invitations: invitations && invitations.content.length,
          requests: requests && requests.content.length,
        };
        localStorage.setItem('userPending', JSON.stringify(userPending));
        this.setStateIfIsDifferent('userPending', userPending);
        return userPending;
      } else {
        return null;
      }
    }
  }

  checkRedirects = () => {
    // Types of redirect
    // https://localhost:44392/?from=invitations&brand=11&mail=acampillo@seidor.es
    // https://localhost:44392/product/5113?from=product_alarm&brand=11&mail=allussa@seidor.es
    const query = new URLSearchParams(window.location.search);
    const urlWithoutParams = window.location.href.split('?')[0];
    const from = query.get('from');
    const brand = query.get('brand');
    const mail = query.get('mail');
    if (from && brand && mail) {
      const actualItem = JSON.parse(window.localStorage.getItem('redirect')!) || {};
      actualItem[`${mail}_${brand}`] = {
        from: from,
        brand: brand,
        url: urlWithoutParams,
      };
      window.localStorage.setItem('redirect', JSON.stringify(actualItem));
    }
  };

  manageLang = async () => {
    const { userData, userPreferences } = this.state;
    // Get locale lang
    const localeLang = userData?.userB2CData?.locale?.toUpperCase() ?? 'EN';
    // Check if i18n has the wrong lang
    if (this.props.i18n.language.includes('-')) {
      await this.updateLanguageAndMoment(localeLang);
    }
    // Check if userPreferences lang (state) is different from localeLang (user data) then change language
    if (userPreferences?.language !== localeLang) {
      await this.updateLanguageAndMoment(localeLang);
      this.setState({ userPreferences: { ...this.state.userPreferences!, language: localeLang } });
      // this.setStateIfIsDifferent('userPreferences', { ...this.state.userPreferences!, language: localeLang });
    }

    return localeLang;
  };

  getUserPreferences = async (userData: UserBasicData) => {
    const language = await this.manageLang();
    this.setState({ userPreferences: { language, measures: userData.userMeasureUnits } });
    // this.setStateIfIsDifferent('userPreferences', { language, measures: userData.userMeasureUnits });
  };

  // Check and update i18n language and Moment global settings
  updateLanguageAndMoment = async (localeLang: string) => {
    await this.props.i18n.changeLanguage(localeLang);
    if (Moment.globalMoment !== moment || Moment.globalLocale !== localeLang) {
      Moment.globalMoment = moment;
      Moment.globalLocale = localeLang;
    }
  };

  setNewLanguage = async (lang) => {
    if (this.state.languagesFetched.includes(lang) && this.state.currentLanguageFetching === '') {
      await this.AuthGate.setNewLanguage(lang);
    } else if (this.state.currentLanguageFetching !== '') {
      this.setState({
        message: {
          type: 'info',
          content: t('stillFetchingTranslations').replaceAll('%s', this.state.currentLanguageFetching),
        },
      });
    } else {
      this.getTranslations(lang);
      this.setState({
        message: {
          type: 'info',
          content: t('fetchingTranslations').replaceAll('%s', lang),
        },
      });
    }
  };

  render() {
    return (
      <>
        {this.state.loading || this.state.loadingInitialLanguage ? (
          <Loader />
        ) : (
          <>
            {this.state.message && <Message {...this.state.message} onClose={() => this.setState({ message: null })} />}
            {(this.state.isAuthenticated && this.state.authInfo && this.state.userData && this.state.userPending) ||
            this.state.freeAccess ? (
              <AuthenticationContext.Provider
                value={{
                  isAuthenticated: this.state.isAuthenticated,
                  authInfo: this.state.authInfo as AuthInfo,
                  brandCode: this.props.brandCode,
                  brandName: this.props.brandName,
                  freeAccess: this.state.freeAccess,
                  pathname: window.location.pathname,
                  userData: this.state.userData,
                  userPending: this.state.userPending,
                  userPreferences: this.state.userPreferences,
                  login: async () => await this.AuthGate.login(),
                  editProfile: async () => await this.AuthGate.editProfile(),
                  forgotPassword: async () => await this.AuthGate.forgotPassword(),
                  changePassword: async () => await this.AuthGate.changePassword(),
                  logout: async () => await this.AuthGate.logout(),
                  getToken: async () => await this.AuthGate.getToken(),
                  refreshUserData: async () => await this.AuthGate.refreshUserData(),
                  refreshInvitationsAndRequestsPending: async () => await this.AuthGate.refreshInvitationsAndRequestsPending(),
                  setNewLanguage: async (lang: string) => await this.setNewLanguage(lang),
                  setNewMeasures: async (measures: UserMeasure[]) => await this.AuthGate.setNewMeasures(measures),
                  fullScreen: (status: boolean | undefined) => this.AuthGate.fullScreen(status),
                  manageLocalStorage: (action: string, keyName: string, value?: string) =>
                    this.AuthGate.manageLocalStorage(action, keyName, value),
                }}
              >
                {/* TODO layout could change its position when the menu is refactored */}
                {this.state.fullScreen ? this.props.children : <Layout>{this.props.children}</Layout>}
              </AuthenticationContext.Provider>
            ) : (
              <LandingContainer
                login={async () => await this.AuthGate.login()}
                locationHref={this.state.locationHref}
                userData={this.state.userData!}
                accessToken={this.state.accessToken}
                freeAccess={this.state.freeAccess}
              />
            )}
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(LoginContainer);
