import Style from '../styles/EmptyLastValueChartView.module.scss';
import { EmptyLastValueChartProps } from '../types/ChartsComponents';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const EmptyLastValueChartView = (props: EmptyLastValueChartProps) => {
  return (
    <div className={Style.EmptyLastValueChartView}>
      <div className='container-EmptyLastValueChartView'>
        <div style={{ padding: '10px 5px', marginLeft: 0, width: '200px' }} className='prodChildNoZoom'>
          <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ height: '55px', lineHeight: '36px', textAlign: 'center' }}>
              <h4 className='kpiTitle mb-1' style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                {props.title}
              </h4>
            </div>           
            <div className="icon">
              {props.error.type === 'call' ? <ErrorOutlineIcon fontSize='large' /> : ''}
              {props.error.type === 'empty' ? <NotInterestedIcon fontSize='large' /> : ''}
            </div>
            <h1 style={{ textAlign: 'center', padding: '5px 0' }}>-</h1>
            <div className='kpiValueAc t2'>
              <p className='error-text'>{props.error.message}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyLastValueChartView;
