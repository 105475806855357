import axios from "axios";
import i18n from '../i18n/i18n';

export default class HttpClient {


    constructor(context, timeZoneOffset) {
        this.context = context;
        this.language = i18n.language;
        this.debug = false; 
        this.timeZoneOffset = timeZoneOffset ? timeZoneOffset: "00:00"
    }

    addTimezone = (timeStamp) => {
      const sign = this.timeZoneOffset.includes('-') ? 'min' : 'plus';
      return `${timeStamp}${sign}${(this.timeZoneOffset.replace('+','').replace('-','')).substring(0, 5)}`;
    };

    InstallationChartsDatapoints = async (id, kpiName, kpiType, groupBy, granularity, aggregateBy, calculation, startTimeStamp, endTimeStamp, type, filter, deviceUnits) => {
        const accessToken = await this.context.getToken();      
        const config = {
          headers: {
            Authorization: `${accessToken.accessToken}`,
            "x-device-units": JSON.stringify(deviceUnits)
          },
        };
        const url = `installations/${this.language}/${id}/graphic-data-points/${kpiName}/${kpiType}/${groupBy}/${granularity}/${aggregateBy}/${calculation}/${this.addTimezone(startTimeStamp)}/${this.addTimezone(endTimeStamp)}/${type}`;

        try {
          const response = await axios.post(url, filter, config);      
          if (this.debug) {
            console.log("graphic-data-points: " + url);
            console.log("response: " + JSON.stringify(response));
          }
          return response;
        } catch (e) {
          return e.response;
        }
      };
      

    InstallationChartsStatisticalMeasures = async (id, kpiName, kpiType, aggregateBy, startTimeStamp, endTimeStamp, groupBy, filter, deviceUnits) => {
        const accessToken = await this.context.getToken();      
        const config = {
            headers: {
              Authorization: `${accessToken.accessToken}`,
              "x-device-units": JSON.stringify(deviceUnits)
            },
        };
        const url = `installations/${this.language}/${id}/statistical-measures/${kpiName}/${kpiType}/${aggregateBy}/${this.addTimezone(startTimeStamp)}/${this.addTimezone(endTimeStamp)}/${groupBy}`;

        try {
            const response = await axios.post(url, filter, config);      
            if (this.debug) {
            console.log("statistical-measures: " + url);
            console.log("response: " + JSON.stringify(response));
            }
            return response;
        } catch (e) {
            return e.response;
        }
    };
}