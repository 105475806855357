import { AuthContextType } from '@RootPath/Authentication/types/AuthContextType';
import _ from 'lodash';
import moment from 'moment';

export const formatDateForLanguage = (date: string, lang: string = "EN"): string => {
  const momentDate = moment(date);
  const formats = {
    EN: 'MM/DD/YYYY',
    ES: 'DD/MM/YYYY',
    IT: 'DD/MM/YYYY',
    FR: 'DD/MM/YYYY',
  };
  const formatDate = formats[lang] || formats.EN;
  momentDate.locale(lang);
  return momentDate.format(formatDate);
};

export const manageInfoUser = async (context: AuthContextType, ctxParamName: string) => {
  if (context.userData && context.userData[ctxParamName]) {
    return context.userData[ctxParamName];
  } else {
    const refreshContext = await context.refreshUserData();
    return refreshContext![ctxParamName];
  }
};

export const findDifferences = (initialData: any, editData: any) => {
  return _.differenceWith(initialData, editData, (obj1, obj2) => {
    return _.isEqual(obj1, obj2);
  });
};

export const checkRedirectAfterLogin = (context: AuthContextType, deleteAfter: boolean = false) => {
  const redirectInLocalStorage = window.localStorage.getItem('redirect');
  if (redirectInLocalStorage && context) {
    const redirectData = JSON.parse(redirectInLocalStorage);
    const userData = context.userData!.userB2CData;
    const key = `${userData.email}_${context.brandCode}`;

    if (userData && key in redirectData) {
      const userRedirectInfo = redirectData[key];

      if (deleteAfter) {
        delete redirectData[key];
        window.localStorage.setItem('redirect', JSON.stringify(redirectData));
      }
      return userRedirectInfo;
    }
  }
  return false;
};

export const calculateExpirationTime = (duration: number) => {
  const currentTime = new Date().getTime();
  return currentTime + duration;
};
