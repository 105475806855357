import { t } from "i18next";
import { InstallationUsersViewProps } from "../types/InstallationUsersTypes";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Message from '../../../../Message/Message';

import Table from '../../../../CustomComponents/ReactTable/Table';
import React from "react";
import InstallationUserEditContainer from '../../../Tabs/InstallationUserEdit/container/InstallationUserEditContainer';
import InvitationsOutbox from '../../../../Invitations/InvitationsOutbox';

const InstallationUsersView = (props: InstallationUsersViewProps) => {
    let dataLoaded = props.userInstallations != undefined;

    return (
        <React.Fragment>
          {dataLoaded ? (
            <div>
              {props.message && <Message {...props.message} onClose={props.handleDialogClose} />}
              <Dialog disableEscapeKeyDown open={props.showDelete} onClose={props.handleCloseDelete}>
                <DialogTitle>{t('installationspages.userinstallation.deletequestiontitle')}</DialogTitle>
                <DialogContent>
                  <div>
                    <span>{t('installationspages.userinstallation.deletequestion')}</span>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button className='primary pull-right' onClick={() => props.confirmDelete(t)}>
                    {t('actions.yes')}
                  </Button>
                  <Button className='primary pull-right' onClick={props.handleCloseDelete}>
                    {t('actions.no')}
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog disableEscapeKeyDown={false} open={props.showForm} onClose={() => props.handleClose()}>
                <DialogTitle>{t('actions.editname', { name: t('user') })}</DialogTitle>
                <DialogContent>
                  <div id='divForm'>
                    <div className='form-row'>
                      <div className='form-group col-md-12'>
                        <InstallationUserEditContainer
                          installationId={props.id}
                          roleSelected={props.role}
                          userId={props.userId}
                          mail={props.mail}
                          onSaveClose={props.handleClose}
                        ></InstallationUserEditContainer>
                      </div>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions></DialogActions>
              </Dialog>
              <div className='row prodChildNoZoom mb-5 userManagementTable'>
                  <div className='row col-12'>
                    <h3>
                      {t('installationspages.usermanagement')} / {props.data.name}
                    </h3>
                  </div>
                 
                    <div className='col chartContainer'>
                      <Table
                        name={'InstallationUsers-locales'}
                        t={t}
                        resize={true}
                        columns={props.columns}
                        rows={props.userInstallations}
                        search={true}
                        page={props.page}
                        pageSize={props.sizePerPage}
                      />
                  </div>
              </div>
              {props.invitationsOutboxVisible ? (
                <div className='row prodChildNoZoom userManagementInvitations'>
                  <InvitationsOutbox id={props.id} installationImage={props.data.avatarUri} />
                </div>
              ) : null}
            </div>
          ) : (
            <div className='loadingParent'>
              <div className='loadingImg'></div>
            </div>
          )}
        </React.Fragment>
      );
  };

  export default InstallationUsersView;