import React, { Component } from 'react';
import '../App.css';
import InstallationHttpClient from "../HttpClient/InstallationsHttpClient";
import SiteHttpClient from "../HttpClient/SitesHttpClient";
import AuthenticationContext from "../Authentication/types/AuthContextType";
import { Navigate } from 'react-router-dom';
import NoData from '../components/NoData';
import { withRouter } from './withRouter';
import { checkRedirectAfterLogin } from '../components/Common/utils/helpers'


class Home extends Component {
    static contextType = AuthenticationContext;
    constructor(props) {
        super(props);
        this.state = {
            sites: null
        }
    }

    async componentDidMount() {
        if (this.props.location.search == "?action=logout") {
            localStorage.removeItem('t');
            if (this.context.isAuthenticated)
                this.context.logout();
        }
        else if (this.props.location.search == "?action=editProfile") {
            this.context.editProfile();
        }
        else if (this.props.location.search == "?action=forgotPassword") {
            this.context.forgotPassword();
        }
        else if (this.props.location.search == "?action=changePassword") {
            let changePassword = window.localStorage.getItem("changePassword");
            if (changePassword == "true") {
                window.localStorage.removeItem("changePassword");
                this.context.changePassword();
            } else {
                window.location.href = "/";               
            }
        }
        else if (this.props.location.search == "?action=login") {
            if (!this.context.isAuthenticated)
                this.context.login();
            else {
                window.location.href = "/";               
            }
        }
        else {
            const fromRedirect = checkRedirectAfterLogin(this.context);
            if(fromRedirect){
                window.location.href = fromRedirect.url;                
            }
            this.http = new InstallationHttpClient(this.context);
            this.httpSites = new SiteHttpClient(this.context);
            await this.getSiteInstallationsData();
        }
    }

    async getSiteInstallationsData() {

        let sites = await this.httpSites.GetSites();
        let installations = await this.http.InstallationGetInstallations();
        this.setState({ sites: sites, installations: installations });
    }


    render() {
        let dataLoaded = this.state.sites ? true : false
        let error = false;

        if (dataLoaded) {
            if ((this.state.sites.content == undefined || this.state.sites.content == null) && (this.state.installations.content == undefined || this.state.installations.content == null)) {
                //error API sites
                error = true;
            }
            else {
                if (this.state.sites.content.length == 0) {
                    // no sites assigned to the user
                    if (this.state.installations.content.length == 0) {
                        // no sites assigned to the user
                        return (
                            <React.Fragment>
                                {
                                    <NoData />
                                }
                            </React.Fragment>
                        );
                    }
                    if (this.state.installations.content.length == 1) {
                        // redirect to site detail
                        let url = '/installation/' + this.state.installations.content[0].id
                        return <Navigate to={url} />
                    }
                    else if (this.state.installations.content.length > 1) {
                        return <Navigate to='filter/installations' />
                    }
                    else {
                        return (
                            <React.Fragment>
                                {
                                    <NoData />
                                }
                            </React.Fragment>
                        );
                    }
                }
                else if (this.state.sites.content.length == 1) {
                    // redirect to site detail
                    let url = '/site/' + this.state.sites.content[0].id
                    return <Navigate to={url} />
                }
                else if (this.state.sites.content.length > 1) {
                    return <Navigate to='/sites' />
                }
                else {
                    return (
                        <React.Fragment>
                            {
                                <NoData />
                            }
                        </React.Fragment>
                    );
                }
            }
            if (error) {
                return (
                    <React.Fragment>
                        {
                            <div className="loadingParent">Error getting site's information from RESTAPI</div>
                        }
                    </React.Fragment>
                );
            }
        }
        else {
            return (
                <React.Fragment>
                    {
                        <div className="loadingParent"><div className="loadingImg"></div></div>
                    }
                </React.Fragment>
            );
        }
    }
}
export default (withRouter(Home, [{ path: '/home/:action' }, { path: '/home' }, { path: '/' }]));