import React, { Component } from 'react';
import interact from 'interactjs';
import { Tooltip, Whisper } from 'rsuite';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { withRouter } from '../../withRouter';
import { withTranslation } from 'react-i18next';

import * as jsonpatch from 'fast-json-patch/index.mjs';
import Moment from 'react-moment';
import moment from 'moment';

import InstallationsHttpClient from '../../../HttpClient/InstallationsHttpClient';
import ProductsHttpClient from '../../../HttpClient/ProductHttpClient';
import AuthenticationContext from '../../../Authentication/types/AuthContextType';
import Message from './../../Message/Message';

import '../Installations.css';

import RotateRightIcon from '@mui/icons-material/RotateRight';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import PublishIcon from '@mui/icons-material/Publish';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ClearIcon from '@mui/icons-material/Clear';
import Loader from '../../Common/Loader';

class ZoomInstallationsLayout extends Component {
  static contextType = AuthenticationContext;

  constructor(props) {
    super(props);
    this.state = {
      imageFile: this.props.imageFile ? this.props.imageFile : null,
      layoutImage: this.props.layoutImage ? this.props.layoutImage : '',
      status: this.props.status ? this.props.status : '',
      openConfirmDelete: this.props.openConfirmDelete ? this.props.openConfirmDelete : false,
      editingLayout: this.props.editingLayout ? this.props.editingLayout : false,
      items: this.props.items ? this.props.items : this.props.items,
      activeItems: this.props.activeItems ? this.props.activeItems : [],
      notActiveItems: this.props.notActiveItems ? this.props.notActiveItems : [],
      width: 0,
      disableRotation: false,
      message: null,
      loading: false,
    };
    this.id = parseInt(window.location.pathname.split('/')[2]);

    this.imageW = 0;
    this.imageH = 0;

    this.fileTypes = ['jpg', 'jpeg', 'png', 'pdf'];

    this.dragMoveListener = this.dragMoveListener.bind(this);
    this.droppedElement = this.droppedElement.bind(this);
    this.saveDroppedElements = this.saveDroppedElements.bind(this);
    this.handleCloseConfirm = this.handleCloseConfirm.bind(this);
    this.deleteLayout = this.deleteLayout.bind(this);

    interact('.dropzone').dropzone({
      ondropactivate: function (event) {},
      ondragenter: function (event) {
        let draggableElement = event.relatedTarget;
        let dropzoneElement = event.target;

        // feedback the possibility of a drop
        dropzoneElement.classList.add('drop-target');
        draggableElement.classList.add('can-drop');
      },
      ondragleave: function (event) {
        // remove the drop feedback style
        event.target.classList.remove('drop-target');
        event.relatedTarget.classList.remove('can-drop');
      },
      ondrop: function (event) {},
      ondropdeactivate: function (event) {
        // remove active dropzone feedback
        event.target.classList.remove('drop-active');
        event.target.classList.remove('drop-target');
      },
    });

    interact('.drag-drop').draggable({
      inertia: true,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: 'parent',
          endOnly: true,
        }),
      ],
      autoScroll: true,
      // dragMoveListener from the dragging demo above
      listeners: { move: this.dragMoveListener, end: this.droppedElement },
    });
  }

  renderMessage = (type, content) => {
    this.setState({
      message: {
        type: type,
        content: content,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({ message: null });
  };

  updateDimensions = () => {
    let oldWidth = this.state.width;
    this.setState({ width: window.innerWidth });
    if (oldWidth != window.innerWidth) {
      this.setImageSize();
    }
  };
  setImageSize() {
    let width = document.getElementById('modal-drop-zone-container').offsetWidth;
    let height = (width * 2) / 3;

    if (this.imageW != width && this.imageH != height) {
      height = parseInt(height.toString().split('.')[0]);

      document
        .getElementById('modal-inner-dropzone')
        .setAttribute('style', 'height:' + height + 'px; width:' + width + 'px; background-size:cover');

      this.imageW = width;
      this.imageH = height;

      // we use a setTimeOut so the canvas container has rendered the height depending
      // on the canvas height itself. This way we can get the real height of the
      // container

      setTimeout(() => {
        try {
          //let containerHeight = document.getElementById('modal-drop-zone-container').offsetHeight + 62;
          let containerHeight = document.getElementById('modal-drop-zone-container').offsetHeight + 24;
          this.containerH = containerHeight;
          this.getProductInstallation();
        } catch (err) {
          console.error(err);
        }
      }, 1);
    }
  }
  componentDidMount() {
    this.setImageSize();

    //We use that listener to re-render the image when the width of the page change.
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  droppedElement(ev) {
    let item = ev.target;

    if (item) {
      // check if we have the dropped object
      let id = parseInt(item.getAttribute('data-id'));
      if (parseFloat(item.getAttribute('data-y')) > this.imageH) {
        // Dropped item is inside the unplaced dropzone
        let i = this.state.activeItems.findIndex((x) => x.id == id);
        if (i != -1) {
          let items = [];

          let obj = { ...this.state.activeItems[i] };

          obj.floorPlanX = null;
          obj.floorPlanY = null;

          let active = [...this.state.activeItems];
          active.splice(i, 1);

          let unActive = [...this.state.notActiveItems];
          unActive.push(obj);

          items = [...active, ...unActive];

          this.refreshItems(items);
        } else {
          let items = [];

          let unActive = [...this.state.notActiveItems];
          let active = [...this.state.activeItems];

          items = [...active, ...unActive];

          this.refreshItems(items);
        }
      } else {
        // Dropped item is inside the dropzone
        let i = this.state.notActiveItems.findIndex((x) => x.id == id);
        if (i != -1) {
          // If object was previously unplaced

          let items = [];

          let obj = { ...this.state.notActiveItems[i] };

          obj.floorPlanX = parseFloat(item.getAttribute('data-x-percent'));
          obj.floorPlanY = parseFloat(item.getAttribute('data-y-percent'));

          let active = [...this.state.activeItems];
          active.push(obj);

          let unActive = [...this.state.notActiveItems];
          unActive.splice(i, 1);

          items = [...active, ...unActive];

          this.refreshItems(items);
        }
      }
    }
  }

  refreshItems(items) {
    // We have to do this so the items get completely re-rendered.
    // This way we avoid problems with previous information about their position creating buggy behabiour

    this.setDraggableItems([], null, null, false);
    setTimeout(() => {
      this.setDraggableItems(items, null, null, false);
    }, 1);
  }

  dateFormatter(cell, row) {
    return (
      <Moment format='D MMM YYYY HH:mm' withTitle locale='es'>
        {cell}
      </Moment>
    );
  }

  setDraggableItems(items, layoutImage, installationName, afterSave, saveInitial) {
    try {
      let itemsWithPos = [];
      let itemsWithoutPos = [];

      let image = layoutImage ? layoutImage : this.state.layoutImage;
      let name = installationName ? installationName : this.state.installationName;

      if (saveInitial) {
        this.initialProducts = [];
        this.initialItems = [];
      }

      items.map((item) => {
        if (item.floorPlanX != null && item.floorPlanY != null) {
          itemsWithPos.push(item);
        } else itemsWithoutPos.push(item);

        if (saveInitial) {
          this.initialItems.push(item);
          let element = {
            id: item.id,
            position: { floorPlanX: item.floorPlanX, floorPlanY: item.floorPlanY },
          };
          this.initialProducts.push(element);
        }
      });

      if (itemsWithoutPos.length > 0 && document.getElementById('modal-lower-dropzone') !== null) {
        document.getElementById('modal-lower-dropzone').style.height = '40px';
      } else if (document.getElementById('modal-lower-dropzone') !== null) {
        document.getElementById('modal-lower-dropzone').style.height = '18px';
      }

      if (afterSave)
        this.setState({
          activeItems: itemsWithPos,
          notActiveItems: itemsWithoutPos,
          items: items,
          layoutImage: image,
          installationName: name,
          editingLayout: false,
          loading: false,
        });
      else
        this.setState({
          activeItems: itemsWithPos,
          notActiveItems: itemsWithoutPos,
          items: items,
          layoutImage: image,
          installationName: name,
          loading: false,
        });
    } catch (err) {
      console.error(err);
    }
  }

  async getProductInstallation() {
    this.setState({ loading: true }, async () => {
      try {
        this.http = new InstallationsHttpClient(this.context, this.id);
        let response = await this.http.InstallationGetInstallationsById();
        if (response.status == 200) {
          this.setDraggableItems(
            // this.props.data.products,
            // this.props.data.floorPlanUri,
            // this.props.data.name,
            response.data.content.products,
            response.data.content.floorPlanUri,
            response.data.content.name,
            false,
            true
          );
        }
      } catch (Err) {}
    });
  }

  saveDroppedElements = () => {
    let items = [];
    let placedItemsToSend = [];
    this.state.items.map((item) => {
      let itemElement = document.getElementsByName(item.id)[0];
      if (itemElement.classList == 'drag-drop can-drop') {
        item.floorPlanX = itemElement.getAttribute('data-x-percent');
        item.floorPlanY = itemElement.getAttribute('data-y-percent');

        if (item.floorPlanX > 100) item.floorPlanX = 100;
        if (item.floorPlanY > 100) item.floorPlanY = 100;

        if (item.floorPlanX < 0) item.floorPlanX = 0;
        if (item.floorPlanY < 0) item.floorPlanY = 0;

        let elemento = {
          id: item.id,
          position: [
            { op: 'replace', path: '/floorPlanY', value: itemElement.getAttribute('data-y-percent') },
            { op: 'replace', path: '/floorPlanX', value: itemElement.getAttribute('data-x-percent') },
          ],
        };

        placedItemsToSend.push(elemento);
        items.push(item);
      } else {
        let elemento = {
          id: item.id,
          position: [
            { op: 'replace', path: '/floorPlanY', value: null },
            { op: 'replace', path: '/floorPlanX', value: null },
          ],
        };
        placedItemsToSend.push(elemento);
        items.push(item);
      }
    });

    this.setState({ loading: true }, async () => {
      const response = await this.postProductsPosition(this.RepairPatch(placedItemsToSend));
      if (response) {
        this.setState({ loading: false });
        this.setDraggableItems(items, null, null, true);
      }
    });
  };

  RepairPatch(patch) {
    for (var x = patch.length - 1; x == 0; x--) {
      if (patch[x].position.length == 0) patch.splice(x, 1); //Elimino la entrada del array si no tiene posici�n
    }
    return patch;
  }
  showLayout(show) {
    this.props.onChange(show);
  }

  async postProductsPosition(patchOperation) {
    this.http = new ProductsHttpClient(this.context, this.id);
    const formulario = new FormData();
    formulario.append('patchoptions', JSON.stringify(patchOperation));
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    let data = await this.http.PatchInstallationsProductsLocation(formulario);
    return data;
  }

  async uploadFile(file) {
    this.http = new InstallationsHttpClient(this.context, this.id);
    const form = new FormData();

    form.append('file', file);

    let data = await this.http.InstallationUploadFile(form, this.id).then((x) => {
      this.getProductInstallation(true);
    });

    this.showLayout(true);
  }

  dragMoveListener(event) {
    if (document.getElementById('modal-lower-dropzone') !== null) {
      document.getElementById('modal-lower-dropzone').style.height = '40px';
    }

    let target = event.target;

    // keep the dragged position in the data-x/data-y attributes

    let x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
    let y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

    // elimino el top y el left
    target.style.top = null;
    target.style.left = null;

    // translate the element

    target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

    // update the posiion attributes
    let perX = (x / this.imageW) * 100;
    let perY = (y / this.imageH) * 100;
    target.setAttribute('data-x-percent', perX);
    target.setAttribute('data-y-percent', perY);

    target.setAttribute('data-x', x);
    target.setAttribute('data-y', y);
  }

  async deleteLayout() {
    try {
      this.http = new InstallationsHttpClient(this.context, this.id);

      await this.http.deleteFloorplan(this.id);

      this.setState({ openConfirmDelete: false, layoutImage: null, editingLayout: false });

      this.showLayout(false);
    } catch (err) {
      this.setState({ openConfirmDelete: false });
    }
  }

  handleCloseConfirm() {
    this.setState({ openConfirmDelete: false });
  }

  handleOpenConfirm() {
    this.setState({ openConfirmDelete: true });
  }

  dialogConfirmDelete() {
    const { t } = this.props;
    return (
      <Dialog
        open={this.state.openConfirmDelete}
        onClose={this.handleCloseConfirm}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{t('actions.delete')}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {t('actions.confirmdelete', { name: t('general.thisImage') })}
          </DialogContentText>
          <DialogActions>
            <Button className='primary pull-right' onClick={this.handleCloseConfirm}>
              {t('actions.no')}
            </Button>
            <Button className='primary pull-right' onClick={this.deleteLayout}>
              {t('actions.yes')}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }

  // onMouseOverItem = (e) => {
  //   const { t } = this.props;
  //   let el = document.getElementsByClassName('detalleProducto')[0];
  //   if (!el.classList.contains('productDetailShow') && !this.state.editingLayout) {
  //     el.classList.add('productDetailShow');
  //     let x = parseFloat(e.currentTarget.getAttribute('data-x')) + 40;
  //     let y = parseFloat(e.currentTarget.getAttribute('data-y')) - 100;
  //     let xPercent = parseFloat(e.currentTarget.getAttribute('data-x-percent'));
  //     try {
  //       let parentW = document.getElementById('InstallationLayoutParent').offsetWidth;
  //       let calc = (xPercent * parentW) / 100;
  //       if (parentW - calc < 250) {
  //         //No hi ha suficient espai a la dreta
  //         x -= 300;
  //       }
  //     } catch (e) {
  //       if (xPercent > 50) x -= 300;
  //     }

  //     el.style.webkitTransform = el.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
  //   }

  //   document.getElementsByName('productNameLabel')[0].innerHTML = e.currentTarget.getAttribute('data-designline');
  //   document.getElementsByName('puuidLabel')[0].innerHTML = e.currentTarget.getAttribute('data-puuid');
  //   document.getElementsByName('descriptionLabel')[0].innerHTML = e.currentTarget.getAttribute('data-name');
  //   if (e.currentTarget.getAttribute('data-state').toLowerCase() == 'online') {
  //     document.getElementsByName('lastDataLabel')[0].innerHTML = moment(e.currentTarget.getAttribute('data-lastdata')).format(
  //       'D MMM YYYY HH:mm'
  //     );
  //     document.getElementsByName('lastDataTitleLabel')[0].innerHTML = t('installationspages.installationTree.lastdata') + ':';
  //   } else {
  //     document.getElementsByName('lastDataLabel')[0].innerHTML = moment(e.currentTarget.getAttribute('data-lasttimeseen')).format(
  //       'D MMM YYYY HH:mm'
  //     );
  //     document.getElementsByName('lastDataTitleLabel')[0].innerHTML = t('productspages.general.lastTimeSeen') + ':';
  //   }
  //   document.getElementsByName('warrantyLabel')[0].innerHTML =
  //     e.currentTarget.getAttribute('data-warranty') == '' ? 'No' : e.currentTarget.getAttribute('data-warranty');
  //   document.getElementsByName('productImage')[0].src = e.currentTarget.getAttribute('data-image');
  //   document.getElementsByName('productStateLabel')[0].removeAttribute('class');
  //   document.getElementsByName('productStateLabel')[0].classList.add(e.currentTarget.getAttribute('data-state'));
  //   document.getElementsByName('refLabel')[0].innerHTML = e.currentTarget.getAttribute('data-ref');
  // };

  // onMouseLeaveItem = (e) => {
  //   if (document.getElementsByClassName('detalleProducto')[0].classList.contains('productDetailShow'))
  //     document.getElementsByClassName('detalleProducto')[0].classList.remove('productDetailShow');
  // };

  navigateItemDetail(id) {
    if (!this.state.editingLayout) {
      let url = '/product/' + id;
      this.props.navigate(url);
    }
  }

  notActiveItemsRender(item, i) {
    var estado = 'disconnectedSquare';
    if (item.connectionState != null) {
      estado = item.connectionState + 'Square';
    }
    let imagen = item.productType.iconUri;
    if (item.productType.iconUri != undefined) {
      imagen = item.productType.iconUri.replace('black', 'white');
    }

    let transform = 'translate( ' + (i * 50 + 30) + 'px , ' + (this.containerH - 30) + 'px ) ';

    return (
      <div
        unplaced='true'
        key={i}
        name={item.id}
        id='drop'
        onClick={() => this.navigateItemDetail(item.id)}
        className={this.state.editingLayout ? 'drag-drop' : 'not-drag-drop can-drop'}
        onMouseOver={this.onMouseOverItem}
        onMouseLeave={this.onMouseLeaveItem}
        data-id={item.id}
        data-puuid={item.puuid}
        data-name={item.productType.name}
        data-description={item.name}
        data-image={item.productType.imageUri}
        data-state={item.connectionState}
        data-designline={
          item.designLine == null ? item.productType.name : item.designLine + ' - ' + item.productType.letter + item.tag
        }
        data-ref={item.productType.sku}
        data-tagid={item.tag}
        data-warranty={item.warranty}
        data-lastdata={item.lastProcessedValue}
        data-lasttimeseen={item.lastTimeSeen}
        style={{ position: 'absolute', transform: transform }}
        data-x={i * 50 + 30}
        data-y={this.containerH - 30}
      >
        <div className='prodInstallationManagment centerObj'>
          <div className={estado}>
            <div className={'stateDiv' + ' prdEventId-' + item.id}>
              <div>
                <img className={'state-' + item.connectionState} src='' />
                <img src={imagen} />
                <span>
                  {item.productType.letter}
                  {item.tag}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  activeItemsRender(item, i) {
    var estado = 'disconnectedSquare';

    if (item.connectionState != null) {
      estado = item.connectionState + 'Square';
    }
    let imagen = item.productType.iconUri;
    if (item.productType.iconUri != undefined) {
      imagen = item.productType.iconUri.replace('black', 'white');
    }

    let x = item.floorPlanX;
    let y = item.floorPlanY;
    let py = (y * this.imageH) / 100;

    let transform = 'translate( ' + (x * this.imageW) / 100 + 'px , ' + py + 'px ) ';

    return (
      <div
        unplaced='false'
        key={i}
        name={item.id}
        id='drop'
        onClick={() => this.navigateItemDetail(item.id)}
        className={this.state.editingLayout ? 'drag-drop can-drop' : 'not-drag-drop can-drop'}
        onMouseOver={this.onMouseOverItem}
        onMouseLeave={this.onMouseLeaveItem}
        style={{ position: 'absolute', transform: transform }}
        data-id={item.id}
        data-puuid={item.puuid}
        data-name={item.productType.name}
        data-description={item.name}
        data-image={item.productType.imageUri}
        data-state={item.connectionState}
        data-designline={
          item.designLine == null ? item.productType.name : item.designLine + ' - ' + item.productType.letter + item.tag
        }
        data-ref={item.productType.sku}
        data-tagid={item.tag}
        data-warranty={item.warranty}
        data-lastdata={item.lastProcessedValue}
        data-lasttimeseen={item.lastTimeSeen}
        data-x={(x * this.imageW) / 100}
        data-y={py}
        data-x-percent={x}
        data-y-percent={y}
      >
        <div className='prodInstallationManagment centerObj'>
          <div className={estado}>
            <div className={'stateDiv' + ' prdEventId-' + item.id}>
              <div>
                <img className={'state-' + item.connectionState} src='' />
                <img src={imagen} />
                <span>
                  {item.productType.letter}
                  {item.tag}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  async rotateImage(degrees) {
    try {
      this.http = new InstallationsHttpClient(this.context, this.id);
      this.setState({ disableRotation: true });
      let response = await this.http.rotateInstallationPlan(degrees);
      if (!response.errors) {
        this.setState({ layoutImage: response.content, disableRotation: false });
      } else {
        this.setState({ disableRotation: false });
      }
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    const { t } = this.props;
    const handleClickEdit = (e) => {
      this.saveDroppedElements();
    };

    const handleClickSave = (e) => {
      this.setState({ editingLayout: true });
    };

    const handleImageChange = (e) => {
      e.preventDefault();

      let reader = new FileReader();
      let file = e.target.files[0];

      if (file != null) {
        let fileSizeInMB = file.size / (1024 * 1024); // Size in mb
        var extension = file.name.split('.').pop().toLowerCase();
        if (this.fileTypes.indexOf(extension) == -1) {
          this.renderMessage('error', 'Extention ' + extension + ' not accepted.');
        } else if (fileSizeInMB > 2) {
          const fileSizeLimitMB = 2; // Size limit
          this.renderMessage('error', t('messages.errorSizeFile', { size: `${fileSizeLimitMB}mb` }));
        } else {
          reader.onloadend = () => {
            this.setState({
              imageFile: file,
              layoutImage: reader.result,
              status: 'change',
            });

            this.uploadFile(file);
          };
          reader.readAsDataURL(file);
        }
      }
    };

    const btnCancelEdit = () => {
      this.setDraggableItems([], null, null, true, false);
      setTimeout(() => {
        this.setDraggableItems(this.initialItems, null, null, true, false);
      }, 1);
    };

    const btnImageChange = () => {
      document.getElementById('modal-inputLayout').click();
    };

    const btnImageRemove = () => {
      this.setState({ openConfirmDelete: true });
    };

    const rotateImageLeft = () => {
      this.rotateImage(270);
    };
    const rotateImageRight = () => {
      this.rotateImage(90);
    };

    function tooltip(text) {
      let t = text.toLowerCase();
      return <Tooltip style={{ textTransform: 'capitalize' }}>{t}</Tooltip>;
    }

    const handleClose = () => {
      this.setState({ showEditModal: false });
    };
    const handleCloseInvitation = () => {
      this.setState({ showInvitation: false });
    };
    let dataLoadedRole = this.state.roles != undefined;

    return (
      <React.Fragment>
        <div style={{ margin: '10px' }}>
          <div
            id='parentCanvas'
            style={{ margin: '0 auto', marginBottom: '20px' }}
            className='row canvasSize canvaMapCustomSelector zoom-canvaMapCustomSelector'>
              {this.state.loading && <Loader />}
            <div className='col-12' style={{ padding: '0' }}>
              <div id='modal-drop-zone-container' className='canvasSize canvasContainer' style={{ padding: 0, margin: 0 }}>
                {this.state.notActiveItems.map((item, i) => this.notActiveItemsRender(item, i))}
                {this.state.activeItems.map((item, i) => this.activeItemsRender(item, i))}
                <canvas
                  id='modal-inner-dropzone'
                  className='canvasSize dropzone'
                  style={{
                    backgroundImage: "url('" + this.state.layoutImage + "')",
                    // width: 645,
                    // height: 433,
                    // backgroundSize: 'contain'
                  }}
                ></canvas>
                <div style={{ borderBottom: '1px solid #a4a4a4' }}></div>
                {!this.state.editingLayout ? (
                  <div
                    className='layoutConfigButtons'
                    style={{
                      margin: 0,
                      padding: 0,
                      justifyContent: 'end',
                    }}
                  >
                    <Whisper
                      placement='bottom'
                      trigger='hover'
                      delayOpen={500}
                      speaker={tooltip(t('actions.editname', { name: t('installationspages.layout') }))}
                    >
                      <Button className='managmentBtnClass float-right' onClick={handleClickSave}>
                        <EditIcon />
                      </Button>
                    </Whisper>
                  </div>
                ) : null}
                <canvas
                  id='modal-lower-dropzone'
                  className='dropzone'
                  style={{
                    width: '100%',
                    height: '18px',
                  }}
                ></canvas>
              </div>
              <div style={{ float: 'left', width: 'auto' }}></div>
            </div>
          </div>

          <div className='col' style={{ display: 'flex', padding: 0 }}>
            {this.state.editingLayout ? (
              <div
                className='layoutConfigButtons col row'
                style={{ margin: 0, marginTop: '24px', padding: 0, justifyContent: 'space-between' }}
              >
                <div className='col-auto' style={{ margin: 0, padding: 0 }}>
                  <Whisper
                    placement='bottom'
                    trigger='hover'
                    delayOpen={500}
                    speaker={tooltip(t('installationspages.installationsummaryedit.uploadimage'))}
                  >
                    <Button className='managmentBtnClass' onClick={btnImageChange}>
                      <PublishIcon />
                    </Button>
                  </Whisper>
                  <input type='file' id='modal-inputLayout' style={{ display: 'none' }} onChange={handleImageChange} />
                  <Whisper
                    placement='bottom'
                    trigger='hover'
                    delayOpen={500}
                    speaker={tooltip(t('actions.deletename', { name: t('general.floorplan') }))}
                  >
                    <Button
                      className='managmentBtnClass'
                      onClick={btnImageRemove}
                      disabled={!this.state.layoutImage || this.state.layoutImage == ''}
                    >
                      <DeleteOutlineIcon />
                    </Button>
                  </Whisper>
                </div>
                <div className='col-auto' style={{ margin: 0, padding: 0 }}>
                  <Whisper placement='bottom' trigger='hover' delayOpen={500} speaker={tooltip(t('actions.rotate-right'))}>
                    <Button
                      className='managmentBtnClass'
                      style={{ minWidth: 0 }}
                      onClick={rotateImageRight}
                      disabled={!this.state.layoutImage || this.state.layoutImage == '' || this.state.disableRotation}
                    >
                      <RotateRightIcon />
                    </Button>
                  </Whisper>
                  <Whisper placement='bottom' trigger='hover' delayOpen={500} speaker={tooltip(t('actions.rotate-left'))}>
                    <Button
                      className='managmentBtnClass'
                      style={{ minWidth: 0 }}
                      onClick={rotateImageLeft}
                      disabled={!this.state.layoutImage || this.state.layoutImage == '' || this.state.disableRotation}
                    >
                      <RotateLeftIcon />
                    </Button>
                  </Whisper>
                </div>
                <div className='col-auto' style={{ margin: 0, padding: 0 }}>
                  <Whisper
                    placement='bottom'
                    trigger='hover'
                    delayOpen={500}
                    speaker={tooltip(t('actions.savename', { name: t('installationspages.layout') }))}
                  >
                    <Button className='managmentBtnClass' onClick={handleClickEdit}>
                      <CheckIcon />
                    </Button>
                  </Whisper>
                  <Whisper placement='bottom' trigger='hover' delayOpen={500} speaker={tooltip(t('actions.cancel'))}>
                    <Button className='managmentBtnClass' onClick={btnCancelEdit}>
                      <ClearIcon />
                    </Button>
                  </Whisper>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {this.dialogConfirmDelete()}
      </React.Fragment>
    );
  }
}
export default withTranslation()(withRouter(ZoomInstallationsLayout));
